/**
 * @fileoverview Primary navigation component that handles both desktop and mobile navigation
 * @module features/primaryNavigation/primaryNavigationNew
 */
import React, { FunctionComponent, useState } from 'react';
import { PrimaryNavigation, ResponsiveMenu } from 'basecamp';
import { useSubjectsNavLinks } from '../../routes/hooks';

/**
 * @typedef {Object} Props Empty props object as component doesn't accept any props
 */
type Props = {};

/**
 * Primary navigation component that displays navigation links and handles responsive behavior
 * @component
 * @param {Props} props - Component props (empty object)
 * @returns {JSX.Element} The rendered navigation component with responsive menu
 */
const SubjectsNavigtion: FunctionComponent<Props> = () => {
	/**
	 * State to manage the small screen menu
	 */
	const [smallScreenMenuIsOpen, setSmallScreenMenuIsOpen] = useState<boolean>(false);

	/**
	 * Get menu configurations for different screen sizes
	 * @type {{largeScreenMenu: JSX.Element[], smallScreenMenu: JSX.Element[]}}
	 */
	const { largeScreenMenu, smallScreenMenu } = useSubjectsNavLinks();

	// Navigation z-index should be set in the 9000 range to ensure it is above all other components
	return (
		<PrimaryNavigation setAs="secondary" zIndex={9000} width={1}>
			<ResponsiveMenu
				largeScreenMenu={largeScreenMenu}
				smallScreenMenu={smallScreenMenu}
				setSmallScreenMenuIsOpen={setSmallScreenMenuIsOpen}
				smallScreenMenuIsOpen={smallScreenMenuIsOpen}
			/>
		</PrimaryNavigation>
	);
};

/**
 * Memoized version of PrimaryNav component to prevent unnecessary re-renders
 */
export default React.memo(SubjectsNavigtion);
