/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useMonitoringAccuracyNavLinks = () => {
	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const monitoringAccuracyNavLinks = [
		{
			name: 'monitoringAccuracyDifference',
			width: 110,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-monitoring-accuracy-difference-link`}
					withInternalUrl={`/monitoring/difference`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.monitoring.DIFFERENCE')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'monitoringAccuracyVariance',
			width: 110,
			isVisible: true,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-monitoring-accuracy-variance-link`}
					withInternalUrl={`/monitoring/variance`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.monitoring.VARIANCE')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'monitoringAccuracyExamVsMonitoring',
			width: 110,
			isVisible: true,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-monitoring-accuracy-exam-vs-monitoring-link`}
					withInternalUrl={`/monitoring/exam-vs-monitoring`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.monitoring.EXAM_VS')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses subjectsNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(
		monitoringAccuracyNavLinks,
		width,
		0,
		[]
	);

	return { largeScreenMenu, smallScreenMenu };
};

export default useMonitoringAccuracyNavLinks;
