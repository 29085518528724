import { totalActualPoints } from '../../../../utils/outcomeSet';
import { getMatchedPercentageForOutcomeGroups } from '../../../../utils/outcomeSet';
import {
	filterStudentsByUdfType,
	sortComparisonStudentViewGroups,
	VASCalculationDetails,
} from '../../../../utils/studentGroup';

import {
	getAverageGCSEPoints,
	getAveragePA,
	groupStudentsByViewGroups,
} from '../../../../utils/studentGroup';
import {
	getPointsForOutcomes,
	getTotalStudentsForOutcomes,
	getTotalPointsInOutcome,
	getTotalChangesForOutcomes,
} from '../../../../utils/outcome';
import { translate } from '../../../../utils/locale';
import { ColDef } from '@ag-grid-enterprise/all-modules';
import { getAvailableSubjectComparisons, getClientCountry } from 'features/app/redux/context';
import { CountryEnum, ExamLevels } from '../../../../types/enum';
import padLeft from '../../../../utils/padLeft';
import { convertGCSEToQCA } from '../../../../utils/convert';
import { getComparisonsForSubjects } from 'features/analysisSettings/redux';
import { SubjectPage } from 'features/subjectPage/types';

/**
 ** Returns the amount of students in each band range as a number
 ** @param targetBandId - the unique identifier for the band
 ** @param students - this list of students to check against the band
 */
export const totalStudentsInBand = (targetBandId: number, students: any): number => {
	return students.filter((s: any) => {
		return s.TargetBandId === targetBandId && !s.IsQGrade && s.IsIncluded;
	}).length;
};

/**
 ** Returns the dataset required to display the student bands grid e.g:
 ** | Band         | Students | x | Target | = | Expected Points |
 ** | 7.75         | 1        | x | 124    | = | 124             |
 ** | 7.00 -< 7.75 | 0        | x | 109.82 | = | 109.82          |
 ** @param targetBandId - the unique identifier for the band
 ** @param students - this list of students to check against the band
 */
export const getOutcomeBandsForFilteredStudentsInSubject = (
	filteredStudents: SubjectPage.Student[],
	subjectPage: any,
	country: CountryEnum
): any => {
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];
	const isKS5 = selectedSubject.ExamLevel !== ExamLevels.KS4;

	if (!data || !selectedSubject) {
		return [];
	}

	return selectedSubject.TargetSet.map((set: any, index, arr) => {
		const fromBand = convertGCSEToQCA(Number(set.From), { country });

		const gcsePoints =
			index === arr.length - 1
				? `${fromBand} +`
				: `${fromBand} -< ${convertGCSEToQCA(Number(set.To), { country })}`;

		return {
			band: isTopBand(selectedSubject.TargetSet, set) ? set.From + '+' : set.Display,
			target: set.Target.toFixed(2),
			multiply: 'x',
			equals: '=',
			students: totalStudentsInBand(set.Id, filteredStudents),
			expectedPoints: (set.Target * totalStudentsInBand(set.Id, filteredStudents)).toFixed(2),
			gcsePoints: isKS5 && country !== CountryEnum.England ? gcsePoints : undefined,
		};
	}).reverse(); // reverse the order of the data so that the largest band is at the top
};

const isTopBand = (targetSet: SubjectPage.TargetSet[], set: SubjectPage.TargetSet): boolean => {
	const tS = targetSet[targetSet.length - 1];
	if (tS.Id === set.Id) {
		// check it doesn't already have a plus (GCSE Grades come preformatted)
		return tS.Display.includes('+') ? false : true;
	}

	return false;
};

/**
 ** Returns the dataset required to display the student outcomes grid e.g:
 ** | Students | A   | A*  | B   | C  | D  | E  | U | X | Q | A* > E % | A* > C% | A* > B% | Avg PA |
 ** | 4        | 0   | 3   | 1   | 0  | 0  | 0  | 0 | 0 | 0 | 100      | 83.3%   | 66.6%   | 4.80   |
 ** | Points   | 140 | 120 | 100 | 80 | 60 | 40 | 0 | 0 | 0 |            Total Actual Points        |
 ** | Total    | 0   | 360 | 100 | 0  | 0  | 0  | 0 | 0 | 0 |                  460                  |
 ** @param targetBandId - the unique identifier for the band
 ** @param students - this list of students to check against the band
 */
export const formatOutcomesData = (
	element: string,
	students: SubjectPage.Student[],
	outcomes: any,
	outcomeGroups: any,
	country: CountryEnum,
	whatIf: boolean = false
) => {
	const totalActualPointsResult = totalActualPoints(
		{ outcomeGroups: outcomeGroups, outcomes: outcomes },
		students,
		whatIf
	);
	const arr = [
		{
			// Creates this row of the grid:
			// | Students | A   | A*  | B   | C  | D  | E  | U | X | Q | A* > E % | A* > C% | A* > B% | Avg PA |
			// | 4        | 0   | 3   | 1   | 0  | 0  | 0  | 0 | 0 | 0 | 100      | 100%    | 100%    | 4.80
			students: students.length,
			totalActual: totalActualPointsResult,
			AvgPa: getAveragePA(students).toFixed(1),
			AvgGCSEPoints:
				country === CountryEnum.England ? undefined : padLeft(getAverageGCSEPoints(students), 1),
			...getTotalStudentsForOutcomes(outcomes, students, whatIf),
			...getMatchedPercentageForOutcomeGroups(outcomeGroups, students, whatIf),
		},
		...(whatIf
			? [
					{
						// Creates this row of the grid:
						// | Changes    | 0   | 3 | 2 | 0  | 0  | 0  | 0 | 0 | 0 |
						students: translate('subjectPage.agGridOutcomes.rowDefs.CHANGES'),
						totalActual: totalActualPointsResult,
						...getTotalChangesForOutcomes(outcomes, students, whatIf),
					},
			  ]
			: []),
		{
			// Creates this row of the grid:
			// | Points   | 140 | 120 | 100 | 80 | 60 | 40 | 0 | 0 | 0 |
			students: translate('subjectPage.agGridOutcomes.rowDefs.POINTS'),
			totalActual: totalActualPointsResult,
			...getPointsForOutcomes(outcomes),
		},
		{
			// Creates this row of the grid:
			// | Total    | 0   | 360 | 100 | 0  | 0  | 0  | 0 | 0 | 0 |
			students: translate('subjectPage.agGridOutcomes.rowDefs.TOTAL'),
			totalActual: totalActualPointsResult,
			...getTotalPointsInOutcome(outcomes, students, whatIf),
		},
	];

	return {
		// Element is the grouping e.g. Female, All, Unknown, Disadvantaged
		[element]: arr,
	};
};

/**
 *  Returns the priorAchievmentType for the current subject
 */
export const getPriorAchievementTypeForSubject = ({ subjectPage }: RootState): any => {
	const data: SubjectPage.Data = subjectPage.data;
	const priorAchievmentType = data && data[subjectPage.currentSubjectId]?.PriorAchievmentType;

	if (!data || !priorAchievmentType) {
		return 0;
	}
	return priorAchievmentType;
};

/**
 * Returns the column definitions for the outcomes grid (the smaller one)
 * as these can differ between subject/level e.g
 * | A* | B | C | D | E | U | X | Q | A* - E | A* - C | A* - B
 */
export const getOutcomeColDefsForSubject = ({ subjectPage }: RootState): any => {
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return [];
	}

	// Returns the outcomes part of the column definition e.g. | A* | A | B | C | D | E | U | Q |
	const outcomes = selectedSubject.Outcomes;
	const outcomeColDefs = outcomes.map((outcome: SubjectPage.Outcomes) => {
		return {
			headerName: outcome.Outcome.Display,
			field: outcome.Outcome.Display,
			minWidth: 80,
			flex: 1,
			cellStyle: { textAlign: 'center' },
		} as ColDef;
	});

	// Returns the group part of the column definition e.g. | A* - E | A* - C | A* - B |
	const outcomeGroups = selectedSubject.OutcomeGroups;
	const outcomeGroupColDefs = outcomeGroups.map((outcomeGroup: SubjectPage.OutcomeGroups) => {
		const formatString = outcomeGroup.Group.replace('>', '-') + '%';
		return {
			headerName: formatString,
			field: outcomeGroup.Group,
			flex: 1,
			cellStyle: { textAlign: 'center' },
			minWidth: 100,
		} as ColDef;
	});

	return { outcomeColDefs: outcomeColDefs, outcomeGroupColDefs: outcomeGroupColDefs };
};

export const getUdfTypeAndValue = (state: RootState): { type: string; value: string } => {
	return {
		type: state.subjectPage.currentSubjectUdfType,
		value: state.subjectPage.currentSubjectUdfValue,
	};
};

export const getAdhocOutcomesForSubject = (state: RootState): any => {
	const { subjectPage } = state;
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!selectedSubject || !selectedSubject.Students) {
		return {};
	}

	const adHoc = subjectPage.adHoc;
	const country = getClientCountry(state);

	// Get the udf type / value
	const udftype: string = subjectPage.currentSubjectUdfType;
	const udfvalue: string = subjectPage.currentSubjectUdfValue;
	const outcomes = selectedSubject.Outcomes;

	const filteredStudents = filterStudentsByUdfType(selectedSubject.Students, udftype, udfvalue);
	if (filteredStudents && adHoc) {
		/**
		 * Generates
		 * purple: { StudentId ...}, { StudentId ...}
		 */
		let studentsByAdhocGroup = filteredStudents.reduce((acc, curr) => {
			const adhocGroup = adHoc && curr.AdHocGroup;

			if (adhocGroup) {
				return {
					...acc,
					[adhocGroup]: [...(acc[adhocGroup] || []), curr],
				};
			}
		}, {});

		let studentGroupOutcomes = getOutcomesForSubject(state);

		let adhocOutcomes = Object.entries(studentsByAdhocGroup).reduce(
			(preValue, currVal) => {
				const adhocGroup = currVal[0];
				const groupStudents = currVal[1];

				// get the outcome bands for the subject
				let outcomeBands = {
					[adhocGroup]: getOutcomeBandsForFilteredStudentsInSubject(
						groupStudents,
						subjectPage,
						country
					),
				};

				preValue.OutcomeBands = { ...preValue.OutcomeBands, ...outcomeBands };

				let outcomesForStudents = formatOutcomesData(
					adhocGroup,
					groupStudents,
					outcomes,
					selectedSubject.OutcomeGroups,
					country
				);

				preValue.OutcomesForStudents = { ...preValue.OutcomesForStudents, ...outcomesForStudents };

				if (subjectPage.whatIf) {
					let whatIfOutcomes = formatOutcomesData(
						adhocGroup,
						groupStudents,
						outcomes,
						selectedSubject.OutcomeGroups,
						country,
						subjectPage.whatIf
					);

					preValue.WhatIfOutcomes = { ...preValue.WhatIfOutcomes, ...whatIfOutcomes };
				}

				return preValue;
			},
			{
				OutcomeBands: [] as any,
				OutcomesForStudents: [] as any,
				WhatIfOutcomes: [] as any,
			}
		);

		return {
			OutcomeBands: { ...adhocOutcomes.OutcomeBands, ...studentGroupOutcomes.OutcomeBands },
			OutcomesForStudents: {
				...adhocOutcomes.OutcomesForStudents,
				...studentGroupOutcomes.OutcomesForStudents,
			},
			WhatIfOutcomes: { ...adhocOutcomes.WhatIfOutcomes, ...studentGroupOutcomes.WhatIfOutcomes },
		};
	}
};

export const getOutcomesForSubject = (state: RootState): any => {
	const { subjectPage, context } = state;
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];
	const country = getClientCountry(state);

	if (!data || !selectedSubject) {
		return [];
	}

	/**
	 * Get the udf type & value and format
	 */
	const udfType = subjectPage.currentSubjectUdfType;
	const udfValue = subjectPage.currentSubjectUdfValue;

	/**
	 * Get all students and filter if we have a UDF applied
	 */

	const students = filterStudentsByUdfType(
		data[subjectPage.currentSubjectId].Students,
		udfType,
		udfValue
	);
	/**
	 * Get outcomes and applied comparisons
	 */
	const outcomes = selectedSubject.Outcomes;

	const appliedComparisons = context.appliedComparisons;
	const customComparisons = getComparisonsForSubjects(state);
	const availableComparisons = getAvailableSubjectComparisons(state);

	// go through the comparisions/filters that have been applied and find the matching item from the custom comparisons
	const customAppliedComparisons = appliedComparisons.filter(
		(appliedComparison: any) => customComparisons[appliedComparison.value]
	);

	const studentViewGroups = groupStudentsByViewGroups(students, customAppliedComparisons);

	const sortedStudents = sortComparisonStudentViewGroups(
		availableComparisons,
		studentViewGroups,
		appliedComparisons,
		customComparisons
	);

	return Object.entries(sortedStudents).reduce(
		(preValue, currVal) => {
			let label = currVal[0];
			let filteredStudents = currVal[1].filter(
				(student) => student.IsIncluded // Q grades are included here
			);

			if (filteredStudents) {
				let outcomeBands = {
					[label]: getOutcomeBandsForFilteredStudentsInSubject(
						filteredStudents,
						subjectPage,
						country
					),
				};
				preValue.OutcomeBands = { ...preValue.OutcomeBands, ...outcomeBands };

				let outcomesForStudents = formatOutcomesData(
					label,
					filteredStudents,
					outcomes,
					selectedSubject.OutcomeGroups,
					country
				);

				preValue.OutcomesForStudents = {
					...preValue.OutcomesForStudents,
					...outcomesForStudents,
				};

				// if what if is turned on, create the data for the what if outcomes grid
				if (subjectPage.whatIf) {
					let whatIfOutcomes = formatOutcomesData(
						label,
						filteredStudents,
						outcomes,
						selectedSubject.OutcomeGroups,
						country,
						subjectPage.whatIf
					);

					preValue.WhatIfOutcomes = { ...preValue.OutcomesForStudents, ...whatIfOutcomes };
				}
			}

			return preValue;
		},
		{
			OutcomeBands: [] as any,
			OutcomesForStudents: [] as any,
			WhatIfOutcomes: [] as any,
		}
	);
};

/**
 * Returns the VAS information for all student groups within a subject e.g:
 * {
 * 	orange: {
 *		score: 1.22,
 *		grade: 1,
 *		totalExpectedPoints: 600,
 *		totalActualPoints: 430,
 *		studentCount: 6,
 *		multiplier: 100
 *	},
 *   purple: {
 * 		score: 1.22,
 *		grade: 1,
 *		totalExpectedPoints: 600,
 *		totalActualPoints: 430,
 *		studentCount: 6,
 *		multiplier: 100
 *  }
 * }
 */
function calculateVASInformationForAdHocStudentGroups(
	selectedSubject: any,
	whatIf: boolean = false,
	subjectPage: SubjectPage.Data
) {
	const {
		Id,
		PercentileSet,
		OutcomeGroups,
		Outcomes,
		TargetSet,
		VAMultiplier,
		Students,
	} = selectedSubject;

	let subjectInformation = {
		displayName: Id,
		percentileSet: PercentileSet,
		outcomeSet: {
			outcomes: Outcomes,
			outcomeGroups: OutcomeGroups,
		},
		targetSet: TargetSet,
		entryMultiplier: VAMultiplier,
	};

	const adHoc = true;
	let includedStudents = Object.values(Students).filter(
		(student) => student.IsIncluded && !student.IsQGrade
	);

	/**
	 * Generates
	 * purple: { StudentId ...}, { StudentId ...}
	 */
	const studentsGroupedByAdhoc = includedStudents.reduce((acc, curr) => {
		let adhocGroup = adHoc && curr.AdHocGroup;
		if (adhocGroup) {
			if (subjectPage != null && subjectPage.currentSubjectUdfType === 'Teaching Set') {
				return {
					...acc,
					[adhocGroup]: filterStudentsByUdfType(
						[...(acc[adhocGroup] || []), curr],
						subjectPage.currentSubjectUdfType,
						subjectPage.currentSubjectUdfValue
					),
				};
			} else {
				return {
					...acc,
					[adhocGroup]: [...(acc[adhocGroup] || []), curr],
				};
			}
		}
	}, {});

	return Object.entries(studentsGroupedByAdhoc).reduce((preVal, curVal) => {
		return {
			...preVal,
			[curVal[0]]: VASCalculationDetails(curVal[1], subjectInformation, whatIf),
		};
	}, []);
}

/**
 * Returns the VAS information for all student groups within a subject e.g:
 * {
 * 	All: {
 *		score: 1.22,
 *		grade: 1,
 *		totalExpectedPoints: 600,
 *		totalActualPoints: 430,
 *		studentCount: 6,
 *		multiplier: 100
 *	},
 *   Female: {
 * 		score: 1.22,
 *		grade: 1,
 *		totalExpectedPoints: 600,
 *		totalActualPoints: 430,
 *		studentCount: 6,
 *		multiplier: 100
 *  }
 * }
 */

export const getVASInformationForAllStudentGroupsInSubject = (state: RootState): any => {
	const { subjectPage, context } = state;
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return [];
	}

	const appliedComparisons = context.appliedComparisons;
	const customComparisons = getComparisonsForSubjects(state);

	// go through the comparisions/filters that have been applied and find the matching item from the custom comparisons
	const customAppliedComparisons = appliedComparisons.filter(
		(appliedComparison: any) => customComparisons[appliedComparison.value]
	);

	if (subjectPage.adHoc) {
		let vasForAdhocGroups = calculateVASInformationForAdHocStudentGroups(
			selectedSubject,
			false,
			subjectPage
		);
		let vasForStudentGroups = calculateVASInformationForAllStudentGroupsInSubject(
			selectedSubject,
			appliedComparisons,
			false,
			subjectPage
		);
		return { ...vasForAdhocGroups, ...vasForStudentGroups };
	}

	return calculateVASInformationForAllStudentGroupsInSubject(
		selectedSubject,
		customAppliedComparisons,
		false,
		subjectPage
	);
};

function calculateVASInformationForAllStudentGroupsInSubject(
	selectedSubject: any,
	appliedComparisons: any,
	whatIf: boolean = false,
	subjectPage: SubjectPage.Data
) {
	const {
		Id,
		PercentileSet,
		OutcomeGroups,
		Outcomes,
		TargetSet,
		VAMultiplier,
		Students,
	} = selectedSubject;

	let includedStudents = Object.values(Students).filter(
		(student) => student.IsIncluded && !student.IsQGrade
	);

	if (subjectPage != null && subjectPage.currentSubjectUdfType === 'Teaching Set') {
		includedStudents = filterStudentsByUdfType(
			includedStudents,
			subjectPage.currentSubjectUdfType,
			subjectPage.currentSubjectUdfValue
		);
	}
	let subjectInformation = {
		displayName: Id,
		percentileSet: PercentileSet,
		outcomeSet: {
			outcomes: Outcomes,
			outcomeGroups: OutcomeGroups,
		},
		targetSet: TargetSet,
		entryMultiplier: VAMultiplier,
	};

	// Even if there are no comparisons, this function will return the "All" group
	let studentsGroupedByComparisons = groupStudentsByViewGroups(
		Object.values(includedStudents),
		appliedComparisons
	);

	return Object.entries(studentsGroupedByComparisons).reduce((preVal, currVal) => {
		const label = currVal[0];
		const students = currVal[1];
		return {
			...preVal,
			[label]: VASCalculationDetails(students, subjectInformation, whatIf),
		};
	}, []);
}

export const getWhatIfVASInformationForAllStudentGroupsInSubject = (state: RootState): any => {
	const { subjectPage, context } = state;
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return [];
	}

	const appliedComparisons = context.appliedComparisons;

	if (subjectPage.adHoc) {
		let vasForAdhocGroups = calculateVASInformationForAdHocStudentGroups(
			selectedSubject,
			subjectPage.whatIf,
			subjectPage
		);
		let vasForStudentGroups = calculateVASInformationForAllStudentGroupsInSubject(
			selectedSubject,
			appliedComparisons,
			subjectPage.whatIf,
			subjectPage
		);
		return { ...vasForAdhocGroups, ...vasForStudentGroups };
	}

	return calculateVASInformationForAllStudentGroupsInSubject(
		selectedSubject,
		appliedComparisons,
		subjectPage.whatIf,
		subjectPage
	);
};
