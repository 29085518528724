import { SagaIterator } from 'redux-saga';
import {
	fetchRoleRequestConfirmation,
	setCensusAlpsRoleData,
	setCensusModalLoading,
	setCensusQuestionsResults,
	setConfirmationOfCensusData,
	setConfirmationOfRoleRequest,
	setIsSchoolMatChild,
	setShowCensusModal,
} from '../users';
import { cancel, put, select, retry, call } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../constants';
import {
	addPendingStatusToCensusAudit,
	fetchAlpsRoles,
	getCensusContext,
	getIsSchoolMatForCensusContext,
} from '../../../../api/contextAPI';
import { Census } from 'features/manageUsers/types';
import { CensusAuditStatus } from '../../../../types/enum';
import { setIsCensusInProgress } from 'features/app/redux/context';
import { getCurrentSubscriptionAgreement } from 'features/manageAccount/redux';
import { getConnectPage } from '../../../../utils/sagaHelpers';
import { loading } from 'features/selectOrganisation/redux/switch';

/**
 * Saga controls the Users joureny through the Alps Census
 */
export function* completeCensusFlow(): SagaIterator<void> {
	//confirm if a request for the Alps roles has been made
	const confirmationOfRequest: boolean = yield select(fetchRoleRequestConfirmation);

	//switch org loading
	const switchOrganisationLoading: boolean = yield select(loading);

	//Check the current page
	const connectPage: string = yield call(getConnectPage);

	//If someone has used the back button to nav out of the Census we need to bail
	//This saga ONLY wants to run on the census/additional information page
	if (
		connectPage === 'change-password' ||
		switchOrganisationLoading ||
		(connectPage === 'home' && confirmationOfRequest)
	) {
		//hide the option to manually start the census set it to pending
		yield put(setIsCensusInProgress(CensusAuditStatus.Pending));
		//Magically close the census modal
		yield put(setShowCensusModal(false));
		//bail
		yield cancel();
	}

	//Get the Subscription Agreement info
	//Will not be undefined at this point
	const agreements: ManageAccount.SubscriptionAgreement[] = yield select(
		getCurrentSubscriptionAgreement
	);

	//set the internal modal loading
	yield put(setCensusModalLoading(true));

	//incase some one has switched schools and wants to re input data
	yield put(setCensusQuestionsResults(undefined));

	//Get the Current status of the Census Audit
	const currentStatusOfCensus: ResponseBuilder<Census.AlpsCensusStatus> = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getCensusContext
	);

	//someone else has started the census just before the current user
	if (currentStatusOfCensus.ResponseObject.status === CensusAuditStatus.Pending) {
		yield cancel();
	}

	//Check the subs to see if there is any contract
	if (agreements.some((agreement) => agreement.isContracted)) {
		//call api to check client
		const response = yield retry(
			sagaFlowConfigurations.apiRetry,
			sagaFlowConfigurations.milliSeconds,
			getIsSchoolMatForCensusContext
		);

		//push the result to state for use
		yield put(setIsSchoolMatChild(response.ResponseObject));
	}

	//set Confirmation of Data to false (this is the first time through loop)
	yield put(setConfirmationOfCensusData(false));

	//Magically open the census modal
	yield put(setShowCensusModal(true));

	//Dispatch a new Pending status to the Census Audit it here so we dont dispatch loads to the DB
	//This will block other uses from filling the census in at the same time
	yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		addPendingStatusToCensusAudit
	);

	//Check to see if the client has any Alps Roles saved
	//This will return an empty array if not
	const response = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		fetchAlpsRoles
	);
	//Add the response to State
	yield put(setCensusAlpsRoleData(response));

	//Confirm we have now made the request
	yield put(setConfirmationOfRoleRequest(true));

	//Set loading to false
	yield put(setCensusModalLoading(false));

	//bail
	yield cancel();
}
