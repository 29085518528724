/**
 * @fileoverview Component that displays an indicator and menu for active strategic analysis sessions
 * @module features/layouts/standardPage/components/strategicSessionIndicator
 */

import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, ButtonMenu } from 'basecamp';
import {
	getAppliedDefaultExamLevels,
	showStrategicSessionModal,
} from 'features/strategicAnalysis/redux';
import levels from '../../../../utils/examLevel';
import { translate } from '../../../../utils/locale';
import { useHistory } from 'react-router-dom';
import { styles } from './common';

/**
 * @typedef {Object} Props
 * @property {boolean} isSmallScreen - Whether the screen is small
 */
type Props = { isSmallScreen: boolean; setSmallScreenMenuIsOpen: (isOpen: boolean) => void };

/**
 * Component that renders a button menu for managing strategic analysis sessions
 * Only displays when there is an active session and user has permission
 * @returns {JSX.Element|null} ButtonMenu component or null if no active session
 */
const StrategicSessionIndicator: FunctionComponent<Props> = ({
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	/**
	 * React Router history object for navigation
	 */
	const history = useHistory();

	/**
	 * Redux hooks and selectors
	 */
	const dispatch = useDispatch();
	const getExamLevels = useSelector(getAppliedDefaultExamLevels).map((level) => level.value);

	/**
	 * Formats the exam levels into a display string
	 * @example ['GCSE', 'A-Level'] becomes 'GCSE & A-Level'
	 */
	const examLevels = getExamLevels.map((examLevel) => {
		return levels[examLevel as keyof typeof levels].display;
	});
	const sessionType = [examLevels.slice(0, -1).join(', '), examLevels.slice(-1)[0]].join(
		examLevels.length < 2 ? '' : ' & '
	);

	return (
		<Box {...styles(isSmallScreen)}>
			<ButtonMenu
				setAs="tertiary"
				menuContent={[
					{
						buttonText: 'Go to Session',
						setAs: 'success',
						onClick: () => {
							history.push('/strategic-analysis');
						},
					},
					{
						buttonText: 'Stop Session',
						setAs: 'error',
						onClick: () => {
							dispatch(showStrategicSessionModal());
							setSmallScreenMenuIsOpen(false);
						},
					},
				]}
				triggerText={translate('strategicAnalysis.standardPage.SESSION', { sessionType })}
			/>
		</Box>
	);
};

export default StrategicSessionIndicator;
