/**
 * Gets the redux slice for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns slice for the active page
 */
//TODO: Add a generic stat return type
export const getSlice = (page: string) => (state: RootState) => {
	const slice = page as keyof RootState;

	return state[slice];
};

/** Export Excel Flag Selector
 * Gets the exportExcel flag for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns exportExcel flag for the active page
 */
export const exportExcel = (page: string) => (state: RootState) => {
	const slice = getSlice(page)(state);

	//@ts-ignore
	return slice.exportExcel;
};
