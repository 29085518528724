import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useAgGrid } from '../../../agGrid/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { Box } from 'basecamp';
import { GridHeader } from '.';
import { AgGridReactProps } from '@ag-grid-community/react';
import { createDefaultGridOptions } from '../../../agGrid';
import { useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { studentScoreSort, megSort } from '../../../utils/performanceMeasures';
import { IconRenderer } from '../../../agGrid/cellRenderers';

import { ColDef } from '@ag-grid-community/all-modules';

type Props = {
	isMultiGrid?: boolean;
	data?: {
		metrics: any;
		tableName: string;
	};
};

const AgGridP8: FunctionComponent<Props> = ({ isMultiGrid = false, data }) => {
	/**
	 * Handle window size
	 * Used for pinning / un-pinning columns on smaller screens
	 */
	const { width } = useWindowSize();

	/**
	 ** Handle  Ag Grid Api
	 */
	const gridOptions = useAgGrid();

	/**
	 * Col Defs
	 */
	const coldefs: ColDef[] = [
		{
			headerName: translate('spo.progress8.agGrid.coldefs.STUDENT_ID') as string,
			field: 'student-id',
			pinned: 'left',
			hide: true,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.STUDENT_NAME') as string,
			field: 'student-name',
			pinned: 'left',
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.OVERALL_P8') as string,
			field: 'Overall-P8',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.ENGLISH_P8') as string,
			field: 'English-P8-Element',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.MATHS_P8') as string,
			field: 'Mathematics-P8-Element',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.EBACC_P8') as string,
			field: 'EBacc-P8-Element',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.OPEN_P8') as string,
			field: 'Open-P8-Element',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.PA') as string,
			field: 'prior-attainment',
			pinned: 'right',
			comparator: studentScoreSort,
		},
		{
			headerName: translate('spo.progress8.agGrid.coldefs.MEG') as string,
			field: 'meg',
			pinned: 'right',
			comparator: megSort,
		},
	];

	/**
	 * Update grid
	 */
	// Resize columns on mount
	useEffect(() => {
		gridOptions.columnApi?.autoSizeAllColumns();
	}, [gridOptions.api, gridOptions.columnApi]);

	// Resize columns on mount
	useEffect(() => {
		if (width && width > 1400) {
			gridOptions.api?.sizeColumnsToFit();
		}
	}, [width]);

	/**
	 * Default Grid Options
	 */
	const defaultGridOptions: AgGridReactProps = createDefaultGridOptions({
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Manage Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressPivotMode: true,
						suppressRowGroups: true,
						suppressValues: true,
					},
				},
			],
		},
		domLayout: 'normal',
		suppressHorizontalScroll: false,
		frameworkComponents: {
			iconRenderer: (params: any) => {
				const icon = !!Number(params.value) ? 'check' : 'xmark';
				const color = !!Number(params.value) ? 'STATUS.success.0' : 'THERMOMETER.tBlue.0';

				return <IconRenderer icon={icon} color={color} />;
			},
		},
		onGridColumnsChanged: () => {
			gridOptions.api?.redrawRows();
			gridOptions.columnApi?.autoSizeAllColumns();
			if (width && width > 1400) {
				gridOptions.api?.sizeColumnsToFit();
			}
		},
	});

	/**
	 * Handle Excel Export
	 */
	const handleExcelExport = () => {
		return gridOptions.api?.exportDataAsExcel({
			sheetName: translate('performanceMeasures.agGrid.excel.EXPORT', {
				title: data?.tableName,
			}) as string,
			fileName: translate('performanceMeasures.agGrid.excel.EXPORT', {
				title: data?.tableName,
			}) as string,
		});
	};

	/**
	 * Render Component
	 */
	return (
		<Box width={1} px={4} pt={2} pb={4}>
			{/* Heading */}
			<GridHeader
				heading={data?.tableName as string}
				handleExcelExport={() => handleExcelExport()}
			/>
			<Box
				className={isMultiGrid ? 'ag-theme-alps is-multi-grid' : 'ag-theme-alps'}
				width={1}
				height="calc(100vh - 435px)"
			>
				<AgGridReact
					{...defaultGridOptions}
					onGridReady={(params) => gridOptions.onGridReady(params)}
					columnDefs={coldefs}
					rowData={data?.metrics}
				/>
			</Box>
		</Box>
	);
};

export default AgGridP8;
