import { SagaIterator } from 'redux-saga';
import { cancel, put } from 'redux-saga/effects';
import {
	getAllSingleSchoolPerformanceMeasuresDataFlow,
	resetSingleSchoolPerformanceMeasures,
} from 'features/groups/analysis/redux/ks5PerformanceMeasures/singleSchool/slice';

/**
 * Saga that handles the overall fetching of single school performance measures
 */
export function* handleOverallFetchingSingleSchoolPerformanceFlow(): SagaIterator<void> {
	//reset state - this is a temporary measure as eventually we will store a certain amount and retain that
	yield put(resetSingleSchoolPerformanceMeasures());

	//get all the single school performance measures data
	yield put(getAllSingleSchoolPerformanceMeasuresDataFlow);

	//bail out of the saga
	yield cancel();
}
