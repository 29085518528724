import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	HeadlinePerformanceMeasures,
	HeadlinePerformanceMeasuresGraphData,
} from 'features/groups/analysis/redux/types/headlineMeasures';

const initialState: HeadlinePerformanceMeasures.State = {
	loading: false,
	exportExcel: false,

	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	error: undefined,
	graphLoading: true,
	graphExamLevels: undefined,
	graphs: undefined,
	xAxis: undefined,
	graphError: undefined,
};

const headlinePerformanceMeasures = createSlice({
	name: 'headlinePerformanceMeasures',
	initialState,
	reducers: {
		setLoading(state: HeadlinePerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		setGraphLoading(state: HeadlinePerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				graphLoading: action.payload,
			};
		},
		setAveragePointsScoreGridDataSuccess(
			state: HeadlinePerformanceMeasures.State,
			action: PayloadAction<HeadlinePerformanceMeasures.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			return {
				...state,
				examLevels,
				grids,
				rows,
				loading: false,
				graphLoading: false,
				error: undefined,
			};
		},
		setMainGraphDataSuccess(
			state: HeadlinePerformanceMeasures.State,
			action: PayloadAction<HeadlinePerformanceMeasuresGraphData.GraphData>
		) {
			const { examLevels, graphs, xAxis } = action.payload;

			// If this is the first fetch just set the data
			if (!state.graphs || !state.graphExamLevels) {
				return {
					...state,
					examLevels,
					graphs,
					xAxis,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				graphError: undefined,
				graphExamLevels: {
					...state.graphExamLevels,
					id: examLevels.id,
					byId: {
						...state.graphExamLevels!.byId,
						...examLevels.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.graphExamLevels!.allIds, ...examLevels.allIds],
				},
				graphs: {
					...state.graphs,
					id: graphs.id,
					byId: {
						...state.graphs?.byId,
						...graphs.byId,
					},
					allIds: [...state.graphs!.allIds, ...graphs.allIds],
				},
				xAxis: {
					...state.xAxis,
					id: xAxis.id,
					byId: {
						...state.xAxis!.byId,
						...xAxis.byId,
					},
					allIds: [...state.xAxis!.allIds, ...xAxis.allIds],
				},
				graphLoading: false,
			};
		},
		handleExcelExport(state: HeadlinePerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		setError(state: HeadlinePerformanceMeasures.State, action: PayloadAction<string | undefined>) {
			return {
				...state,
				error: action.payload,
			};
		},
		setGraphError(
			state: HeadlinePerformanceMeasures.State,
			action: PayloadAction<string | undefined>
		) {
			return {
				...state,
				graphError: action.payload,
			};
		},
		resetHeadlinePerformanceMeasuresState(state: HeadlinePerformanceMeasures.State) {
			return initialState;
		},
	},
});

/**
 ** Export Reducers
 */
export default headlinePerformanceMeasures.reducer;

/**
 ** Export Actions
 */
export const {
	setLoading,
	setGraphLoading,
	setAveragePointsScoreGridDataSuccess,
	setMainGraphDataSuccess,
	handleExcelExport,
	setError,
	setGraphError,
	resetHeadlinePerformanceMeasuresState,
} = headlinePerformanceMeasures.actions;
