import { takeLatest } from 'redux-saga/effects';
import { fetchSubjectsTakenFlow } from './fetchSubjectsTakenflow';
import { schoolsInGroupFlow } from './schoolsInGroupFlow';

/**
 * Export all Context Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const contextSagas = [
	//Flows trigger by specific actions
	takeLatest('context/setAppContextSuccess', fetchSubjectsTakenFlow),
	takeLatest('context/changePrimaryGradepoint', fetchSubjectsTakenFlow),
	takeLatest('homepage/setAgreementData', fetchSubjectsTakenFlow),

	takeLatest('context/triggerSchoolsInGroup', schoolsInGroupFlow),
	takeLatest('context/retriggerSingleSchoolPerformanceMeasures', schoolsInGroupFlow),
];
