import * as React from 'react';
import { translate } from '../../../utils/locale';
// TODO - import from Basecamp in prod
import { Flex, Heading, Icon } from 'basecamp';

const WarningMessage: React.FC = () => {
	/**
	 ** Render Component
	 */
	return (
		<Flex
			bg="STATUS.warning.0"
			withAlign="center"
			color="STATUS.warning.3"
			position="fixed"
			height={60}
			top={0}
			left={0}
			right={0}
			zIndex={1000}
			fontSize={3}
			dataTest="gpSelectorWarningMessage"
		>
			<Icon icon="circle-exclamation" />
			<Heading setAs="small" withColor="white" textAlign="center" ml={1} mt={0} mb={0}>
				{translate('gradepointSelector.WARNING_MESSAGE')}
			</Heading>
		</Flex>
	);
};

export default WarningMessage;
