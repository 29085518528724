import { all } from 'redux-saga/effects';
import { strategicAnalysisSagas } from 'features/strategicAnalysis/redux/sagas';
import { subjectAreaSagas } from 'features/subjectArea/redux/sagas';
import { manageAccountSagas } from 'features/manageAccount/redux/sagas';
import { authSagas } from 'features/auth/redux/sagas';
import { manageUsersSagas } from 'features/manageUsers/redux/sagas';
import { summitPerformanceMeasuresSaga } from 'features/groups/analysis/redux/sagas';
import { summitPerformanceMeasuresSingleSchoolSaga } from 'features/groups/analysis/redux/sagas/singleSchoolPerformanceMeasuresSagas';
import { contextSagas } from 'features/app/redux/sagas';
import { matTrendSelectorSagas } from 'features/gradepointSelector/redux/sagas';

function* rootSaga() {
	// @ts-ignore
	yield all([...strategicAnalysisSagas]);
	yield all([...subjectAreaSagas]);
	yield all([...manageAccountSagas]);
	yield all([...authSagas]);
	yield all([...manageUsersSagas]);
	yield all([...summitPerformanceMeasuresSaga]);
	yield all([...summitPerformanceMeasuresSingleSchoolSaga]);
	yield all([...contextSagas]);
	yield all([...matTrendSelectorSagas]);
}

export default rootSaga;
