/**
 * @fileoverview Component that displays the current key stage and academic year for group trends
 * @module GroupTrendIndicator
 */

import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Flex, HeaderLink, Text } from 'basecamp';
import { setCurrentKeyStage } from 'features/groups/setup/redux';
import { getGroupAccessRecords } from 'features/groups/setup/redux';
import { getSelectedTrend } from 'features/groups/analysis/redux';
import { academicYearStandardFormat } from '../../../../utils/academicYear';
import { calculateSummitKeyStage } from '../../../../utils/summitKeyStage';
import { styles } from './common';

/**
 * @typedef {Object} Props
 * @property {boolean} isSmallScreen - Whether the screen is small
 */
type Props = { isSmallScreen: boolean; setSmallScreenMenuIsOpen: (isOpen: boolean) => void };

/**
 * Component that displays the current key stage and academic year for the selected group trend
 * @returns {JSX.Element|null} Returns the indicator component if conditions are met, null otherwise
 */
const GroupTrendIndicator: FunctionComponent<Props> = ({
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	const dispatch = useDispatch();

	/**
	 * Redux selectors for accessing state
	 */
	const selectedTrend: { groupAccessId: number } = useSelector(getSelectedTrend);
	const accessRecords: { academicYear: number; id: number; examLevel: number }[] = useSelector(
		getGroupAccessRecords
	);

	/**
	 * Find the access record that matches the selected trend's group access ID
	 */
	const getAccessRecord =
		selectedTrend && accessRecords.find((record) => record.id === selectedTrend.groupAccessId);

	/**
	 * Calculate the key stage and format the academic year based on the access record
	 */
	const keyStage = getAccessRecord && calculateSummitKeyStage(getAccessRecord.examLevel);
	const academicYear = getAccessRecord && academicYearStandardFormat(getAccessRecord!.academicYear);

	/**
	 * Set the current key stage in local storage if not already set
	 */
	useEffect(() => {
		if (!localStorage.getItem('keyStageStorage')) {
			dispatch(setCurrentKeyStage({ label: keyStage, value: getAccessRecord?.id }));
		}
	}, [keyStage]);

	return (
		<HeaderLink
			buttonText={keyStage}
			lozengeText={academicYear}
			lozengeIcon="circle-check"
			dataTest="groupTrendIndicator"
			onClick={() => {
				setSmallScreenMenuIsOpen(false);
			}}
			{...styles(isSmallScreen)}
		/>
	);
};

export default GroupTrendIndicator;
