/**
 * @module useSummitHomeRoutes
 * @description Hook that returns navigation routes configuration for the Summit home page
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { knowledgeBaseRoutes } from '../../common/knowledgeBaseRoutes';
import {
	getBenchmarkSetCountryFromNationDataSetBenchmark,
	getCurrentEntitlements,
	getIsContractOwner,
	getPermissions,
} from 'features/app/redux/context';
import { getEmbargo, getSubscriptionStatus } from 'features/app/redux/context';
import { getConfig, getFeature } from 'features/app/redux/features';
import { getUserRoles } from 'features/app/redux/context';
import { useManageUserRoutes } from '../';

/**
 * Hook that returns the navigation routes for the Summit home page
 * @returns {SecondaryNavigationLinks[]} Array of navigation route configurations containing:
 * @property {string} displayName - Translated heading text for the navigation item
 * @property {string} icon - Icon name to display with the navigation item
 * @property {boolean} isVisible - Whether the navigation item should be visible
 * @property {boolean} disabled - Whether the navigation item is disabled
 * @property {string} [withInternalUrl] - Internal route URL for direct navigation links
 * @property {string} [withExternalUrl] - External URL for external navigation links
 * @property {Object[]} [flyoutContent] - Array of flyout menu configurations
 * @property {string} flyoutContent[].heading - Translated heading for the flyout section
 * @property {Object[]} flyoutContent[].flyoutLinks - Array of link configurations for the flyout
 * @property {string} flyoutContent[].flyoutLinks[].displayName - Translated text for the flyout link
 * @property {boolean} flyoutContent[].flyoutLinks[].isVisible - Whether the flyout link should be visible
 * @property {string} [flyoutContent[].flyoutLinks[].withInternalUrl] - Internal route URL for the flyout link
 * @property {string} [flyoutContent[].flyoutLinks[].withExternalUrl] - External URL for the flyout link
 * @property {boolean} [flyoutContent[].flyoutLinks[].disabled] - Whether the flyout link is disabled
 */
const useSummitHomeRoutes = (): SecondaryNavigationLinks[] => {
	const permissions: string[] | undefined = useSelector(getUserRoles);
	const currentEntitlements: any = useSelector(getCurrentEntitlements);
	const groups = useSelector(getPermissions);
	const embargo = useSelector(getEmbargo);
	const embargoFeature = useSelector(getFeature('embargo_feature'));
	const applyEmbargo = embargoFeature && embargo?.some((x) => x.value === true);
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	const summitFeatureEnabled = useSelector(getFeature('alps_summit'));
	// Manage Users Route Definitions
	const { manageUserHomeRoutes } = useManageUserRoutes();
	const enableMfa = useSelector(getFeature('enable_mfa'));
	const canViewClientBmToggle = useSelector(getFeature('summit_benchmark'));

	const avaliableBenchmarks = useSelector(getConfig('avaliable_benchmarks'));
	const currentBenchmark = useSelector(getBenchmarkSetCountryFromNationDataSetBenchmark);
	const isBenchmarkAllowed = avaliableBenchmarks?.includes(currentBenchmark);
	return [
		// View As School
		{
			displayName: translate('home.groups.viewAsSchool.HEADING') as string,
			isVisible: groups.groupsViewAsSchool && summitFeatureEnabled && !applyEmbargo,
			withInternalUrl: `/groups/view-as-school`,
			icon: 'eye',
			disabled: !subscriptionStatus.hasContract,
		},
		// Group Management
		{
			displayName: translate('home.groups.groupSetupManagement.HEADING') as string,
			isVisible: true, //TODO: this could be changed after meeting with PD
			disabled: false,
			icon: 'chart-line',
			flyoutContent: [
				{
					heading: 'Monitoring Configuration',
					flyoutLinks: [
						{
							displayName: translate(
								'home.groups.groupSetupManagement.links.MONITORING_TIMELINE'
							) as string,
							withInternalUrl: `/groups/setup/monitoring-timeline`,
							isVisible: groups.groupsMonitoringTimeline
								? currentEntitlements &&
								  !!permissions &&
								  permissions.includes('Groups_Setup') &&
								  summitFeatureEnabled
								: false,
							disabled: !subscriptionStatus.hasContract,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
		// Manage Users
		manageUserHomeRoutes,
		// Analysis Settings
		{
			displayName: translate('home.groups.analysisSettings.HEADING') as string,
			isVisible: subscriptionStatus.hasContract,
			disabled: false,
			icon: 'file-chart-column',
			flyoutContent: [
				{
					heading: translate('home.groups.analysisSettings.links.FLYOUT_HEADING') as string,
					flyoutLinks: [
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.ANALYSIS_SETTINGS'
							) as string,
							withInternalUrl: '/analysis-settings/client-benchmarks',
							isVisible: canViewClientBmToggle && isBenchmarkAllowed,
							disabled: !subscriptionStatus.hasContract,
						},
						{
							displayName: translate(
								'home.groups.groupSetupManagement.links.ANALYSIS_SETUP'
							) as string,
							withInternalUrl: '/groups/setup/school-groups',
							isVisible:
								currentEntitlements &&
								!!permissions &&
								permissions.includes('Groups_Setup') &&
								summitFeatureEnabled,
							disabled: !subscriptionStatus.hasContract,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
		// Reports
		{
			displayName: translate('home.groups.reportsDownloads.HEADING') as string,
			isVisible: subscriptionStatus.hasContract,
			disabled: false,
			icon: 'file-chart-column',
			flyoutContent: [
				{
					heading: 'Report Management',
					flyoutLinks: [
						{
							isVisible: true,
							withInternalUrl: '/groups/reports/group-reports',
							displayName: translate('home.groups.reportsDownloads.links.GROUP_REPORTS') as string,
							disabled: !subscriptionStatus.hasContract,
						},
						{
							isVisible: true,
							withInternalUrl: '/groups/reports/school-and-college-reports',
							displayName: translate('home.groups.reportsDownloads.links.SCHOOL_COLLEGE') as string,
							disabled: !subscriptionStatus.hasContract,
						},
						{
							isVisible: true,
							withInternalUrl: '/groups/reports/additional-downloads',
							displayName: translate(
								'home.singleSchool.reportsDownloads.links.ADDITIONAL_DOWNLOADS'
							) as string,
							disabled: !subscriptionStatus.hasContract,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
		// Manage Account
		{
			displayName: 'Manage Account',
			isVisible: true,
			disabled: false,
			icon: 'gear',
			flyoutContent: [
				{
					heading: 'Manage Account',
					flyoutLinks: [
						{
							isVisible: true,
							withInternalUrl: '/manage-account/change-password',
							displayName: translate(
								'home.singleSchool.manageAccount.links.CHANGE_PASSWORD'
							) as string,
						},
						{
							isVisible: !!enableMfa,
							disabled: !enableMfa,
							withInternalUrl: `/manage-account/mfaSetup`,
							displayName: translate(
								'home.singleSchool.manageAccount.links.MULTI_FACTOR_AUTHENTICATION'
							) as string,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
	];
};

export default useSummitHomeRoutes;
