import normalizeKey from '../../../utils/normalizeKey';
import { priorAch } from '../../../utils/display';
import { getMegIdOutcome, getDisplayValue } from '../../../agGrid/utils';
import { getPersonalisedTargetsForStudent } from 'features/subjectPage/redux/selectors/studentSelectors';
import { getPriorAttainmentTypes } from 'features/spo/redux';
import { getFineGrades } from 'features/subjectPage/redux';
import { getExamLevelFromFlag } from '../../../utils/examLevel';
import {
	adjustPriorAttainmentPrecision,
	getPriorAttainmentPrecision,
} from '../../../utils/priorAttainment';
import { getFeature } from 'features/app/redux/features';

/**
 * Get loading status
 */
export const getLoadingStatus = ({ studentModal }: RootState) => {
	return studentModal.loading;
};
/**
 * Get the student modal error message
 */
export const getErrorMessage = (state: RootState) => state.studentModal.errorMessage;

/**
 * Have we got an error?
 */
export const hasError = (state: RootState) => {
	return getErrorMessage(state) !== null;
};

/**
 * Get the modal status
 */
export const getModalStatus = ({ studentModal }: RootState) => {
	return studentModal.open;
};

/**
 * Get student information
 */
export const getStudentInformation = ({ studentModal }: RootState) => {
	const {
		Forename,
		Surname,
		DateOfBirth,
		Gender,
		Ethnicity,
		ExternalId,
		TutorGroup,
		Disadvantaged,
		EAL,
		SEND,
	} = studentModal.data as StudentModal.StudentInformation;

	if (!Object.keys(studentModal.data).length) {
		return {};
	}

	const NO_DATA = '-';

	return {
		studentName:
			!!Surname || !!Forename ? `${Surname}${!!Forename ? ',' : ''} ${Forename}` : NO_DATA,
		studentId: !!ExternalId ? ExternalId : NO_DATA,
		gender: !!Gender ? Gender : NO_DATA,
		dob: !!DateOfBirth ? DateOfBirth : NO_DATA,
		ethnicity: !!Ethnicity ? Ethnicity : NO_DATA,
		tutorGroup: !!TutorGroup ? TutorGroup : NO_DATA,
		disadvantaged: !!Disadvantaged ? Disadvantaged : NO_DATA,
		eal: !!EAL ? EAL : NO_DATA,
		send: !!SEND ? SEND : NO_DATA,
	};
};

/**
 * Get a list of unique outcomes from the subjects the student is taking
 * For example this could be mixture of A Level and GCSE, where outcome sets are different
 * If the student has taken 4 GCSEs and 2 A Levels this will return 2 outcome sets
 */
export const getStudentsOutcomeSets = (state: RootState): SubjectPage.Outcome[] => {
	const studentInformation = state.studentModal.data as StudentModal.StudentInformation;

	if (!Object.keys(studentInformation).length) {
		return [];
	}

	const { SubjectTrend } = studentInformation;

	return SubjectTrend.flatMap((subjectTrend: StudentModal.SubjectTrend) => {
		return subjectTrend.Outcomes.map((outcome: SubjectPage.Outcome) => outcome);
	}).filter((outcome: SubjectPage.Outcome, index: number, outcomes: SubjectPage.Outcome[]) => {
		const allValues = outcomes.map((o) => o.Value);

		return allValues.indexOf(outcome.Value) === index;
	});
};

/**
 * Get a list of all unique outcomes and add fine grades
 */
export const getStudentsOutcomeSetsIncludeFineGrades = (
	state: RootState
): StudentModal.AllOutcomesWithFineGrades[] => {
	const allOutcomes = getStudentsOutcomeSets(state);
	const fineGrades = getFineGrades(state);
	return allOutcomes
		.flatMap((outcome: SubjectPage.Outcome) => {
			return [
				...fineGrades.stretch.map((fg: string) => ({
					...outcome,
					Display: `${outcome.Display}${fg}`,
				})),
				...fineGrades.secure.map((fg: string) => ({
					...outcome,
					Display: `${outcome.Display}${fg}`,
				})),
				outcome,
				...fineGrades.atRisk.map((fg: string) => ({
					...outcome,
					Display: `${outcome.Display}${fg}`,
				})),
			];
		})
		.map((outcome: SubjectPage.Outcome, i: number) => ({
			display: outcome.Display,
			value: outcome.Value,
			order: outcome.Points === 0 ? allOutcomes.length + i : i,
		}))
		.sort(
			(a: StudentModal.AllOutcomesWithFineGrades, b: StudentModal.AllOutcomesWithFineGrades) => {
				return a.order - b.order;
			}
		);
};

/**
 * Get all unique targets
 */
export const getStudentTargets = (state: RootState): StudentModal.AllTargets[] => {
	const studentInformation = state.studentModal.data as StudentModal.StudentInformation;

	if (!Object.keys(studentInformation).length) {
		return [];
	}

	const { SubjectTrend } = studentInformation;

	return SubjectTrend.flatMap((subjectTrend: StudentModal.SubjectTrend) => {
		return (subjectTrend.Targets || []).map((target: SubjectPage.TargetSet) => target);
	})
		.filter((target, index, targets) => {
			const allValues = targets.map((o) => o.Outcome);

			return allValues.indexOf(target.Outcome) === index;
		})
		.map((target, i) => {
			return {
				Id: target.Id,
				Outcome: target.Outcome,
				Order: i,
				Target: target.Target,
			};
		});
};

/**
 * Get student row data for ag grid
 */
export const getStudentRowData = (state: RootState): StudentModal.RowData[] => {
	const studentInformation = state.studentModal.data as StudentModal.StudentInformation;

	if (!Object.keys(studentInformation).length) {
		return [];
	}

	const { SubjectTrend, PriorAch } = studentInformation;

	const allOutcomesIncludeFineGrades = getStudentsOutcomeSetsIncludeFineGrades(state);
	const allTargets = getStudentTargets(state);

	return SubjectTrend.map((subject: StudentModal.SubjectTrend) => {
		const { SubjectName, MinimumExpectedGrade, GradepointDetails, Outcomes, ExamLevel } = subject;

		const subjectExamLevelString: string = ExamLevel ? getExamLevelFromFlag(ExamLevel) : '';

		return Object.assign(
			{
				subject: SubjectName,
				meg: getMegIdOutcome(MinimumExpectedGrade, allTargets),
				pt: subject.PT == '' ? '-' : { display: subject.PT },
				outcomes: Outcomes.filter((outcome: SubjectPage.Outcome) => outcome.Points > 0).map(
					(outcome: SubjectPage.Outcome) => outcome.Value
				),
			},
			...PriorAch.map((pa: StudentModal.PriorAchievement) => {
				const precision = getPriorAttainmentPrecision(pa.PAType, getPriorAttainmentTypes(state));
				const score =
					getFeature('pa_display_precision') && pa.PAType != null
						? adjustPriorAttainmentPrecision(pa.Score, precision)
						: priorAch(pa.Score, { type: pa.Type });

				return {
					[`pa${pa.Type}`]: subjectExamLevelString === pa.Type ? score : '-',
				};
			}),
			...GradepointDetails.map((gp: StudentModal.GradepointDetail) => {
				const { Name, Outcome, OutcomeDisplay } = gp;
				const gpkey = normalizeKey(Name);

				const getOutcome = allOutcomesIncludeFineGrades.find(
					(outcome: StudentModal.AllOutcomesWithFineGrades) => {
						return outcome.display === gp.OutcomeDisplay;
					}
				);

				return {
					[`gp${gpkey}`]: {
						value: Outcome,
						display: OutcomeDisplay || '-',
						order: getOutcome ? getOutcome.order : 0,
					},
				};
			})
		);
	});
};

/**
 * Get student Prior Achievement
 */
export const getStudentPriorAch = ({ studentModal }: RootState) => {
	const studentInformation = studentModal.data as StudentModal.StudentInformation;

	if (!Object.keys(studentInformation).length || !studentInformation.PriorAch.length) {
		return [];
	}

	return studentInformation.PriorAch;
};
