import * as React from 'react';
import { FunctionComponent } from 'react';
import { useLocation } from 'react-router';
import { Heading, Flex, IconButton, Label, Switch, Tooltip } from 'basecamp';

import { translate } from '../../../utils/locale';
import ConditionalWrapper from 'features/filterBar/conditionalWrapper';

type Props = {
	heading: string;
	handleExcelExport: () => void;
	handleValueToggle?: () => void;
	isOn?: boolean;
	disableExport?: boolean;
};

const GridHeader: FunctionComponent<Props> = ({
	heading,
	handleExcelExport,
	handleValueToggle,
	isOn,
	disableExport,
}) => {
	const { pathname }: { pathname: string } = useLocation();
	const studentPage = pathname.substring(pathname.lastIndexOf('/') + 1);
	return (
		<Flex setAs="row" withAlign="distribute" mt={2} mb={3} width={1}>
			{/* Heading */}
			<Heading setAs="medium" withColor="black" m={0}>
				{heading}
			</Heading>
			<Flex setAs="row" withAlign="centerRight">
				{/* Toggle value shown on grids between avg / count and % */}
				{handleValueToggle && (
					<Flex setAs="column" withAlign="centerLeft" mr={4}>
						<Label>{translate('commonPerformanceMeasures.accordionHeader.VALUE_TOGGLE')}</Label>
						<Flex withAlign="center" height={40}>
							<Switch
								onClick={() => handleValueToggle()}
								isOn={isOn}
								setAs="primary"
								trackOverrideColour="accent"
							/>
						</Flex>
					</Flex>
				)}
				{/* Export grid as Excel */}
				<Flex setAs="column" withAlign="centerLeft">
					<Label>{translate('performanceMeasures.agGrid.accordionHeader.EXPORT')}</Label>
					<ConditionalWrapper
						condition={studentPage === 'progress-8-measures'}
						wrapper={(children) => (
							<Tooltip
								withMessage={translate('performanceMeasures.agGrid.accordionHeader.TOOLTIP')}
								withSize="small"
								withAlign="left"
							>
								{children}
							</Tooltip>
						)}
					>
						<React.Fragment>
							<IconButton
								disabled={disableExport}
								withIcon="file-excel"
								setAs="accent"
								onClick={() => handleExcelExport()}
							/>
						</React.Fragment>
					</ConditionalWrapper>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default GridHeader;
