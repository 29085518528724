/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getFeature } from 'features/app/redux/features';
import { withinEmbargoAndViewAsSchool } from '../../../utils/embargoHelpers';
import { getEmbargo, getSubscriptionStatus, getViewAsSchool } from 'features/app/redux/context';
import { getAllGroupTrends } from 'features/groups/analysis/redux/selectors';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useSummitPrimaryNavLinks = () => {
	const { params }: { params: PrimaryNavigation.RouteParams } = useRouteMatch();

	const embargoFeature = useSelector(getFeature('embargo_feature'));
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	const viewAsSchool = useSelector(getViewAsSchool);
	const trends = useSelector(getAllGroupTrends);
	const canViewSummitKs5Pm = useSelector(getFeature('summit_ks5'));
	const applyEmbargo = useSelector(getEmbargo);
	const embargoAccess = withinEmbargoAndViewAsSchool(embargoFeature, applyEmbargo, viewAsSchool);
	const commonDisabledLinkCritieria =
		!subscriptionStatus.hasValidSubscriptionOrContract || !trends || embargoAccess;
	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const summitPrimaryNavLinks = [
		{
			name: 'strategicAnalysis',
			width: 104,
			isVisible: true,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-summit-strategic-analysis-link`}
					withInternalUrl="/groups/strategic"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.summit.root.STRATEGIC')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'bbpaAnalysis',
			width: 124,
			isVisible: true,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-summit-bbpa-link`}
					withInternalUrl="/groups/bbpa"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.summit.root.BBPA')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectsAnalysis',
			width: 175,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-summit-subjects-analysis-link`}
					withInternalUrl="/groups/subjects"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.summit.root.SUBJECTS_ANALYSIS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'groupOverview',
			width: 175,
			isVisible: canViewSummitKs5Pm,
			menuTransferOrder: 4,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-summit-overview-of-providers-in-group-link`}
					withInternalUrl="/groups/ks5pm/group-overview"
					disabled={!canViewSummitKs5Pm || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.summit.root.OVERVIEW_OF_PROVIDERS_IN_GROUP')}
				</PrimaryNavLink>
			),
		},
		// TODO: Sort this when Summit KS5 PM is near completion, we need to for the new routing/menu
		{
			name: 'providerLevelSummary',
			width: 165,
			isVisible: canViewSummitKs5Pm,
			menuTransferOrder: 5,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-summit-provider-level-summary-link`}
					withInternalUrl={`/groups/ks5pm/${params.groupsSchoolName}`}
					disabled={!canViewSummitKs5Pm || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.summit.root.PROVIDER_LEVEL_SUMMARY')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses primaryNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(
		summitPrimaryNavLinks,
		width,
		100,
		[]
	);

	return { largeScreenMenu, smallScreenMenu };
};

export default useSummitPrimaryNavLinks;
