import { takeLatest } from 'redux-saga/effects';
import { getSummaryKpiTileFlow } from './getSummaryKpiTileFlow';
import { getSummaryProfileGridFlow } from './getSummaryProfileGridFlow';
import { getAveragePointsScoreGraphFlow } from './getAveragePointsScoreGraphFlow';
import { getFacilitatingSubjectsFlow } from './getFacilitatingSubjectsFlow';
import { getBest3SummaryProfileGridFlow } from './getBest3SummaryProfileGridFlow';
import { getBest3GradeDistributionProfileGridFlow } from './getBest3GradeDistributionProfileGridFlow';
import { getRawAttainmentTileFlow } from './getRawAttainmentTileFlow';
import { handleOverallFetchingSingleSchoolPerformanceFlow } from './handleOverallFetchingSingleSchoolPerformanceFlow';
import { getRawAttainmentGraphFlow } from './getRawAttainmentGraphFlow';
import { retriggerSchoolAndDataFetchFlow } from './retriggerSchoolAndDataFetchFlow';

export const summitPerformanceMeasuresSingleSchoolSaga = [
	//get overall single school performance measures flow
	takeLatest(
		'summitPerformanceMeasures/handleOverallFetchingSingleSchoolPerformanceFlow',
		handleOverallFetchingSingleSchoolPerformanceFlow
	),

	//retrigger school and data fetch flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/retriggerSchoolAndDataFetchFlow',
		retriggerSchoolAndDataFetchFlow
	),

	/**
	 * These trigger when a error is thrown
	 */

	//get summary profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getSummaryProfileGridFlow',
		getSummaryProfileGridFlow
	),

	//get average points score graph flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAveragePointsScoreGraphFlow',
		getAveragePointsScoreGraphFlow
	),

	//get summary kpi tile flow
	takeLatest('summitPerformanceMeasuresSingleSchool/getSummaryKpiTileFlow', getSummaryKpiTileFlow),

	//get raw attainment tile flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getRawAttainmentTileFlow',
		getRawAttainmentTileFlow
	),

	//get facilitating subjects flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getFacilitatingSubjectsFlow',
		getFacilitatingSubjectsFlow
	),

	//get raw attainment graph flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getRawAttainmentGraphFlow',
		getRawAttainmentGraphFlow
	),

	//get best 3 summary profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getBest3SummaryProfileGridFlow',
		getBest3SummaryProfileGridFlow
	),

	//get best 3 grade distribution profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getBest3GradeDistributionProfileGridFlow',
		getBest3GradeDistributionProfileGridFlow
	),

	/**
	 * These trigger tfor intial calls
	 */

	//get all the single school performance measures data
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getAveragePointsScoreGraphFlow
	),

	//get summary kpi tile flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getSummaryKpiTileFlow
	),

	//get raw attainment graph flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getRawAttainmentGraphFlow
	),

	//get summary profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getSummaryProfileGridFlow
	),

	//get facilitating subjects flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getFacilitatingSubjectsFlow
	),

	//get raw attainment tile flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getRawAttainmentTileFlow
	),

	//get best 3 summary profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getBest3SummaryProfileGridFlow
	),

	//get best 3 grade distribution profile grid flow
	takeLatest(
		'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow',
		getBest3GradeDistributionProfileGridFlow
	),
];
