import { SingleSchoolKpiSummary } from 'features/groups/analysis/redux/types/singleSchoolKpiSummary';

/**
 * Get the component loading state
 */
export const getComponentLoading = (component: keyof SingleSchoolKpiSummary.ComponentLoading) => (
	state: RootState
) => state.matSingleSchoolPerformanceMeasures.componentLoading[component];

/*
Gets the KPI data for the Summary tiles
 */
export const getSummaryKpiData = (state: RootState): SingleSchoolKpiSummary.Tile[] | undefined => {
	return state.matSingleSchoolPerformanceMeasures.summaryKpis;
};

/*
Gets the KPI data for the Cumulative Raw Attainment tiles
 */
export const getRawAttainmentSummaryPercentageKpiData = (
	state: RootState
): SingleSchoolKpiSummary.Tile[] | undefined => {
	return state.matSingleSchoolPerformanceMeasures.rawAttainmentSummaryPercentageKpis;
};

/**
 * Gets the single school initialised state
 */
export const getSingleSchoolInitialised = (state: RootState): boolean => {
	return state.matSingleSchoolPerformanceMeasures.initaialised;
};

/**
 * Gets the component errors
 */
export const getComponentErrors = (component: keyof SingleSchoolKpiSummary.ComponentErrors) => (
	state: RootState
): string | undefined => {
	return state.matSingleSchoolPerformanceMeasures.componentErrors[component];
};

/**
 * Gets the ks4HasBeenChecked state
 */
export const getKs4HasBeenChecked = (state: RootState): boolean => {
	return state.matSingleSchoolPerformanceMeasures.ks4HasBeenChecked;
};

/**
 * Gets the comparisons
 */
export const getComparisons = (state: RootState): CommonUiTypes.SelectOption[] | undefined => {
	return state.matSingleSchoolPerformanceMeasures.comparisons;
};
