import { translate } from '../../../../../utils/locale';

export enum CumulativePercentageSortType {
	AStarA = 'A*-A',
	AStarB = 'A*-B',
	AStarC = 'A*-C',
	AStarE = 'A*-E',
}

export enum SortType {
	SchoolNameAZ = 'School Name A-Z',
	SchoolNameZA = 'School Name Z-A',
	ApsPointsHighToLow = 'Aps Points High - Low',
	ApsPointsLowToHigh = 'Aps Points Low - High',
	DifferenceHighToLow = 'Difference High - Low',
	DifferenceLowToHigh = 'Difference Low - High',
}

export const sortByOptions = [
	{ label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_ONE'), value: SortType.SchoolNameAZ },
	{ label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_TWO'), value: SortType.SchoolNameZA },
	{
		label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_THREE'),
		value: SortType.ApsPointsHighToLow,
	},
	{
		label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_FOUR'),
		value: SortType.ApsPointsLowToHigh,
	},
	{
		label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_FIVE'),
		value: SortType.DifferenceHighToLow,
	},
	{
		label: translate('groups.ks5Pm.filterBar.gridSort.LABEL_SIX'),
		value: SortType.DifferenceLowToHigh,
	},
];

export const selectCumulativePercentageOptions = [
	{ label: 'A* > A', value: CumulativePercentageSortType.AStarA },
	{ label: 'A* > B', value: CumulativePercentageSortType.AStarB },
	{ label: 'A* > C', value: CumulativePercentageSortType.AStarC },
	{ label: 'A* > E', value: CumulativePercentageSortType.AStarE },
];
