import * as React from 'react';
import { components } from 'react-select';
import { Flex, Link } from 'basecamp';

const MenuRenderer = (props: any) => {
	return (
		<components.Menu {...props}>
			<Flex
				data-test="menuRendererWrapper"
				setAs="row"
				withAlign="center"
				px={2}
				pb={2}
				pt={1}
				bg="UI.secondary.1"
			>
				<Link
					setAs="button"
					withType="accent"
					withInternalUrl={props.selectProps.withInternalUrl}
					p={1}
					width={1}
					borderRadius={2}
					data-test="menuRendererCustomComparisonButton"
				>
					Add new custom comparison
				</Link>
			</Flex>
			{props.children}
		</components.Menu>
	);
};

export default MenuRenderer;
