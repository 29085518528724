/**
 * @module ManageUsers
 * @description Main component for managing users, handling navigation, state management and layout
 */
import * as React from 'react';
import { FunctionComponent, Fragment, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { PageWithSidebarNew } from 'features/layouts/pageWithSidebar';
import { UnsavedChangeModal, ResetChangesModal } from './components';
import { useManageUserRoutes } from '../../routes/hooks';
import GetRoutes from '../../routes';
import { SecondaryNavigation } from 'basecamp';
import { listUsersThunk } from './redux/users';
import { useDispatch } from 'react-redux';
import { withTransaction } from '@elastic/apm-rum-react';
import PageTitle from 'features/common/ui/pageTitle';

/**
 * Props for the ManageUsers component
 * @typedef {Object} Props
 * @property {Routes.Route[]} routes - Array of route configurations
 */
type Props = {
	routes: Routes.Route[];
};

/**
 * Context for sharing state across manage users components
 */
// @ts-ignore
export const ManageUsersContext = createContext<ManageUsers.ManageUsersContext>();

/**
 * Main component for managing users functionality
 * @param {Props} props - Component props
 * @returns {JSX.Element} Rendered component
 */
const ManageUsers: FunctionComponent<Props> = (props) => {
	const { manageUserRoutes } = useManageUserRoutes();

	/**
	 * Component state management
	 */
	const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
	const [showUnsavedModal, setShowUnsavedModal] = useState<boolean>(false);
	const [showResetModal, setShowResetModal] = useState<boolean>(false);
	const [nextRoute, setNextRoute] = useState<string>('');
	const [resetData, setResetData] = useState<boolean>(false);

	const dispatch = useDispatch();

	/**
	 * Context values shared across components
	 * @type {ManageUsers.ManageUsersContext}
	 */
	//@ts-ignore
	const ManageUsersContextValues: ManageUsers.ManageUsersContext = {
		saveDisabled,
		setSaveDisabled,
		showResetModal,
		setShowResetModal,
		resetData,
		setResetData,
		setShowUnsavedModal,
		showUnsavedModal,
	};

	/**
	 * History management
	 */
	const history = useHistory();

	/**
	 * Blocks navigation when there are unsaved changes
	 * @param {Object} param0 - Navigation details
	 * @param {string} param0.pathname - Target path
	 * @returns {boolean} Whether navigation should be blocked
	 */
	const unblock = history.block(({ pathname }): any => {
		if (!saveDisabled && !!pathname) {
			setShowUnsavedModal(true);
			setNextRoute(pathname);
		}

		return saveDisabled;
	});

	/**
	 * Handles navigation after confirming unsaved changes
	 */
	const handleNavigation = (): void => {
		setSaveDisabled(true);
		unblock();
		setShowUnsavedModal(false);
		history.push(nextRoute);
		setNextRoute('');
		setResetData(true);
		dispatch(listUsersThunk());
	};

	/**
	 * Handles resetting page data
	 */
	const handleReset = () => {
		setShowResetModal(false);
		setResetData(true);
	};

	return (
		<Fragment>
			{/* Page Title */}
			<PageTitle routes={props.routes} />

			{/* Navigation modal */}
			{showUnsavedModal && (
				<UnsavedChangeModal
					setShowModal={() => setShowUnsavedModal(false)}
					handleNavigation={() => handleNavigation()}
				/>
			)}

			{/* Reset modal */}
			{showResetModal && (
				<ResetChangesModal
					setShowResetModal={() => setShowResetModal(false)}
					handleReset={() => handleReset()}
				/>
			)}

			{/* Page Layout */}
			<ManageUsersContext.Provider value={ManageUsersContextValues}>
				<PageWithSidebarNew
					sidebarContent={<SecondaryNavigation setAs="primary" links={manageUserRoutes} />}
					dataTest="manageUsers"
				>
					<GetRoutes routes={props.routes} />
				</PageWithSidebarNew>
			</ManageUsersContext.Provider>
		</Fragment>
	);
};

export default withTransaction('Manage Users', 'component')(ManageUsers);
