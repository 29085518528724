import { cancel, put, retry, select } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import {
	setComponentLoading,
	setKs4HasBeenChecked,
	setComponentErrors,
} from '../../ks5PerformanceMeasures/singleSchool/slice';
import { getGridDataForGridId } from '../../../../../../sharedSelectors';
import { sagaFlowConfigurations } from '../../../../../../constants';
import { getBest3GradeDistributionProfileDataBySchoolId } from '../../../../../../api/groupsAPI';
import { getActiveChildSchool, getGroupAccessId } from 'features/app/redux/context';
import { setMainGridDataSuccess } from '../../ks5PerformanceMeasures/singleSchool/slice';
import { getGroupAccessRecords } from 'features/groups/setup/redux';
import { getKs4HasBeenChecked } from '../../ks5PerformanceMeasures/singleSchool/selectors';
import { translate } from '../../../../../../utils/locale';

/**
 * Get the best 3 grade distribution profile grid data
 * @returns {SagaIterator<void>}
 */
export function* getBest3GradeDistributionProfileGridFlow(): SagaIterator<void> {
	//get the school id
	const schoolId = yield select(getActiveChildSchool);

	if (!schoolId) {
		//bail out of the saga
		yield cancel();
	}

	//get the data from the store
	const initialData = yield select(
		getGridDataForGridId(
			'matSingleSchoolPerformanceMeasures',
			`0-${schoolId.childSchoolId},BestThreeGradeDistributionProfile`
		)
	);
	yield put(
		setComponentErrors({ component: 'best3GradeDistributionProfileGrid', value: undefined })
	);

	//set the component loading state to true
	yield put(setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: true }));

	//get the group access id
	const groupAccessId = yield select(getGroupAccessId);

	//if the group access id is 0, bail out of the saga
	if (groupAccessId === 0) {
		//set the errors for this component
		yield put(
			setComponentErrors({
				component: 'best3GradeDistributionProfileGrid',
				value: translate('groups.ks5Pm.common.NO_ACCESS_ID') as string,
			})
		);
		//set the component loading state to false
		yield put(
			setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: false })
		);
		//bail out of the saga
		yield cancel();
	}

	const accessRecords = yield select(getGroupAccessRecords);

	const isKs4 = accessRecords?.find((x) => x.id === groupAccessId).examLevel === 32;
	const ks4HasBeenChecked = yield select(getKs4HasBeenChecked);

	if (isKs4) {
		if (!ks4HasBeenChecked) {
			yield put(setKs4HasBeenChecked(true));
		}
		yield put(
			setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: false })
		);

		//error
		yield cancel();
	}
	yield put(setKs4HasBeenChecked(false));
	//check to see if the data is already in the store
	if (initialData !== undefined) {
		yield put(
			setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: false })
		);
		yield cancel();
	}

	//get the data from the api
	const best3GradeDistributionProfileData = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getBest3GradeDistributionProfileDataBySchoolId,
		groupAccessId,
		schoolId.childSchoolId
	);

	if (best3GradeDistributionProfileData.hasError) {
		yield put(
			setComponentErrors({
				component: 'best3GradeDistributionProfileGrid',
				value: translate('groups.ks5Pm.common.MESSAGE') as string,
			})
		);
		yield put(
			setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: false })
		);
		yield cancel();
	}

	//set the data in the store
	yield put(setMainGridDataSuccess(best3GradeDistributionProfileData.responseObject));

	//set the component loading state to true
	yield put(setComponentLoading({ component: 'best3GradeDistributionProfileGrid', value: false }));

	//Bail out of the saga
	yield cancel();
}
