import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBulletTrainFeature } from 'flagsmith';
import { translate } from '../../../utils/locale';
import { hasPermission } from 'features/app/redux/permissions';
import JSON5 from 'json5';

const initialState: Features.State = {
	loadingFlags: true,
	flags: {
		alps_summit: true,
		cache_subject_page_data: false,
		can_view_pdf_subject_filter: true,
		can_view_sidebar: true,
		comparisons_high_mid_low: true,
		embargo_feature: false,
		enable_connect_data: true,
		enable_error_boundary: true,
		filters_high_mid_low: true,
		groups_monitoring_timeline: true,
		groups_view_as_school: true,
		hide_qi8: true,
		home_page_export_modal: true,
		ks4_perf_measures: true,
		ks4_perf_measures_spo: true,
		ks4_pms_student_table: false,
		ks4_progress_8: true,
		ks4_progress_8_spo: true,
		ks4_subject_pages: false,
		ks5_a_level_perf_measures: true,
		ks5_ib_perf_measures: true,
		ks5_perf_measures: true,
		maintenance_mode: false,
		my_reports: true,
		pa_display_precision: true,
		reporting_season: false,
		show_auto_renew_toggle: true,
		show_contracts: true,
		show_error_boundary_info: false,
		show_status_toggle: true,
		show_under_target_columns: false,
		strategic_analysis: false,
		strategic_analysis_old: true,
		subjects_page_save_reports: true,
		subscriptions: true,
		zoho_help: true,
		news_feed_enabled: false,
		use_old_pricing: false,
		strategic_embedded_tables: false,
		spo_meg_count_columns: false,
		enable_paywall_restrictions: false,
		alternative_pa: false,
		client_benchmark: false,
		summit_benchmark: false,
		show_change_prior_attainments: false,
		subject_area_attainment_profile: false,
		trends_pt: false,
		enable_mfa: false,
		enable_meg_roy_export: false,
		student_vas: false,
		alps_annual_census: false,
		hide_organisation_management: true,
		summit_ks5: false,
		ui_subjects_taken: false,
		enable_reasons_for_leaving: false,
	},
	config: {
		maintenance_title: translate('error.maintenance.TITLE') as string,
		maintenance_message: translate('error.maintenance.MESSAGE') as string,
		zoho_link: 'https://alpseducation.zohodesk.eu/portal',
		tag_manager_id: '',
		error_boundary_blacklist: {
			types: [],
			messages: [],
		},
		census_window: {
			start: ' ',
			end: ' ',
		},
		early_access_ks4: {
			start: ' ',
			end: ' ',
		},
		early_access_ks5: {
			start: ' ',
			end: ' ',
		},
		password_complexity: {
			LowerCase: '',
			UpperCase: '',
			Numbers: '',
			SpecialCharacters: '',
			Length: '',
		},
		avaliable_benchmarks: [],
	},
};

export const features = createSlice({
	name: 'features',
	initialState,
	reducers: {
		setFeatureFlag(
			state: Features.State,
			action: PayloadAction<{ id: keyof Features.Flags; enabled: boolean }>
		): Features.State {
			return {
				...state,
				flags: {
					...state.flags,
					[action.payload.id]: action.payload.enabled,
				},
			};
		},

		setFeatureConfig(
			state: Features.State,
			action: PayloadAction<{ id: keyof Features.Config; value: string }>
		): Features.State {
			let value = action.payload.value;

			try {
				value = JSON5.parse(action.payload.value);
			} catch (e) {}

			return {
				...state,
				config: {
					...state.config,
					[action.payload.id]: value,
				},
			};
		},

		setLoadingFlags(state: Features.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loadingFlags: action.payload,
			};
		},

		setBulkFlagsAndConfig(
			state: Features.State,
			action: PayloadAction<{ flags: { [id: string]: boolean }; config: { [id: string]: string } }>
		): Features.State {
			// parse each config value as JSON if applicable
			const config = Object.entries(action.payload.config).reduce((config, [key, value]) => {
				let parsedValue = value;

				try {
					parsedValue = JSON5.parse(value);
				} catch (e) {}

				return {
					...config,
					[key]: parsedValue,
				};
			}, {});

			return {
				...state,
				loadingFlags: false,
				flags: {
					...state.flags,
					...action.payload.flags,
				},
				config: {
					...state.config,
					...config,
				},
			};
		},
	},
});

export default features.reducer;

export const {
	setFeatureFlag,
	setFeatureConfig,
	setLoadingFlags,
	setBulkFlagsAndConfig,
} = features.actions;

// Get default flags from initial state, formatted for flagsmith
export const getInitialFlags = (): { [feature: string]: IBulletTrainFeature } => {
	if (
		typeof window.flagsmithDefaultFlags === 'object' &&
		Object.keys(window.flagsmithDefaultFlags ?? {}).length
	) {
		return window.flagsmithDefaultFlags;
	}

	return Object.entries(initialState.flags).reduce((struct, [id, enabled]) => {
		return {
			...struct,
			[id]: {
				enabled,
			},
		};
	}, {});
};

export const getInitialConfig = () => {
	return Object.entries(initialState.config).reduce((struct, [id, value]) => {
		return {
			...struct,
			[id]: {
				value: typeof value === 'string' ? value : JSON.stringify(value),
			},
		};
	}, {});
};

export const getFeature = (featureId: keyof Features.Flags) => (state: RootState): boolean => {
	return (
		((state.features as Features.State).flags[featureId] ?? false) &&
		hasPermission(featureId)(state)
	);
};

export const getConfig = (configId: keyof Features.Config) => (state: RootState): any => {
	return (state.features as Features.State).config[configId];
};

export const getLoadingFlags = (state: RootState): boolean => {
	return state.features.loadingFlags;
};
