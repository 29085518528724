import { SagaIterator } from 'redux-saga';
import { cancel, put } from 'redux-saga/effects';

import { resetBest3CumulativePercentageState } from '../ks5PerformanceMeasures/best3CumulativePercentage/slice';
import { resetCumulativePercentageState } from '../ks5PerformanceMeasures/cumulativePercentage/slice';
import { resetHeadlinePerformanceMeasuresState } from '../ks5PerformanceMeasures/headlineMeasures/slice';
import {
	getSummitInitialPerformanceMeasures,
	triggerSummitPmsFlow,
} from '../ks5PerformanceMeasures/slice';

/**
 * Saga that resets the Summit performance measures state
 */
export function* resetSummitPerformanceMeasuresFlow(): SagaIterator<void> {
	//reset all states
	//Tab 1 - Headline Measures
	yield put(resetHeadlinePerformanceMeasuresState());
	//Tab 2 - Cumulative Percentage
	yield put(resetCumulativePercentageState());
	//Tab 3 - Best 3 Cumulative Percentage
	yield put(resetBest3CumulativePercentageState());

	//trigger data fetch again
	//This triggers the saga to fetch the data for tab 1
	yield put(getSummitInitialPerformanceMeasures());
	//This triggers the saga to fetch the data for tab 2 and 3
	yield put(triggerSummitPmsFlow());

	yield cancel();
}
