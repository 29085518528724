const CST_NUMBER = '(+44) 01484 887600';
const TERMS_CONDITIONS_LINK = 'https://alps.education/downloads/connect/Alps_Terms_Conditions.pdf';
const AUTO_RENEWAL_LINK =
	'https://alps.education/wp-content/uploads/2022/04/Auto-renewal_of_your_subscription.pdf';

export default {
	/**
	 * Primary Navigation
	 */
	primaryNavigation: {
		connect: {
			root: {
				STRATEGIC_ANALYSIS: 'Strategic Analysis',
				STRATEGIC_ANALYSIS_OLD: 'Strategic Analysis (Old)',
				SUBJECT_ANALYSIS: 'Subject Analysis',
				STUDENT_ANALYSIS: 'Student Analysis',
				MONITORING: 'Monitoring Accuracy',
				KS4_PERFORMANCE_MEASURES: 'KS4 Performance Measures',
				KS5_PERFORMANCE_MEASURES: 'A Level PMs',
				IB_PERFORMANCE_MEASURES: 'IB Analysis',
			},
			subjects: {
				OVERVIEW: 'Overview',
				STUDENTS: 'Students',
				OUTCOMES: 'Outcomes',
				TRENDS: 'Trends',
				FINE_GRADES: 'Fine Grades',
				SAVED: 'Saved',
				GAP: 'Gap Analysis',
				ATTAINMENT: 'Attainment',
				COHORT: 'Cohort Profile',
				HOME: 'Home',
			},
			reports: {
				ADDITIONAL: 'Additional Downloads',
				MY_REPORTS: 'My Reports',
				SCHOOL_REPORTS: 'School and College Reports',
				MEG_REPORTS: 'MEG Reports',
			},
			monitoring: {
				DIFFERENCE: 'Difference',
				VARIANCE: 'Variance',
				EXAM_VS: 'Exam Vs Monitoring',
			},
			ks4Measures: {
				ATTAINMENT: 'Attainment Measures',
				PROGRESS: 'Progress 8 Measures',
			},
			settings: {
				USERS: 'Manage Users',
				SETTINGS: 'Analysis Settings',
				ACCOUNT: 'Manage Account',
			},
			spo: {
				OVERVIEW: 'Overview',
				KS4_ATTAINMENT_MEASURES: 'Attainment Measures',
				KS4_P8_MEASURES: 'Progress 8 Measures',
			},
			strategicAnalysis: {
				SUMMARY: 'Summary',
				OVERVIEW: 'Overview',
				BBPA_CURRENT_YEAR: 'BBPA Current Year',
				BBPA_MULTI_YEAR: 'BBPA Multi Year',
				PERFORMANCE_MEASURES: 'Performance Measures',
				QUALITY_INDICATOR: 'Quality Indicator',
				QUALITY_OF_TEACHING: 'Quality of Teaching',
				ATTAINMENT_PROFILE: 'Attainment Profile',
				STUDENTS_NOT_INCLUDED: 'Students Not Included',
				SUBJECT_VALUE_ADDED: 'Subject Value Added',
				T_SCORE: 'T Score',
			},
		},
		summit: {
			root: {
				STRATEGIC: 'Strategic Analysis',
				BBPA: 'BBPA Analysis',
				SUBJECTS_ANALYSIS: 'Subject Value Added Overview',
				OVERVIEW_OF_PROVIDERS_IN_GROUP: 'Overview of Providers in Group',
				PROVIDER_LEVEL_SUMMARY: 'Provider Level Summary',
			},
			reports: {
				GROUP_REPORTS: 'Group Reports',
				SCHOOL_REPORTS: 'School and College Reports',
			},
		},
	},
	error: {
		noJavascript: {
			TITLE: 'You dont have JavaScript Enabled',
			MESSAGE: 'To use Connect you must enable JavaScript in your browser',
		},
		maintenance: {
			TITLE: "We'll be back soon!",
			MESSAGE: 'Connect is currently undergoing maintenance. We are sorry for any disruption.',
		},
		boundary: {
			TITLE: 'An unexpected error occurred',
			MESSAGE: `We are sorry for any inconvenience. Please try again, or if the problem persists contact our customer support team on ${CST_NUMBER}`,
			RELOAD: 'Restart Connect',
			CALLSTACK: 'Callstack',
		},
	},
	termsAcceptance: {
		TITLE: 'Action Required',
		ACCEPT_TERMS: 'Accept',
		DECLINE_TERMS: 'Sign Out',
		HEADER: 'Terms & Conditions',
		BODY: `Alps’ Terms and Conditions have been updated to include additional provisions relating to the auto-renewal of your subscription, training provisions and updates to GDPR clauses. Here is a copy of the <a target="_blank" href="${TERMS_CONDITIONS_LINK}">updated terms and conditions</a> and details of the changes are included in Annex A at the end. Here is a <a target="_blank" href="${AUTO_RENEWAL_LINK}">note on the new auto-renewal clauses</a>.`,
		BODY1:
			'Please note that you have the option to cancel your auto-renewal at any time up to 60 days before the date of renewal. We will notify you of this date in advance. If you would like to cancel your auto-renewal now, please contact us at <a href="mailto:support@alps-va.co.uk">support@alps-va.co.uk</a>',
		BODY2:
			'Please review and agree to the new terms and conditions before proceeding to the platform.',
	},
	exportModal: {
		DEFAULT_FILE_NAME: 'AlpsConnectExport',
		TITLE: 'Export Page',
		CANCEL: 'Close',
		FILENAME: 'Filename',
		download: {
			XLSX: 'Export Excel',
			PDF: 'Export PDF',
		},
	},
	/**
	 ** Routes
	 */
	routes: {
		home: {
			singleSchool: {
				STRATEGIC: 'Strategic Analysis',
				SUBJECTS: 'Subject Analysis',
				STUDENTS: 'Student Analysis',
				MONITORING: 'Monitoring Accuracy',
			},
			groups: {
				STRATEGIC: 'Strategic Analysis',
				SUBJECTS: 'Subject Analysis',
				BBPA: 'BBPA Analysis',
				STUDENTS: 'Student Analysis',
				MONITORING: 'Monitoring Accuracy',
			},
		},
		manageAccount: {
			CHANGE_PASSWORD: 'Change Password',
			SUBSCRIPTIONS: 'Subscribe',
		},
	},
	/**
	 * Modal Messages
	 */
	modalMessages: {
		pdfExportModal: {
			TITLE: 'The way you download PDF exports has changed',
			BODY:
				'We have updated the way in which your PDF exports are generated to make the process more efficient. <br /><br /> On clicking the ‘export’ button you will now see a pop-up window which acknowledges your export request. You can continue to work in Connect without having to wait for the export to appear on the screen. <br /><br />It may take a few minutes to produce your export. Once ready, you will see a red number appear on the message icon in the top right hand corner of Connect. Please click on this icon to download your export.',
			ACCEPT: 'I acknowledge the new method for generation of exports',
			CLOSE: 'Close',
		},
	},
	/**
	 ** Alps Admin
	 */
	alpsAdmin: {
		PAGE_TITLE: 'Alps Admin',
		sidebar: {
			HEADING: 'Alps Admin',
			links: {
				ADMIN_SEARCH: 'Search',
				DETAILS: 'Details',
				ALL_USERS: 'Users',
				ENTITLEMENTS: 'Entitlements',
				CONFIG: 'Configuration',
				SUBSCRIPTIONS: 'Subscriptions',
				VIEW_AS_CLIENT: 'View Connect as Client',
				CONTRACTS: 'Contracts',
				PATYPE_CONFIG: 'Prior Attainment',
			},
		},
		alpsAdminSearch: {
			PAGE_TITLE: 'Alps Admin Search',
			PLACEHOLDER: 'Search for a Client Name or Reference',
		},
		tooltip: {
			ADMIN: 'Admin',
			USER: 'User',
			IMPERSONATE: 'Impersonate',
		},
		details: {
			provider: {
				HEADING: 'Provider',
				NAME: 'Name',
				REFERENCE: 'Reference',
				STATUS: 'Status',
			},
			contact: {
				HEADING: 'Contact',
				CITY: 'City',
				COUNTY: 'County',
				COUNTRY: 'Country',
				POSTCODE: 'Postcode',
				TELEPHONE: 'Telephone',
				EMAIL: 'Email',
				WEB: 'Web Address',
			},
			activationCode: {
				CREATE_NEW_ACTIVATION_CODE: 'Create new activation code',
				SUCCESSFULLY_CREATED_NEW_ACTIVATION_CODE:
					'A new activation code has been successfully created',
				FAILED_NEW_ACTIVATION_CODE:
					'There was a problem with creating new activation code for the client',
				IN_PROGRESS: 'In Progress ....',
				DESCRIPTION:
					'This will disable the users for this school only and create a new activation code. If the users are also linked to other schools they will not be affected but please ensure this is intentional before doing it.',
				HEADING: 'Manage Activation Code',
			},
		},
		paType: {
			HEADING: 'Change Prior Attainment Type for School Year',
			ACYEAR: 'Academic Year',
			YEARGROUP: 'Year Group',
			PATYPE: 'Prior Attainment Type',
			INFO_HEADING: 'Information',
			DIALOG_HEADING: 'REMEMBER - CST!',
			DIALOG_BODY:
				'<b>The changes have been saved,remind the client to:- <br/><br/> To refresh their screen. <br/>Logout and back in. <br/>Import any new PA data needed. <br/>REPUBLISH any existing gradepoints.</br>If no cohort changes are going to be made, please republish for the client, this will not set the gradepoints to pending.<b/> ',
			DIALOG_BODY_ERROR:
				'There was a problem trying to update the Prior Attainment Type. Please try again!.',
			PA_CHANGE:
				'You are changing the Prior Attainment Types from <b>%{currentPA}</b> to <b>%{newPA}</b> .',
			PA_CHANGE_TEXT:
				'Please advise the client the number of students that will be affected by the change!',
			PALIST_ITEM1: 'Current Prior Attainment',
			PALIST_ITEM2: 'Student Count',
			PALIST_ITEM3: 'KS4 Prior Attainments',
			PALIST_ITEM4: 'KS5 Prior Attainments',
			PALIST_ITEM5: 'CAT4 Prior Attainments',
		},
		entitlements: {
			HEADING_1: 'Current Entitlements',
			HEADING_2: 'Previous Entitlements',
			HEADING_3: 'Configuration',
			HEADING_4: "Edit an entitlement's information",
			HEADING_5: 'Entitlement information',
			HEADING_6: 'Academic Year',
			HEADING_7: 'Start date',
			HEADING_8: 'End date',
			HEADING_9: 'In year monitoring',
			HEADING_10: 'Future Entitlements',
			HEADING_11: 'Add New Entitlement',
			HEADING_12: 'Group setup',
			config: {
				DATASET: 'Dataset',
				CONFIG: 'Configuration',
			},
			agGrid: {
				START: 'Start',
				END: 'End',
				IN_YEAR: 'In Year',
				GROUP_SETUP: 'Group Setup',
				EXAM_LEVELS: 'Exam Levels',
				EDIT: 'Edit',
				ADD: 'Add',
			},
		},
		contracts: {
			agGrid: {
				CONTRACT_NAME: 'Contract Name',
				LEVELS: 'Levels Included',
				DATA_SHARING: 'Data Sharing Enabled',
				START: 'Start Date',
				END: 'End Date',
				EDIT: 'Edit',
				NAME: 'Name',
				REFERENCE: 'Reference',
				NO_CLIENTS: 'Number of Clients',
				PARENT_NAME: 'Name of Parent',
			},
			HEADING_PREVIOUS: 'Previous Contracts',
			HEADING_CURRENT: 'Current Contracts',
			HEADING_FUTURE: 'Future Contracts',
			HEADING_CONTRACTS: 'Contracts',
			EDIT_HEADING: 'Edit Contract Start & End dates for academic year',
			EDIT_HEADING_INFORMATION: 'Contract Information',
			EDIT_NOTICE: '',
			errors: {
				PAST_START_DATE: 'A date in the past is not allowed',
				END_BEFORE_START: 'Start Date must be before End Date',
				INVALID: 'Invalid date',
				OUT_OF_ACADEMIC_YEAR: 'The date must be within the Academic Year',
				SAME_DATE: 'Start/End cannot be the same Date',
			},
		},
		subscriptions: {
			HEADING: 'Subscriptions',
			EDIT_HEADING: 'Edit Subscription',
			HEADING_SUBSCRIPTION_INFORMATION: 'Subscription Information',
			HEADING_ADD_SUBSCRIPTION: 'Add New Subscription',
			HEADING_SUBSCRIPTION_NOTE: 'Subscription Status Change Note',
			HEADING_SUBSCRIPTION_STATUS: 'Subscription Status',
			EDIT_NOTICE: 'Please note: Only the subscription status can be modified at this time.',
			HEADING_SUBSCRIPTION_SIZE: 'Select students this subscription will cover',
			HEADING_SUBSCRIPTION_LENGTH: 'Subscription Length',
			HEADING_SUBSCRIPTION_LEVEL: 'Subscription Level',
			HEADING_SUBSCRIPTION_COST: 'Subscription Cost',
			HEADING_DUPLICATE_SUBSCRIPTION: 'Duplicate Subscription',
			DUPLICATE_SUBSCRIPTION_MESSAGE:
				'There is already an active subscription for the key stage and dates requested. Please cancel the existing subscription or change the dates.',
			SAVE: 'Save',
			AUTO_RENEW: 'Auto Renewal Enabled',
			agGrid: {
				START: 'Start',
				END: 'End',
				SUBSCRIPTION_SIZE: 'Size (Students)',
				SUBSCRIPTION_LENGTH: 'Length (Years)',
				LEVEL: 'Level',
				STATUS: 'Status',
				EDIT: 'Edit',
				DELETE: 'Delete',
			},
			deleteModal: {
				ARE_YOU_SURE: 'Are you sure you want to delete the following subscription?',
			},
			subscriptionStatus: {
				INCLUDED_IN_CONTRACT: 'Included in Contract',
				AVAILABLE_FOR_RENEWAL: 'Available for renewal',
				CURRENT_SUBSCRIPTION: 'Current Subscription',
				FUTURE_SUBSCRIPTION: 'Future Subscription',
				PENDING_SUBSCRIPTION: 'Pending Subscription',
			},
			autorenewal: {
				ON: 'Auto renewal is on',
				OFF: 'Auto renewal is off',
			},
		},
		viewAsClient: {
			INTRO:
				"Select the options below to view Connect as this client. You can do this for any client.<br/>You will be given access to all levels and exam results and in-year data, regardless of the client's current entitlement.<br/>Please do not edit any client entitlements for viewing Connect.",
			WARN: 'Please do not edit any client entitlements for viewing Connect.',
			options: {
				HEADING: 'Options',
				DATASET: 'Dataset',
				CONFIG: 'Configuration',
			},
			GO: 'View as Client',
		},
		errors: {
			REQUIRED_ERROR: 'This Field is required',
		},
		permissions: {
			ACCESS: 'No Access',
			PDF_NO_DATA: 'View Full PDF Reports',
			DATA_AND_PDF: 'Upload Data & View Full PDF Reports',
		},
		heading: {
			MAIN_TITLE: "Edit a user's information",
			USER_INFO: 'User Information',
			EMAIL: 'Email Address',
			FULL_NAME: 'Full Name',
			JOB_TITLE: 'Job Title',
			PERMISSIONS: 'User Information',
			CONNECT_DATA: 'Connect Data',
			ADMIN: 'Admin',
			DISABLE: 'Disable User Account',
		},
		agGrid: {
			EMAIL: 'Email',
			FULL_NAME: 'Full Name',
			JOB_TITLE: 'Job Title',
			STATUS: 'Status',
			EDIT: 'Edit',
		},
		notice: {
			PARA_ONE:
				'Please note that adding a user to your Organisation will grant them access to your data.',
			PARA_TWO:
				'Complete the users details and we will email the address provided with instructions for the new user to set their password.',
		},
		modals: {
			generic: {
				OK_ACTION: 'Ok',
			},
			resetChangesModal: {
				ACTION_TITLE: 'Okay',
				CURRENT_CHANGES: 'Are you sure you want to cancel your current changes?',
				DELETED_MESSAGE: 'All changes made will be permanently deleted.',
			},
			searchBar: {
				LABEL: 'Search',
				PLACEHOLDER: 'Search users..',
			},
			unsavedChanges: {
				ACTION_TITLE: 'Okay',
				WARNING_ONE: 'You have unsaved changes!',
				WARNING_TWO: 'Are you sure you want to navigate away? All unsaved changes will be lost.',
			},
			confirmationModal: {
				SUCCESS_TITLE: 'Success',
				SUCCESS: 'Your details have been updated.',
				ERROR_TITLE: 'Error',
				ERROR: 'Unfortunately we have been unable to update your details.',
			},
		},
	},
	/**
	 * Reporting Season
	 */
	reportingSeason: {
		nav: {
			HEADER: 'Reporting Season Data',
			sidebar: {
				CLIENT_INFO: 'Client Info',
			},
		},
		components: {
			overall: {
				HEADER: 'Customer Overview Grid',
			},
			groups: {
				HEADER: 'Customer Groups Table',
			},
			filterBar: {
				EXPORT: 'Export Excel',
				TOGGLE: 'Toggle MAT View',
				SEARCH: 'Search Clients',
			},
		},
		clientInfo: {
			overallGrid: {
				gridHeaders: {
					DATE: 'Date of Submission',
					NAME: 'Account Name',
					PARENT_NAME: 'Parent Name',
					REF: 'Reference',
					CLIENT_TYPE: 'Client Type',
					CLIENT_TYPE_KS4: 'Client Type KS4',
					CLIENT_TYPE_KS5: 'Client Type KS5',
					DATE_OF_SUBMISSION_KS4: 'Date of submission KS4',
					DATE_OF_SUBMISSION_KS5: 'Date of submission KS5',
					CONTRACT_PARENT_KS4: 'Contract Parent KS4',
					CONTRACT_PARENT_KS5: 'Contract Parent KS5',
					TARGET_SETTING_KS4: 'Target Setting KS4',
					TARGET_SETTING_KS5: 'Target Setting KS5',
					COUNT_OF_DAYS_FROM_EMBARGO_KS4: 'Count of Days from Embargo KS4',
					COUNT_OF_DAYS_FROM_EMBARGO_KS5: 'Count of Days from Embargo KS5',
					LEVEL: 'Level',
					START: 'Start Date',
					END: 'End Date',
					YEAR: 'Academic Year',
					NUMBER_OF_YEARS: 'Subscription Length',
					GRADEPOINT: 'Gradepoint Name',
					STUDENT: 'No. of Students',
					ENTRIES: 'No. of Entries',
					DATA_SHARING: 'Data Sharing',
					EXAMLEVEL: 'Exam Level',
					ACCOUNT: 'Account Ref',
					A: 'A',
					AS: 'AS',
					BTEC: 'BTEC',
					BTECCERT: 'BTECCERT',
					BTECCRED: 'BTECCred',
					KS4: 'KS4',
					KS5: 'KS5',
					KS4KS55: 'KS4/KS5',
					PRE: 'Pre-U',
					IB: 'IB',
					LA: 'LA',
					KS5_STUDENT_COUNT: 'KS5 Student Count',
					KS5_SUBJECT_ENTRY_COUNT: 'KS5 Subject Entry Count',
					KS4_STUDENT_COUNT: 'KS4 Student Count',
					KS4_SUBJECT_ENTRY_COUNT: 'KS4 Subject Entry Count',
					groupHeaders: {
						CLIENT_INFORMATION: 'Customer information',
						KS4: 'KS4',
						KS5: 'KS5',
						EXAM_LEVELS: 'Exam Levels',
						ENTITLEMENTS: 'Entitlements',
					},
				},
			},
			groupsGrid: {
				gridHeaders: {
					CONTRACT: 'Contract Parent',
					Z_REF: 'Z Ref',
				},
			},
		},
	},
	/**
	 ** Standard Page
	 */
	standardPage: {
		userProfileDropdown: {
			SELECT_ORGANISATION: 'Select Organisation',
			END_IMPERSONATION: 'End Impersonation Session',
			SIGN_OUT: 'Sign Out',
			MANAGE_ACCOUNT: 'Manage Account',
		},
		gradepointSelector: {
			CURRENT_GRADEPOINT: 'Current Gradepoint',
			CURRENT_TREND: 'Current Trend',
		},
		benchmarkButton: {
			CURRENT_BENCHMARK: 'Current Benchmark',
			HELP_TEXT:
				'Selection of benchmark will affect value-added analysis. Our recommendation for the most appropriate benchmark across specific academic years and cohorts can be found <a style="color: white;text-decoration: none;" href="https://support.alps.education/portal/en/kb/articles/recommendations-for-benchmark-selection" target="_blank">here</a>',
		},
		warnings: {
			CLIENT_BENCHMARK_ACTIVE: 'Alps Provider',
			NATION_BENCHMARK_ACTIVE: 'National',
		},
	},
	grid: {
		NO_ROWS_MESSAGE: 'No data to display',
		ERROR_MESSAGE: `An error occurred generating your report. <br />Please try again, or if the problem persists contact our customer support team on ${CST_NUMBER}`,
		NO_DATA_FOR_GP: `You have no data for your selected Gradepoints. Please try again, or if the problem persists contact our customer support team on ${CST_NUMBER}`,
		RETRY: 'Retry',
	},
	/**
	 ** RTM
	 */
	rtm: {
		noMessage: {
			HEADING: 'All up to date',
			BODY: 'You have no new messages. When you have new messages they will appear here.',
		},
		clearMessage: 'Clear All',
		downloading: 'Downloading... please wait',
	},
	/**
	 ** Gradepoint Selector
	 */
	gradepointSelector: {
		WARNING_MESSAGE: 'You must select at least one Gradepoint before continuing.',
		gradepointLabel: {
			TYPE_MONITORING_POINT: 'monitoring point ',
			TYPE_EXAM_RESULTS: 'exam results ',
			FOR: 'for ',
			FROM: 'from ',
		},
		SELECT_PLACEHOLDER_TEXT: 'Select ...',
		filters: {
			ACADEMIC_YEAR: 'Filter by Academic Year',
			YEAR_GROUP: 'Filter by Year Group',
			GRADEPOINT_TYPE: 'Filter by Gradepoint Type',
			AVAILABLE_GRADEPOINTS: 'Available Gradepoints',
			AVAILABLE_SESSION_GRADEPOINTS: 'Available Gradepoints For Active Strategic Analysis Session',
		},
		gradepoint: {
			label: {
				MAIN_GRADEPOINT: 'Main Gradepoint',
				COMPARISON_GRADEPOINT: 'Comparison Gradepoint',
				AUTO_SELECTED: ' - auto selected',
			},
		},
	},
	trendSelector: {
		KEY_STAGE: 'Please select a Key Stage',
		ACADEMIC_YEAR: 'Please select an Academic Year',
		NO_TRENDS_AVALIBLE: 'No Trends avalible for given Academic Year',
		DROP_DOWN_VALUE: 'Please select Key Stage',
		PLEASE_SELECT: 'Please select a pre-configured trend:',
		SELECTED_TREND: 'Your selected trend contains the following Gradepoints:',
	},
	/**
	 ** Home
	 */
	home: {
		welcome: {
			HEADING: 'Welcome to Alps Connect, ',
		},
		gradepointTrendSelectorTile: {
			stepHeading: {
				STEP: '1',
				HEADING: 'Your Current Gradepoint Trend is ',
				TEXT:
					'You can easily change this at anytime by clicking on the Gradepoint button at the top of your screen or clicking on the button below.',
				NONE_SELECTED: 'None Selected',
			},
			CHANGE_TREND: 'Change the GP Trend',
			FIND_OUT_MORE: 'Find Out More',
			FIND_OUT_MORE_URL:
				'https://support.alps.education/portal/en/kb/articles/using-the-gradepoint-selection-tool',
		},
		gradepointSelectorTile: {
			stepHeading: {
				STEP: '1',
				HEADING: 'Your Current Gradepoint is ',
				TEXT: 'You can keep this Gradepoint or change it by selecting option A, B or C.',
				NONE_SELECTED: 'None Selected',
			},
			optionTiles: {
				optionA: {
					OPTION_TEXT: 'Option A',
					TITLE: 'Results Analysis Data',
					HELP_TEXT: 'Click Examination Trend for your latest examination results.',
					HELP_LINK:
						'https://support.alps.education/portal/en/kb/articles/using-the-gradepoint-selection-tool',
					EXAMINATION_TREND: 'Examination Trend',
				},
				optionB: {
					OPTION_TEXT: 'Option B',
					TITLE: 'Latest Monitoring Data by Year Group',
					HELP_TEXT: 'Click a Year Group for their latest Monitoring Gradepoint.',
					HELP_LINK:
						'https://support.alps.education/portal/en/kb/articles/using-the-gradepoint-selection-tool',
				},
				optionC: {
					OPTION_TEXT: 'Option C',
					TITLE: 'Gradepoint Selector',
					SUBTITLE: 'Choose bespoke data points',
					HELP_TEXT:
						'Click to open the Gradepoint Selector to choose any combination of Results and/or Monitoring Gradepoints.',
					HELP_LINK:
						'https://support.alps.education/portal/en/kb/articles/using-the-gradepoint-selection-tool',
					NONE_SELECTED: 'None Selected',
				},
			},
			latestMonitoringByYearGroupTile: {
				NO_YEAR_GROUPS_AVAILABLE: 'No year groups available',
			},
		},
		chooseYourSummitAnalysisZone: {
			strategicAnalysisZoneTile: {
				HEADING: 'Strategic Analysis',
				TEXT: 'Analyse the overall value-added and attainment data for the whole school/college',
				URL: '/groups/strategic',
			},
			priorAttainmentAnalysisZoneTile: {
				HEADING: 'Prior Attainment Analysis',
				TEXT: 'Analyse value-added data across Alps determined prior attainment bands',
				URL: '/groups/bbpa',
			},
			subjectAnalysisZoneTile: {
				HEADING: 'Subject Analysis',
				TEXT: 'Analyse subject value-added across all schools and colleges',
				URL: '/groups/subjects',
			},
			overviewOfProvidersInGroupZoneTile: {
				HEADING: 'Overview of Providers in Group',
				TEXT: 'Analyse subject value-added across all schools and colleges',
				URL: '/groups/ks5pm/group-overview',
			},
			providerLevelSummaryZoneTile: {
				HEADING: 'Provider Level Summary',
				TEXT: 'Analyse subject value-added across all schools and colleges',
				URL: '/groups/ks5pm/%{schoolId}',
			},
			common: {
				VIEW_ANALYSIS: 'View Analysis',
			},
		},
		chooseYourAnalysisZone: {
			stepHeading: {
				STEP: '2',
				HEADING: 'Choose Your Analysis Zone',
				TEXT:
					'Once you have completed Step 1, choose a zone to access your data quickly and easily.',
			},
			common: {
				VIEW_ANALYSIS: 'View Analysis',
			},
			strategicAnalysisZoneTile: {
				HEADING: 'Strategic Analysis',
				TEXT: 'Analyse the overall value-added and attainment data for the whole school/college',
				URL: '/strategic-analysis/summary',
			},
			subjectAnalysisZoneTile: {
				HEADING: 'Subjects Analysis',
				TEXT: 'Analyse value-added and attainment data for subjects and teaching sets​',
				flyoutMenu: {
					HEADING: 'Subjects',
					VIEW_ALL: 'View All',
					TRIGGER_TEXT: 'Choose',
					URL: '/subjects',
				},
			},
			studentAnalysisZoneTile: {
				HEADING: 'Student Analysis',
				TEXT: 'Analyse progress metrics at student level',
				URL: '/students/overview',
			},
			performanceMeasuresZoneTile: {
				HEADING: 'Performance Measures',
				TEXT: 'Analyse all your performance measures',
				flyoutMenu: {
					TRIGGER_TEXT: 'Choose',
					ks4: {
						HEADING: 'DfE Accountability Measures for KS4',
						TEXT:
							'Analyse DfE Accountability Measures for KS4 including Progress 8, Attainment 8, EBacc and other key performance indicators',
						BUTTON_TEXT_DISABLED: 'Subscribe to view KS4 PM',
						BUTTON_TEXT: 'View KS4 PM',
						LIST_ITEM_ONE: 'Attainment Measures',
						LIST_ITEM_TWO: 'Progress 8 Measures',
						LIST_ITEM_THREE: 'Student Level Attainment Measures',
						LIST_ITEM_FOUR: 'Student Level Progress 8 Measures',
					},
					ks5: {
						HEADING: 'DfE Accountability Measures for KS5',
						TEXT:
							'Analyse DfE Accountability Measures for KS5 including Progress 8, Attainment 8, EBacc and other key performance indicators',
						BUTTON_TEXT_DISABLED: 'Subscribe to view KS5 PM',
						BUTTON_TEXT: 'View KS5 PM',
						LIST_ITEM_ONE: 'All A Levels',
						LIST_ITEM_TWO: 'Best 3 A Levels',
						LIST_ITEM_THREE: 'Prior Attainment Profile',
					},
				},
			},
			monitoringAccuracyZoneTile: {
				HEADING: 'Monitoring Accuracy',
				TEXT: 'Analyse all your performance measures',
				URL: '/monitoring/difference',
			},
			ibHeadlineMeasuresZoneTile: {
				HEADING: 'Headline Measures For IB',
				TEXT: 'Analyse the overall value-added and attainment data for the whole school/college',
				URL: '/ib-performance-measures',
			},
		},
		leftFooterTile: {
			tile1: {
				BUTTON_TEXT: 'View All Explorer Videos',
				HEADING: 'View All Explorer Videos',
				VIDEO_TITLE: 'Alps Explorer Video Tutorial 4: Strategic Analysis',
				VIDEO_URL: 'https://youtu.be/tEVHZ8aCNAE',
			},
			tile2: {
				BUTTON_TEXT: 'Book Your Webinar Space',
				HEADING: 'Alps Champions',
				TEXT:
					'Our Alps Champion Programme is designed for those of you who are driving the Alps culture in your school/college.',
				URL: 'https://alps.education/alps-training-hub/webinars/',
			},
		},
		rightFooterTile: {
			HEADING: 'Alps Latest News',
			BUTTON_TEXT: 'Read More',
			URL: 'https://alps.education/latest/news/',
			ERROR_HEADING: 'No news articles found',
			ERROR_TEXT: 'Please try again later or click below to visit the Alps Website.',
			ERROR_BUTTON_TEXT: 'Visit the Alps Website',
		},
		census: {
			modal: {
				HEADER: 'Census Survey Notification',
				CANCEL: 'Not Now',
				TITLE: 'Start Census',
				PARA:
					'Please complete our Alps Annual Census. It should only take you  2-5 minutes to complete and will help us ensure that we comply with GDPR.',
				PARA_TWO:
					"The Census is only available to Admin users in Connect and needs only to be completed once. By clicking 'Not Now' you will be prompted next time you login to Connect, unless it has been completed by another Admin user. <br> <br> Note: Once you start the Census you will not be able to exit until all steps are completed. ",
				informationPrompt: {
					HEADING: 'Important: Pre-Census Step',
					PROMPT_ONE:
						'Before starting please ensure that there are active users in your account for these four roles where applicable:',
					LINK: '<b>Click here</b>',
					PROMPT_TWO:
						' to update users before starting the Census. If users list is up-to-date then you are ready to Start Census',
					list: {
						OPTION_ONE: 'Headteacher',
						OPTION_TWO: 'Head of Sixth',
						OPTION_THREE: 'Data Leader',
						OPTION_FOUR: 'KS4 Leader',
					},
				},
			},

			pill: {
				TEXT: 'Complete Census',
			},
		},
		primaryNavigation: {
			WELCOME_TEXT_CONNECT: 'Welcome to Alps Connect.',
			WELCOME_TEXT_SUMMIT: 'Welcome to Alps Summit.',
		},
		dashboard: {
			SECTION_LINKS: 'Connect Analysis',
			ALPS_QA: 'Alps  Quality Assurance Cycle',
			ALPS_CHAMPS_HEADING: 'Alps Champions',
			ALPS_CHAMPS_TEXT:
				'Our Alps Champions Programme is designed for those of you who are driving the Alps culture in your school/college. Our Champions webpage has great, practical resources to support you and your staff in getting the most from your Alps analysis.',
			ALPS_CHAMPS_LINK: 'https://support.alps.education/portal/en/kb/champions',
			WEBINARS_LINK: 'https://alps.education/alps-training-hub/alps-champions-webinar-programme/',
			RESOURCES_BUTTON: 'Champions Resource page',
			WEBINARS_BUTTON: 'Book your webinar space',
			KNOWLEDGE_BASE_LINK: 'https://support.alps.education/portal/en/kb',
			KNOWLEDGE_BASE_BUTTON: 'Knowledge Base',
			EXPLORER_VIDEOS_LINK:
				'https://support.alps.education/portal/en/kb/champions/alps-explorer-programme',
			EXPLORER_VIDEOS_BUTTON: 'Explorer videos',
			NEWS: 'The Latest',
			NEWS_LINK: 'Read More',
			INTRO_MESSAGE_ONE:
				'You do not currently have access to Connect. Connect is our web-based reporting platform, allowing internal analysis using our familiar Alps benchmarks and established methodology via simple yet accessible new tools. <a href="https://alps.education/connect">Find out more.</a> Connect is an annual subscription, and must be re-ordered from results day each year. Your Data Manager/Exams Officer is able to do this at the time of ordering your Summer 2020 reports.',
			INTRO_MESSAGE_TWO:
				'Data managers at your organisation can still use Connect Data to prepare data and order End of Year Reports. If you wish to order Connect, you can do so in the report ordering screen of Connect Data. Target setting reports are available free of charge.',
		},
		noTrendModal: {
			MODAL_TITLE: 'Gradepoint Trends Unavailable',
			HEADING: 'You have no available Gradepoint Trends',
			TEXT: `Your Analysis Administrator can setup a new trend in Analysis Setup, alternatively please contact your Account Manager or our Customer Support Team on ${CST_NUMBER}`,
		},
		subscriptionPanel: {
			TITLE: 'Subscription',
			TITLE_ZERO_DAYS: 'You need to subscribe',
			SUBSCRIPTION: 'Subscription(s)',
			CONTRACT: 'Contract',
			TOMORROW: 'tomorrow',
			DAYS: 'in %{numDays} days',
			HEADING: 'Your %{hasSubscription}%{hasBoth}%{hasContract} will renew %{numDays}',
			HEADING_AUTO_RENEW_ON: 'Auto-renew is due in...',
			TEXT_AUTO_RENEW_ON: 'Please click <b>manage</b> to see further details.',
			HEADING_AUTO_RENEW_OFF: 'Your %{hasSubscription}%{hasBoth}%{hasContract} is due in ...',
			TEXT_AUTO_RENEW_OFF:
				'Auto-renewal is <b>not enabled</b>. If you wish to continue with your subscription please click <b>manage</b> to update your details.',
			HEADING_ZERO_DAYS: 'Your %{hasSubscription}%{hasBoth}%{hasContract} will expire %{numDays}',
			TEXT_ZERO_DAYS:
				'You have no active subscription for Connect. Please subscribe to continue using Connect.',
			NO_RENEWAL_HEADING: 'Your %{hasSubscription}%{hasBoth}%{hasContract} will expire %{numDays}',
			CONFIRM: 'Confirm Subscription',
			MANAGE_SUB: 'Manage Subscriptions',
			MANAGE_SUB_ROUTE: 'manage-account/subscribe-step-1',
			MANAGE_SUB_ROUTE_2: 'manage-account/manage-subscriptions',
			WARNING: 'You can resubscribe now to ensure you do not lose access to Connect.',
			BUTTON: 'Manage',
			CONTRACT_PRE_MID_JULY_SUBTEXT:
				'You are part of a group contract and we will aim to let you know by 15th July %{year} if this has been renewed for %{academicYear}',
			CONTRACT_POST_MID_JULY_SUBTEXT:
				'You are currently part of a group contract, due to expire on 31 July %{year}. The project is still in negotiation, but no new contract has yet been agreed. <b>You will need to subscribe directly to be able to access your account from 31 July.</b> If a Project is subsequently agreed, we would credit any difference in charge to you, as appropriate.',
		},
		embargoPanel: {
			HEADER: 'Embargo Period',
			BODY:
				'Your analysis is subject to the JCQ embargo and will be available to all users tomorrow at 8am. <br/><br/> If you require early privileged access, please contact your account administrator.',
			BUTTON_TEXT: 'Learn More',
		},
		noSubscription: {
			HEADING_SUBSCRIPTION: 'You need to subscribe',
			HEADING_CONTRACT: 'You need to renew your contract',
			TEXT_SUBSCRIPTION:
				'You have no active subscription for Connect. Please subscribe to continue using Connect.',
			TEXT_CONTRACT: `You have no active contract for Summit. Please contact the Alps Customer Team on ${CST_NUMBER}.`,
			BUTTON: 'Subscribe',
		},
		autoRenewal: {
			HEADING: 'Your subscription is auto-renewing.',
			BODY:
				'Your subscription is due to auto-renew %{numDays}. Please click the Manage Subscriptions button below to check your renewal details.',
			BUTTON: 'Subscription page',
			INDICATOR: 'Auto-Renewal: On',
		},
		noAutoRenewal: {
			HEADING: 'Your subscription is ending.',
			BODY:
				'Your subscription is due to end %{numDays}, but auto-renewal is not enabled. If you wish to continue to use Connect or discuss your renewal options, please click the Manage Subscriptions button below.',
			BUTTON: 'Subscription page',
			INDICATOR: 'Auto-Renewal: Off',
		},
		/**
		 ** Single School Sidebar Links
		 */
		singleSchool: {
			sectionLinks: {
				STRATEGIC: 'Strategic Analysis',
				SUBJECTS: 'Subject Analysis',
				STUDENTS: 'Student Analysis Overview',
				MONITORING: 'Monitoring Accuracy',
				KS4_PERF: 'KS4 Performance Measures',
				flags: {
					NEW: 'New!',
				},
			},
			performanceMeasures: {
				HEADING: 'KS4 Performance Measures',
				links: {
					KS4_PERF: 'KS4 Performance Measures',
				},
			},
			interactiveAnalysis: {
				HEADING: 'Interactive Analysis',
				links: {
					STRATEGIC: 'Strategic',
					SUBJECTS: 'Subjects',
					STUDENTS: 'Students',
					MONITORING: 'Monitoring Accuracy',
				},
			},
			alpsAdmin: {
				HEADING: 'Alps Admin',
				links: {
					ALPS_ADMIN: 'Alps Admin',
					REPORTING: 'Reporting Season',
				},
			},
			dataManagement: {
				HEADING: 'Data Management',
				FLYOUT_HEADING_1: 'Connect Data',
				FLYOUT_HEADING_2: 'Connect Data Settings',
				links: {
					MANAGE_DATA: 'Manage Data',
					MANAGE_USERS: 'Manage Users',
					ANALYSIS_SETTINGS: 'Analysis Settings',
					MANAGE_ACCOUNT: 'Manage Account',
					COHORTS: 'Cohorts',
					GRADEPOINTS: 'Gradepoints',
					GRADES: 'Grades',
					STUDENTS: 'Students',
					MEG_REPORTS: 'MEG Reports',
					COLUMNS_SUBJECT_MAPPINGS: 'Columns & Subject Mappings',
					FINE_GRADES: 'Fine Grades',
					CONSORTIUM_SCHOOLS: 'Consortium Schools',
					CUSTOM_COLUMNS: 'Custom Columns',
				},
			},
			reportsDownloads: {
				HEADING: 'Reports & Downloads',
				FLYOUT_HEADING: 'Report Management',
				links: {
					MEGS: 'MEG Reports',
					MY_REPORTS: 'My Reports',
					ADDITIONAL_DOWNLOADS: 'Additional Downloads',
				},
			},
			userGuides: {
				HEADING: 'User Guides',
				links: {
					GETTING_STARTED: 'Connect - Getting Started',
					CONNECT_DATA_USER_GUIDE: 'Connect Data - User Guide',
					CONNECT_DATA_INT: 'Connect Data - International Schools',
					ADDING_USERS: 'Adding Users',
					TARGET_SETTING: 'Target Setting',
				},
			},
			alpsGuides: {
				HEADING: 'Alps Guides',
				links: {
					ENG: 'England & International Schools following the reformed curriculum',
					WAL: 'Wales',
					NI: 'Northern Ireland',
					INT: 'International Guide for Schools whose GCSE results are graded A* to G',
				},
			},
			resources: {
				HEADING: 'Other Resources',
				links: {
					KNOWLEDGE_BASE: 'Knowledge Base',
					ALPS_CHAMPIONS: 'Alps Champions',
					FIND_OUT_MORE: 'Find more resources at our website',
				},
			},
			news: {
				ERROR:
					'News articles are unavailable at this time, please click here to visit our website.',
			},
			manageAccount: {
				HEADING: 'Manage Account',
				FLYOUT_HEADING: 'Account Settings',
				links: {
					MULTI_FACTOR_AUTHENTICATION: 'Multi-Factor Authentication',
					ALPS_ANNUAL_CENSUS: 'Alps Annual Census',
					CHANGE_PASSWORD: 'Change Password',
					SUBSCRIPTIONS: 'Manage Subscriptions',
					DATA_SHARING: 'Data Sharing',
					ORGANISATION_MANAGEMENT: 'Organisation Management',
				},
			},
			manageUsers: {
				HEADING: 'Manage Users',
				FLYOUT_HEADING: 'User Administration',
				links: {
					BULK_ADD_USERS: 'Bulk Add Users',
					DISABLED_USERS: 'Deactivated Users',
					ALL_USERS: 'Active Users',
					ADD_USER: 'Add User',
				},
			},
		},
		/**
		 ** Knowledge Base Links
		 */
		knowledgeBase: {
			HEADING: 'Need Help?',
			links: {
				KNOWLEDGE_BASE: 'Knowledge Base',
				KNOWLEDGE_BASE_LINK: 'https://support.alps.education/portal/en/kb',
			},
		},
		/**
		 ** Groups Sidebar Links
		 */
		groups: {
			interactiveAnalysis: {
				HEADING: 'Interactive Analysis',
				links: {
					STRATEGIC: 'Strategic Analysis',
					SUBJECTS: 'Subjects Analysis',
					BBPA: 'BBPA Analysis',
				},
			},
			viewAsSchool: {
				HEADING: 'View as School/College',
				links: {
					VIEW_AS_SCHOOL: 'View as School/College',
				},
			},
			groupSetupManagement: {
				HEADING: 'Group Setup Management',
				links: {
					MONITORING_TIMELINE: 'Monitoring Timeline',
					MANAGE_USERS: 'Manage Users',
					ANALYSIS_SETUP: 'Analysis Setup',
				},
			},
			analysisSettings: {
				HEADING: 'Analysis Settings',
				links: {
					FLYOUT_HEADING: 'Analysis Configuration',
				},
			},
			groupBenchmarkSetup: {
				HEADING: 'Benchmark Setup',
				links: {
					BENCHMARK_SELECTOR: 'Account Benchmark Selector',
					BENCHMARK_TOGGLE: 'Session Benchmark Toggle',
				},
			},
			reportsDownloads: {
				HEADING: 'Reports & Downloads',
				links: {
					GROUP_REPORTS: 'Group Reports',
					ADDITIONAL_DOWNLOADS: 'Additional Downloads',
					SCHOOL_COLLEGE: 'School & College Reports',
				},
			},
			userGuides: {
				HEADING: 'User Guides',
				links: {
					MAT_USER_GUIDE: 'MAT User Guide',
					GROUP_USER_GUIDE: 'Groups User Guide',
				},
			},
			alpsGuides: {
				HEADING: 'Alps Guides',
				links: {
					ENG: 'England',
					WAL: 'Wales',
					NI: 'Northern Ireland',
					INT: 'International Guide for Schools whose GCSE results are graded A* to G',
				},
			},
			resources: {
				HEADING: 'Other Resources',
				links: {
					FIND_OUT_MORE: 'Find more resources at our website',
				},
			},
		},
	},
	/**
	 * View As School
	 */
	viewAsSchool: {
		HEADING: 'View Connect for your schools/colleges',
		TEXT:
			'Select a school/college to view their individual access to Connect. This will open in a new tab - to end your session as the school/college simply close the tab. You can continue to use this tab to access your MAT/Group analysis.',
		AG_GRID: 'School/College Name',
		SEARCH: 'Search Schools/Colleges',
		PERMISSIONS: 'You do not have permission to view this school/college',
	},
	/**
	 ** Manage Account
	 */
	manageAccount: {
		sidebar: {
			HEADING: 'Manage Account',
			links: {
				CHANGE_PASSWORD: 'Change Password',
				SUBSCRIPTIONS: 'Manage Subscriptions',
			},
		},

		additionalInformation: {
			PAGE_HEADING: 'Alps Annual Census',
			pageText: {
				PARA_ONE:
					'Once every Academic Year, we ask you to complete our Alps Census.  <br /><br /> During the Census window, we ask you to update key contact information and details about your organisation.  <br /><br />By updating your information, we can ensure the right communications are sent to the correct contacts throughout the year, in line with GDPR compliance. <br /> <br />',
				PARA_TWO:
					'If you would like to update any information at other times during the Academic Year, please click the Update Census button below.',
				PARA_THREE: 'Your Census was completed by <b> %{name} </b> on the <b> %{date} </b>',
				BUTTON: 'Update Census',
			},
			progressMethod: {
				NEXT: 'Next',
				BACK: 'Back',
				SUBMIT: 'Submit',
				CLOSE: 'Close',
			},
			completeModal: {
				TITLE: 'Alps Annual Census',
				stepOneText: {
					TEXT:
						'Details completed in this window will support us in getting the right information to you at the right time. <br> <br> You are now in the Census, and you cannot exit without completing the next two steps.',
					TEXT_TWO: 'Choose the best email from user list in each of the dropdowns.',
					ERROR: 'Please enter a vaild Email address',
					jobRoles: {
						HEAD: 'Headteacher',
						HEAD_OF_SIXTH: 'Head of Sixth',
						DATA_MANAGER: 'Data Leader',
						KS4_LEAD: 'KS4 Leader',
						FINANCE:
							'Finance Contact - this is a free text entry. Insert the best email address to use to contact your Finance Team',
					},
				},
				STEP_ONE_HEADER: 'Step 1 of 2: Key user information',
				STEP_TWO_HEADER: 'Step 2 of 2: Systems Information',
				STEP_THREE_HEADER: 'Census Complete',
				NOT_INCLUDED: 'Not In List',
				stepTwo: {
					INTRO_TEXT:
						'We would be grateful if you could take a moment to complete the following additional information.',
					questions: {
						ALPS_USAGE: 'What do you use Alps for? Click all that apply.',
						OTHER_SYSTEMS:
							'Where applicable, what other systems do you use as part of your assessment cycle. Click all that apply',
						MIS: 'What MIS do you use?',
						MONITORING_DATE:
							'In which months do you expect to be uploading monitoring data into Connect. Click all that apply across all year groups.',
					},
					answers: {
						questionOne: {
							TARGET_SETTING: 'Target Setting',
							EXAM_RESULTS: 'Exam Results Analysis',
							IN_YEAR_MONITORING: 'In Year Monitoring',
						},
						questionTwo: {
							SMID: 'SMID',
							FFT: 'FFT',
							SISRA: 'Sisra',
							MATRIX: '4Matrix',
							CEM: 'CEM',
							GO_SCHOOLS: 'Go4Schools',
							NA: 'N/A',
						},
						questionMis: {
							ARBOR: 'Arbor',
							BROMCOM: 'Bromcom',
							SIMS: 'SIMS',
							ISAMS: 'iSAMS',
							JUNIPER: 'Juniper',
							PROGRESSO: 'Progresso',
							PRO_SOLUTION: 'Pro-Solution',
							PHOENIX: 'Phoenix',
							REMS: 'REMS',
							OTHER: 'Other MIS not listed above',
						},
						questionThree: {
							JAN: 'January',
							FEB: 'February',
							MARCH: 'March',
							APRIL: 'April',
							MAY: 'May',
							JUNE: 'June',
							JULY: 'July',
							AUG: 'August',
							SEP: 'September',
							OCT: 'October',
							NOV: 'November',
							DEC: 'December',
							NONE: 'No Monitoring',
						},
					},
				},
				stepThree: {
					THANKS: 'Thank you for completing the Alps Annual Census. <br>',
					INFO:
						'Information can be amended across the year via the Manage Account area of Connect. <br> Please note that at this time the information submitted has not affected any details in the Active Users table. We recommend that you update and amend the user information stored there for your own GDPR purposes.',
				},
			},
			mediaPlayer: {
				HEADER: 'Alps Explorer videos',
				LINK: 'https://www.youtube.com/watch?v=8Ed6L5mgD7I',
			},
		},
		changePassword: {
			HEADING: 'Change Password',
			TEXT:
				'Please confirm your current password, and then choose a new password for your account (at least 8 characters)',
			inputHeadings: {
				NEW: 'New Password',
				CONFIRM: 'Confirm Password',
				CURRENT: 'Current Password',
			},
			errors: {
				REQUIRED_ERROR: 'This field is required',
				MATCHING_ERROR: 'New password and confirm password do not match',
				LENGTH_ERROR: 'Your password must contain at least 8 characters',
			},
			buttons: {
				SUBMIT: 'Submit',
				CLEAR: 'Clear',
			},
		},
		subscriptions: {
			step1: {
				RENEWAL_HEADING: 'Renew Subscription',
				DAYS_REMAINING:
					'%{smart_count} day left of subscription |||| %{smart_count} days left of subscription',
				CURRENT_SUBS_HEADING: 'Current Subscriptions',
				SUBS_HEADING: 'Choose your subscription level.',
				SUBS_SUB_HEADING:
					'Choose your subscription level from: KS4 only, KS5 only or both KS4 & KS5.<br/>',
				SUBS_SUB_HEADING1:
					'You can opt for 1, 2 or 3 year subscription. The price depends on the number of students you have.',
				SUBS_SUB_HEADING2: '<b>Lock in lower prices if you chose 2 or 3 years.</b>',
				SUBS_SUB_HEADING3:
					'<b>2 year subscription = 5% discount, 3 year subscription = 7.5% discount.</b>',
				SUBS_SUB_HEADING4: '<b>Staff training / support packages also available.</b>',
				SUBS_SUB_HEADING5: `Please call us on ${CST_NUMBER} if you have any queries at all and a member of the team will be happy to help.`,
				REPORTS_HEADING: 'Filter reports (KS5) - optional',
				REPORTS_SUBHEADING:
					'You can select any additional filter reports at an extra cost to your subscription.',
				currentSub: {
					START: 'Start:',
					END: 'End:',
					AUTO_RENEWAL_ON: 'Turn on automatic renewal',
					AUTO_RENEWAL_OFF: 'Turn off automatic renewal',
				},
				futureSub: {
					BODY: 'Please contact us if you would like to add or amend your subscription.',
				},
				autoRenewal: {
					PRICE_AMOUNT: 'Price: £%{price} + VAT',
					START_DATE: 'Start Date',
					END_DATE: 'End Date',
					LEVEL: 'Level',
					LENGTH: 'Length',
					KS5_SIZE: 'KS5 Student Number',
					PRICE: 'Price',
					PRICE_INFO: 'Price is excluding any discounts that may have been applied',
					RENEWAL_INFORMATION_HEADING: 'Renewal Information',
					AUTO_RENEWAL_HEADING: 'Automatic Renewal Options',
					CANCEL_AUTO_RENEWAL: 'Cancel Automatic Renewal',
					AUTO_RENEWAL_WARNING_CONTACT_US:
						'Please contact us using the button below to discuss your subscription.',
					AUTO_RENEWAL_WARNING:
						'If you wish to cancel your auto-renewal, you can cancel using the button below before %{date}. By cancelling, you will lose access to your analysis and reports when your current subscription ends.',
					AUTO_RENEWAL_CANCELLED:
						'Your automatic subscription renewal has been cancelled. When your current subscription ends you will need to resubscribe to access your analysis and reports. If you wish to discuss your renewal please email support@alps.education.',
					CONTACT_US: 'Contact Us',
				},
				REPORTS_CHARGE:
					'PDF gap analysis reports are available for gender, disadvantage, ethnicity, high grades and teaching set.<br />There are additional charges for these reports.<br /><br />Note that you can construct gap analysis reports within Connect.',
				ENQUIRE_HEADING:
					'Please call us on 01484 887600 to enquire about adding to your subscription.<br />Alternatively, please send us an email using the link below and we will get back to you.',
				ENQUIRE_BUTTON: 'Enquire Now',
				labels: {
					FILTER_REPORTS: 'Filter Reports',
					PURCHASED_REPORTS: 'You have already purchased the following reports:',
					PURCHASED_ALL_REPORTS: 'You have all reports available at this level:',
				},
			},
			step2: {
				HEADING: 'Please add your organisation details below',
				formHeadings: {
					SCHOOL: 'School/College Details',
					CONTACT: 'Your Contact Details',
					MAT: 'MAT/Group Details',
				},
				labels: {
					SCHOOL: 'School/College Name',
					FULL_NAME: 'Full Name',
					EMAIL: 'Email Address',
					NUMBER: 'Contact Telephone number',
					POSITION: 'Position',
					MAT_GROUP: 'Are you part of a MAT/Group? - <i>optional</i>',
					MAT_GROUP_NAME: 'Name of MAT/Group',
					ADDRESS_1: 'Address line 1',
					ADDRESS_2: 'Address line 2',
					ADDRESS_3: 'Address line 3',
					TOWN: 'Town/City',
					POSTCODE: 'Postcode',
					COUNTRY: 'Country',
				},
				errors: {
					INVALID_PHONE: 'Please enter a valid phone number',
					INVALID_EMAIL: 'Please enter a valid email address',
					INVALID_POSTCODE: 'Please enter a valid postcode',
					INVALID_VALUE: 'Value cannot be empty',
				},
			},
			step3: {
				HEADING: 'Summary',
				details: {
					HEADING: 'Details of your subscription',
					SELECTED_SUB: 'Selected Subscription Level',
					SELECTED_REPORTS: 'Selected Filter Reports',
					START: 'Start Date',
					END: 'End Date',
					SUBSCRIPTION_TOTAL: 'Subscription Total',
					REPORT_TOTAL: 'Report Total',
				},
				filterReports: {
					HEADING: 'Filter Reports',
					SUB_HEADING: 'Additional cost for 1 year',
				},
				contact: {
					HEADING: 'Contact Details',
					labels: {
						SCHOOL: 'School/College',
						MAT: 'Mat Group',
						FULLNAME: 'Full Name',
						EMAIL: 'Email Address',
						JOB: 'Position',
						NUMBER: 'Contact Number',
						ADDRESS1: 'Address Line 1',
						ADDRESS2: 'Address Line 2',
						ADDRESS3: 'Address Line 3',
						CITY: 'City',
						POSTCODE: 'Postcode',
						COUNTRY: 'Country',
					},
				},
				organisation: {
					HEADING: 'Organisation Details',
				},
				COST_DISPLAY: '£%{cost} + VAT',
				TOTAL_COST_DISPLAY: 'TOTAL £%{cost} + VAT',
				consent: {
					TERMS_ERROR: 'You must accept our terms and conditions to complete this order',
					TERMS_LABEL: 'I have read and accept the',
					TERMS: 'Terms and Conditions',
					TERMS_TOOLTIP:
						'By ticking this you agree to our terms and conditions for the duration of the subscription',
					DATA_SHARING: 'Data Sharing',
					DATA_SHARING_TOOLTIP:
						'Data sharing consent for %{contractName}. <br/> <br/> Only data specified in the contract will be shared. Please be aware that subscriptions or filter reports purchased in addition to this will incur an additional cost',
				},
			},
			autoRenewCancel: {
				HEADER: 'Cancel Auto Renewal for %{subscriptionName} Subscription',
				UNSUBSCRIBE: 'Confirm',
				CLOSE: 'Close',
				LABEL: 'Please tell us why you wish to cancel your auto renewal',
				TEXT_AREA_PLACEHOLDER: 'Please provide a reason to cancel auto renewal...',
				MULTI_LEVEL_SUBSCRIPTION:
					'As this is a joint subscription, you have the option to downgrade to a KS4 or KS5 only subscription, or cancel the joint subscription. Please select the option you would like to continue with:',
				REQUIRED_REASON: 'A reason is required.',
				KS4_SUBSCRIPTION: 'KS4 Subscription',
				KS5_SUBSCRIPTION: 'KS5 Subscription',
				CANCEL_JOINT_SUBSCRIPTION: 'Cancel Joint Subscription',
				BODY_ONE:
					'Are you sure you want to cancel your auto-renewal? Your subscription is due to auto-renew on %{date}. If you cancel the auto-renewal then you will lose access to your Connect analysis and reports after %{date}.',
				BODY_TWO:
					'Please share further information to process your Alps subscription cancellation. All questions must be completed. The auto-renewal of your subscription will only be switched off after you click Confirm at the end of the short survey.',
				QUESTION1: 'What did you use Alps for? (Please select all that apply)',
				QUESTION1_REQUIRED: 'Please tell us what you used Alps for',
				QUESTION2: 'Why do you wish to cancel your Alps subscription?',
				QUESTION2_OTHER_SOLUTION:
					'Please share the other solution you are currently using. Please select all that apply',
				QUESTION2_SPECIFY_OTHER_SOLUTION: 'Please specify the other solution you are using',
				QUESTION2_PROD_NOT_MEET_REQ:
					'Please share why Alps does not meet your current requirements',
				QUESTION2_SPECIFY_CANCEL: 'Please specify why you wish to cancel your Alps subscription',
				QUESTION3_FURTHER_COMMENTS: 'Please share any further comments',
				MODAL_HEADER: 'Cancellation Complete',
				MODAL_TEXT:
					'Your auto-renewal has now been cancelled. You will receive a confirmation email shortly with further details. </br> If the auto-renewal has been cancelled in error, please contact support@alps.education or call 01484 887600.',
				MODAL_OK: 'Ok',
				validationErrors: {
					// these are internal validation errors
					ALPS_USAGE_NOT_SELECTED: 'Alps usage answer not selected.',
					ALT_SOLUTION_NOT_SELECTED: 'Alternative solution not selected.',
					OTHER_SOLUTION_PROVIDER_NOT_SUPPLIED: 'Other solution provider not supplied',
					REASONS_PROD_NOT_MEET_REQ_NOT_SUPPLIED:
						'Reasons why product does not meet requirements have not been provided.',
					MAT_SOLUTIONS_NOT_SELECTED: 'MAT Solutions not selected',
					CENTRAL_MAT_OTHER_SOLUTION_NOT_SUPPLIED:
						'Central/MAT decision - Other solution provider not supplied.',
					OTHER_CANCELLATION_REASON_NOT_SUPPLIED: 'Other cancellation reason not provided.',
					REASON_FOR_CANCELLATION_NOT_SUPPLIED:
						'Reason for cancelling Alps subscription not provided.',
				},
				question_one_answers: {
					TARGET_SETTING: 'Target Setting',
					EXAM_RESULTS_ANALYSIS: 'Exam Results Analysis',
					IN_YEAR_MONITORING: 'In Year Monitoring',
				},
				cancellation_reason_answers: {
					ALT_SOLUTION: 'Using an Alternative Solution',
					CENTRAL_MAT_DECISION: 'Central/MAT decision',
					PROD_NOT_MEET_REQ: 'Product does not meet our requirements',
					SIXTH_FORM_CLOSING: 'Sixth Form is closing',
					OTHER: 'Other',
				},
				other_solution_answers: {
					FOUR_MATRIX: '4 Matrix',
					CEM: 'CEM',
					FFT: 'FFT',
					GO_FOR_SCHOOLS: 'Go4Schools',
					IN_HOUSE_SYSTEM: 'In-house System',
					PROGRESS_TEACHING: 'Progress Teaching',
					SISRA: 'Sisra',
					SMID: 'SMID',
					OTHER: 'Other',
				},
			},
			modals: {
				unsavedChanges: {
					ACTION_TITLE: 'Okay',
					WARNING_ONE: 'You have unsaved changes!',
					WARNING_TWO: 'Are you sure you want to navigate away? All unsaved changes will be lost.',
				},
				confirm: {
					ORDER_SUCCESS: 'Order Successful',
					ORDER_SUCCESS_SUBS: 'Congratulations you have subscribed!',
					ORDER_SUCCESS_TEXT:
						`You can now access Connect to start your analysis – Have a look at our <a href="https://alpseducation.zohodesk.eu/portal/en/kb/alps-education/connect-data/importing-data/setting-up-your-year-group" target="_blank">‘How to start’ </a> article. ​` +
						`<br/>A member of our team will contact you shortly to check you have what you need.​` +
						`<br/>If you have any questions call us on ${CST_NUMBER}.`,
				},
			},
			ks4: {
				HEADING: 'Connect KS4',
				DESC_TITLE: 'Included in this plan: ',
				DESC: '• Feature 1<br/>• Feature 2<br/>• Feature 3<br/>• Feature 4',
			},
			ks5: {
				HEADING: 'Connect KS5',
				DESC_TITLE: 'Included in this plan: ',
				DESC: '• Feature 1<br/>• Feature 2<br/>• Feature 3<br/>• Feature 4',
			},
			ks4ks5: {
				HEADING: 'Connect KS4 & KS5',
				DESC_TITLE: 'Included in this plan: ',
				DESC: '• Feature 1<br/>• Feature 2<br/>• Feature 3<br/>• Feature 4',
			},
			footer: {
				RESET: 'Reset',
				BACK: 'Back',
				CONTINUE: 'Continue',
				ORDER_NOW: 'Order Now',
			},
			selector: {
				NUM_STUS: 'Number of Students ',
				AT_KS: 'at KS5',
				NUM_YEARS: 'Number of Years',
				BUTTON: 'Select ',
				COST_PER_YEAR: '£%{cost} + VAT per year',
				FROM_COST: 'From £%{fromCost} + VAT per year',
				NO_FROM_COST: '£%{fromCost} + VAT per year',
				numberOfYearOptions: {
					ONE: '1 Year',
					TWO: '2 Years - (per year) 5% discount',
					THREE: '3 Years - (per year) 7.5% discount',
				},
			},
			badges: {
				BEST: 'Complete Alps Package',
				CONTRACTED: 'Included in your contract',
			},
			indicator: {
				step1: 'Step 1 - Choose Plan',
				step2: 'Step 2 - Your Details',
				step3: 'Step 3 - Order Summary',
			},
			A: {
				TITLE: 'A Level',
				DESC_TITLE: 'Reports available at this qualification: ',
				DESC:
					'• Gender Report<br />• Disadvantaged Report<br/>• Teaching Sets Report<br/>• Ethnicity Report',
			},
			AS: {
				TITLE: 'AS Level',
				DESC_TITLE: 'Reports available at this qualification: ',
				DESC:
					'• Gender Report<br />• Disadvantaged Report<br/>• Teaching Sets Report<br/>• Ethnicity Report',
			},
			BTEC: {
				TITLE: 'BTEC',
				DESC_TITLE: 'Reports available at this qualification: ',
				DESC:
					'• Gender Report<br />• Disadvantaged Report<br/>• Teaching Sets Report<br/>• Ethnicity Report',
			},
			errors: {
				FAILED_TO_FETCH_DATA:
					'It looks like we encountered an error. Please try again or contact us if this continues',
			},
		},
		pages: {
			dataSharing: {
				PAGE_HEADER: 'You are part of the following Group Project(s)',
				CONTRACT: 'Long Contract Name (Qual Type)',
				page: {
					CONSENT: 'Consented',
					NO_CONSENT: 'Not Consented',
					TITLE: 'Contracts available for ',
				},
				notes: {
					NOTE_ONE:
						'For Group contracts, such as LA or MAT contracts, consenting to data sharing will allow the Group to access reports and Alps Summit analysis for the Key Stages included in your contract.',
					NOTE_TWO: 'You can remove consent at any time by using the toggle(s).',
					NOTE_THREE:
						'If you receive your analysis through a Group contract and you do not consent to share your data with your LA/MAT/Group as part of the project, you will be invoiced directly.',
					NOTE_FOUR: 'Please contact us if you require any further information.',
				},
			},
			organisationManagement: {
				PAGE_HEADING: 'Keep the details of your organisation up to date using the section below.',
				PAGE_LOAD_FAIL:
					'Unfortunately the details for your organisation cannot be obtained at this time. Please try again later.',

				form: {
					HEADTEACHER_INFO: 'Headteacher Information',
					JOB_TITLE: 'Headteacher Name',
					FULL_NAME: 'James Key',
					HEAD_EMAIL: 'Headteacher Email',
					PLACE_HOLDER_EMAIL: 'username@organisation.org',
					CONFIRM_EMAIL: 'Confirm Address',
				},

				school: {
					HEADER: 'School Information',
					NAME: 'School Name',
					PLACE_HOLDER_SCHOOL: 'The Alps School',
					DFE_LABEL: 'DfE / LAESTAB Number or DfEE Number (if applicable)',
					PLACE_HOLDER_NUMBER: '123/2345 or 1111',
				},

				address: {
					LINE_ONE: 'School Address Line One',
					ONE_PLACEHOLDER: 'Alps Education',
					LINE_TWO: 'School Address Line Two',
					TWO_PLACEHOLDER: 'Kevin Conway House',
					LINE_THREE: 'School Address Line Three',
					THREE_PLACEHOLDER: 'Longbow Close',
					POSTCODE: 'School Postcode',
					POSTCODE_PLACEHOLDER: 'HD2 1GQ',
					PHONE_NUMBER: 'School Telephone Number',
					NUMBER_PLACEHOLDER: '01484 887600',
					SCHOOL_EMAIL: 'School Email',
					SCHOOL_EMAIL_PLACEHOLDER: 'school@organisation.org',
					DD_MOBILE: 'Direct Dial / Mobile for Key Contact in the event of closure',
					DD_MOBILE_PLACEHOLDER: '01484 887600',
				},

				finance: {
					INFO: 'Finance Information',
					CONTACT_NAME: 'Finance Contact Name',
					CONTACT_PLACEHOLDER: 'Claire Williams',
					EMAIL_LABEL: 'Finance Contact Email',
					PLACEHOLDER_EMAIL: 'username@organisation.org',
				},

				misInformation: {
					HEADER: 'MIS Information',
					MIS_LABEL: 'MIS used',
					MIS_PLACEHOLDER: 'MIS',
				},

				information: {
					TITLE: 'Oops',
					BODY: 'Please click the Confirm Changes checkbox to save your changes',
				},

				confirmChanges: {
					HEADER: 'Confirm Changes',
					CONFIRM: 'I have checked that the Organisation Details above are correct',
				},

				errors: {
					EMAIL_MISMATCH: 'Email addresses do not match',
					PHONE_NOT_VALID: 'Phone is not valid',
				},

				notice: {
					NOTICE_ONE:
						'Please note that adding a user to your Organisation will grant them access to your data.',
					NOTICE_TWO:
						'Complete the users details and we will email the address provided with instructions for the new user to set their password.',
				},
			},
			mfaValidation: {
				otp: {
					HELP:
						'If you encounter any issues or need further assistance, please contact our support team.',
				},
				mediaPlayer: {
					videos: {
						LINK_1: 'https://youtu.be/isH6WXGa3-Q',
						LINK_2: 'https://youtu.be/Jm6DcPPpQkE',
					},
					TITLE: 'Alps Support Videos',
					tabs: {
						TAB_1: 'Set up One Time Password (Authenticator App)',
						TAB_2: 'Set up Email Authentication',
					},
				},
			},
			mfaConfig: {
				NEED_HELP: 'Need help?',
				BACK_TO_OTP: 'Go back',
				PAGE_TITLE: 'Setting up Multi-Factor Authentication (MFA)',
				WHAT_IS_TITLE: 'What is MFA?',
				WHAT_IS_TEXT:
					"At Alps, we understand the importance of keeping your information safe in today's digital age. To enhance the protection of your account, we have an optional MFA feature. Enabling MFA will add a layer of protection to your existing password sign-in process.",
				SET_UP_TITLE: 'Set-up MFA',
				SET_UP_TEXT:
					'When logging into Connect/Summit, you will continue to use your password. With MFA enabled you will be asked to verify your identity through one or both of the following:',
				BULLET_POINT_ONE:
					'A one-time code sent to your usual Connect/Summit login email account. You will be prompted to enter this code during the sign in process. ',
				BULLET_POINT_TWO:
					'A one-time code generated by a secure authenticator app on your personal mobile device. ',
				OTP: 'One Time Password',
				EMAIL: 'Email',
				SUBMIT: 'Submit',
				SIGN_IN: 'Sign in',
				NEXT_STEP: 'Progress to next step',
				SUCCESS: 'Success',
				TRY_AGAIN: 'Try again',
				ENABLE: 'Enable Multi-Factor Authentication',
				warningModal: {
					CONTINUE: 'Continue',
					CANCEL: 'Cancel',
					MODAL_TEXT: 'Are you sure you wish to cancel?',
					DEACTIVATE: 'Deactivate',
					DEACTIVATE_WARNING:
						'Confirm that you wish to deactivate this method of Multi-Factor Authentication. You can re-enable this at any time.',
				},
				email: {
					headers: {
						STEP_ONE: 'One-Time Password Verification',
						STEP_TWO: 'Enter the 6-digit OTP Below: ',
					},
					INSTRUCTION_TEXT:
						"Please check your email inbox for a message containing your one-time password (OTP). If you don't see the email within a few minutes, please check your spam folder.",
					SEND_EMAIL: 'Send Email',
					VALIDATE_STEP_ONE:
						'*This OTP is valid for a limited time and should be used immediately to complete your verification.',
					VALIDATE_STEP_TWO:
						"If you encounter any issues or did not receive the OTP, click on the 'Resend Email' button.",
					RESEND_EMAIL: 'Re-send Email',
					ENTER_CODE_HERE: 'Please open the email and enter the 6 digit code in the fields below.',
					SUCCESS: 'Success!',
					FAILURE: `A problem occured when trying to authenticate your passcode. Please click the retry button below to try again. If the problem persists please call our customer service team on ${CST_NUMBER}.`,
				},
				otp: {
					otpHelpScreen: {
						DOWNLOAD_OTP_AUTH_INSTRUCTIONS_STEP_ONE:
							'To use OTP (one time passcode) authentication you must download a third-party authenticator app to a mobile device. This app generates a 6-digit code for you to log in using multi-factor authentication.',
						// DOWNLOAD_OTP_AUTH_INSTRUCTIONS_STEP_TWO:
						// 	'There are many authenticator apps however we recommend the Microsoft authenticator – use the QR code below to get the app.',
						DOWNLOAD_OTP_AUTH_INSTRUCTIONS_STEP_TWO:
							'There are many authenticator apps however we recommend one of the two below which are free to download and use.',
						temp: {
							apple: {
								HEADER: 'For Apple users:',
								STEP_ONE: '• Open Apple Store on your mobile device.',
								STEP_TWO:
									'• Search for either <b>Google Authenticator</b> or <b>Microsoft Authenticator</b>',
							},
							android: {
								HEADER: 'For Android users:',
								STEP_ONE: '• Open Google Play Store on your mobile device.',
								STEP_TWO:
									'• Search for either <b>Google Authenticator</b> or <b>Microsoft Authenticator</b>',
							},
						},
						APP_NAME: 'Application Name',
						APPLE: 'Apple App Store',
						GOOGLE: 'Google Play Store',
						MICROSOFT_AUTH: 'Microsoft Authenticator',
						GOOGLE_AUTH: 'Google Authenticator',
						stepTwo: {
							BULLET_POINT_ONE: '1. Download and install app on your mobile device',
							BULLET_POINT_TWO: '2. Open the app',
							BULLET_POINT_THREE:
								'3. Add your Connect/Summit account by scanning the QR code below',
							BULLET_POINT_FOUR: '4. Your account will now have been added successfully to the app',
							BULLET_POINT_FIVE: '5. Click next',
						},
						stepThree: {
							BULLET_POINT_ONE: '• Open authenticator app on your mobile device.',
							BULLET_POINT_TWO: '• Select the Alps Education entry to generate a unique code.',
							BULLET_POINT_THREE: '• Enter the 6-digit code below.',
							NOTE:
								'Note: the code changes every few seconds for added security. Ensure that you use the current code displayed in the app.',
						},
						ERROR: 'Your login has been unsuccessful, please generate a new code and try again.',
					},
					NEXT: 'Next',
					PREVIOUS: 'Previous',
					ENTER_CODE_INSTRUCTION_TEXT:
						'Please open your preferred authentictor app and select the Alps Education entry. Once your have the 6 digit code please enter it into the field below.',
					GET_CODE_INSTRUCTION_TEXT:
						'Start by downloading and opening an OTP app on your hand held device. Then scan the QR code below using that app.',
					SUCCESS: 'Success!',
					FAILURE: `A problem occured when trying to authenticate your passcode. Please click the retry button below to try again. If the problem persists please call our customer service team on ${CST_NUMBER}.`,
				},
			},
		},
	},
	/**
	 ** Analysis Settings
	 */
	analysisSettings: {
		sidebar: {
			analysisSetup: {
				HEADING: 'Analysis Settings',
				FLYOUT_HEADING: 'Analysis Configuration',
				links: {
					CUSTOM_COMPARISONS: 'My Comparisons',
					CLIENT_BENCHMARKS: 'Session Benchmark Toggle',
					BENCHMARK_SELECTOR: 'Account Benchmark Selector',
					ADHOC_GROUPS: 'Adhoc Groups',
				},
			},
		},
		clientBenchmark: {
			INTRO_TEXT:
				'While active, using this feature will change the analysis basis for all data seen during this session.',
			TOGGLE_LABEL: 'Use client benchmark.',
			ACCESS_DENIED: 'You do not have access to change your benchmark.',
			RETURN_HOME: 'Home',
			info: {
				TITLE: 'Switch benchmarks for Connect and Summit analysis.',
				PARAGRAPH_ONE:
					'Your value-added analysis in Connect is currently based on the national dataset released by the DfE in 2019. Since the pandemic, we have continued to use this 2019 benchmark to provide a consistent baseline to support you in making judgements on your school/college improvement priorities.',
				PARAGRAPH_TWO:
					'The outcomes from the 2023 examination series present a unique set of challenges for colleagues:',
				paragraphTwoBulletPoints: {
					BULLET_POINT_ONE:
						'The examination standards are set to return to pre-pandemic levels. This brings them back into line with 2019 outcomes.',
					BULLET_POINT_TWO:
						'In terms of value-added, there are important differences in the baseline for your KS5 students.',
					BULLET_POINT_TWO_A:
						'There was uplift in the prior attainment for the 2023 KS5 cohort based on the awarded TAGs in 2021.',
					BULLET_POINT_TWO_B:
						"Therefore your KS5 (A level and BTEC) value-added progress is likely to be less 'red' when viewing results against the 2019 dataset.",
					BULLET_POINT_THREE:
						'At KS4 the scaled score prior attainment baseline differed from the fine grades in 2019.',
				},
				PARAGRAPH_THREE:
					'To respond to this unique context, we have used the data submitted to Alps for Year 11 and Year 13 cohorts in 2023 to generate an Alps ‘client’ benchmark. We offer you the opportunity to switch your benchmark from 2019 to the 2023 Alps ‘client’ benchmark.',
				PARAGRAPH_FOUR: 'Important points for your awareness:',
				paragraphFourBulletPoints: {
					BULLET_POINT_ONE:
						'The 2023 client benchmark will be applied to your 2023 gradepoints across both Year 12 and 13, and across Year 10 and 11.',
					BULLET_POINT_TWO:
						'The 2022 A level examination gradepoint will continue to show analysis against the 2022 client benchmark when you use the toggle to display the client benchmark.',
					BULLET_POINT_THREE:
						'The DFE full national dataset for 2022 for KS4 is being used for the 2022 examination gradepoint (see article linked below) ',
				},
				PARAGRAPH_FIVE: 'Which benchmark should I use to view my analysis:',
				paragraphFiveBulletPoints: {
					BULLET_POINT_ONE:
						'Decisions on datasets to use to give the most valid value-added analysis over the past few years has been complex. We have written KS5 and KS4 articles on our Knowledge Base to support you in working through this question.',
				},
				PARAGRAPH_SIX: 'Using the benchmark:',
				paragraphSixBulletPoints: {
					BULLET_POINT_ONE:
						'Your account administrator has given you permission to change the benchmark for your account.',
					BULLET_POINT_TWO:
						'When you log out of Connect, the analysis will default to the 2019 benchmark.',
				},
			},
			connectLinks: {
				HOW_TO_SWITCH_LINK:
					'<a href="https://support.alps.education/portal/en/kb/articles/using-the-2023-client-benchmarks-in-connect-june-2023" target="_blank">How do I switch my benchmark?</a>',
			},
			summitLinks: {
				HOW_TO_SWITCH_LINK:
					'<a href="https://support.alps.education/portal/en/kb/articles/switching-your-benchmark-in-summit-view-client-2023-benchmark-august-2023" target="_blank">How do I switch my benchmark?</a>',
			},
			sharedLinks: {
				KS4_ENGLAND:
					'<a href="https://support.alps.education/portal/en/kb/articles/implications-of-switching-your-benchmark-ks4-england" target="_blank">Implications of switching your benchmark - KS4 England.</a>',
				KS5_ENGLAND:
					'<a href="https://support.alps.education/portal/en/kb/articles/implications-for-switching-your-benchmark-ks5-england" target="_blank">Implications of switching your benchmark - KS5 England.</a>',
				A_LEVEL_ENGLAND:
					'<a href="https://support.alps.education/portal/en/kb/articles/alps-analysis-of-2023-client-benchmarks-a-level-england" target="_blank">Alps analysis of 2023 client benchmarks - A level England.</a>',
				GCSE_ENGLAND:
					'<a href="https://support.alps.education/portal/en/kb/articles/alps-analysis-of-2023-client-benchmarks-gcse-england-a" target="_blank">Alps analysis of 2023 client benchmarks - GCSE England.</a>',
			},
		},
		comparisonTable: {
			INTRO_TEXT:
				'Using multiple filters, build your own comparison groups to use in the analysis areas of Connect. All ‘My comparison’ groups will be stored locally and available when you log back into the same machine. Your comparison groups can include standard filters: gender, disadvantage, ethnicity, high/middle/low PA, SEN and EAL. In addition, you can use the custom groups added by your Data Manager.',
			colDefs: {
				NAME: 'Name',
				APPLIES_TO: 'Applies To',
				ITEMS: 'Comparison Item',
				EDIT: 'Edit Comparison',
				DELETE: 'Delete Comparison',
			},
			VALIDATION:
				'You have multiple comparisons with the same name. Please use unique Names for your comparisons.',
			LIMIT: 'Please limit Comparison Name to 25 Characters',
		},
		modals: {
			resetChanges: {
				ACTION_TITLE: 'Okay',
				CURRENT_CHANGES: 'Are you sure you want to cancel your current changes?',
				DELETED_MESSAGE: 'All changes made will be permanently deleted.',
			},
			searchBar: {
				PLACEHOLDER: 'Search users..',
			},
			unsavedChanges: {
				ACTION_TITLE: 'Okay',
				WARNING_ONE: 'You have unsaved changes!',
				WARNING_TWO: 'Are you sure you want to navigate away? All unsaved changes will be lost.',
			},
			comparisonModal: {
				ADD_COMPARISON: 'Add New Comparison',
				SAVE: 'Save Group',
				TITLE_EDIT: 'Edit Existing Comparison',
				TITLE_ADD: 'Add New Comparison Group',
				PLACEHOLDER: 'Enter name ...',
				validation: {
					NAME_EMPTY: 'Please enter a valid name',
					NO_SELECTED_VALUES:
						'<b>Step 1</b> – select the analysis pages where comparison will be available<br/><br/><b>Step 2</b> - choose a unique name, eg white British boys<br/><br/><b>Step 3</b> – Select your first comparison type, eg Gender<br/><br/><b>Step 4</b> – Select value, eg Male<br/><br/>Repeat steps 3 & 4 for to build the comparison with additional types & value, eg ethnicity & white British',
				},
				labels: {
					NAME: 'Name',
					APPLIES_TO: 'Applies To',
					TYPE: 'Comparison Type',
					VALUE: 'Comparison Value',
				},
			},
		},
	},
	/**
	 ** Manage Users
	 */
	manageUsers: {
		sidebar: {
			HEADING: 'Manage Users',
			links: {
				ALL_USERS: 'Active Users',
				ADD_USER: 'Add User',
				BULK_ADD_USERS: 'Bulk Add Users',
				DATA_SHARING: 'Data Sharing',
				DISABLED_USERS: 'Deactivated User Accounts',
				ORGANISATION_MANAGEMENT: 'Organisation Management',
				ADDITIONAL_INFORMATION: 'Alps Annual Census',
			},
		},
		errors: {
			REQUIRED_ERROR: 'This field is required',
			SIZE_ERROR: 'Dropped File is too large.',
			LENGTH_ERROR: 'Please limit the number of users to 100 per upload.',
			MULTIPLE_ERROR: 'Please upload one file at a time.',
			MATCHING_EMAIL: 'Please ensure the Email Addresses you have entered match',
			PERMISSIONS: 'Please select an option',
			INVALID_EMAIL: 'Please enter a valid email address',
			DUPLICATE_USER: 'Users must be unique',
			EMAIL: 'Please enter a vaild Email address',
			GENERIC: 'There has been a problem with your upload, please see below for details',
		},
		bulkUsers: {
			buttons: {
				VIEW: 'View',
				CLOSE: 'Close',
			},
		},
		pages: {
			addUser: {
				HEADING: "Add a new user to your organisation's Connect",
				USER_INFO: 'User Information',
				EMAIL_ADDRESS: 'Email Address',
				CONFIRM_EMAIL: 'Confirm Address',
				PLACE_HOLDER_EMAIL: 'username@organisation.org',
				FULL_NAME_HEADER: 'Full Name',
				FULL_NAME: 'James Key',
				JOB_TITLE_HEADER: 'Job Title',
				JOB_TITLE: 'Head Teacher',
				ANALYSIS_TITLE: 'Analysis Setup',
				ANALYSIS_TEXT:
					"Enable or disable this user's ability to setup or amend the analysis options that your users will see when using the app. e.g. Custom comparisons. <b>Administrators will always have this option<b>.",
				CONNECT_ACCESS: 'Connect Access',
				ADMINISTRATION: 'Administration',
				PERF_MEASURES: 'Performance Measures',

				KS4: 'KS4',
				KS5: 'KS5',
				optionsLabel: {
					NO_ACCESS_LABEL: 'No Access',
					NONE_VALE: 'none',
					VIEW_PDF: 'View Full PDF Reports',
					PDF_VALUE: 'pdf',
					UPLOADS_LABEL: 'Upload Data & View Full PDF Reports',
					FULL_VALUE: 'full',
				},

				embargo: {
					HEADER: 'Early Access Privilege',
					BODY:
						'Administrators can be given early access privilege to allow them to view analysis during the JCQ embargo period',
					LABEL: 'Early Access Privilege',
				},

				HEADER_TWO: 'Permissions',
				ADMIN: 'Admin',
				CONNECT_DATA: 'Connect Data',

				notice: {
					ADDING_USER:
						'Please note that adding a user to your organisation will grant them access to your data.',
					USERS_DETAILS:
						'Complete user details. User will receive email allowing them to set a password.',
				},

				permissionMessages: {
					MESSAGE_ONE: 'Use the options below to control areas of Connect accessible to user.',
					MESSAGE_TWO: 'Administrators can add, edit and remove users from the Connect account.',
					PERF_MEASURES:
						"Enable or disable this user's ability to access the performance measures for KS4 and KS5.",
				},
			},
			allUsers: {
				table: {
					EMAIL_HEADER: 'Email',
					FULL_NAME_HEADER: 'Full Name',
					JOB_TITLE_HEADER: 'Job Title',
					ALPS_CHAMPION_HEADER: 'Alps Champions',
					DATA_HEADER: 'Data Contact',
					STATUS: 'Status',
					EDIT_HEADER: 'Edit',
					TRIAL_START: 'Trial Start',
					TRIAL_END: 'Trial End',
					TRIAL_DAYS_ELAPSED: 'Days Elapsed',
					TRIAL_EXTENSIONS: 'Extensions',
					EARLY_ACCESS: 'Early Access',
					KS4_PM: 'KS4 PM',
					KS5_PM: 'KS5 PM',
					CLIENT_BENCHMARK_SWITCHING: 'Client Benchmark Switching',
					MFA_STATUS: 'MFA Enabled',
				},
				other: {
					SEARCH_BAR_HEADER: 'Add, remove and manage your staff access.',
				},
			},
			bulkAddUsers: {
				PAGE_HEADING: 'Add multiple users to your organisation',
				DRAG_TITLE: 'Drag & Drop files here',
				DRAG_SUBTITLE: '(or click here to browse and upload)',
				DOWNLOAD_HEADING: 'Download our template here',
				instructionalText: {
					PARA_ONE:
						'Please note that adding a user to your organisation will grant them access to your data.',
					PARA_TWO:
						'Once the file has loaded, press View to make any changes to your users before submission.You will receive a notification for each user.',
					PARA_THREE: 'Please add a maximum of 100 users per upload.',
				},
			},
			disabledUsers: {
				SEARCH_BAR_HEADER: 'Deactivated User Accounts',
				table: {
					EMAIL_HEADER: 'Email',
					FULL_NAME_HEADER: 'Full Name',
					JOB_TITLE_HEADER: 'Job Title',
					EDIT_HEADER: 'Edit',
				},
			},
			editUsers: {
				PAGE_HEADING: "Edit a user's information",
				USER_INFO: 'User Information',
				EMAIL_ADDRESS: 'Email Address',
				CONFIRM_EMAIL: 'Confirm Address',
				PLACE_HOLDER_EMAIL: 'username@organisation.org',
				FULL_NAME_HEADER: 'Full Name',
				FULL_NAME: 'James Key',
				JOB_TITLE_HEADER: 'Job Title',
				JOB_TITLE: 'Head Teacher',

				embargo: {
					HEADER: 'Early Access Privilege',
					BODY:
						'Administrators can be given early access privilege to allow them to view analysis during the JCQ embargo period',
					LABEL: 'Early Access Privilege',
				},

				ANALYSIS_TITLE: 'Analysis Setup',
				ANALYSIS_TEXT:
					"Enable or disable this user's ability to setup or amend the analysis options that your users will see when using the app. e.g. Custom comparisons. <b>Administrators will always have this option<b>.",

				CLIENT_BENCHMARK: 'Client Benchmarks',
				CONNECT_DATA: 'Connect Data',
				HEADER_TWO: 'Permissions',
				ADMIN: 'Admin',
				CONNECT_ACCESS: 'Connect Access',
				ADMINISTRATION: 'Administration',
				PERF_MEASURES: 'Performance Measures',
				KS4: 'KS4',
				KS5: 'KS5',
				CLIENT_BENCHMARK_TOGGLE: 'Allow benchmark switching for this user',
				optionsLabel: {
					NO_ACCESS_LABEL: 'No Access',
					NONE_VALE: 'none',
					VIEW_PDF: 'View Full PDF Reports',
					PDF_VALUE: 'pdf',
					UPLOADS_LABEL: 'Upload Data & View Full PDF Reports',
					FULL_VALUE: 'full',
				},

				SETTINGS: 'Settings',
				DISABLE: 'Disable User Account',
				REMOVE: 'Delete User',

				notice: {
					NOTICE_ONE:
						'Please note that adding a user to your Organisation will grant them access to your data.',
					NOTICE_TWO:
						'Complete user details. User will receive email allowing them to set a password.',
				},

				permissionMessages: {
					MESSAGE_ONE: 'Use the options below to control areas of Connect accessible to user.',
					MESSAGE_TWO: 'Administrators can add, edit and remove users from the Connect account.',
					PERF_MEASURES:
						"Enable or disable this user's ability to access the performance measures for KS4 and KS5.",
					CLIENT_BENCHMARK: 'Enables the user to switch between standard and Client Benchmarks.',
				},
			},
		},
		modals: {
			generic: {
				OK_ACTION: 'Ok',
			},
			resetChangesModal: {
				ACTION_TITLE: 'Okay',
				CURRENT_CHANGES: 'Are you sure you want to cancel your current changes?',
				DELETED_MESSAGE: 'All changes made will be permanently deleted.',
			},
			searchBar: {
				PLACEHOLDER: 'Search users..',
			},
			unsavedChanges: {
				ACTION_TITLE: 'Okay',
				WARNING_ONE: 'You have unsaved changes!',
				WARNING_TWO: 'Are you sure you want to navigate away? All unsaved changes will be lost.',
			},
			confirmationModal: {
				SUCCESS_TITLE: 'Success',
				SUCCESS: 'Your details have been updated.',
				ERROR_TITLE: 'Error',
				ERROR: 'Unfortunately we have been unable to update your details.',
			},
		},
	},
	/**
	 ** Auth
	 */
	auth: {
		login: {
			TITLE: 'Login to Alps Connect',
			messages: {
				UNEXPECTED_ERROR: 'Unexpected error - Please try again',
				TOO_MANY_ATTEMPTS: `%{statusText}. Please try again after %{retryAfter}secs`,
				INVALID_LOGIN_DETAILS: 'Invalid Login Information - Please try again',
				CONFIRM_ACCOUNT:
					'Please confirm your account before attempting to log in. You can click the link below to resend the confirmation email',
				EMAIL_ADDRESS_REQUIRED: 'Email address required',
				PASSWORD_REQUIRED: 'Password required',
				CONFIRMATION_EMAIL:
					'Email successfully sent to %{emailAddress}. Please follow the instructions in your email',
				VALID_EMAIL: 'This should be a valid email address',
				EMAIL_REQUIRED: 'Email is required.',
				PLEASE_TRY: 'Please try again',
				TRIAL_EXPIRED: 'Trial Period has expired',
				TRIAL_EXTENDED: 'Trial Period has been extended till %{endDate}.',
			},
			trial_text: {
				PARA_ONE: 'Your Alps Trial Period has now expired.',
				PARA_TWO: 'To further extend your trial period, please click the button below.',
			},
			links: {
				ABOUT: 'What is Connect',
				RESET_PASSWORD: 'Reset Password',
				CONTACT: 'Contact Us',
				CREATE_ACCOUNT: 'Create an account',
				RESEND_CONFIRM_EMAIL: 'Resend confirmation',
				EXTEND_TRIAL_PERIOD: 'Extend Trial Period',
			},
			buttons: {
				SIGN_IN: 'Sign In',
				EXTEND_TRIAL: 'Extend Trial',
			},
		},
		createPassword: {
			DESCRIPTION:
				'To activate your account, please create a password (at least  %{smart_count} characters)',
			LOADING: 'Your password is being set...',
			COMPLETE: 'Your password has been set',
			BUTTONS: {
				SET_PASSWORD: 'Submit',
			},
		},
		forgottenPassword: {
			TITLE: 'Forgotten Password',
			DESCRIPTION:
				'Enter your email below and we will send you information on how to reset your password',
			messages: {
				PASSWORD_RESET_MESSAGE:
					'Password reset email successfully sent to %{username}. Please follow the instructions in your email',
				FAILED: 'Unable to reset your password, please try again or contact support',
				VALID_EMAIL: 'This should be a valid email address',
			},
			buttons: {
				CANCEL: 'Cancel',
				SEND_INSTRUCTIONS: 'Send Instructions',
			},
		},
		forgottenPasswordComplete: {
			DESCRIPTION:
				'Please complete this form to reset your password. Passwords must meet our complexity requirements',
			CHARACTER_LENGTH: 'At least %{smart_count} characters long',
			UPPERCASE_LENGTH: 'Contain at least 1 lower case character',
			LOWERCASE_LENGTH: 'Contain at least 1 upper case character',
			SPECIAL_CHARACTERS_LENGTH:
				'Contain at least 1 special character. Accepted characters #?!@$%^&*-',
			NUMBERS_LENGTH: 'Contain at least 1 number',
			RESET_PASSWORD: 'Reset Password',
			LOADING: 'Your password is being reset...',
			COMPLETE: 'Your password has been reset',
			LOGIN: 'Go to login',
			placeholders: {
				PASSWORD: 'Password',
				CONFIRM: 'Confirm Password',
			},
			errors: {
				RESET_FAILURE:
					'An error occurred while setting your password, please try again or contact support',
				PASSWORD_REQUIRED: 'You must enter a password',
				PASSWORD_LENGTH: 'Passwords must be at least %{smart_count} characters long',
				PASSWORD_COMPLEXITY_NOT_MET: 'Password does not meet our complexity requirements',
				CONFIRM_REQUIRED: 'Please re-enter your new password',
				CONFIRM_MATCH: 'Passwords do not match',
			},
		},
		activate: {
			TITLE: 'In-depth, interactive analysis of your Alps reports',
			messages: {
				ACTIVATION_CODE_REQUIRED: 'Activation code required',
				POSTCODE_REQUIED: 'Postcode required',
				SUCCESSFUL_ACTIVATION_PART1:
					'Thank you for entering your code. The next step is to sign in with your existing Connect account or ',
				SUCCESSFUL_ACTIVATION_PART2: ' to continue the activation process.',
				CREATE_ACCOUNT: 'Create a new account here',
			},
			links: {
				ABOUT: 'What is connect',
				CONTACT: 'Contact Us',
			},
			buttons: {
				ACTIVATE: 'Activate',
			},
		},
		clientConfirmation: {
			TITLE: 'Organisation Confirmation',
			DESCRIPTION: 'Please confirm the organisation to be activated.',
			buttons: {
				YES: 'YES',
				NO: 'NO',
			},
			form: {
				NAME: 'Name',
				TYPE: 'Type',
				ADDRESS: 'Address',
				POSTCODE: 'Postcode',
			},
		},
		confirmAccount: {
			TITLE: 'Thank you, activation complete!',
			description: {
				PART1:
					'The activation process is now complete. You can go straight to the Connect Login page.',
				PART2: 'You can add other users at any point by going to the settings area from Connect.',
			},
			addingUsers: {
				TITLE: 'Adding Other Users',
				DESCRIPTION:
					'You are currently the only Administrator. We recommend that you create at least one additional Administrator in case you are unavailable.',
			},
			LINKS: {
				ADD_USER: 'Add User',
				CONNECT_INTERACTIVE: 'Alps Connect',
			},
			errorMessages: {
				RESEND_EMAIL:
					'Please use the link in the confirmation email sent to your email address. If you would like a new email confirmation link please ',
				RESEND_EMAIL_LINK: 'click here',
				UNSUCCESSFUL_ATTEMPT: 'Unable to confirm the account, please try again or contact support',
			},
		},
		createAccount: {
			TITLE: 'CREATE CONNECT ACCOUNT',
			descriptions: {
				CREATE_ACCOUNT_PART1: `After creating your account you will be the administrator for the organisation, allowing you to add other users
				and amend user permissions. We recommend having at least two administrators on the account. Please see our Knowledge Base article `,
				CREATE_ACCOUNT_PART2: ' for more information on adding users.',
				CONTACT_SUPPORT:
					'If you have any questions about this process, please do not hesitate to contact us.',
			},
			links: {
				KNOWLEDGE_BASE_ARTICLE: '[%{zoho_link}/en/kb/articles/how-do-i-add-new-users]',
			},
			form: {
				userAccount: {
					TITLE: 'USER ACCOUNT INFORMATION',
					EMAIL_ADDRESS: 'Email Address',
					CONFIRM_EMAIL: 'Confirm Email',
					FULL_NAME: 'Full Name',
					JOB_TITLE: 'Job Title',
					ORGANISATION: 'Organisation',
				},
				passwords: {
					TITLE: 'CHOOSE A PASSWORD',
					PASSWORD: 'Password',
					CONFIRM_PASSWORD: 'Confirm Password',
				},
				contactPreferences: {
					TITLE: 'CONTACT PREFERENCES',
					ALPS_NEWS: 'Alps News',
					ALPS_NEWS_DESCRIPTION:
						'Keep me up to date about Alps news & events by email from time to time',
					IMPORTANT_UPDATES: 'Important Updates',
					IMPORTANT_UPDATES_DESCRIPTION:
						'Send me important alerts about updates to the Alps software and anything which may affect my data submissions',
				},
				termsAndConditions: {
					TITLE: 'TERMS & CONDITIONS',
					TERMS_AND_CONDITIONS_MESSAGE_PART_1: 'I have read and accept the Terms & Conditions.',
					TERMS_AND_CONDITIONS_MESSAGE_PART_2:
						'You must accept our terms and conditions to submit this form.',
				},
			},
			buttons: {
				CLEAR: 'Clear',
				CREATE: 'Create Account',
			},
			messages: {
				CONTACT_SUPPORT: 'Unable to create the account, please try again or contact support',
				EMAIL_ADDRESS_REQUIRED: 'Email address is required',
				CONFIRM_EMAIL_ADDRESS_REQUIRED: 'Confirmation email address is required',
				EMAIL_AND_CONFIRM_EMAIL_DO_NOT_MATCH:
					'Email address and confirm email address do not match',
				FULL_NAME_REQUIRED: 'Full name is required',
				JOB_TITLE_REQUIRED: 'Job title is required',
				ORGANISATION_REQUIRED: 'Organisation is required',
				PASSWORD_REQUIRED: 'Password is required',
				PASSWORD_LENGTH: 'Password must be at least 8 characters',
				CONFIRM_PASSWORD_REQUIRED: 'Confirm password is required',
				PASSWORD_AND_CONFIRM_PASSWORD_DO_NOT_MATCH: 'Password and confirm password do not match',
				TERMS_AND_CONDITIONS_REQUIRED: 'Terms and conditions are required',
			},
		},
	},
	monitoring: {
		filterBar: {
			PAGE_HEADER: 'Filter Subjects',
			PLACEHOLDER_TEXT: 'Enter subject name ...',
			QUAL_TYPE: 'Filter by Qualification Type',
			COMPARISON: 'Comparison Gradepoints',
			MORE_ACTIONS: 'More Actions',
		},
		graph: {
			ALPS_LABEL: 'Alps Score',
			MONITORING_LABEL: 'Monitoring Points',
			LEGEND_LABEL: 'Legend',
			OVERVIEW_LABEL: 'Overview',
			TEACHING_LABEL: 'Teaching Set',
			TEACHER_LABEL: 'Teacher',
			WARNING: 'There is no available exam gradepoint data for this subject',
		},
		overview: {
			INSTRUCTIONAL_TEXT:
				"This report plots a subject's Alps scores over a year. You can also plot scores for individual teachers and teaching sets on the same graph to help spot any trends.",
			SET: 'Teaching Sets',
			TEACHERS: 'Teachers',
			messages: {
				UNABLE_TO_FETCH_DATA: `It looks like we were unable to fetch data for subject %{subjectName}, please try again or if the problem persists contact support on ${CST_NUMBER}`,
			},
			VIEW_SUBJECT: 'View Subject Page',
			EXAM_GP: 'View Latest Exam GP',
		},
		messages: {
			SELECT_ANOTHER_GRADEPOINT: 'Select Another Gradepoint',
		},
		pages: {
			table: {
				difference: {
					SUBJECT_HEADER: 'Subjects',
					ALPS_SCORE_HEADER: 'Alps Score Diff.',
					STUDENT_GRADE_HEADER: '% Student Grade Diff.',
					AG_GRID_TOOLPANELS: 'Columns',

					differenceTable: {
						ENTRIES_HEADER: 'Entries',
						SCORE_HEADER: 'Score',
						GRADE_HEADER: 'Grade',
					},

					PARAGRAPH:
						'Select any two Gradepoints to compare the Alps score difference. Where the Gradepoints contain matched students the table will show the percentage of grades changed.',
					messages: {
						NO_SHARED_DATA:
							'There are no shared subjects available for comparison from the %{secondaryGradepointName} and %{primaryGradepointName}.',
						SELECT_ANOTHER_GRADE_POINT:
							'There is only one Gradepoint in your custom Gradepoint selection. Please select another Gradepoint to compare to.',
					},
				},
				examVsMonitoring: {
					SUBJECT_HEADER: 'Subjects',
					ALPS_SCORE_HEADER: 'Alps Score Difference.',
					STUDENT_GRADE_HEADER: '% Student Grade Difference.',
					AG_GRID_TOOLPANELS: 'Columns',

					examVsMonitoringTable: {
						ENTRIES_HEADER: 'Entries',
						SCORE_HEADER: 'Score',
						GRADE_HEADER: 'Grade',
					},

					PARAGRAPH:
						'Select an Examination Gradepoint to automatically compare it to the latest in year monitoring Gradepoint for the same cohort of students. ',
					messages: {
						GRADE_POINT_TYPE:
							'Your selected Gradepoint %{primaryGradepointName} is an In Year monitoring point. Please select an End of Year Gradepoint to see an Exam vs Monitoring comparison.',
						NO_IN_YEAR_GRADE_POINTS:
							'You have no in-year monitoring gradepoints for this exam gradepoint, please select another gradepoint.',
						NO_SHARED_DATA:
							'There are no shared subjects available for comparison from the %{secondaryGradepointName} and %{primaryGradepointName}.',
					},
				},
				variance: {
					SUBJECT_HEADER: 'Subjects',
					ALPS_SCORE_HEADER: 'Alps Score Diff.',
					STUDENT_NAME_HEADER: '% Student Grade Diff.',
					AG_GRID_TOOLPANELS: 'Columns',
					LARGEST_DIFF_HEADER: 'Largest Diff.',
					TREND_HEADER: 'Trends',

					varianceTable: {
						ENTRIES_HEADER: 'Entries',
						SCORE_HEADER: 'Score',
						GRADE_HEADER: 'Grade',
					},

					INSTRUCTIONAL_TEXT:
						'Select a monitoring Gradepoint to interrogate any variation in the Alps scores across a monitoring trend. Click on subject names to analyse teaching set variation.',
					messages: {
						GRADE_POINT_TYPE:
							'Your selected Gradepoint %{primaryGradepointName} is an End of Year monitoring point. Please select an In Year Gradepoint to see an in year comparison.',
						SELECT_ANOTHER_GRADE_POINT:
							'There is only one Gradepoint in your custom Gradepoint selection. Please select another Gradepoint to compare to.',
						NO_SHARED_DATA:
							'There are no shared subjects available between your selected comparisons.',
					},
				},
			},
		},
	},
	/**
	 ** Subject Page
	 */
	subjectPage: {
		TITLE: 'Subjects',
		savedReport: {
			LOADING: 'Loading saved report data',
			NO_REPORTS: 'You have no saved/shared reports for this area.',
		},
		filterBar: {
			SHOW_THERMO: 'Show Thermometer?',
			APPLY_FILTERS: 'Apply Filters',
			APPLY_COMPARISONS: 'Apply Comparisons',
			ADHOC_GROUPS: 'Adhoc Groups',
			WHAT_IF: 'What If',
			SIMPLIFY_VIEW: 'Simplify View',
			COMPARISON_GP: 'Comparison Gradepoint',
			DOWNLOADS: 'Downloads',
			REPORT_CONTROLS: 'Manage Report',
			MORE_ACTIONS: 'More Actions',
			tooltips: {
				DOWNLOAD_PDF: 'Export PDF',
				DOWNLOAD_XLXS: 'Export Excel',
			},
		},
		rangeScale: {
			START: '0%',
			END: '100%',
		},
		overallVasEquation: {
			OVERALL_VAS: 'Overall VAS = ',
			MINUS: '-',
			TIMES: 'x ',
			PLUS_ONE: '+ 1 = ',
			GRADE: 'grade ',
		},
		agGridOutcomes: {
			colDefs: {
				STUDENTS: 'Students',
				A_STAR: 'A*',
				A: 'A',
				B: 'B',
				C: 'C',
				D: 'D',
				E: 'E',
				U: 'U',
				X: 'X',
				Q: 'Q',
				A_STAR_E: 'A* > E%',
				A_STAR_C: 'A* > C%',
				A_STAR_B: 'A* > B%',
				PA: 'Avg. PA',
				GCSE_SCORE: 'Avg. pts',
			},
			rowDefs: {
				POINTS: 'Points',
				TOTAL: 'Total',
				CHANGES: 'Changes',
			},
		},
		agGridOutcomeBands: {
			colDefs: {
				BAND: 'Band',
				STUDENTS: 'Students',
				X: 'x',
				TARGET: 'Target',
				EQUALS: '=',
				EXPECTED_POINTS: 'Expected Points',
				GCSE_SCORE: 'GCSE Points',
			},
		},
		overlay: {
			ERROR_MESSAGE:
				'This subject contains no data for this gradepoint. Either select another gradepoint or return to subjects overview',
			RETURN_BUTTON: 'Return to Subjects Overview',
			NEW_GP: 'Select Another Gradepoint',
		},
		studentModal: {
			colDefs: {
				SUBJECT: 'Subject',
				PA: 'Prior Attainment',
				MEG: 'MEG',
				PT: 'Personalised Target',
				TREND_LINE: 'Trend Line',
				DIFF: 'Grade Diff.',
				STUDENT_ID: 'Student Id',
				GENDER: 'Gender',
				DOB: 'Date of Birth',
				ETHNICITY: 'Ethnicity',
				TUTOR_GROUP: 'Tutor Group',
				DISADVANTAGED: 'Disadvantaged',
				EAL: 'EAL',
				SEND: 'SEND',
				MEAN_SAS: 'Mean SAS',
				VERBAL: 'Verbal',
				QUANTATIVE: 'Quantative',
				NON_VERBAL: 'Non-Verbal',
				SPATIAL: 'Spatial',
				LEARNING_BIAS: 'Learning Bias',
			},
			filterBar: {
				INSTRUCTIONAL_TEXT:
					'The "Grade Diff." column shows the grade difference between your comparison Gradepoint and the main Gradepoint. Fine Grades will not be used in the diff. calculation. Use the dropdown to choose the comparison Gradepoint.',
				labels: {
					COMPARISON_GP: 'Comparison Gradepoint',
					DOWNLOADS: 'Downloads',
				},
				MORE_ACTIONS: 'More Actions',
			},
		},
		exportModal: {
			pages: {
				TITLE: 'What pages would you like to export?',
				SUBJECT_ONLY: 'Print subject page',
				SUBJECT_AND_TEACHING: 'Print subject and teaching set pages',
			},
			student: {
				TITLE: 'How would you like to export the student data?',
				INCLUDE_NAME: 'Include student names',
				INCLUDE_ID: 'Replace student names with student ID',
				EXCLUDE_NAME: 'Exclude student names',
			},
			noPDFAccess: {
				ACCEPT: 'Accept',
				HEADING: 'PDF Report request',
				BODY: 'PDF Report requested, we will notify you when the PDF is ready for download',
			},
		},
		reportControls: {
			REFETCH: 'Clear saved report',
			RESET: 'Reset',
			UNDO: 'Undo',
			REDO: 'Redo',
			SAVE: 'Save',
			HOW_SAVE: 'How would you like to save this report?',
			REPLACE: 'Replace',
		},
		fineGrades: {
			INSTRUCTIONAL_TEXT:
				"Drag the sliders to see the potential effects of various fine grade scenarios on your subject's Alps grade.",
			STUDENTS: 'students',
			NO_FINE_GRADES:
				'<i>This group does not contain any students that can be included in fine grades analysis.</i></br>' +
				'To find out more about switching on and using fine grades, please visit our ' +
				'<a href="%{zoho_link}/en/kb/articles/switching-on-fine-grades" target="_blank">Knowledge Base</a>.',
			stretch: {
				HEADING: 'Stretch',
				LABEL: 'Percentage to go up to the next grade.',
			},
			secure: {
				HEADING: 'Secure',
				LABEL_UP: 'Percentage to go up.',
				LABEL_ACHIEVE: 'Percentage to achieve.',
				LABEL_DROP: 'Percentage to drop.',
			},
			risk: {
				HEADING: 'At Risk',
				LABEL: 'Percentage to drop down to the grade below.',
			},
		},
		trends: {
			agGrid: {
				colDefs: {
					NAME: 'Name',
					TEACHING_SET: 'Teaching Set',
					PA: 'Prior Attainment',
					MEG: 'MEG',
					PT: 'Personalised Target',
					TREND_LINE: 'Trend Line',
					DIFF: 'Difference',
					LEARNING_BIAS: 'Learning Bias',
					MEAN_SAS: 'Mean SAS',
					VERBAL_SAS: 'Verbal SAS',
					NON_VERBAL_SAS: 'Non Verbal SAS',
					SPATIAL_SAS: 'Spatial SAS',
					QUANTITATIVE_SAS: 'Quantitative SAS',
					EAL: 'EAL',
					SEND: 'SEND',
					DISADVANTAGED: 'Disadvantaged',
					GENDER: 'Gender',
					UPN: 'UPN',
				},
			},
			PARAGRAPH: 'Difference column compares main Gradepoint to selected Gradepoint.',
			NO_TREND: 'No Trend',
		},
		students: {
			agGrid: {
				colDefs: {
					ADHOC: 'Group',
					STUDENT_ID: 'Student Id',
					NAME: 'Name',
					TEACHING_SET: 'Teaching Set',
					GENDER: 'Gender',
					PA: 'Prior Attainment',
					MEG: 'MEG',
					PT: 'Personalised Target',
					GRADE: 'Grade',
					WHAT_IF: 'What If Grade',
					GCSE_SCORE: 'GCSE Points',
				},
			},
			contextMenu: {
				HEADING: 'Set Adhoc Group',
				GREEN: 'Green',
				ROSE: 'Rose',
				ORANGE: 'Orange',
				PURPLE: 'Purple',
				UNKNOWN: 'Unknown',
			},
			accordion: {
				STUDENT: '%{label} - %{smart_count} Student |||| %{label} - %{smart_count} Students ',
			},
			reportTitlePrefix: {
				REPORT: 'Report: ',
			},
		},
		overview: {
			agGrid: {
				colDefs: {
					ENTRIES: 'Entries',
					SCORE: 'Score',
					GRADE: 'Grade',
					AVG_PA: 'Avg. Pa',
				},
			},
			groupTitles: {
				SUMMARY: 'Summary',
				TEACHING_SET: 'Teaching Sets',
				TEACHER: 'Teacher',
			},
			NO_BENCHMARK: 'This subject does not have a benchmark.',
		},
		outcomes: {
			NO_GROUP_DATA:
				'This group does not contain any students that can be included in a VAS calculation.',
			original: {
				HEADING: 'Original Outcomes',
			},
			whatIf: {
				HEADING: 'What if Outcomes',
				GRADE_WARNING:
					'This grade is not valid and has not been</br>included in the "What If" calculation.</br>Please select a valid grade to be included.',
			},
		},
		thermometer: {
			IS_PLURAL: 's',
			GRADE: '%{grade} grade',
			GRADES: '%{grades} grades',
			DEFAULT_GRADE: '1 grade',
			POINTS: '%{numberOfPoints} Points',
			DEFAULT_POINTS: '40 Points',
			GRADE_IMPS: 'Grade Improvements = ',
			VAS_SCORE_DIFF: 'Difference in VA score',
			TOTAL_STUDENTS: 'Number of students',
			PERCENTAGE_COHORT: 'This is <b>%{percentage}</b> of your cohort.',
			PER_ENTRY: 'per entry',
			GRADE_IMPROVEMENTS_LESS_THAN_COHORT:
				'On average, <b>%{smart_count}</b> student will need to improve by <b>%{moveAmount} %{perEntry}</b> to reach an Alps grade <b>%{droppedGrade}</b>||||On average, <b>%{smart_count}</b> students will need to improve by <b>%{moveAmount} %{perEntry}</b> to reach an Alps grade <b>%{droppedGrade}</b>',
			gradeImprovementsLargerThanCohort: {
				SENTENCE_START:
					'On average, <b>%{smart_count}</b> student will need to improve by <b>%{moveAmount} %{perEntry}</b>||||On average, <b>%{smart_count}</b> students will need to improve by <b>%{moveAmount} %{perEntry}</b>',
				SENTENCE_MID:
					/**
					 * Pluralisation is handled differently for the following. We have multiples pluralisations where the smart_count is not rendered
					 * therefore these are passed in as pluralSingleGrades & pluralFullCohort rather than the usual ||||.
					 */
					' and <b>%{remainderStudents} student%{pluralSingleGrades}</b> will need to improve by <b>%{moveAmount}%{pluralFullCohort} %{perEntry}</b>',
				SENTENCE_END: 'to reach an Alps grade <b>%{droppedGrade}</b>',
			},
			GRADE_REDUCTIONS_LESS_THAN_SIZE_OF_COHORT:
				'On average, if <b>%{smart_count}</b> student dropped by <b>%{moveAmount} %{perEntry}</b> you would fall to an Alps grade <b>%{droppedGrade}</b>.||||On average, if <b>%{smart_count}</b> students dropped by <b>%{moveAmount} %{perEntry}</b> you would fall to an Alps grade <b>%{droppedGrade}</b>.',
			gradeReductionsLargerThanSizeofCohort: {
				SENTENCE_START:
					'On average, <b>%{smart_count}</b> student dropped by <b>%{moveAmount} %{perEntry}</b>||||On average, <b>%{smart_count}</b> students dropped by <b>%{moveAmount} %{perEntry}</b>',
				SENTENCE_MID:
					' and <b>%{remainderStudents} student%{pluralRemainderStudents}</b> dropped by <b>%{moveAmount}%{pluralFullCohort} %{perEntry}</b>',
				SENTENCE_END: 'your cohort would fall to an Alps grade <b>%{droppedGrade}</b>',
			},
		},
		saved: {
			agGrid: {
				colDefs: {
					RENAME: 'Rename',
					REPORT: 'Report',
					CREATED_BY: 'Created by',
					LAST_UPDATED: 'Last updated',
					OPEN: 'Open',
					SHARE: 'Share',
					DELETE: 'Delete',
				},
			},
		},
		modals: {
			delete: {
				DELETE: 'Delete',
				WARNING: 'This cannot be undone - are you sure you want to delete this report?',
			},
			share: {
				ALLOW_SHARE: 'Allow other users to see this report',
				DISALLOW_SHARE:
					'Only users that created a report can change whether or not it is visible to others',
				COPY: 'Copy and share this URL with colleagues to allow them to open the report:',
				COPIED: 'Copied to clipboard!',
			},
		},
		attainment: {
			TITLE: 'GCSE England Summary Attainment Profile',
			agGrid: {
				examResults: {
					TITLE: 'Exam Results',
					headers: {
						YEAR: 'Year',
						PA: 'Avg. Prior Attainment',
						'%9': '9',
						'%8': '8',
						'%7': '7',
						'%6': '6',
						'%5': '5',
						'%4': '4',
						'%3': '3',
						'%2': '2',
						'%1': '1',
						'%U': 'U',
						'%9-7': '9-7',
						'%9-6': '9-6',
						'%9-5': '9-5',
						'%9-4': '9-4',
						'%9-1': '9-1',
						APS: 'APS',
					},
				},
				currentLearners: {
					TITLE: '2020/21',
					headers: {
						CURRENT_LEARNER: '2020/21',
						PA: 'Avg. Prior Attainment',
						'%9': '9',
						'%8': '8',
						'%7': '7',
						'%6': '6',
						'%5': '5',
						'%4': '4',
						'%3': '3',
						'%2': '2',
						'%1': '1',
						'%U': 'U',
						'%9-7': '9-7',
						'%9-6': '9-6',
						'%9-5': '9-5',
						'%9-4': '9-4',
						'%9-1': '9-1',
						APS: 'APS',
					},
				},
			},
			charts: {
				disadvantaged: {
					TITLE: 'Average Point Score - Disadvantaged Comparison',
					YAXIS: 'Average Point Score',
					XAXIS: 'Cohorts',
					DIS: 'Dis.',
					NON_DIS: 'Non Dis.',
				},
				attainment: {
					TITLE: 'Attainment Profile',
					YAXIS: '% of Students',
					XAXIS: 'Cohorts',
					'%9-7': '%9-7',
					'%9-5': '%9-5',
					'%9-4': '%9-4',
				},
				gender: {
					TITLE: 'Average Point Score - Gender Comparison',
					YAXIS: 'Average Point Score',
					XAXIS: 'Cohorts',
					MALES: 'Males',
					FEMALES: 'Females',
				},
			},
		},
		cohortProfile: {
			TITLE: 'GCSE England Cohort Profile',
			agGrid: {
				paProfile: {
					TITLE: 'Prior Attainment Profile',
					headers: {
						OVERALL_PA: 'Overall PA Group',
						ALPS_PA: 'Alps Prior Attainment Band',
					},
				},
				megProfile: {
					TITLE: 'Minimum Expect Grade Profile',
					headers: {
						GRADE: 'Grade %',
					},
				},
			},
			charts: {
				disadvantaged: {
					TITLE: 'Disadvantaged',
					YAXIS: '% of Students',
					XAXIS: 'Cohorts',
					DIS: 'Dis.',
					NON_DIS: 'Non Dis.',
				},
				gender: {
					TITLE: 'Gender',
					YAXIS: '% of Students',
					XAXIS: 'Cohorts',
					MALES: 'Males',
					FEMALES: 'Females',
				},
				send: {
					TITLE: 'SEND',
					YAXIS: '% of Students',
					XAXIS: 'Cohorts',
					SEND: 'SEN',
					NON_SEND: 'Non SEND',
					EHCP: 'EHCP',
				},
			},
		},
		gapAnalysis: {
			TITLE: 'GCSE England Summary Attainment Profile',
			agGrid: {
				paBands: {
					TITLE: 'Prior Attainment Bands',
					headers: {
						AVG_PA: 'Avg. Prior Attainment Band',
						POINTS_TARGET: 'Points Target',
						STUDENTS: 'Student Numbers',
						POINTS_SCORED: 'Points Scored',
						ALPS_GRADE: 'Alps Grade',
					},
				},
				comparisons: {
					GENDER: 'Gender Comparison',
					DISADVANTAGED: 'Disadvantaged Comparison',
					SEND: 'SEND Comparison',
					headers: {
						STUDENTS: 'Student Numbers',
						AVG_PA: 'Avg. Prior Attainment',
						ALPS_QI_SCORE: 'Alps QI Score',
						GENDER: 'Male vs Female',
						DISADVANTAGED: 'Disadvantaged vs Non-Disadvantaged',
						SEND: 'SEND vs Non-SEND',
					},
				},
			},
		},
		landingPage: {
			dashboard: {
				overview: {
					HEADING: '%{level} %{country} Subject Overview',
					ATTAINMENT: 'Attainment Profile',
					OVERALL: 'Overall',
				},
				examResults: {
					HEADING: '+/- 2021 Exam Results',
					ALL: 'All',
					PRIOR_ATTAINMENT: 'Prior Attainment',
					GENDER: 'Gender',
					DISADVANTAGED: 'Disadvantaged',
				},
				currentLearners: {
					HEADING: '+/- Current Learners - %{year}',
					ALL: 'All',
					PRIOR_ATTAINMENT: 'Prior Attainment',
					GENDER: 'Gender',
					DISADVANTAGED: 'Disadvantaged',
				},
				targets: {
					HEADING: '+/- Targets',
					headers: {
						GROUP: 'Group',
						ON_OR_ABOVE: 'On or above target',
						LOWER_OF_SPLIT: 'Lower of split grade targets',
						BELOW_TARGET_1: 'Below target by 1 grade',
						BELOW_TARGET_PLUS: 'Below target by 1+ grades',
					},
				},
			},
			sidebar: {
				profileAnalysis: {
					HEADING: 'Profile & Analysis',
					COHORT_PROFILE: 'Cohort Profile',
					ATTAINMENT_PROFILE: 'Attainment Profile',
					GAP_ANALYSIS: 'Gap Analysis',
				},
				teachingSets: {
					HEADING: 'Teaching Sets',
					ALL: 'All Teaching Sets',
				},
				monitoring: {
					HEADING: 'Monitoring',
					LINK: 'Monitoring',
				},
			},
		},
	},
	/**
	 * Switch Organisation
	 */
	switchOrganisation: {
		page: {
			agGrid: {
				NAME: 'Name',
				CHECKBOX: 'Always log in as this organisation',
			},
			SUBTITLE:
				'Your account is linked to multiple organisations. To continue, please select the organisation you wish to use this session.',
		},
		REDUX: 'Please seek support',
	},
	/**
	 ** Subject Overview
	 */
	subjectsOverview: {
		TITLE: 'Subject Analysis',
		NOT_IN_ANALYSIS: 'Students not included in analysis',
		filterBar: {
			SEARCH: 'Search Subjects',
			FILTER_QUAL: 'Filter By Qualification Type',
			APPLY_FILTERS: 'Apply Filters',
			APPLY_COMPARISONS: 'Apply Comparisons',
			SHOW_TEACHING_SETS: 'Show Teaching Sets',
			SHOW_ONE_TABLE: 'Show In One Table',
			DOWNLOADS: 'Downloads',
			MORE_ACTIONS: 'More Actions',
			tooltips: {
				DOWNLOAD_PDF: 'Export PDF',
				DOWNLOAD_XLXS: 'Export Excel',
			},
		},
		warnings: {
			OUT_OF_YEAR: 'Your main Gradepoint selection does not contain this custom comparison field',
			CLEAR_COMPARISON_BUTTON: 'Clear Comparisons',
		},
		agGrid: {
			colDefs: {
				SUBJECTS: 'Subjects',
				ENTRIES: 'Entries',
				SCORE: 'Score',
				GRADE: 'Grade',
				AVG_PA: 'Avg PA',
			},
		},
		exportModal: {
			pages: {
				TITLE: 'What pages would you like to export?',
				SUBJECT_ONLY: 'Print all subject pages',
				SUBJECT_AND_TEACHING: 'Print all subject and teaching set pages',
			},
			qualFilter: {
				HEADING: 'Filter By Qualification Type',
				WARNING:
					'<i>Note: we advise you export KS4 and KS5 separately. This will reduce the time taken to generate your report.</i>',
			},
			student: {
				TITLE: 'How would you like to export the student data?',
				INCLUDE_NAME: 'Include student names',
				INCLUDE_ID: 'Replace student names with student ID',
				EXCLUDE_NAME: 'Exclude student names',
			},
			filter: {
				FILTER: 'You have the following filters applied:',
				COMPARISON: 'You have the following comparisons applied:',
			},
		},
		pdfRequestedModal: {
			OK: 'Ok',
			HEADING: 'PDF Report Requested',
			BODY: 'PDF Report requested, we will notify you when the PDF is ready for download.',
		},
	},
	/**
	 ** SPO
	 */
	spo: {
		filterBar: {
			SEARCH: 'Search Students',
			SEARCH_PLACEHOLDER: 'Search...',
			APPLY_FILTERS: 'Apply Filters',
			APPLY_COMPARISONS: 'Apply Comparisons',
			COMPARISON_GP: 'Comparison Gradepoint',
			DOWNLOADS: 'Downloads',
			FOCUS_ON: 'Focus On',
			TUTOR_GROUP: 'Tutor Group',
			MORE_ACTIONS: 'More Actions',
			// PMs Filter Bar
			COMPARISON_SELECT: 'Select Comparison',
			METRIC_SELECT: 'Select Metrics',
			APPLY_CHANGES: 'Apply changes',
			APPLY: 'Apply',
			STATUS_TOGGLE_MEG: 'MEG / PT',
		},
		agGrid: {
			headers: {
				NAME: 'Student Name',
				PA: 'Prior Attainment',
				TUTOR_GROUP: 'Tutor Group',
				SUBJECTS: 'Subjects',
				UPN: 'UPN',
				EAL: 'EAL',
				SEND: 'SEND',
				LEARNING_BIAS: 'Learning Bias',
				MEAN_SAS: 'Mean SAS',
				VERBAL_SAS: 'Verbal SAS',
				NON_VERBAL_SAS: 'Non Verbal SAS',
				QUANTITATIVE_SAS: 'Quantitative SAS',
				SPATIAL_SAS: 'Spatial SAS',
				DISADVANTAGED: 'Disadvantaged',
				A_LEVEL_VAS: 'A Level Student VAS',
				KS4_LEVEL_VAS: 'KS4 Student VAS',
				GENDER: 'Gender',
				toggledHeaders: {
					meg: {
						ABOVE: 'Subj 1+ above MEG',
						HIT: 'Subj on MEG',
						BELOW: 'Subj 1 below MEG',
						WELL_BELOW: 'Subj 1+ below MEG',
					},
					pt: {
						ABOVE: 'Subj 1+ above PT',
						HIT: 'Subj on PT',
						BELOW: 'Subj 1 below PT',
						WELL_BELOW: 'Subj 1+ below PT',
					},
				},
			},
		},
		performanceMeasures: {
			gradepointError: {
				HEADING: 'Please select a KS4 Gradepoint',
				TEXT:
					'Your selected gradepoint does not contain KS4 data, please selected a gradepoint use the selector above.',
			},
			dataError: {
				HEADING: 'No Data for selected Gradepoint',
				TEXT:
					'You have no data for your selected Gradepoint, please select an alternative gradepoint using the selector above.',
			},
			Ks4PmNameRenderer: {
				Average: '(Avg)',
				Count: '(Count)',
				Percentage: '(%)',
			},
			agGrid: {
				headers: {
					STUDENT_ID: 'Student Id',
					STUDENT_NAME: 'Student Name',
					PRIOR_ATTAINMENT: 'Prior Attainment',
					MEG: 'MEG',
					'5X-9-4-INCLUDING-ENGLISH-AND-MATHEMATICS': '5x 9-4 inc. Eng/Ma',
					'5X-9-5-INCLUDING-ENGLISH-AND-MATHEMATICS': '5x 9-5 inc. Eng/Ma',
					'ENGLISH-ELEMENT-AT8': 'En AT8',
					'MATHEMATICS-ELEMENT-AT8': 'Ma AT8',
					'EBACC-ELEMENT-AT8': 'EBacc AT8',
					'OPEN-ELEMENT-AT8': 'Open AT8',
					'EBACC-BUCKET---SLOTS-FILLED': 'EBacc Slots',
					'OPEN-BUCKET---SLOTS-FILLED': 'Open Slots',
					'OPEN-BUCKET---NO--OF-GCSES': 'Open - GCSE',
					'ATTAINMENT-8': 'AT8',
					'ENGLISH-4+-ONLY': 'En 4+',
					'ENGLISH-5+-ONLY': 'En 5+',
					'MATHEMATICS-4+-ONLY': 'Ma 4+',
					'MATHEMATICS-5+-ONLY': 'Ma 5+',
					'ENGLISH-AND-MATHEMATICS-4+': 'En and Ma 4+',
					'ENGLISH-AND-MATHEMATICS-5+': 'En and Ma 5+',
					'ENGLISH-ELEMENT-APS': 'En APS',
					'MATHEMATICS-ELEMENT-APS': 'Ma APS',
					'SCIENCE-ELEMENT-APS': 'Sci APS',
					'HUMANITIES-ELEMENT-APS': 'Humanities APS',
					'LANGUAGES-ELEMENT-APS': 'Lang APS',
					'EBACC-APS': 'Ebacc APS',
					'EN-LANG-GRADE': 'En Lang Grade',
					'EN-LIT-GRADE': 'En Lit Grade',
					'MA-GRADE': 'Ma Grade',
				},
			},
		},
		progress8: {
			agGrid: {
				coldefs: {
					STUDENT_ID: 'Student Id',
					STUDENT_NAME: 'Student Name',
					OVERALL_P8: 'Overall P8',
					ENGLISH_P8: 'English P8',
					MATHS_P8: 'Mathematics P8',
					EBACC_P8: 'EBacc P8',
					OPEN_P8: 'Open P8',
					PA: 'Prior Attainment',
					MEG: 'MEG',
				},
			},
		},
	},
	/**
	 * Reports
	 */
	reports: {
		MEG: {
			noData: {
				TITLE: 'You have not added target setting data for %{year}',
				LINE1:
					'Use Connect Data to generate a target setting gradepoint for your current Academic Year. This will allow you to set personalised targets and export your Alps minimum expected grades for each student.',
				LINE2:
					'Find out more about Target Setting on our <a href="%{zoho_link}/en/kb/articles/checking-that-your-personalised-targets-are-aspirational" target="_blank">Support Hub</a>.',
			},
			reportTypes: {
				//these are the report definition names
				InteractiveTargetSetting_Excel_Tabular:
					'Excel - Row per subject (Alps MEGs and Personalised Targets)',
				InteractiveTargetSetting_Excel_Marksheet_MEGs: 'Excel - Marksheet (Alps MEGs)',
				InteractiveTargetSetting_Excel_Marksheet_PTs: 'Excel - Marksheet (Personalised Targets)',
				InteractiveTargetSettingTutorGroup_PDF: 'Targets by tutor group',
				InteractiveTargetSettingStudent_PDF: 'Targets by student',
				InteractiveTargetSettingSubject_PDF: 'Targets by subject',
				InteractiveTargetSettingTeachingSet_PDF: 'Targets by teaching set',
				'Interactive.StudentPostcard': 'Student postcards',
			},
		},
		additional: {
			noData: {
				TITLE: 'There are no additional downloads available',
			},
		},
		myReports: {
			noData: {
				TITLE: 'There are no report downloads available',
			},
			FEATURE_FLAG_NO_ACCESS: 'Reports will be available from Results Day',
		},
		filterBar: {
			ACADEMIC_YEAR: 'Academic Year',
			SEARCH_REPORTS: 'Search Reports',
			SEARCH_PLACEHOLDER: 'Search Reports...',
			FILE_TYPE: 'File Type',
			RECENT: 'Show Previous Report',
			MORE_ACTIONS: 'More Actions',
		},
		schoolAndCollege: {
			BUTTON: 'Click to View',
			FOLLOWING_REPORTS: 'The Following reports are available for ',
			NO_REPORTS: 'No reports are avaliable for %{name}',
			DATA_SHARING: ' has the data sharing been ticked?',
			AVAILABLE_REPORTS: 'The following clients have reports available for %{year}',
			ACADEMIC_YEAR: 'Please select an academic year to get started',
			SEARCH_SCHOOL: 'Search Schools',
			NO_SCHOOLS: 'There are no schools with reports available for %{year}',
			EMBARGO:
				'Your analysis is subject to the JCQ embargo and will be available to all users tomorrow.',
		},
		agGrid: {
			myReports: {
				headers: {
					NAME: 'Name',
					SIZE: 'File Size',
					DATE: 'Created Date',
					TYPE: 'File Type',
				},
			},
			additional: {
				titles: {
					COMMENTS: 'Comments',
					ATTACHMENTS: 'Attachments',
				},
				headers: {
					NOTES: 'Notes',
					USER: 'User',
					TIMESTAMP: 'Timestamp',
					NAME: 'Name',
					SIZE: 'Size',
					FILE_TYPE: 'File Type',
				},
			},
		},
	},
	commonUi: {
		LOADING: 'Loading...',
	},
	/**
	 * Labels shared by performance measure pages
	 */
	commonPerformanceMeasures: {
		accordionHeader: {
			VALUE_TOGGLE: 'Toggle % / Count',
			YEARLY_TREND_TOGGLE: 'Show Yearly Trend',
			EXPORT: 'Export Excel',
		},
		charts: {
			NO_DATA: 'You have no data for this metric',
			COUNT: 'Count',
			PERCENTAGE: 'Percentage (%)',
			AVG: 'Average',
		},
	},
	/**
	 * Perfomance Measures
	 */
	performanceMeasures: {
		noMetricsMessage: {
			heading: {
				NO_IN_YEAR_GP: 'You have no in year gradepoints available',
				NO_COMPARISON_SELECTED: 'You have no Comparison Gradepoint selected',
				NO_MAIN_SELECTED: 'You have no Main Gradepoint selected',
				NO_KS4_IN_YEAR: 'Your Main Gradepoint does not contain any KS4 data',
				NO_GPS: 'You have no gradepoints available',
				HAS_NO_METRICS: 'You have no metrics available',
			},
			message: {
				N0_KS4_DATA_SELECTED:
					'Please select a Gradepoint containing KS4 data from the drop down on the left.',
				NO_COMPARISON_MESSAGE: 'To view comparison graphs, please select a Comparison Gradepoint.',
				NO_MAIN_MESSAGE: 'Please select a Main Gradepoint.',
				NO_IN_YEAR_GP: 'Please upload and publish some in year data.',
				NO_GPS: 'Please upload and publish some data.',
				HAS_NO_METRICS: 'You have no calculated metrics. Please upload and publish some data.',
				HAS_NO_METRICS_FOR_COMPARISON:
					'You have no calculated metrics for the selected comparison.',
			},
		},
		dataStatusIndicator: {
			UPDATED: 'Data last recalculated: ',
			REFRESH: 'Refresh now',
		},
		charts: {
			HEADING: 'Attainment',
			COMPARISON_SELECT: 'Select Comparison (charts only)',
			COMPARISON_PROGRESS: 'Select Comparison',
			MAIN_GP_SELECT: 'Select Main Gradepoint',
			COMPARISON_GP_SELECT: 'Select Comparison Gradepoint (charts only)',
			NO_DATA: 'You have no data for this metric',
			COUNT: 'Count',
			PERCENTAGE: 'Percentage (%)',
			AVG: 'Average',
			attainment: {
				TITLE: 'Attainment 8',
				2020: '2020',
				CURRENT: 'Current',
			},
			ebacc: {
				TITLE: 'EBacc APS',
				2020: '2020',
				CURRENT: 'Current',
			},
			englishMathsGrade4: {
				TITLE: 'English & Maths Grade 4+',
				2020: '2020',
				CURRENT: 'Current',
			},
			englishMathsGrade5: {
				TITLE: 'English & Maths Grade 5+',
				2020: '2020',
				CURRENT: 'Current',
			},
			APPLY: 'Apply',
			APPLY_CHANGES: 'Apply Changes',
		},
		accordionHeaders: {
			SUMMARY: 'Achievement Overview',
			AT8: 'Attainment 8',
			THRESHOLD: 'English / Maths Threshold',
			APS: 'EBacc Avg Score',
			GRADE4: 'EBacc Grade 4',
			GRADE5: 'EBacc Grade 5',
			SCIENCE: 'Science',
			ENTRIES: 'Entries to EBacc',
		},
		progress8: {
			HEADING: 'Progress 8',
			POST_24_HEADING: 'Indicative Progress 8',
			SUB_HEADING:
				'Progress 8 measures in table below are indicative as measured from indicative KS2 baseline',
			accordion: {
				// Key is ComparisonId.ComparisonValueId
				'0.0': 'All',
			},
			EXPORT_FILENAME: 'KS4 PMs - Progress 8',
			grid: {
				columns: {
					OVERALL: 'Overall',
					ENGLISH: 'English',
					MATHS: 'Maths',
					SCIENCE: 'Science',
					HUMANITIES: 'Humanities',
					LANGUAGES: 'Languages',
					EBACC: 'EBacc',
					OPEN: 'Open',
				},
				rows: {
					AT8: 'Attainment 8',
					STUDENT_COUNT: 'Number of pupils included',
					CONFIDENCE_INTERVAL: 'Confidence Interval',
					P8: 'Progress 8',
					PERCENTAGE: 'Percentage Coverage',
				},
				sidebar: {
					COLUMNS: 'Columns',
				},
			},
		},
		agGrid: {
			accordionHeader: {
				TOGGLE: 'Toggle % / Count',
				EXPORT: 'Export Excel',
				TOOLTIP: 'Expand all comparisons to export',
			},
			summary: {
				HEADING: 'Achievement Overview for ',
				headers: {
					A8: 'A8',
					X94: '5x 9-4 inc En and Ma 9 (%)',
					X95: '5x 9-5 inc En and Ma (%)',
					ebacc: 'EBacc Entries (%)',
					ebaccGrade4: 'EBacc Grade 4+ (%)',
					ebaccGrade5: 'EBacc Grade 5+ (%)',
					ebaccAps: 'EBacc APS',
					avgEbacc: 'Average EBacc slots filled',
					openGcse: 'Open – GCSE (%)',
					openVoc: 'Open – Vocational (%)',
					avgOpen: 'Average Open slots filled',
					NO_EAL: 'No EAL',
					EAL: 'EAL',
					EAL_UNKNOWN: 'EAL Unknown',
					NON_DIS: 'Non Dis',
					DIS: 'Dis',
					EHCP: 'EHCP',
					SEN_SUPPORT: 'SEN Support',
					NO_SEND: 'No SEND',
					SEND_UNKNOWN: 'SEND Unknown',
					FEMALE: 'Female',
					MALE: 'Male',
					GENDER_UNKNOWN: 'Gender Unknown',
					ALL: 'All',
				},
			},
			excel: {
				YES: 'Yes',
				NO: 'No',
				EXPORT: 'KS4 PMs Student Export - %{title}',
			},
			at8: {
				HEADING: 'Attainment 8 for ',
			},
			engMathsThreshold: {
				HEADING: 'English and Maths Threshold for ',
				headers: {
					engMa4: 'Eng and Ma 4+ (%)',
					engMa5: 'Eng and Ma 5+(%)',
					eng4: 'Eng 4+ (%)',
					ma4: 'Ma 4+ (%)',
					eng5: 'Eng 5+ (%)',
					ma5: 'Ma 5+ (%)',
				},
			},
			ebaccAttainment: {
				headings: {
					AVGSCORE: 'Average Point Score for ',
					GRADE4: 'Achieving the EBacc at grade 4 and above for',
					GRADE5: 'Achieving the EBacc at grade 5 and above for',
				},
				headers: {
					english: 'English (%)',
					maths: 'Maths (%)',
					humanities: 'Humanities (%)',
					languages: 'Languages (%)',
					science: 'Science (%)',
				},
			},
			science: {
				HEADING: 'Science for ',
				headers: {
					science4x1: '1x Science Grade 4+ (%)',
					science4x2: '2x Science Grade 4+ (%)',
					science4x3: '3x Science Grade 4+ (%)',
					science5x1: '1x Science Grade 5+ (%)',
					science5x2: '2x Science Grade 5+ (%)',
					science5x3: '3x Science Grade 5+ (%)',
				},
			},
			entries: {
				HEADING: 'Entries to EBacc for ',
				headers: {
					english: 'English (%)',
					maths: 'Maths (%)',
					humanities: 'Humanities (%)',
					languages: 'Languages (%)',
					science2: 'Science x 2 (%)',
					science3: 'Science x 3 (%)',
				},
			},
		},
	},
	/**
	 * KS5 Performance Measures
	 */
	ks5PerformanceMeasures: {
		ib: {
			tabs: {
				HEADLINE: 'Headline Measures',
				ATTAINMENT: 'Subject Attainment',
				STUDENT_LEVEL: 'Student Level',
				DISTRIBUTION: 'Subject Area Distribution',
			},
			gridHeaders: {
				HEADLINE_MEASURES: 'Diploma Qualification Overview​',
				ATTAINMENT: 'Individual Subject Attainment ​',
				STUDENT_LEVEL: 'Key Metrics - Student Level​',
				DISTRIBUTION: 'Subject Area Grade Distribution',
			},
			warning: {
				student: {
					HEADER: 'No Data Available',
					MESSAGE: 'At student level you are not yet able to apply comparisons.',
				},
			},
			dataFetchError: {
				HEADING: 'Unable to Conduct IB Analysis',
				MESSAGE:
					'Please check that the main gradepoint in the gradepoint selector is one which contains IB data.',
				BUTTON: 'Try again',
			},
		},
		aLevels: {
			gridHeaders: {
				SUMMARY: 'Average Grade Summary',
				VALUE_TOGGLE: 'Toggle % / Count',
				SUMMARY_PROFILE: 'Summary Profile',
				ALL_ALEVELS_GRADE_SUMMARY_DISTRIBUTION: 'Students Achieving Profile',
				STUDENTS_ACHIEVING_AAB: 'Achieving AAB in 2 Facilitating Subjects',
				STUDENTS_ACHIEVING: 'Grade Distribution Profile',

				BEST_THREE_ALEVELS_SUMMARY: 'Best Summary Profile',
				BEST_THREE_ALEVELS_GRADE_DISTRIBUTION: 'Best 3 A levels Grade Distribution Profile',

				BEST_PRIOR_ATAINMENTS: 'Best 3 A levels - Average Point Score by Prior Attainment',
				PRIOR_ATAINMENTS_COHORTS: 'Cohort size by Prior Attainment (Average GCSE grade)',
				PRIOR_ATAINMENTS_AVERAGE: 'All A levels - Average Point Score by Prior Attainment',
			},
			charts: {
				headers: {
					ATTAINMENT_PROFILE: 'All A levels Grade Distribution Profile',
					AVG_GRADE_SUMMARY: 'All A levels Average Grade and Point Score',
					AVG_POINTS_SCORE: 'Average Points Score',
				},
			},
			tabs: {
				ALL_ALEVEL: 'All A Levels',
				BEST_ALEVEL: 'Best 3 A Levels',
				PA_PROFILE: 'PA Profile',
			},
			export: {
				FILENAME: '',
			},
			HEADING: 'KS5 Performance Performance Measures (A Levels)',
		},
		filterBar: {
			FOCUS_ON: 'Focus On',
			APPLY_COMPARISONS: 'Apply Comparisons',
			APPLY: 'Apply',
			EXCEL_EXPORT: 'Export Excel',
		},
		common: {
			dataFetchError: {
				HEADING: 'Unable to Conduct KS5 PM Analysis',
				MESSAGE:
					'Please check that the main gradepoint in the gradepoint selector is one which contains KS5 data.',
				BUTTON: 'Try again',
			},
			noDataMessage: {
				HEADING: 'No Data Found',
				MESSAGE: 'Unable to generate report; no data available using the specified parameters.',
			},
			LOADING: 'Loading ...',
		},
	},
	/**
	 * IB performance measures
	 */
	ibPerformanceMeasures: {
		agGrid: {
			headers: {
				NO_EAL: 'No EAL',
				EAL: 'EAL',
				FEMALE: 'Female',
				MALE: 'Male',
				ALL: 'All',
				MEAN: 'Mean',
				SEVEN: '7',
				SIX: '6',
				FIVE: '5',
				FOUR: '4',
				THREE: '3',
				TWO: '2',
				ONE: '1',
				SEVEN_TO_FIVE: '7 - 5',
				SEVEN_TO_ONE: '7 - 1',
			},
			diplomaSummary: {
				HEADING: 'Diploma Attainment - Summary for',
			},
			distributionOfTotalPoints: {
				HEADING: 'Distribution of Total Points for',
			},
			corePointsDistribution: {
				HEADING: 'Core Points Distribution for',
			},
			subjectAreaGradeDistribution: {
				HEADING: 'Subject Area Grade Distribution for',
			},
			overallSubjectAttainment: {
				HEADING: 'Overall Subject Attainment for',
			},
			accordionHeader: {
				TOGGLE: 'Toggle % / Count',
				EXPORT: 'Export Excel',
			},
			sidebar: {
				COLUMNS: 'Columns',
			},
		},
		accordionHeaders: {
			DIPLOMASUMMARY: 'Diploma Attainment - Summary',
			DISTRIBUTIONOFTOTALPOINTS: 'Distribution of Total Points',
			COREPOINTDISTRIBUTION: 'Core Points Distribution',
			SUBJECTAREAGRADEDISTRIBUTION: 'Subject Area Grade Distribution',
			OVERALLSUBJECTATTAINMENT: 'Overall Subject Attainment',
		},
		charts: {
			passRate: {
				TITLE: 'Pass Rate (%)',
			},
			mainGrade: {
				TITLE: 'Main Grade (Avg)',
			},
			meanTotalPoints: {
				TITLE: 'Mean Total Points (Avg)',
			},
			meanCorePoints: {
				TITLE: 'Mean Core Points (Avg)',
			},
		},
		HEADING: 'International Baccalaureate Performance Measures',
		filters: {
			SELECT_COMPARISON: 'Select Comparison',
			SELECT_ACADEMIC_YEAR: 'Select Academic Year',
			SELECT_HML: 'Select High, Mid or Low Prior Attainers',
		},
	},
	/**
	 * React Select
	 */
	reactSelect: {
		optionWithAddAll: {
			REMOVE_ALL: 'Remove All',
			ADD_ALL: 'Add All',
		},
	},
	/**
	 * GROUPS
	 */
	groups: {
		links: {
			MONITORING_TIMELINE: 'Monitoring Timeline',
			CUSTOM_COLUMNS: 'Custom Columns',
			SCHOOL_GROUPS: 'School / College Groups',
			CUSTOM_COMPARISONS: 'Custom Comparisons',
			TRENDS: 'Gradepoints / Trends',
			SCHOOL_CONTRACTS: 'My Schools / Colleges',
			ROLLOVER: 'Rollover',
		},
		filters: {
			DROP_DOWN: 'Set Configuration',
			ACADEMIC_YEAR: 'Academic Year',
		},
		errorModal: {
			TITLE: 'An Error Occurred',
			DESCRIPTION:
				'Something went wrong while updating your data. Any changes made may not have been saved. Please try again.',
			BUTTON: 'OK',
		},
		resetModal: {
			TITLE: 'Revert Changes',
			CANCEL: 'No',
			CONFIRM: 'Yes',
			BODY: 'This will revert any changes you have not yet saved. Would you like to continue?',
		},
		unsavedChanges: {
			TITLE: 'Unsaved Changes',
			CANCEL: 'Cancel',
			CONFIRM: 'Discard Changes',
			BODY:
				'You have unsaved changes. You can either discard your changes, or cancel this action to continue editing and save your data.',
		},
		mySchools: {
			SUBTITLE:
				'The list below shows the schools/colleges linked to your Group account. Their analysis is included in your Group analysis.',
			SEARCH_BAR: 'Search',
			agGrid: {
				SCHOOL_NAME: 'School/College Name',
				INCLUDE_FROM: 'Include From',
				EDIT: 'Edit',
			},
			SAVE: 'Save Schools/Colleges',
			RESET: 'Reset Schools/Colleges',
			modals: {
				resetChanges: {
					HEADER: 'Revert Changes',
					TEXT: 'This will revert any changes you have not yet saved. Would you like to continue?',
					YES: 'Yes',
					NO: 'No',
				},
				confirmation: {
					HEADING: 'Confirmation',
					TEXT:
						'Making this change will cause your customised Group analysis to be re-calculated. You will be notified when the new analysis is ready for use.',
					SAVE: 'Save School/College',
				},
				edit: {
					HEADING: 'Edit ',
					TEXT: 'Includes From',
					SAVE: 'Save Schools/Colleges',
					ERROR: 'Please select a year',
				},
			},
		},
		customComparisons: {
			HEADING: 'Manage Custom Comparisons',
			ADD_BUTTON: 'Add a Group',
			DISABLED_ADD_BUTTON: 'Maximum groups reached',
			DESCRIPTION:
				'Using multiple filters, build up your own key groups of students and compare them against each other. Buildup each group by selecting the filters you want to apply to that group. <br />You can compare by the following standard filters: Gender, Disadvantage, Ethnicity, High-Middle-Low PA. In addition, you can create up to five custom comparison groups. You will always get an <i>Overall</i> group of students for comparison.',
			NO_CONTENT: "You have not set any Custom Comparisons. Please click 'Add a Group' to begin.",
			SAVE: 'Save Comparisons',
			RESET: 'Reset Comparisons',

			table: {
				NAME: 'Name',
				ITEM: 'Comparison Items',
			},
			filters: {
				groups: {},
				values: {},
			},
			modal: {
				DROPDOWN_PLACEHOLDER: 'Select or type to search...',
				create: {
					TITLE: 'Add New Comparison',
					CONFIRM: 'Add Group',
				},
				edit: {
					TITLE: 'Edit Existing Comparison',
					CONFIRM: 'Save Group',
				},
				errors: {
					NAME_MIN_CHARS: 'Comparison Group names cannot be empty',
					NAME_MAX_CHARS: {
						_:
							'Comparison Group name has exceeded the maximum character limit |||| Comparison Group names must be %{smart_count} characters or less',
						smart_count: 1,
					},
					NAME_TAKEN: 'Comparison Group name has already been used',
				},
				NAME: 'Group Name',
				NAME_PLACEHOLDER: 'Enter name here',
				TYPE: 'Comparison Type',
				VALUE: 'Comparison Value',
			},
		},
		benchmarkSelector: {
			SAVE: 'Save',
			RESET: 'Reset',
		},
		schoolGroups: {
			HEADING: 'Manage School/College Groups',
			ADD_BUTTON: 'Add a Group',
			DISABLED_ADD_BUTTON: 'Maximum groups reached',
			DESCRIPTION:
				'Create, edit and delete School / College groups that will be available for analysis.',
			NO_CONTENT:
				"You have not set any School / CollegeGroups. Please click 'Add a Group' to begin.",
			SAVE: 'Save Groups',
			RESET: 'Reset Groups',
			modal: {
				DROPDOWN_PLACEHOLDER: 'Select or type to search...',
				add: {
					TITLE: 'Add New School Group',
					BUTTON: 'Add Group',
				},
				edit: {
					TITLE: 'Edit Existing School Group',
					BUTTON: 'Save Group',
				},
				GROUP_NAME: 'Group Name',
				GROUP_NAME_PLACEHOLDER: 'Group name...',
				SCHOOL_SELECTION_NAME: 'Schools/Colleges',
				errors: {
					NAME_MIN_CHARS: 'Group name cannot be empty',
					NAME_MAX_CHARS: {
						_:
							'Group name has exceeded the maximum character limit |||| Group names must be %{smart_count} characters or less',
						smart_count: 1,
					},
					NAME_TAKEN: 'Group name has already been used',
				},
			},
		},
		customColumns: {
			HEADING: 'Manage Custom Columns',
			ADD_BUTTON: 'Add a Custom Column',
			DISABLED_ADD_BUTTON: 'Maximum custom columns reached',
			DESCRIPTION:
				'Manage the custom columns that you would like to be available for the schools and colleges in your group to upload via their own Connect Data.\n This area allows you to restrict the allowed values so that you can view consistent analysis.',
			NO_CONTENT:
				"You have not defined any custom columns. Please click 'Add a Custom Column' to begin.",
			SAVE: 'Save Custom Columns',
			RESET: 'Reset Custom Columns',
			table: {
				NAME: 'Column Name',
				ACADEMIC_YEAR: 'Academic Year',
				ITEM: 'Allowed Values',
			},
			modal: {
				add: {
					TITLE: 'Add New Custom Column',
					BUTTON: 'Add Custom Column',
				},
				edit: {
					TITLE: 'Edit Existing Custom Column',
					BUTTON: 'Save Custom Column',
				},
				GROUP_NAME: 'Custom Column Name',
				GROUP_NAME_PLACEHOLDER: 'Custom Column name...',
				GROUP_VALUE: 'Enter each allowed value separately',
				GROUP_VALUE_PLACEHOLDER: 'Allowed value...',
				GROUP_ADD_VALUE: 'Add',
				errors: {
					NAME_MIN_CHARS: 'Custom Column name cannot be empty',
					NAME_MAX_CHARS: {
						_:
							'Custom Column name has exceeded the maximum character limit |||| Custom Column names must be %{smart_count} characters or less',
						smart_count: 1,
					},
					RESERVED_NAME: 'This is a reserved column name and cannot be used',
					NAME_TAKEN: 'Custom Column name has already been used',
					VALUE_MIN_CHARS: 'Allowed values cannot be empty',
					VALUE_MAX_CHARS: {
						_:
							'Allowed value has exceeded the maximum character limit |||| Allowed values must be %{smart_count} characters or less',
						smart_count: 1,
					},
					VALUE_TAKEN: 'Allowed value has already been used',
				},
			},
		},
		rollover: {
			HEADING: 'Rollover',
			DESCRIPTION:
				'Rollover your setup from a previous Academic Year. This will copy your monitoring gradepoints, trends, groups, custom columns and comparisons. If you wish to rollover from the previous Academic Year, first delete any setup in this year',
			MESSAGE: 'The following setup will be copied into the current academic year:',
			BUTTON: 'Rollover',
			SUCCESS: 'Successfully rolled over setup',
			NO_PREVIOUS: 'There is no previous Academic Year available to rollover',
			UNAVAILABLE: 'This Academic Year already contains setup.',
			ERROR: 'Error - could not rollover setup',
		},
		trends: {
			HEADING: 'Manage Gradepoint Trends',
			ADD_BUTTON: 'Add a Trend',
			DISABLED_ADD_BUTTON: 'Maximum trends reached',
			DESCRIPTION:
				'Create and edit trends of Gradepoints that will be available for comparison in your data analysis.',
			NO_CONTENT:
				"You have not created any Gradepoint Trends. Please click 'Add a Trend' to begin.",
			SAVE: 'Save Trends',
			RESET: 'Reset Trends',
			modal: {
				NAME_PLACEHOLDER: 'Please provide a name for this trend',
				NAME_LABEL: 'Trend Name',
				add: {
					TITLE: 'Add new Trend',
					DESCRIPTION:
						'Add a new trend of Gradepoints to your analysis. Once calculated, you will be able to view the trend in each of the reports in the application.',
					BUTTON: 'Add Trend',
				},
				edit: {
					TITLE: 'Edit Trend',
					DESCRIPTION:
						'Edit your trend by removing, adding or re-ordering Gradepoints. Once saved, you will be able to view the trend in each of the reports in the application.',
					BUTTON: 'Save Trend',
				},
				validation: {
					EMPTY_NAME: 'Trend names cannot be empty',
					DUPLICATE_NAME: 'Trend name has already been used',
					NAME_MAX_CHARS: {
						_:
							'Trend name has exceeded the maximum character limit |||| Trend names must be %{smart_count} characters or fewer',
						smart_count: 1,
					},
					EMPTY_GPS: 'Please select at least one Gradepoint',
				},
			},
		},
		schoolContracts: {
			// None of this text looks to be used
			HEADING: 'My Schools',
			ADD_BUTTON: 'Add a School',
			DESCRIPTION:
				'The list below shows the schools linked to your Group account. Their analysis is included in your Group analysis.',
			DISABLED_ADD_BUTTON: 'Maximum number reached',
			NO_CONTENT: "Please click 'Add a School' to begin.",
			SAVE: 'Save Schools',
			RESET: 'Reset Schools',
			table: {
				SCHOOL_FILTER: 'Search / Filter Schools',
				SCHOOL_NAME: 'School Name',
				START_DATE: 'Include From',
				EDIT: 'Edit',
			},
			modal: {
				SCHOOL_NAME: 'School Name',
				START_DATE: 'Include From',
				edit: {
					TITLE: 'Edit %{name}',
					CONFIRM: 'Save School',
				},
			},
		},
		monitoringTimeline: {
			HEADING: 'Manage Monitoring Timelines',
			ADD_BUTTON: 'Create new Monitoring Point',
			DISABLED_ADD_BUTTON: 'Maximum number reached',
			DESCRIPTION:
				'Define the monitoring Gradepoints you wish your schools and colleges to complete within this Academic Year. <br /><br /> You can change any Gradepoint up until the data entry start date, after which they become locked as schools/colleges may have already begun uploading their data.',
			NO_CONTENT:
				"You have not set any Timelines. Please click 'Create new Monitoring Point' to begin.",
			SAVE: 'Save Monitoring Timeline',
			RESET: 'Reset Monitoring Timeline',
			YEAR: 'Year',
			table: {
				NAME: 'Name',
				ITEM: 'Restricted to Years',
				DATESTART: 'Data Entry Start Date',
				DATEEND: 'Data Entry End Date',
			},
			filters: {
				groups: {},
				values: {},
			},
			modal: {
				DROPDOWN_PLACEHOLDER: 'Select...',
				POINTNAME: 'Monitoring point name',
				YEARGROUPS: 'Restrict monitoring point to Year Group(s)',
				DATE_START: 'Data entry start date',
				DATE_END: 'Data entry end date',
				DATE_DESCRIPTION:
					'Please create a date range in which your schools/colleges can upload their data for this monitoring point. You can do this by using the inputs below to specify a start and end date for your data upload.',
				create: {
					TITLE: 'Create New Monitoring Point',
					CONFIRM: 'Add Monitoring Point',
				},
				edit: {
					TITLE: 'Edit Monitoring Existing Point',
					CONFIRM: 'Save Monitoring Point',
				},
				errors: {
					NAME_MIN_CHARS: 'Monitoring Point name cannot be empty',
					NAME_MAX_CHARS: {
						_:
							'Monitoring Point name has exceeded the maximum character limit |||| Monitoring Point names must be %{smart_count} characters or less',
						smart_count: 1,
					},
					NAME_TAKEN: 'Monitoring Point name has already been used',
					PAST_START_DATE: 'A date in the past is not allowed',
					END_BEFORE_START: 'Start Date must be before End Date',
					INVALID: 'Invalid date',
					OUT_OF_ACADEMIC_YEAR: 'The date must be within the next Academic Year',
					SAME_DATE: 'Start/End cannot be the same Date',
				},
			},
			lockModal: {
				TITLE_LOCKED: 'Unlock Gradepoint',
				TITLE_UNLOCKED: 'Lock Gradepoint',
				DESCRIPTION_LOCKED:
					'Unlocking this Gradepoint will allow your schools to edit their monitoring data. Your analysis will be recalculated.<br /><br /> Are you sure you want to unlock ',
				DESCRIPTION_UNLOCKED: 'Are you sure you want to lock ',
				BUTTON: 'Ok',
			},
		},
		viewAs: {
			TITLE: 'View Connect for your schools/colleges',
			DESCRIPTION:
				'Select a school/college to view their individual access to Connect. This will open in a new tab - to end your session as the school/college simply close the tab. You can continue to use this tab to access your MAT/Group analysis',
			SCHOOLS_COLUMN_HEADER: 'Schools',
			NO_SCHOOLS_MESSAGE: 'No schools found',
			ACCESS_MESSAGE: 'Accessing school Connect...',
		},
		tableFilters: {
			SCHOOLS_FILTER: 'Search Schools/Colleges',
			GROUPS_FILTER: 'Search Groups',
			EXAM_LEVEL_FILTER: 'Qualification Filter',
			COMPARISON_FILTER: 'Custom Comparisons',
			DOWNLOADS: 'Downloads',
			ALPS_COMPARISONS: 'Alps Comparisons',
			APPLY_FILTERS: 'Apply Filters',
			APPLY_FILTERS_BUTTON: 'Apply',
			APPLY_FILTERS_BUTTON_LOADING: 'Loading ...',
			PLACEHOLDER: 'Search or Select ...',
		},
		subjectsPage: {
			HEADING: 'Subject Overview / %{subject} %{group_name}',
			labels: {
				THERMOMETER: 'Alps Grades',
			},
			columnDefs: {
				ENTRIES: 'Entries',
				SCORE: 'Score',
				GRADE: 'Grade',
				AVG_PA: 'Avg PA',
				UNDER_TARGET: 'Under Target #',
				UNDER_TARGET_PERCENTAGE: 'Under Target %',
			},
		},
		subjectsOverview: {
			PAGE_TITLE: 'Subject Value Added Overview',
			HEADING: 'Subject Value Added Overview',
			NO_DATA: 'No data present in Trend',
			columnDefs: {
				ENTRIES: 'Entries',
				SCORE: 'Score',
				GRADE: 'Grade',
				AVG_PA: 'Avg PA',
			},
			excel: {
				SHEET_NAME: 'Alps Summit Subjects Overview',
				FILE_NAME: 'Alps Summit Subjects Overview Export',
			},
		},
		bbpaOverview: {
			PAGE_TITLE: 'BBPA Overview',
			HEADING: 'Banded By Prior Attainment Overview',
			SELECT_QUALIFICATION:
				'Please select a qualification to see your banded by prior attainment for that qualification.',
			NO_DATA: 'No data to display ...',
			columnDefs: {
				ENTRIES: 'Entries',
				SCORE: 'Score',
				GRADE: 'Grade',
				AVG_PA: 'Avg PA',
			},
			excel: {
				SHEET_NAME: 'Alps Summit BBPA Overview',
				FILE_NAME: 'Alps Summit BBPA Overview Export',
			},
		},
		strategicOverview: {
			PAGE_TITLE: 'Strategic Analysis Overview',
			HEADING: 'Strategic Analysis Overview',
			SELECT_QUALIFICATION:
				'Please select a qualification to see your Strategic Indicators for that qualification.',
			NO_DATA: 'No data to display ...',
			excel: {
				SHEET_NAME: 'Alps Summit Strategic Overview',
				FILE_NAME: 'Alps Summit Strategic Overview Export',
			},
		},
		sidebar: {
			headings: {
				MONITORING_SETUP: 'Monitoring Setup',
				ANALYSIS_SETUP: 'Analysis Setup',
			},
			links: {
				MONITORING_TIMELINE: 'Monitoring Timeline',
				CUSTOM_COLUMNS: 'Custom Columns',
				SCHOOL_GROUPS: 'School / College Groups',
				CUSTOM_COMPARISONS: 'Custom  Comparisons',
				TRENDS: 'Gradepoints / Trends',
				MY_SCHOOLS: 'My Schools / Colleges',
				ROLLOVER: 'Rollover',
			},
		},
		modals: {
			saveConfirmationModal: {
				common: {
					TITLE: 'Confirmation',
					BUTTON: 'Save Changes',
					DESCRIPTION:
						'Making this change will cause your customised Group analysis to be re-calculated. You will be notified when the new analysis is ready for use.',
				},
				TRENDS:
					'Making this change will cause your customised Group analysis to be re-calculated. You will be notified when the new analysis is ready for use.',
				MONITORING_TIMELINE:
					'Making this change will cause your customised Group analysis to be re-calculated. You will be notified when the new analysis is ready for use.',
				CUSTOM_COLUMNS:
					'Making this change will cause your customised Group analysis to be re-calculated. You will be notified when the new analysis is ready for use.',
			},
		},
		ks5Pm: {
			filterBar: {
				COMPARISON_GRADEPOINT_1: 'Comparison Gradepoint 1',
				COMPARISON_GRADEPOINT_2: 'Comparison Gradepoint 2',
				SELECT_GRADEPOINT: 'Select two Gradepoints to compare metrics',
				SORT_BY: 'Sort by',
				CUMULATIVE_PERCENTAGE: 'Cumulative Percentage',
				APPLY_FILTERS: 'Apply Filters',
				APPLY: 'Apply',
				LOADING: 'Loading...',
				COMPARISONS: 'Comparisons',
				SORT_DATA_BY: 'Sort data by...',
				CHOOSE_A_GRADEPOINT: 'Choose a gradepoint',
				CHOOSE_A_COMPARISON: 'Choose a comparison',
				gridSort: {
					LABEL_ONE: 'School Name A-Z',
					LABEL_TWO: 'School Name Z-A',
					LABEL_THREE: 'Aps Points High - Low',
					LABEL_FOUR: 'Aps Points Low - High',
					LABEL_FIVE: 'Difference High - Low',
					LABEL_SIX: 'Difference Low - High',
				},
			},
			common: {
				HEADING: 'Error fetching Key Stage 5 Performance Measures',
				MESSAGE:
					'There was an error fetching the Key Stage 5 Performance Measures. Please try again.',
				BUTTON: 'Retry',
				NO_ACCESS_ID: 'Please select a vaild key stage to view Performance Measures',
				WARNING_HEADER: 'Please select KS5 from the Gradepoint selector above.',
				WARNING_MESSAGE: 'Performance Measures are currently available for A level only.',
			},
			singleSchoolPerformanceMeasures: {
				tile: {
					SUMMARY_PROFILE: 'Values shown in tiles are from Main Gradepoint',
				},
				table: {
					SUMMARY_PROFILE: 'Summary Profile',
					BEST_3_SUMMARY_PROFILE: 'Best 3 Summary Profile',
					BEST_3_GRADE_DISTRIBUTION_PROFILE: 'Best 3 Grade Distribution Profile',
					ACHIEVING_AAB: 'Achieving AAB in 2 Facilitating Subjects',
					RAW_ATTAINMENT_GRAPH: 'Raw Attainment',
					ALL_AVERAGE_POINTS_SCORE_GRAPH: 'All A levels Average Point Score',
				},
				common: {
					comparisons: {
						GENDER: 'Gender',
						GENDER_MALE: 'Male',
						GENDER_FEMALE: 'Female',
						EAL: 'EAL',
						EAL_YES: 'Yes',
						EAL_NO: 'No',
						DISADVANTAGED: 'Disadvantaged',
						DISADVANTAGED_YES: 'Disadvantaged',
						DISADVANTAGED_NO: 'Non-Disadvantaged',
						SEND: 'SEND',
						SEND_NO: 'No SEN',
						SEND_SEN_SUPPORT: 'SEN Support',
						SEND_EHCP: 'EHCP',
					},
					ERROR_MESSAGE:
						'An Error has occurred fetching the Key Stage 5 Performance Measures, please try again.',
				},
			},
			overviewOfProvidersGroup: {
				PAGE_TITLE: 'Overview of Providers in Group',
				tab1: {
					TITLE: 'A Level PM 1: APS',
					aps: {
						HEADING: 'Average Point Score (APS) of all providers in the group',
						YAXIS: 'APS Score',
					},
					apsDifference: {
						HEADING: 'APS Difference Comparison – select gradepoints using dropdown above',
						YAXIS: 'APS Difference',
						SELECT_ANOTHER_GP_TO_SEE_COMPARISONS: 'Select another gradepoint to see comparisons',
					},
					apsAvgGradeForSelectedGradepoints: {
						HEADING: 'APS and Average Grade for selected gradepoints',
						colDefs: {
							SCHOOL: 'School',
							STUDENTS: 'Students',
							ENTRIES: 'Entries',
							APS: 'APS',
							AVG_GRADE: 'Avg. Grade',
							DIFFERENCE: 'Difference',
						},
					},
				},
				tab2: {
					TITLE: 'A Level Raw Attainment',
					rawAttainmentForAllProviders: {
						HEADING: 'Raw Attainment for all providers in the group - ',
						YAXIS: 'Raw Attainment %',
						SELECTED_OUTCOME_GROUP: 'Please select a Cumulative Percentage',
					},
					rawAttainmentDifferenceComparison: {
						HEADING:
							'Raw Attainment Difference Comparison – select gradepoints using dropdown above',
						YAXIS: 'Raw Attainment Difference',
					},
					rawAttainmentCumulativePercentages: {
						HEADING: 'Raw Attainment Cumulative Percentages',
						colDefs: {
							SCHOOL: 'School',
							STUDENTS: 'Students',
							ENTRIES: 'Entries',
							A_STAR_A: 'A* - A',
							A_STAR_B: 'A* - B',
							A_STAR_C: 'A* - C',
							A_STAR_E: 'A* - E',
							DIFFERENCE: 'Difference',
						},
					},
				},
				tab3: {
					TITLE: 'A Level PM 2: Best 3',
					best3CumulativePercentage: {
						HEADING: 'Best 3 Cumulative Percentage Headline Measures',
						colDefs: {
							SCHOOL: 'School',
							STUDENTS: 'Students',
							ENTRIES: 'Entries',
							BEST_3_A_STAR_B: 'Best 3 3 x A* - B',
							BEST_3_A_STAR_C: 'Best 3 3 x A* - C',
							BEST_3_A_STAR_E: 'Best 3 3 x A* - E',
							BEST_3_APS_POINTS: 'Best 3 APS Points',
							BEST_3_AVG_GRADE: 'Best 3 Avg Grade',
						},
					},
				},
			},
			providerLevelSummary: {
				filterBar: {
					COMPARISONS: 'Comparisons',
					APPLY_FILTERS: 'Apply Filters',
					APPLY: 'Apply',
				},
				mainGradePointKpis: {
					HEADING: 'Values shown in tiles are from Main Gradepoint',
					APS: 'Average Point Score',
					AVG_GRADE: 'Average Grade',
					STUDENTS: 'Students',
					ENTRIES: 'Entries',
				},
				rawAttainmentKpis: {
					HEADING: 'Raw Attainment %',
					A_STAR_A: 'A* - A',
					A_STAR_B: 'A* - B',
					A_STAR_C: 'A* - C',
					A_STAR_E: 'A* - E',
				},
				tables: {
					summaryProfile: {
						HEADING: 'Summary Profile',
					},
					achievingAab: {
						HEADING: 'Achieving AAB in 2 Facilitating Subjects',
					},
					best3SummaryProfile: {
						HEADING: 'Best 3 Summary Profile',
					},
					best3GradeDistribution: {
						HEADING: 'Best 3 Grade Distribution Profile',
					},
				},
				charts: {
					allAlevelsAps: {
						HEADING: 'All A levels Average Point Score',
						YAXIS: 'APS Score',
					},
					rawAttainment: {
						HEADING: 'Raw Attainment and Best 3 Cumulative Percentages',
						YAXIS: 'Percentage Value',
					},
				},
			},
		},
	},
	errors: {
		FAILED_TO_FETCH_DATA: `It looks like we encountered an error. Please try again or contact our Customer Support Team on ${CST_NUMBER} if the problem continues.`,
	},
	pageNotFound: {
		FOUR: '4',
		NO_MATCH: 'We cannot find a match for',
		RETURN: 'Return to home page',
	},
	subjectArea: {
		common: {
			LOADING: 'Loading ...',
			dataFetchError: {
				HEADING: 'Unable to fetch data',
				MESSAGE: `Something went wrong whilst trying to fetch your data, please click the button below to try again. If the problems persists please call our customer support team on ${CST_NUMBER}.`,
				BUTTON: 'Try again',
			},
			overlay: {
				ERROR_MESSAGE:
					'This subject contains no data for this gradepoint. Either select another gradepoint or return to subjects overview',
				RETURN_BUTTON: 'Return to Subjects Overview',
				NEW_GP: 'Select Another Gradepoint',
			},
			filterBar: {
				EXCEL_EXPORT: 'Export Excel',
				COUNT_PERCENT: 'Count/Percent',
			},
		},
		attainment: {
			GRID_HEADER: 'Grade',
		},
	},
	strategicAnalysis: {
		standardPage: {
			SESSION: 'Strategic Analysis session running for %{sessionType}',
		},
		modal: {
			ACTION: 'Yes, end Session',
			HEADING:
				'Are you sure you want to end your active %{sessionType} Strategic Analysis session?',
			MESSAGE:
				'You are about to end a Strategic Analysis session you have running for %{sessionType}. This means you will lose all your analysis data and configuration for this session. Are you sure you want to do this?',
			PDF_HEADING: 'A new %{examLevel} %{reportTemplate} PDF Report has been requested.',
			PDF_MESSAGE:
				'Please click the "Create Report" button to proceed and we will notify you when the PDF is ready for download.<br /><br />Your export will be created behind the scenes. You can continue to work in Connect without having to wait for the export to appear on the screen. <br /><br />It may take a few minutes to produce your export. Once ready, you will see a red number appear on the message icon in the top right hand corner of Connect. Please click on this icon to see a list of your available exports.',
			PDF_ACTION: 'Create Report',
		},
		common: {
			headers: {
				STUDENT_NAME: 'Student Name',
				PRIOR_ATTAINMENT: 'Prior Attainment',
				SUBJECTS: 'Subjects',
			},
			dataFetchError: {
				HEADING: 'Unable to fetch data',
				MESSAGE:
					'Something went wrong whilst trying to fetch your data, please click the button below to try again. If the problems persists please call our customer support team on (+44) 01484 887600.',
				BUTTON: 'Try again',
				bbpaCurrentYear: {
					HEADING: 'Analysis Unavailable',
					MESSAGE:
						'This analysis is unavailable for %{qualification}. Please choose a different page or a different qualification tab.',
					KS4: 'Key Stage 4',
					BTEC: 'BTEC 2016',
				},
			},
			noDataMessage: {
				HEADING: 'No Data Found',
				MESSAGE: 'Primary gradepoint contains no data for this qualification.',
			},
			LOADING: 'Loading ...',
		},
		filterBar: {
			ADD_QUAL_TYPE: 'Add Exam Levels',
			APPLY_FILTERS: 'Apply Filters',
			FOCUS_ON: 'Focus On',
			APPLY_COMPARISONS: 'Apply Comparisons',
			APPLY: 'Apply',
			EXCEL_EXPORT: 'Export Excel',
			PIVOT_DATA: 'Pivot Data',
			SHOW_THERMO: 'Show Thermometer',
			qualTypes: {
				labels: {
					BTEC2010: 'BTEC 2010',
					KS4: 'KS4',
					KS4GCSE: 'KS4 GCSE',
					KS4VOC: 'KS4 VOC',
				},
			},
		},
		summary: {
			TITLE: 'Strategic Analysis',
			optionA: {
				OPTION_TEXT: 'Option A',
				TITLE: 'Interactive Connect Session - view analysis in platform',
				STEP_1:
					'<b>Step 1:</b> Select an examination level. You can choose multiple qualification types depending on the data present in your selected gradepoint.',
				STEP_2:
					'<b>Step 2 (optional):</b> Select a comparison group. This will automatically add this comparison group analysis across all pages in the session you create. Alternatively, you can opt to add comparisons once you have created the session.<br/><br/>A maximum of 4 comparisons can be added at any one time.',
				STEP_3: '<b>Step 3:</b> Create Session',
			},
			optionB: {
				OPTION_TEXT: 'Option B',
				TITLE: 'Create Report - export analysis to PDF',
				STEP_1:
					'<b>Step 1:</b> Select an examination level. You can choose multiple qualification types depending on the data present in your selected gradepoint.',
				STEP_2: '<b>Step 2:</b> Select a template for your export.',
				STEP_3:
					'<b>Step 3 (optional):</b> Select a comparison group. This will add this comparison group analysis across the metrics in your report.A maximum of 4 comparisons can be added at any one time.',
				STEP_4: '<b>Step 4:</b> Create Report',
			},
			mediaPlayer: {
				TITLE: 'Alps Explorer videos',
				LINK: 'https://youtu.be/tEVHZ8aCNAE',
				EXPLORER_BUTTON: 'View All Explorer Videos',
				EXPLORER_LINK:
					'https://support.alps.education/portal/en/kb/alps-education/alps-short-videos-how-to-use-connect-interactive',
				TEXT_1:
					'The <b>Alps Explorer Programme</b> includes a series of videos designed to guide you through getting the most from the analysis within the platform.',
				TEXT_2: 'The Explorer video here is the guide to the Strategic Analysis pages.',
				TEXT_3: 'You can find the Explorer videos in our Knowledge Base.',
			},
			EXAM_LEVEL: 'Set exam level(s)',
			EXAM_LEVEL_TOOLTIP:
				'This will determine which qualification pages will appear in your session. You can add more qualifications on individual pages once a session has been created.',
			COMPARISONS: 'Set comparison(s)',
			COMPARISONS_TOOLTIP:
				'This will determine which comparisons will appear in the analysis for your session. You can add comparisons on individual pages once session has been created.',
			CREATE_REPORT: 'Create Report',
			CREATE_SESSION: 'Create Session',
			REPORT_TEMPLATE: 'Set report template',
			CUSTOM_REPORT_PAGES: 'Set custom report page(s)',
			START_SESSION_TITLE: 'Start a new session or download a report',
		},
		qualityIndicator: {
			thermometer: {
				Title: 'Quality Indicator',
				Primary_Group_Title: 'QI Score',
				No_Data_Title: 'No data available',
				No_Data_Body:
					'There is not enough data to generate the thermometer for the primary grade point',
			},
			page: {
				CURRICULUM_BALANCE: 'Curriculum balance factor',
				BALANCE_CALCULATION: 'Balanced QI score = QI + balance factor',
				BALANCE_EQUATION:
					'Balanced QI score = %{uncorrectedQi} + %{correctionFactor} = %{correctedQi}',
				overallVasEquation: {
					OVERALL_VAS: 'Overall VAS = ',
					MINUS: '-',
					TIMES: 'x ',
					PLUS_ONE: '+ 1 = ',
					GRADE: 'grade ',
				},
			},
		},
		qualityOfTeaching: {
			thermometer: {
				titles: {
					RED_TITLE: 'Red Thermometer',
					BLUE_TITLE: 'Blue Thermometer',
				},
				primaryGroupTitles: {
					RED_GROUP_TITLE: 'Red QTL',
					BLUE_GROUP_TITLE: 'Blue QTL',
				},
			},
			ks4Headers: {
				GCSE: 'KS4 GCSE',
				VOC: 'KS4 Vocational',
			},
			gridHeaders: {
				HEADER_1: 'Total number of student entries contributing to each grade band',
				HEADER_2: 'Percentage of student entries contributing to each grade band',
				HEADER_3: 'Percentage of student entries in RED, BLACK and BLUE bands',
			},
		},
		studentsNotIncluded: {
			NO_STUDENTS_MISSING:
				'There are no students listed as all students have a prior attainment score.',
			pageText: {
				HEADER: 'Students not included in value-added analysis',
				PARA_ONE:
					'Value added progress cannot be calculated for students without a prior attainment score. Students are filtered out of the Alps report calculations if they do not have a prior attainment score.',
				PARA_TWO:
					'The following students did not have a prior attainment score in the data added to Connect. If you have prior attainment scores for these students, you can add these via Connect Data.',
			},
		},
		subjectValueAdded: {
			gridHeader: {
				SUBJECTS: 'Subjects ',
			},
			headers: {
				HEADER_1:
					'The following subjects contribute to the 2016 Red & Blue teaching & learning Indicators',
				HEADER_2:
					'The following subjects contribute to the 2010 Red & Blue teaching & learning Indicators',
				HEADER_3:
					'The following subjects do not contribute to the Red & Blue teaching & learning Indicators',
			},
		},
		bbpaMultiYear: {
			gridMeta: {
				MAIN_HEADING: 'Banded By Prior Attainment - Multi-Gradepoint',
				// A & AS Level
				POINTSPERSUBJECT: 'Points Per Subject',
				// KS4
				GCSESCORES: 'Subject Quality of Progress (GCSE Courses)',
				BTECSCORES: 'Subject Quality of Progress (BTEC Courses)',
				CAMNATSCORES: 'Subject Quality of Progress (Cambridge Level 1/2 Courses)',
				EBACCSCORES: 'Subject Quality of Progress (EBACC Courses)',
				SUBSCORES: 'Subjects Taken By Student',
				// BTEC
				EXTCERTPOINTS: 'Points Per Subject - 2016 BTEC Extended Certificate',
				DIPPOINTS: 'Points Per Subject - 2016 BTEC Diploma',
				EXTDIPPOINTS: 'Points Per Subject - 2016 BTEC Extended Diploma',
				BTEC2010POINTS: 'Points Per Subject - 2010 BTEC Subsidiary Diploma/Extended Diploma',
				// TODO - what are these titles??
				CACHEAWARDPOINTS: 'Points Per Subject - Cache Award',
				CACHECERTPOINTS: 'Points Per Subject - Cache Certificate',
				CACHEDIPPOINTS: 'Points Per Subject - Cache Diploma',
				CACHEEXTDIPPOINTS: 'Points Per Subject - Cache Extended Diploma',
				UALDIPPOINTS: 'Points Per Subject - UAL Diploma',
				UALEXTDIPPOINTS: 'Points Per Subject - UAL Extended Diploma',
			},
		},
		sharedEmbeddedTable: {
			BBPA_MULTI: `The students in band %{band}`,
			BBPA_CURRENT: `The students in band %{band}, achieving the grade %{header}`,
		},
		tScore: {
			gridMeta: {
				MAIN_HEADER: 'T Score',
				ROW_ONE: 'Quality Indicator',
				ROW_TWO: '% RED teaching & learning grades',
				ROW_THREE: '% BLUE teaching & learning grades',
				ROW_FOUR: 'T score - single year score',
				ROW_FIVE: 'T score - single year grade (1 to 9)',
			},
			thermometer: {
				SINGLE_YEAR: 'Single Year T Score',
				headers: {
					SINGLE: 'Single Year T Score',
				},
			},
		},
		utils: {
			subscription: {
				PRICING_FALLBACK: 'Price not available at this time',
			},
		},
	},
	supportHub: {
		MAIN_TEXT: 'Knowledge Base',
		SUPPORT_HUB_LINK: 'Find out more',
	},
	earlyAccess: {
		RECIPIENT: 'support@alps.education',
		BUTTON_TEXT: 'Get Early Access',
		EMAIL_SUBJECT: 'Early Access Request - %{clientName} (%{clientRef}/%{clientId})',
		EMAIL_BODY:
			'Please add %{clientName} (%{clientRef}/%{clientId}) to the early access list for new %{area} features.',
	},
	gradepointContextExplainer: {
		HEADING: 'Set your gradepoint trend',
		NO_DATA: 'You have no data in your selected trend',
		GP_TREND_CONNECT:
			'Your selected gradepoint trend contains %{type} data for Year Group%{pluralise} %{yearGroups}.',
		GP_TREND_SUMMIT: 'Your selected gradepoint trend contains %{type} data.',
		TEXT:
			'You can easily change this at anytime by clicking on the gradepoint button at the top of your screen or clicking on the button below.',
		LINK:
			'https://support.alps.education/portal/en/kb/articles/using-the-gradepoint-selection-tool',
		BUTTON: 'Change the GP Trend',
	},
	benchmarkSetup: {
		benchmarkSelector: {
			HEADING: 'Select benchmarks for Connect and Summit analysis.',
			TEXT:
				'ENGLAND ET AL TEXT - Ut congue ligula leo, vehicula condimentum nibh placerat vitae. Nulla sit amet dapibus est. Vestibulum at leo non eros euismod imperdiet vitae vitae tortor. Aenean vitae mauris at eros posuere laoreet. Cras in vestibulum mi. Praesent vulputate, sapien quis dictum semper, mauris magna imperdiet turpis, et tincidunt erat ante non elit. Nunc ac molestie nunc. Nulla ac diam turpis. Phasellus euismod libero vel arcu congue, sit amet viverra libero posuere. Nulla quis vehicula orci. Mauris et finibus nunc. Sed ut nisl ac ipsum tincidunt tempor.',
			WALES_TEXT:
				'WALES TEXT - Ut congue ligula leo, vehicula condimentum nibh placerat vitae. Nulla sit amet dapibus est. Vestibulum at leo non eros euismod imperdiet vitae vitae tortor. Aenean vitae mauris at eros posuere laoreet. Cras in vestibulum mi. Praesent vulputate, sapien quis dictum semper, mauris magna imperdiet turpis, et tincidunt erat ante non elit. Nunc ac molestie nunc. Nulla ac diam turpis. Phasellus euismod libero vel arcu congue, sit amet viverra libero posuere. Nulla quis vehicula orci. Mauris et finibus nunc. Sed ut nisl ac ipsum tincidunt tempor.',
			INTRO_HEADING: 'What does the benchmark selector allow me to do?',
			VIDEO_LINK: 'https://youtu.be/E4CX1jJVcwU',

			HEADING_1: 'What does this mean?',
			HEADING_2: 'Read this carefully before applying any change:',
			SELECTOR_HEADING: 'Set your analysis benchmark',
			TEXT_INTRO:
				'The dropdown on this page allows you to control the benchmark for all users in your Connect account or Summit account.',
			TEXT_1:
				'The value-added scores and grades across Connect and Summit are based on benchmarks - value-added tables derived from large datasets. Alps analysis is traditionally based on the full national dataset.<br/><br/>The pandemic has caused disruption to the value-added landscape. To support colleagues in making judgements based on the current context, we have constructed benchmarks based on client data submitted to Alps.',
			TEXT_2:
				'Decisions on datasets to use to give the most valid value-added analysis over the past few years has been complex. Use the links below to take you to the relevant article on our Knowledge Base.',
			TEXT_3: 'You have the choice between two benchmarks in the dropdown below:',
			BULLET_1: 'The national dataset',
			BULLET_2:
				'The Alps Provider dataset - this is designed for KS5 only for 2023-25. The KS4 analysis will be identical and is based on the latest national data.',
			TEXT_4:
				'Once selected, all users across your account will have this benchmark dataset as their default whenever they log into their account. The lozenge in the top bar will alert them to that benchmark.',
			TEXT_5:
				'You can switch benchmarks by returning to this page. You can also give user the option of switching their benchmarks for individual sessions - ',
			LINK_TEXT: 'Session benchmarks in Connect.',
			SAVE: 'Save',
			RESET: 'Reset',
		},
		sessionBenchmarkToggle: {
			HEADING_1: 'Switch benchmarks for Connect and Summit analysis.',
			HEADING_2: 'What does this mean?',
			HEADING_3: 'Which benchmark should I use to view my analysis:',
			HEADING_4: 'Using the benchmark:',
			SELECTOR_HEADING: 'Set your analysis benchmark',
			TEXT_1: 'This page gives you the option of switching your benchmark.',
			TEXT_2:
				'The value-added scores and grades across Connect and Summit are based on benchmarks - value-added tables derived from large datasets. Alps analysis is traditionally based on the full national dataset.<br/><br/>We recognise that the pandemic has caused disruption to the value-added landscape. To support colleagues in making judgements based on the current value-added context, we have constructed benchmarks based on data submitted to Alps by schools and colleges.',
			TEXT_3:
				'Decisions on datasets to use to give the most valid value-added analysis over the past few years has been complex. We have written KS5 and KS4 articles on our Knowledge Base to support you in working through this question.',
			TEXT_4:
				'Switching the benchmark will apply to your current session only. When you log out of Connect, the analysis will default to the benchmark set by your Connect administrator.<br/><br/>The lozenge in the top banner will indicate whether you are using the national or the Alps Provider benchmark. ',
			VIDEO_LINK: 'https://youtu.be/E4CX1jJVcwU',
		},
		support: {
			HEADING: 'Support Hub',
			connect_links: {
				how_to_switch: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/switching-your-benchmarks-in-connect-and-summit',
					TEXT: 'Using the switch benchmark tools',
				},
			},
			summit_links: {
				how_to_switch: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/switching-your-benchmarks-in-connect-and-summit',
					TEXT: 'Using the switch benchmark tools',
				},
			},
			shared_links: {
				ks4_england: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/implications-of-switching-your-benchmark-ks4-england',
					TEXT: 'KS4 England - Which benchmark should I use?',
				},
				ks5_england: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/which-benchmark-should-i-use-ks5-england-august-2024',
					TEXT: 'KS5 England - Which benchmark should I use?',
				},
				ks5_wales: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/which-benchmark-should-i-use-a-level-wales-august-2024',
					TEXT: 'KS5 Wales - Which benchmark should I use?',
				},
				a_level_england: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/alps-analysis-of-2023-client-benchmarks-a-level-england',
					TEXT: 'Alps analysis of 2023 client benchmarks - A level England.',
				},
				gcse_england: {
					LINK:
						'https://support.alps.education/portal/en/kb/articles/alps-analysis-of-2023-client-benchmarks-gcse-england-a',
					TEXT: 'Alps analysis of 2023 client benchmarks - GCSE England.',
				},
			},
		},
		modals: {
			saveConfirm: {
				HEADING: 'Are you sure you want to do this?',
				CONFIRM_TEXT: 'Select BM',
				TOGGLE_DESCRIPTION:
					'Saving will switch your benchmark for the remainder of this session. When you log out or refresh your page, your benchmark will reset to the default for your Connect account.',
				SELECTOR_DESCRIPTION:
					"Clicking 'Select BM' will mean ALL USERS across your account will have the benchmark you have selected as a default every time they log in to Connect or Summit. The lozenge in the top bar will show the current default benchmark in place.",
			},
		},
	},
	metrics: {
		aLevel: {
			ALEVELCOHORTSIZE: 'A level Cohort Size',
			ALEVELBEST3COHORTSIZE: 'A level Best 3 Cohort Size',
			ALEVELAVERAGEPOINTS: 'A level Average Point Score',
			ALEVELBEST3AVERAGEPOINTS: 'A level Best 3 Average Point Score',
			ALEVELAVERAGEGRADES: 'A level Average Grade',
			ALEVELBEST3AVERAGEGRADE: 'A level Best 3 Average Grade',
		},
		appliedGeneral: {
			APPLIEDGENERALCOHORTSIZE: 'Applied General Cohort Size',
			APPLIEDGENERALAVERAGEPOINTS: 'Applied General Average Points',
		},
		techLevelsCohortSize: {
			TECHLEVELSCOHORTSIZE: 'Tech Levels Cohort Size',
			TECHLEVELSAVERAGEPOINTS: 'Tech Levels Average Points',
		},
		studentAchievingThree: {
			STUDENTSACHIEVING3XA_B: 'Students Achieving 3*A-B',
			STUDENTSACHIEVING3XA_C: 'Students Achieving 3*A-C',
			STUDENTSACHIEVING3XA_E: 'Students Achieving 3*A-E',
		},
		studentsAchievingTwo: {
			STUDENTSACHIEVING2XA_B: 'Students Achieving 2*A-B',
			STUDENTSACHIEVING2XA_C: 'Students Achieving 2*A-C',
			STUDENTSACHIEVING2XA_E: 'Students Achieving 2*A-E',
		},
		studentsAchievingOne: {
			STUDENTSACHIEVING1XA_B: 'Students Achieving 1*A-B',
			STUDENTSACHIEVING1XA_C: 'Students Achieving 1*A-C',
			STUDENTSACHIEVING1XA_E: 'Students Achieving 1*A-E',
			STUDENTSACHIEVINGAAB: 'Achieving AAB in 2 Facilitating Subjects',
		},
		priorAttainment: {
			PRIORATTAINMENTAVERAGEPOINTS: 'PriorAttainment Average Points',
			PRIORATTAINMENTBEST3AVERAGEPOINTS: 'Prior Attainment Best 3 Average Points',
			PRIORATTAINMENTALEVELCOHORTSIZE: 'Prior Attainment Cohort Size',
			AVERAGEPA: 'Average Prior Attainment',
		},
		overall: {
			OVERALL_AX_A: 'Overall A*-A',
			OVERALL_AX_B: 'Overall A*-B',
			OVERALL_AX_C: 'Overall A*-C',
			OVERALL_AX_E: 'Overall A*-E',
		},
		student: {
			STUDENTNUMBERS: 'Number of Students',
			STUDENTPASS: 'Student Pass',
			STUDENTTOTALPOINTS: 'Student Total Points',
			STUDENTCOREPOINTS: 'Student Core Points',
			STUDENTMEANSCORE: 'Student Mean Score',
			STUDENTSUBJECTGRADES: 'Student Subject Grades',
			STUDENTACHIEVEDMAXIMUMPOINTS: 'Student Achieved Maximum Points',
			STUDENTLEVELMETRICS: 'Student Level Metrics',
		},
		mean: {
			MEANSUBJECTGRADE: 'Mean Subject Grade',
			MEANTOTALPOINTS: 'Mean Total Points',
			MEANCORECOMPONENTPOINTS: 'Mean Core Component Points',
		},
		subject: {
			SUBJECTAREAANALYSISPERGRADE: 'Subject Area Analysis per Grade',
			SUBJECTAREAANALYSISPERGRADERANGE: 'Subject Area Analysis per Grade Range',
			SUBJECTATTAINMENT: 'Subject Attainment',
			SUBJECTANALYSISPERGRADE: 'Subject Analysis per Grade',
			SUBJECTANALYSISPERGRADERANGE: 'Subject Analysis per Grade Range',
		},
		pointsDistribution: {
			POINTSDISTRIBUTIONANALYSISOFSTUDENTS: 'Points Distribution Analysis of Students',
			POINTSDISTRIBUTIONANALYSISPERGRADE: 'Points Distribution Analysis per Grade',
		},
		general: {
			HMLPERCENTAGEBREAKDOWN: 'HML Percentage Breakdown',
			PASSRATE: 'Pass Rate',
			EXAMS_READ_TOTAL_ENTRIES: 'Exams to read - Total Entries',
			TOTALGRADEPOINTS: 'Total Points',
		},
	},
};
