import { getSlice } from '../common/selectors';
import { getAllXAxisData } from './graphAxis';
import { ExamLevels } from '../../types/enum';
import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';

/**
 * Gets all the loaded grid data
 * @param state redux state
 * @returns An object byId / allIds for the loaded grid data
 */
export const getAllGraphData = (page: string) => (
	state: RootState
): CommonAgGridChartStateTypes.Graphs | undefined => {
	const slice = getSlice(page)(state);
	return slice.graphs;
};

/**
 * Get the grid data for the active page and exam level
 * @param page the active analysis page
 * @param activeExamLevel the active exam level
 * @returns top level (all) rows with comparisons rows
 */
export const getGraphDataForExamLevel = (page: string, activeExamLevel: ExamLevels) => (
	state: RootState
): CommonAgGridChartStateTypes.GraphDataForExamLevel | undefined => {
	const graphData = getAllGraphData(page)(state);

	const allXAxisData = getAllXAxisData(page)(state);

	const xAxisData = !!allXAxisData ? Object.values(allXAxisData.byId) : [];
	const getGridsForExamLevel = !!graphData
		? Object.values(graphData.byId).filter((data) => {
				return data.examLevelId === activeExamLevel;
		  })
		: [];

	const getGraphDataAddXAxisAndComparisonCount = getGridsForExamLevel.reduce((graphsAcc, graph) => {
		const graphValues = graph.xValueIds.length;
		const comparisonValues = xAxisData.reduce((acc, xAxis) => {
			return xAxis.graphId === graph.graphId ? acc + xAxis.comparisonIds.length : acc;
		}, 0);

		return [
			...graphsAcc,
			{
				...graph,
				rowCount: graphValues + comparisonValues,
			},
		];
	}, []);

	return getGridsForExamLevel.length > 0
		? { id: graphData!.id, graph: getGraphDataAddXAxisAndComparisonCount }
		: undefined;
};

/**
 * Get the grid data for the active page and exam level
 * @param page the active analysis page
 * @param gridId the active exam level
 * @returns top level (all) rows with comparisons rows
 */
export const getGraphDataForGraphId = (page: string, graphId: string) => (
	state: RootState
): CommonAgGridChartStateTypes.GraphDataForExamLevel | undefined => {
	const graphData = getAllGraphData(page)(state);
	const allXAxisData = getAllXAxisData(page)(state);
	const xAxisData = !!allXAxisData ? Object.values(allXAxisData.byId) : [];

	const getGraphsForExamLevel = !!graphData
		? Object.values(graphData.byId).filter((data) => {
				return data.graphId === graphId;
		  })
		: [];

	const getGraphDataAddXAxisAndComparisonCount = getGraphsForExamLevel.reduce(
		(graphsAcc, graph) => {
			const graphValues = graph.xValueIds.length;
			const comparisonValues = xAxisData.reduce((acc, xAxis) => {
				return xAxis.graphId === graph.graphId ? acc + xAxis.comparisonIds.length : acc;
			}, 0);

			return [
				...graphsAcc,
				{
					...graph,
					rowCount: graphValues + comparisonValues,
				},
			];
		},
		[]
	);

	return getGraphsForExamLevel.length > 0
		? { id: graphData!.id, graph: getGraphDataAddXAxisAndComparisonCount }
		: undefined;
};
