import * as React from 'react';
import { FunctionComponent } from 'react';

import { Icon, Flex } from 'basecamp';

type Props = {
	icon?: string;
	color?: string;
};

const IconRenderer: FunctionComponent<Props> = ({ icon = 'poo', color = 'UI.accent.0' }) => {
	if (icon) {
		return (
			<Flex setAs="row" withAlign="center" fontSize="20px" color={color} height="100%">
				<Icon icon={icon as any} />
			</Flex>
		);
	}

	return (
		<Flex setAs="row" withAlign="center">
			-
		</Flex>
	);
};

export default IconRenderer;
