import { AllModules } from '@ag-grid-enterprise/all-modules';
import { AgGridReactProps } from '@ag-grid-community/react';
import { PriorAchievementType } from '../types/enum';
import { CellClassParams } from '@ag-grid-community/all-modules';
import { theme } from 'basecamp';

export type NameMap = { [id: string]: string };
export type RowData = {
	subject: number;
	group?: number;
	school?: number;
	// GP columns dynamic
} & any;
export type SelectOption = { label: string; value: string };
export type ComparisonOption = {
	label: string;
	value: number | string;
	isCustom: boolean;
	parent: string;
};

export const ALL_ID = 0;

export const dashFallback = ({ value }: any) => {
	return !value ? '-' : value;
};

/*
params: 
1. params : params object
2. paType : priorAttainment type

Desc:
Checks the indicator for the  Summit Strategic Analysis agGrid to see if its th AvgPa Score,
the indicator value is = 7. It then checks the paType, if its Mixed types(99)
it will replace the value displayed with a '#'.

*/
export const dashFallbackwithStrategicPA = (params: any, paType: string) => {
	if (!params) {
		return '-';
	}

	if (
		!!paType &&
		!!params.data[paType] &&
		params.data?.indicator === 7 && // Indicator 7 : AveragePa Score
		params.data[paType] === PriorAchievementType.MixedPATypes // 99 Enum value for Mixed PA Types
	) {
		return '#';
	}

	return dashFallback(params);
};

/*
params: 
1. params : params object
2. paType : priorAttainment type

Desc:
Used by the Summit BBPA & Subject Value Overview page.
It then checks the paType, if its Mixed types(99)
it will replace the value displayed with a '#'.

*/
export const dashFallbackwithPA = (params: any, paType: string) => {
	if (!params) {
		return '-';
	}

	// 99 Enum value for Mixed PA Types
	if (
		!!paType &&
		!!params.data[paType] &&
		params.data[paType] === PriorAchievementType.MixedPATypes
	) {
		return '#';
	}

	return dashFallback(params);
};

/*
Function for formatting numbers - such as score - for use in ag-grid
*/
export const twoDecimalPlacesWithFallback = ({ value }: any) => {
	return value ? value.toFixed(2) : dashFallback({ value });
};

/**
 * Cell colours for the MAT strategic analysis.
 *
 */
export const getCellColorClassForRowField = (field: string) => ({ data }: any) => {
	let grade;
	const maxIndicatorValue = 7;

	if (data && !data.indicator) {
		const { [field]: value } = data;
		grade = Number(value);
	}

	if (data.indicator <= maxIndicatorValue) return '';

	const { [field]: value, ['linkedValue']: linkedValue, ['exclude']: exclude } = data;

	if (exclude) return '';
	if (linkedValue) {
		const newValue = linkedValue[field];
		grade = Number(newValue);
	} else {
		grade = Number(value);
	}

	return getCellStyleColors(grade);
};

export const makeNameMapFromOptions = (objects: ({ id: string; name: string } & any)[]) => {
	const nameMap: NameMap = {};
	for (const object of objects) {
		nameMap[object.value] = object.label;
	}
	return nameMap;
};

export const makeIndicatorsNameMap = (objects: {
	[indicatorId: string]: Groups.Analysis.Indicator;
}) => {
	const nameMap: NameMap = {};
	for (const id in objects) {
		nameMap[id] = objects[id] && objects[id].name;
	}
	return nameMap;
};

/*
function to provide AG Grid correct header information
*/
export const agGridCustomHeader = (params, mergeAcross: number) => {
	const gradepointstyle = params.splice(1).map((names) => {
		const columnNames = Object.values(names)[4].colGroupDef.headerName;
		return {
			styleId: 'header',
			data: {
				type: 'String',
				value: columnNames,
			},
			mergeAcross: mergeAcross,
		};
	});
	const headerStyle = [
		[],
		[
			{
				data: {
					type: 'String',
					value: '',
				},
			},
			...gradepointstyle,
		],
	];
	return headerStyle;
};

export const defaultGridOptions: AgGridReactProps = {
	domLayout: 'normal',
	defaultColDef: {
		minWidth: 100,
		flex: 1,
	},
	alwaysShowVerticalScroll: false,
	animateRows: true,
	// @ts-ignore
	modules: AllModules,
	rowModelType: 'serverSide',
	cacheBlockSize: 2000,
	tooltipShowDelay: 0,
	onFirstDataRendered: (params: any) => {
		params.api.sizeColumnsToFit();
	},
	onModelUpdated: (params: any) => {
		params.api.sizeColumnsToFit();
	},
	isServerSideGroup: (dataItem: any) => {
		return dataItem.isAllName && !dataItem.school && !dataItem.message;
	},
	immutableData: true,
	getRowNodeId: (data: any) => data.id,
	treeData: true,
	sideBar: {
		toolPanels: [
			{
				id: 'columns',
				labelDefault: 'Columns',
				labelKey: 'columns',
				iconKey: 'columns',
				toolPanel: 'agColumnsToolPanel',
				toolPanelParams: {
					suppressPivotMode: true,
					suppressRowGroups: true,
					suppressValues: true,
				},
			},
		],
	},
};

export const errorRow = {
	message: '<strong>Data fetch failed</strong>',
};

export const emptyRow = {
	message: '<strong>No data found</strong>',
	display: 'No data found',
};

export const getSchoolViewAsDisplay = (
	schoolName: string,
	groupAccessId: number | null,
	canViewAsSchool: boolean,
	schoolClientId?: number,
	queryParams: { subjectName?: string } = {}
) => {
	if (groupAccessId && schoolClientId && canViewAsSchool) {
		// @ts-ignore
		const params = new URLSearchParams(queryParams);
		return `<a href="/connect/school/start/${groupAccessId}/${schoolClientId}${
			queryParams ? `?${params}` : ''
		}" target="_blank">${schoolName}</a>`;
	} else {
		return schoolName;
	}
};

export const getSchoolSubjectViewAsDisplay = (
	schoolName: string,
	groupAccessId: number | null,
	canViewAsSchool: boolean,
	schoolClientId?: number,
	subjectName?: string
) => {
	return getSchoolViewAsDisplay(schoolName, groupAccessId, canViewAsSchool, schoolClientId, {
		subjectName,
	});
};

export const purgeAllChildCaches = (gridApi: any) => {
	gridApi
		?.getRenderedNodes()
		?.filter(({ childrenCache }: any) => !!childrenCache)
		.forEach(({ key }: any) => gridApi.purgeServerSideCache([key]));
};

export const getGradeCellClassRules = (id: number, key: string = 'grade') => {
	return {
		redBackground: (params: any) => {
			if (!params.data) return false;

			// ensure any elements excluded from colour are not coloured
			const { ['exclude']: exclude } = params.data;
			if (exclude) return false;

			const grade = Number(params.data[`${id}-${key}`]);
			return grade >= 1 && grade <= 3;
		},
		blueBackground: (params: any) => {
			if (!params.data) return false;

			// ensure any elements excluded from colour are not coloured
			const { ['exclude']: exclude } = params.data;
			if (exclude) return false;

			const grade = Number(params.data[`${id}-${key}`]);
			return grade >= 7 && grade <= 9;
		},
	};
};

export const getStrategicGradeCellClassRules = (field: string) => {
	return {
		['alps-grade-red']: ({ data }: any) => {
			if (!data || !data.colourRow) return false;

			const grade = Number(data[field]);

			return grade >= 1 && grade <= 3;
		},
		['alps-grade-blue']: ({ data }: any) => {
			if (!data || !data.colourRow) return false;

			const grade = Number(data[field]);

			return grade >= 7 && grade <= 9;
		},
	};
};

export const getHasTopLevelChange = (options: any, state: any): boolean => {
	type TopLevelChangeStates =
		| 'selectedComparison'
		| 'selectedExamLevels'
		| 'selectedGroups'
		| 'selectedSchools';
	const topLevelChangeStates: TopLevelChangeStates[] = [
		'selectedComparison',
		'selectedExamLevels',
		'selectedGroups',
		'selectedSchools',
	];
	return topLevelChangeStates.some((key: TopLevelChangeStates): boolean => {
		if (state[key] && Array.isArray(options[key])) {
			if (options[key].length !== state[key].length) return true;
			if (options[key] && options[key].length > 0) {
				return options[key].some(
					//@ts-ignore
					(el: ComparisonOption | SelectOption, i: number) => state[key][i] !== el
				);
			} else {
				return state[key].some(
					//@ts-ignore
					(el: ComparisonOption | SelectOption, i: number) => options[key][i] !== el
				);
			}
		} else {
			return options[key] !== state[key];
		}
	});
};
/**
 * Get the styling configuration for AG grid
 */
export const getAGGridExcelStylingOptions = () => {
	return [
		{
			id: 'indent-1',
			alignment: { indent: 1 },
			dataType: 'string',
		},
		{
			id: 'indent-2',
			alignment: { indent: 2 },
			dataType: 'string',
		},
		{
			id: 'indent-3',
			alignment: { indent: 3 },
			dataType: 'string',
		},
		{
			id: 'header',
			interior: {
				color: '#CCCCCC',
				pattern: 'Solid',
			},
			borders: {
				borderBottom: {
					color: '#b3b1b1',
					lineStyle: 'Continuous',
					weight: 1,
				},
				borderLeft: {
					color: '#b3b1b1',
					lineStyle: 'Continuous',
					weight: 1,
				},
				borderRight: {
					color: '#b3b1b1',
					lineStyle: 'Continuous',
					weight: 1,
				},
				borderTop: {
					color: '#b3b1b1',
					lineStyle: 'Continuous',
					weight: 1,
				},
			},
		},
		{
			id: 'blueBackground',
			interior: {
				color: '#DFE3F2',
				pattern: 'Solid',
			},
			font: {
				color: '#AAA0F7',
			},
		},
		{
			id: 'redBackground',
			interior: {
				color: '#FEE6DD',
				pattern: 'Solid',
			},
			font: {
				color: '#FF9595',
			},
		},
	];
};

/**
 * Gets the selected comparison and ensures it returns either undefined to prevent comparisons from been applied or an array of comparisons
 * @param selectedComparison
 */
export const getSelectedComparisonPayload = (
	selectedComparison: undefined | Array<ComparisonOption> | ComparisonOption
) => {
	return Array.isArray(selectedComparison)
		? selectedComparison
		: selectedComparison !== undefined
		? [selectedComparison]
		: undefined;
};

/**
 * Cell styling for groups for BBPA and Subject Tables
 * @param params row params
 * @returns style object for cell
 */
export const groupsCellStyle = (params: CellClassParams): { [key: string]: string } => {
	// try get the gradepoint key from the column definition
	const [, key] = /(.+)-(?:grade|entries|score|avgPA)?/g.exec(params.colDef.field ?? '') || [];
	// If we can't get a gradepoint key or we have no data, don't do any styling
	if (!key || !params.data) {
		return {};
	}

	/**
	 * Get the grade from the row data
	 */
	const grade = params.data[`${key}-grade`];
	const entries = params.data[`${key}-entries`];
	const score = params.data[`${key}-score`];
	const avgPa = params.data[`${key}-avgPa`];
	const avgPaDisplay = params.data[`${key}-avgPaDisplay`];

	return getCellStyleColors(grade, false, entries, score, avgPa);
};

const getCellStyleColors = (grade: number, entries?: any, score?: any, avgPa?: any) => {
	/**
	 * Define the color boundaries
	 */
	const redBoundary = grade >= 1 && grade <= 3;
	const blueBoundary = grade >= 7 && grade <= 9;

	/**
	 * Set the bg / color
	 */
	const handleGradeStyle = {
		bg:
			!grade && !entries && !score && !avgPa
				? theme.colors.UI.secondary[1]
				: redBoundary
				? theme.colors.THERMOMETER.tRed[2]
				: blueBoundary
				? theme.colors.THERMOMETER.tBlue[2]
				: 'transparent',
		color: redBoundary
			? theme.colors.THERMOMETER.tRed[8]
			: blueBoundary
			? theme.colors.THERMOMETER.tBlue[8]
			: theme.colors.UI.black[0],
	};

	/**
	 * Return the cell style
	 */
	return { backgroundColor: handleGradeStyle.bg, color: handleGradeStyle.color };
};

/**
 ** Make Subjects Name Map
 */
export const makeSubjectsNameMap = (objects: { [subjectId: string]: Groups.Analysis.Subject }) => {
	return Object.values(objects).reduce((obj, { id }, i, arr) => {
		return {
			...obj,
			[id]: arr.find((item) => item.id === id).subjectName,
		};
	}, {});
};

/**
 ** Applies indent and text-styles
 */
export const getCellClass = (getHasComparison: () => boolean) => (params: any) => {
	var indent = 0;
	var node = params.node;
	while (node && node.parent) {
		indent++;
		node = node.parent;
	}
	const classes = ['indent-' + indent];

	if (!params.data.isAllName) {
		classes.push('cell-italic');
	} else if (getHasComparison()) {
		classes.push('cell-bold');
	}

	if (params.data.client) {
		classes.push('cell-clickable');
	}

	return classes;
};

export const rowSort = (filterRow: any) => {
	let filteredRows = filterRow;

	//group by school
	filteredRows = Object.entries(
		filteredRows.reduce((obj, i) => {
			const name = !i.isAllName ? i.display.slice(i.display.indexOf(':') + 2) : i.display;
			return {
				...obj,
				[name]: [...(obj[name] || []), i],
			};
		}, {})
	)
		// sort each group - belt and braces
		.map(([name, list]) => {
			return [
				name,
				list.sort((a, b) => {
					/**
					 * Call the correct id function and compare to the currently applied comparison
					 */

					const keysByEnvironment = environmentSorting();
					//HML PA
					const hml =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.hml === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.hml === b.comparisonId);

					//Gender
					const gender =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.gender === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.gender === b.comparisonId);

					//Disadvantaged
					const disadvantaged =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.disadvantage === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.disadvantage === b.comparisonId);

					//EAL
					const eal =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.eal === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.eal === b.comparisonId);

					//Ethnicity
					const ethnicity =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.ethnicity === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.ethnicity === b.comparisonId);

					//SEND
					const send =
						(!a.isAllName && !b.isAllName && keysByEnvironment?.send === a.comparisonId) ||
						(!a.isAllName && !b.isAllName && keysByEnvironment?.send === b.comparisonId);

					//All
					const comparisonNameAll =
						(!a.isAllName && !b.isAllName && a.comparisonName.toLowerCase() !== 'all') ||
						b.comparisonName.toLowerCase() !== 'all';

					if (hml) {
						//sort for high/mid/low
						if (comparisonNameAll) {
							const firstEl = a.comparisonName.toLowerCase();
							const secondEl = b.comparisonName.toLowerCase();

							var order = ['high', 'middle', 'low', 'not specified'];
							var aI = order.indexOf(firstEl);
							var bI = order.indexOf(secondEl);

							if (aI === bI) {
								return firstEl - secondEl;
							}

							return aI - bI;
						}
					}

					if (gender) {
						if (comparisonNameAll) {
							return a.comparisonName.localeCompare(b.comparisonName);
						}
					}

					if (disadvantaged) {
						if (comparisonNameAll) {
							const firstEl = a.comparisonName.toLowerCase();
							const secondEl = b.comparisonName.toLowerCase();

							var order = ['no', 'yes', 'not specified'];
							var aI = order.indexOf(firstEl);
							var bI = order.indexOf(secondEl);

							if (aI === bI) {
								return firstEl - secondEl;
							}

							return aI - bI;
						}
					}

					if (eal) {
						if (comparisonNameAll) {
							const firstEl = a.comparisonName.toLowerCase();
							const secondEl = b.comparisonName.toLowerCase();

							var order = ['no', 'yes', 'not specified'];
							var aI = order.indexOf(firstEl);
							var bI = order.indexOf(secondEl);

							if (aI === bI) {
								return firstEl - secondEl;
							}

							return aI - bI;
						}
					}

					//sort for ethnicity
					if (ethnicity) {
						if (comparisonNameAll) {
							return a.comparisonName.localeCompare(b.comparisonName);
						}
					}

					//sort for SEND
					if (send) {
						if (comparisonNameAll) {
							const firstEl = a.comparisonName.toLowerCase();
							const secondEl = b.comparisonName.toLowerCase();

							var order = ['ehcp', 'no sen', 'sen support', 'not specified'];
							var aI = order.indexOf(firstEl);
							var bI = order.indexOf(secondEl);

							if (aI === bI) {
								return firstEl - secondEl;
							}

							return aI - bI;
						}
					}
					if (a.school !== 0 && a.isAllName && !b.isAllName) {
						return -1;
					}
					if (a.school !== 0 && !a.isAllName && b.isAllName) {
						return 1;
					}
				}),
			];
		})
		// sort groups
		.sort(([nameA], [nameB]) => {
			//find where rows do not have a school id
			const parentDisplay = Object.values(filterRow).map((x) => x.school !== 0);
			//find the index where the sorted name matches the display name in the table
			const displayMatches = Object.values(filterRow)
				.map((x) => x.display.toString().includes(nameA))
				.indexOf(true);

			//Only steps in here if there is a school id at the correct index position
			if (parentDisplay[displayMatches]) {
				return nameA.localeCompare(nameB);
			}
		})
		// convert sorted groups to single list
		.flatMap(([name, list]) => {
			return list;
		});

	return filteredRows;
};

/**
 * Get the ordinal value from the trends grade point and apply it to the analysis data.
 * @param gradePoints The gradepoints you want the ordinal to be applied
 * @param selectedTrendGradePoints The selected trend gradepoints which contains the ordinal value
 */
export const addOrdinalToGradePoints = (
	gradePoints: Array<Groups.Analysis.Gradepoint>,
	selectedTrendGradePoints: Array<Groups.Setup.Gradepoint>
) => {
	return (Object.fromEntries(
		Object.entries(gradePoints).map(([key, value]) => {
			const gradePointOrdinal =
				selectedTrendGradePoints?.find((x: Groups.Setup.Gradepoint) => x.key === value.key)
					.ordinal ?? 0;

			return [key, { ...(value as {}), ordinal: gradePointOrdinal ?? 0 }];
		})
	) as unknown) as Array<Groups.Analysis.Gradepoint>;
};

const environmentSorting = () => {
	//check to see what environment we are in and return the 'correct'
	//comparisonIds for sorting.

	if (window.environmentName === 'test' || window.environmentName === 'development') {
		return {
			hml: 35,
			gender: 9,
			disadvantage: 5,
			ethnicity: 10,
			eal: 7,
			send: 67,
		};
	}
	if (window.environmentName === 'staging') {
		return {
			hml: 8,
			gender: 23,
			disadvantage: 22,
			ethnicity: 24,
			eal: 36,
			send: 17,
		};
	}
	if (window.environmentName === 'production') {
		return {
			hml: 4,
			gender: 2,
			disadvantage: 1,
			ethnicity: 3,
			eal: 52,
			send: 53,
		};
	}
};
