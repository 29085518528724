type Mode = 'CONNECT' | 'SUMMIT';

export const getTheme = (mode: Mode, theme: any) => {
	const variants = {
		accent: theme.colors[mode].accent,
		primary: theme.colors[mode].primary,
	};

	const t = Object.assign(theme, {
		colors: {
			...theme.colors,
			UI: {
				...theme.colors.UI,
				...variants,
			},
			NAV: {
				...theme.colors.NAV,
				...variants,
			},
			DATAVIS: {
				...theme.colors.DATAVIS,
				...variants,
			},
		},
	});

	return { ...t };
};
