import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import context from 'features/app/redux/context';
import features from 'features/app/redux/features';
import permissions from 'features/app/redux/permissions';
import standardPage from 'features/layouts/standardPage/redux/standardPage';
import report from 'features/reports/redux';
import auth from 'features/auth/redux/auth';
import subjectsOverview from 'features/subjectsOverview/redux';
import switchOrganisation from 'features/selectOrganisation/redux/switch';
import monitoring from 'features/monitoring/redux/monitoring';
import rtm from 'features/rtmMessaging/redux';
import subjectPage from 'features/subjectPage/redux';
import manageUsers from 'features/manageUsers/redux/users';
import organisationManagement from 'features/manageUsers/redux/organisationManagement';
import alpsAdmin from 'features/alpsAdmin/redux/alpsAdmin';
import studentModal from 'features/studentModal/redux/';
import spo from 'features/spo/redux';
import groupAnalysis from 'features/groups/analysis/redux';
import groupSetup from 'features/groups/setup/redux';
import accounts from 'features/manageAccount/redux/accounts';
import mat from 'features/reports/redux/MAT';
import settings from 'features/analysisSettings/redux';
import viewAsSchool from 'features/groups/viewAs/redux/slice';
import subscriptions from 'features/manageAccount/redux/slice';
import homepage from 'features/home/redux/';
import performanceMetrics from 'features/performanceMeasures/redux';
import ks5PerformanceMeasures from 'features/ks5PerformanceMeasures/redux';
import ibPerformanceMeasures from 'features/ibPerformanceMeasures/redux';
import navigation from 'features/sideNavigation/redux/slice';
import modalMessages from 'features/app/redux/notificationModals';
import strategicAnalysis from 'features/strategicAnalysis/redux/';
import strategicAnalysisOverview from 'features/strategicAnalysis/redux/overview';
import strategicAnalysisBbpaCurrentYear from 'features/strategicAnalysis/redux/bbpaCurrentYear';
import strategicAnalysisBbpaMultiYear from 'features/strategicAnalysis/redux/bbpaMultiYear';
import strategicAnalysisAttainmentProfile from 'features/strategicAnalysis/redux/attainmentProfile';
import strategicAnalysisQualityIndicator from 'features/strategicAnalysis/redux/qualityIndicator';
import strategicAnalysisSubjectValueAdded from 'features/strategicAnalysis/redux/subjectValueAdded';
import strategicAnalysisTscore from 'features/strategicAnalysis/redux/tScore';
import strategicAnalysisQualityOfTeaching from 'features/strategicAnalysis/redux/qualityOfTeaching';
import strategicAnalysisStudentsNotIncluded from 'features/strategicAnalysis/redux/studentsNotIncluded';
import reportingSeasonSlice from 'features/alpsAdmin/reportingSeason/redux/slice';
import subjectArea from 'features/subjectArea/redux/slice';
import subjectAreaAttainmentProfile from 'features/subjectArea/redux/attainmentProfile/slice';
import mfaSetupOneTimePasswordAuthentication from 'features/manageAccount/redux/oneTimePassword/slice';
import mfaSetupEmailAuthentication from 'features/manageAccount/redux/emailAuthentication/slice';
import mfaSetup from 'features/manageAccount/redux/mfa/slice';
import mfaValidation from 'features/auth/redux/mfaSignIn/slice';
import mfaValidationOneTimePasswordAuthentication from 'features/auth/redux/oneTimePassword/slice';
import mfaValidationEmailAuthentication from 'features/auth/redux/emailAuthentication/slice';
import subjectLookUpStore from 'features/subjectLookUp/redux/slice';
import summitPerformanceMeasuresSlice from 'features/groups/analysis/redux/ks5PerformanceMeasures/slice';
import headlinePerformanceMeasures from 'features/groups/analysis/redux/ks5PerformanceMeasures/headlineMeasures/slice';
import cumulativePercentage from 'features/groups/analysis/redux/ks5PerformanceMeasures/cumulativePercentage/slice';
import best3CumulativePercentage from 'features/groups/analysis/redux/ks5PerformanceMeasures/best3CumulativePercentage/slice';
import matSingleSchoolPerformanceMeasures from 'features/groups/analysis/redux/ks5PerformanceMeasures/singleSchool/slice';

/**
 * Redux Persist Root Config
 */
export const persistConfig = {
	key: 'root',
	storage,
	blacklist: [
		'context',
		'standardPage',
		'auth',
		'mfaValidation',
		'mfaValidationOneTimePasswordAuthentication',
		'mfaValidationEmailAuthentication',
		'subjectLookUpStore',
		'subjectsOverview',
		'switchOrganisation',
		'selectOrganisation',
		'subjectPage',
		'subjectArea',
		'subjectAreaAttainmentProfile',
		'monitoring',
		'manageUsers',
		'report',
		'organisationManagement',
		'alpsAdmin',
		'reportingSeason',
		'features',
		'studentModal',
		'spo',
		'groupAnalysis',
		'summitPerformanceMeasuresSlice',
		'headlinePerformanceMeasures',
		'cumulativePercentage',
		'best3CumulativePercentage',
		'matSingleSchoolPerformanceMeasures',
		'groupSetup',
		'accounts',
		'mfaSetup',
		'mfaSetupEmailAuthentication',
		'mfaSetupOneTimePasswordAuthentication',
		'viewAsSchool',
		'mat',
		'subscriptions',
		'homepage',
		'performanceMetrics',
		'ks5PerformanceMeasures',
		'ibPerformanceMeasures',
		'navigation',
		'modalMessages',
		'permissions',
		'strategicAnalysis',
		'strategicAnalysisOverview',
		'strategicAnalysisBbpaCurrentYear',
		'strategicAnalysisBbpaMultiYear',
		'strategicAnalysisAttainmentProfile',
		'strategicAnalysisQualityIndicator',
		'strategicAnalysisSubjectValueAdded',
		'strategicAnalysisTscore',
		'strategicAnalysisQualityOfTeaching',
		'strategicAnalysisStudentsNotIncluded',
	],
};

const modalPersistConfig = {
	key: 'modalMessages',
	whitelist: ['pdfModalExportAcknowledge'],
	blacklist: ['pdfModalExportIsOpen'],
	storage,
};

/**
 * Handle nested persist (but can only handle one level deep)
 */
// const contextPersistConfig = {
//   key: 'context',
//   storage,
//   whitelist: ['reportContext'],
// };

const rootReducer = combineReducers({
	context, //: persistReducer(contextPersistConfig, context),
	standardPage,
	report,
	auth,
	mfaValidation,
	mfaValidationOneTimePasswordAuthentication,
	mfaValidationEmailAuthentication,
	switchOrganisation,
	monitoring,
	rtm,
	manageUsers,
	subjectLookUpStore,
	subjectsOverview,
	subjectPage,
	subjectArea,
	subjectAreaAttainmentProfile,
	features,
	organisationManagement,
	alpsAdmin,
	reportingSeasonSlice,
	studentModal,
	spo,
	groupAnalysis,
	summitPerformanceMeasuresSlice,
	headlinePerformanceMeasures,
	best3CumulativePercentage,
	cumulativePercentage,
	matSingleSchoolPerformanceMeasures,
	groupSetup,
	accounts,
	mfaSetup,
	mfaSetupOneTimePasswordAuthentication,
	mfaSetupEmailAuthentication,
	mat,
	settings,
	viewAsSchool,
	subscriptions,
	homepage,
	//this is for one time pop up modals
	modalMessages: persistReducer(modalPersistConfig, modalMessages),
	performanceMetrics,
	ks5PerformanceMeasures,
	ibPerformanceMeasures,
	navigation,
	permissions,
	strategicAnalysis,
	strategicAnalysisOverview,
	strategicAnalysisBbpaCurrentYear,
	strategicAnalysisBbpaMultiYear,
	strategicAnalysisAttainmentProfile,
	strategicAnalysisQualityIndicator,
	strategicAnalysisSubjectValueAdded,
	strategicAnalysisTscore,
	strategicAnalysisQualityOfTeaching,
	strategicAnalysisStudentsNotIncluded,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;

export default persistedReducer;
