import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex, IconButton, Text } from 'basecamp';
import { styles } from './common';

/**
 * @typedef {Object} Props
 * @property {() => void} toggleRtmMessaging - Function to toggle the RTM messaging
 * @property {number} unreadMsgs - Number of unread messages
 * @property {boolean} isSmallScreen - Whether the screen is small
 */
type Props = {
	toggleRtmMessaging: () => void;
	unreadMsgs: number;
	isSmallScreen: boolean;
	setSmallScreenMenuIsOpen: (isOpen: boolean) => void;
};

const RtmMessagingBtn: FunctionComponent<Props> = ({
	toggleRtmMessaging,
	unreadMsgs,
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex dataTest="rtmMessagingBtnWrap">
			<IconButton
				withIcon="bell"
				setAs="tertiary"
				onClick={() => {
					toggleRtmMessaging();
					setSmallScreenMenuIsOpen(false);
				}}
				dataTest="rtmMessagingBtn"
				{...styles(isSmallScreen)}
			/>
			{/* Message count */}
			{unreadMsgs > 0 && (
				<Flex
					withAlign="center"
					bg="STATUS.error.0"
					position="absolute"
					width="20px"
					height="20px"
					bottom="-10px"
					left="15px"
					borderRadius={5}
					dataTest="rtmMessagingBtnUnreadWrap"
				>
					<Text m={0} withColor="white" setAs="small" dataTest="rtmMessagingBtnUnreadCount">
						{unreadMsgs}
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default RtmMessagingBtn;
