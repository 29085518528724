import * as React from 'react';
import { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	getUserProfileState,
	getRtmMessagingState,
	toggleGpSelector,
	toggleUserProfile,
	toggleRtmMessaging,
} from 'features/layouts/standardPage/redux/standardPage';
import { RtmMessaging } from 'features/rtmMessaging';
import { Screen, NavBar, Nav, Heading, Decorate, Flex } from 'basecamp';
import {
	getRtmMessages,
	getRtmMessagesThunk,
	acknowledgeMessageThunk,
	removeTRMMessageFromState,
} from '../../rtmMessaging/redux';
import { removeManyMessages as removeManyMessagesAPI } from '../../../api/rtmAPI';
import { getUser, changePrimaryGradepointThunk, getIsGroup } from 'features/app/redux/context';
import { getAllGroupTrends, getGroupSelectedTrendId } from 'features/groups/analysis/redux';
import { Context } from 'features/app/types';
import {
	RenderLogo,
	RenderModals,
	UserProfileMenu,
	RenderGradepointTrendSelector,
	RenderTopbarLinks,
} from './components/';
import { AppPrimaryNavigation } from 'features/primaryNavigation';
import { useLocation } from 'react-router-dom';

/**
 * Standard page layout component that provides the main application structure
 * including navigation bar, user profile menu, gradepoint selector, and RTM messaging
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render within the page layout
 * @returns {JSX.Element} Standard page layout with navigation and child content
 */
const StandardPage: FunctionComponent = ({ children }) => {
	/**
	 * Redux dispatch hook
	 */
	const dispatch = useDispatch();

	/**
	 * Router location hook
	 */
	const location = useLocation();

	/**
	 * Component state
	 */
	const rtmMessages = useSelector(getRtmMessages);
	const [selectedTrend, setSelectTrend] = useState<Groups.Setup.Trend>();

	/**
	 * Load RTM messages when component mounts
	 */
	useEffect(() => {
		dispatch(getRtmMessagesThunk());
	}, []);

	/**
	 * Redux selectors for application state
	 */
	const trends = useSelector(getAllGroupTrends);
	const selectedTrendId = useSelector(getGroupSelectedTrendId);
	const isGroup = useSelector(getIsGroup);
	const userProfileMenuIsOpen = useSelector(getUserProfileState);
	const rtmMessagingIsOpen = useSelector(getRtmMessagingState);
	const user = useSelector(getUser);

	/**
	 * Effect to handle selected trend changes and update gradepoints accordingly
	 */
	useEffect(() => {
		const getSelectedTrend = trends.find(
			(trend: Groups.Setup.Trend) => trend.id === selectedTrendId
		);
		setSelectTrend(getSelectedTrend);

		if (getSelectedTrend && !isGroup) {
			const gradepoints = getSelectedTrend.gradepoints;
			const key = gradepoints[gradepoints.length - 1].key;
			const gradepointSelection = getSelectedTrend.gradepoints.map(
				(gp: Context.Gradepoint) => gp.key
			);

			dispatch(
				changePrimaryGradepointThunk({
					key,
					gradepointSelection,
					isAppStartSelection: false,
					rerunAutoGpPopulation: false,
				})
			);
		}
	}, [trends, selectedTrendId]);

	/**
	 * Handles toggling the RTM messaging panel and requesting notifications
	 */
	const handleRtmMessaging = () => {
		dispatch(toggleUserProfile(false));
		dispatch(toggleGpSelector(false));
		dispatch(toggleRtmMessaging(!rtmMessagingIsOpen));
		requestNotifications();
	};

	/**
	 * Requests notification permissions if not already granted
	 */
	const requestNotifications = () => {
		if (Notification.permission == 'default') {
			Notification.requestPermission();
		}
	};

	/**
	 * Acknowledges a specific RTM message
	 * @param {number} id - ID of the message to acknowledge
	 */
	const acknowledgeMessage = async (id: number): Promise<void> => {
		dispatch(acknowledgeMessageThunk(id));
	};

	/**
	 * Fetches latest RTM messages
	 */
	const getMessages = async (): Promise<void> => {
		dispatch(getRtmMessagesThunk());
	};

	/**
	 * Removes multiple RTM messages
	 * @param {number[]} ids - Array of message IDs to remove
	 */
	const removeMessages = async (ids: number[]): Promise<void> => {
		dispatch(removeTRMMessageFromState(ids));
		await removeManyMessagesAPI(ids);
		await getMessages();
	};

	/**
	 * Renders welcome message or primary navigation bar based on the current path
	 * @returns {JSX.Element} Welcome message or primary navigation bar
	 */
	const renderWelcomeMessageOrPrimaryNav = (isInDevelopment: boolean) => {
		const handleMargins = { mx: 4, mt: 4 };

		// TODO: Remove this once we have a proper home page
		if (isInDevelopment && location.pathname === '/home') {
			return (
				<Fragment>
					<Nav {...handleMargins} position="relative" zIndex={9001}>
						<AppPrimaryNavigation isGroup={isGroup} />
					</Nav>
					<Flex mx={4}>
						<Heading setAs="large" mt={0}>
							Welcome,{' '}
							<Decorate setAs="b" withColor="primary">
								{user ? user.fullname : 'Connect User'}
							</Decorate>
						</Heading>
					</Flex>
				</Fragment>
			);
		}

		// Render welcome message if on home page
		if (location.pathname === '/home') {
			return (
				<Flex {...handleMargins}>
					<Heading setAs="large" mt={0}>
						Welcome,{' '}
						<Decorate setAs="b" withColor="primary">
							{user ? user.fullname : 'Connect User'}
						</Decorate>
					</Heading>
				</Flex>
			);
		}

		// Render primary navigation bar if not on home page
		return (
			<Nav {...handleMargins} position="relative" zIndex={9001}>
				<AppPrimaryNavigation isGroup={isGroup} />
			</Nav>
		);
	};

	/**
	 * Toggle user profile menu
	 */
	const closeUserProfileMenu = () => {
		dispatch(toggleGpSelector(false));
		dispatch(toggleRtmMessaging(false));
		dispatch(toggleUserProfile(false));
	};

	return (
		<Fragment>
			<Flex
				setAs="column"
				pt="70px"
				bg="UI.white"
				display="flex"
				height="100vh"
				dataTest={`standardPage ${isGroup ? 'summit' : 'connect'}`}
			>
				{/* Navigation bar with primary background */}
				<NavBar
					bg="UI.primary.3"
					dataTest="navBar"
					pr={0}
					height="70px"
					zIndex={10000}
					display="flex"
					flexShrink={0}
				>
					{/* Logo section */}
					<RenderLogo />

					{/* Topbar links section */}
					<RenderTopbarLinks
						isGroup={isGroup}
						selectedTrend={selectedTrend}
						handleRtmMessaging={() => handleRtmMessaging()}
					/>
				</NavBar>

				{/* Render welcome message or primary navigation bar based on the current path */}
				{renderWelcomeMessageOrPrimaryNav(false)}

				{/* Main content */}
				{children}
			</Flex>

			{/* Render modals */}
			<RenderModals />

			{/* User profile menu shown when open */}
			{userProfileMenuIsOpen && <UserProfileMenu close={() => closeUserProfileMenu()} />}

			{/* Gradepoint/Trend selector component */}
			<RenderGradepointTrendSelector />

			{/* RTM messaging panel shown when open */}
			{rtmMessagingIsOpen && (
				<RtmMessaging
					close={() => handleRtmMessaging()}
					rtmMessages={rtmMessages}
					acknowledgeMessage={(id: number) => acknowledgeMessage(id)}
					removeMessage={(id: number) => removeMessages([id])}
					removeMessages={(ids: number[]) => removeMessages(ids)}
				/>
			)}
		</Fragment>
	);
};

export default StandardPage;
