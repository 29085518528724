/**
 * Selectors for creating the row data for the main top level grids
 */
import { getAllComparisonData, getAllRowData, getAppliedPageComparisonsOrDefault } from './';
import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';

/**
 * Get the row data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getRowDataWithComparisonsOrDefault = (page: string, rowIds: string[]) => (
	state: RootState
): (CommonAgGridChartStateTypes.GridRow | CommonAgGridChartStateTypes.ComparisonRow)[] | null => {
	const rows = getAllRowData(page)(state);
	const comparisons = getAllComparisonData(page)(state);

	return !!rows
		? rowIds?.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;
				return [
					rows.byId[id],
					...comparisonIds.map((compId) => {
						return comparisons!.byId[compId];
					}),
				];
		  })
		: null;
};

/**
 * Get the row data without comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows
 */
export const getRowDataWithoutComparisonsOrDefault = (page: string, rowIds: string[]) => (
	state: RootState
): CommonAgGridChartStateTypes.GridRow[] | null => {
	const rows = getAllRowData(page)(state);

	return !!rows ? rowIds.flatMap((id) => rows.byId[id]) : null;
};

/**
 * Creates the row data for the main grids
 * @param rowIds row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGridData = (page: string, rowIds: string[] | undefined) => (
	state: RootState
): (CommonAgGridChartStateTypes.GridRow | CommonAgGridChartStateTypes.ComparisonRow)[] | null => {
	if (!rowIds) {
		return null;
	}
	const rows = getAllRowData(page)(state);
	const rowsWithComparisons = getRowDataWithComparisonsOrDefault(page, rowIds)(state);
	const rowsWithoutComparisons = getRowDataWithoutComparisonsOrDefault(page, rowIds)(state);
	const appliedComparisons = getAppliedPageComparisonsOrDefault(page)(state);
	const rowComparisonsIds = rows?.byId[rowIds[0]].comparisonIds;

	const hasComparisonsForRow =
		rowComparisonsIds &&
		rowComparisonsIds.some((id) => {
			const applied = appliedComparisons[0] ? appliedComparisons[0].value.split('|').join('-') : '';
			return id.includes(applied);
		});
	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? rowsWithComparisons : null;
	}

	// Else if no applied comparisons just fetch the all row
	return rowsWithoutComparisons;
};
