import * as React from 'react';
import { FunctionComponent, useEffect } from 'react';
import { useAgGrid } from '../../../agGrid/hooks';
import { AgGridReact } from '@ag-grid-community/react';
import { Box } from 'basecamp';
import { GridHeader } from '../components';
import { AgGridReactProps } from '@ag-grid-community/react';
import { createDefaultGridOptions } from '../../../agGrid';
import { useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { IconRenderer } from '../../../agGrid/cellRenderers';
import { useSelector } from 'react-redux';

import { getAppliedMetrics } from '../redux';
import { ColDef } from 'ag-grid-community';

type Props = {
	isMultiGrid?: boolean;
	data?: {
		metrics: any;
		coldefs: any;
		tableName: string;
	};
};

const AgGridPms: FunctionComponent<Props> = ({ isMultiGrid = false, data }) => {
	/**
	 * Handle window size
	 * Used for pinning / un-pinning columns on smaller screens
	 */
	const { width } = useWindowSize();

	/**
	 ** Handle  Ag Grid Api
	 */
	const gridOptions = useAgGrid();

	const appliedMetrics = useSelector(getAppliedMetrics);

	//Should the excel export have 0 and 1 or yes and no
	const renderNumbersOrWords = (col: ColDef) => {
		// if we are on either of these pages, don't bother looking at the columns, we're using numbers
		if (appliedMetrics.label === 'EBacc Avg Score' || appliedMetrics.label === 'Attainment 8')
			return true;

		// if the id of the column matches any of the following, display the number
		if (col.colId === 'En-Lang-Grade' || col.colId === 'En-Lit-Grade' || col.colId === 'Ma-Grade')
			return true;

		return false;
	};

	/**
	 * Update grid
	 */
	// Resize columns on mount
	useEffect(() => {
		gridOptions.columnApi?.autoSizeAllColumns();
	}, [gridOptions.api, gridOptions.columnApi]);

	// Resize columns on mount
	useEffect(() => {
		if (width && width > 1400) {
			gridOptions.api?.sizeColumnsToFit();
		}
	}, [width]);

	/**
	 * Default Grid Options
	 */
	const defaultGridOptions: AgGridReactProps = createDefaultGridOptions({
		sideBar: {
			toolPanels: [
				{
					id: 'columns',
					labelDefault: 'Manage Columns',
					labelKey: 'columns',
					iconKey: 'columns',
					toolPanel: 'agColumnsToolPanel',
					toolPanelParams: {
						suppressPivotMode: true,
						suppressRowGroups: true,
						suppressValues: true,
					},
				},
			],
		},
		domLayout: 'normal',
		suppressHorizontalScroll: false,
		frameworkComponents: {
			iconRenderer: (params: any) => {
				const icon = !!Number(params.value) ? 'check' : 'xmark';
				const color = !!Number(params.value) ? 'STATUS.success.0' : 'THERMOMETER.tBlue.0';

				return <IconRenderer icon={icon} color={color} />;
			},
		},
		onGridColumnsChanged: () => {
			gridOptions.api?.redrawRows();
			gridOptions.columnApi?.autoSizeAllColumns();
			if (width && width > 1400) {
				gridOptions.api?.sizeColumnsToFit();
			}
		},
	});

	/**
	 * Handle Excel Export
	 */
	const handleExcelExport = () => {
		return gridOptions.api?.exportDataAsExcel({
			processCellCallback: (params: any) =>
				params.value === '1' && !renderNumbersOrWords(params.column)
					? (params.value = translate('performanceMeasures.agGrid.excel.YES'))
					: (params.value =
							params.value && params.value === '0' && !renderNumbersOrWords(params.column)
								? (params.value = translate('performanceMeasures.agGrid.excel.NO'))
								: (params.value = params.value)),
			sheetName: translate('performanceMeasures.agGrid.excel.EXPORT', {
				title: data?.tableName,
			}) as string,
			fileName: translate('performanceMeasures.agGrid.excel.EXPORT', {
				title: data?.tableName,
			}) as string,
		});
	};

	/**
	 * Render Component
	 */
	return (
		<Box width={1} px={4} pt={2} pb={4}>
			{/* Heading */}
			<GridHeader
				heading={data?.tableName as string}
				handleExcelExport={() => handleExcelExport()}
			/>
			<Box
				className={isMultiGrid ? 'ag-theme-alps is-multi-grid' : 'ag-theme-alps'}
				width={1}
				height="calc(100vh - 435px)"
			>
				<AgGridReact
					{...defaultGridOptions}
					onGridReady={(params) => gridOptions.onGridReady(params)}
					columnDefs={data?.coldefs}
					rowData={data?.metrics}
				/>
			</Box>
		</Box>
	);
};

export default AgGridPms;
