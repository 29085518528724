import fetch from './';
import { Context } from 'features/app/types';
import { SubjectAreaGridData } from 'features/subjectArea/types/common';
import { SubjectAreaAttainmentProfile } from 'features/subjectArea/types/attainmentProfile';

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchMainGridData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	udf: { type: string; value: string },
	versionNumber: number = 2
): Promise<ResponseBuilder<SubjectAreaGridData.GridData>> => {
	return fetch(
		`/bigfoot/v${versionNumber}/SubjectArea/${area}/GetSubjectPageAttainmentProfileGridData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: subjectId,
				Teacher: formatUdfValuesForBigfoot(udf, 'Teacher'),
				TeachingSet: formatUdfValuesForBigfoot(udf, 'Teaching Set'),
			},
		},
		true
	);
};

/**
 * Get the main Graph data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 */
export const fetchChartData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	udf: { type: string; value: string },
	versionNumber: number = 2
): Promise<ResponseBuilder<SubjectAreaGridData.GridData>> => {
	return fetch(
		`/bigfoot/V${versionNumber}/SubjectArea/${area}/GetSubjectPageAttainmentProfileChartData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: subjectId,
				Teacher: formatUdfValuesForBigfoot(udf, 'Teacher'),
				TeachingSet: formatUdfValuesForBigfoot(udf, 'Teaching Set'),
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 * @param comparisons
 */
export const fetchAllComparisonGridData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	udf: { type: string; value: string },
	comparisons: Context.ReportAppliedComparisons[]
): Promise<
	ResponseBuilder<SubjectAreaGridData.Comparisons<SubjectAreaAttainmentProfile.ResponseRow>>
> => {
	return fetch(
		`/bigfoot/V2/SubjectArea/${area}/GetSubjectPageAttainmentProfileGridData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: [subjectId],
				AppliedComparisons: comparisons,
				Teacher: formatUdfValuesForBigfoot(udf, 'Teacher'),
				TeachingSet: formatUdfValuesForBigfoot(udf, 'Teaching Set'),
			},
		},
		true
	);
};

/**
 * Get the main grid data for the given area and exam level
 * @param area string,
 * @param examLevel number,
 * @param benchmark string,
 * @param gradepoints Context.Gradepoint[],
 * @param comparisons
 */
export const fetchAllComparisonChartData = (
	area: string,
	examLevel: number,
	benchmarkSet: string,
	gradepoints: Context.Gradepoint[],
	subjectId: number[],
	udf: { type: string; value: string },
	comparisons: Context.ReportAppliedComparisons[]
): Promise<
	ResponseBuilder<SubjectAreaGridData.Comparisons<SubjectAreaAttainmentProfile.ResponseRow>>
> => {
	return fetch(
		`/bigfoot/V2/SubjectArea/${area}/GetSubjectPageAttainmentProfileChartData`,
		{
			method: 'POST',
			body: {
				BenchmarkSet: benchmarkSet,
				GradePoints: gradepoints,
				ExamLevel: examLevel,
				SubjectId: [subjectId],
				AppliedComparisons: comparisons,
				Teacher: formatUdfValuesForBigfoot(udf, 'Teacher'),
				TeachingSet: formatUdfValuesForBigfoot(udf, 'Teaching Set'),
			},
		},
		true
	);
};

//#region Private methods

/**
 * Format the udf value into a string or undefined
 * @param udf teacher or teaching set value
 * @param wantedType which value is needed
 * @returns
 */
const formatUdfValuesForBigfoot = (
	udf: { type: string; value: string },
	wantedType: string
): string | undefined => {
	if (udf.value.length === 0) {
		return undefined;
	} else if (udf.type === wantedType) {
		return udf.value;
	} else {
		return undefined;
	}
};

//#endregion
