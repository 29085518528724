/**
 * @fileoverview PageTitle component for rendering breadcrumb-style navigation
 */
import React, { Fragment, FunctionComponent } from 'react';
import { Flex, PageTitleNavLink } from 'basecamp';
import { useLocation } from 'react-router-dom';

/**
 * Props interface for PageTitle component
 * @interface Props
 * @property {string} [pageTitle] - Optional custom page title to display
 * @property {Routes.Route[]} [routes] - Optional array of route configuration objects
 */
type Props = {
	/** Page title */
	pageTitle?: string;
	/** Array of route configuration objects */
	routes?: Routes.Route[];
};

/**
 * PageTitle Component
 *
 * Renders a breadcrumb-style navigation component showing the current page title.
 * The title can either be provided directly via props, or derived from the current route.
 *
 * If a route's baseName is provided, it will show as the first breadcrumb level,
 * followed by the route's displayName. Otherwise just the displayName is shown.
 * @example
 * // Basic usage with custom page title
 * <PageTitle pageTitle="My Custom Page" />
 *
 * // Usage with routes configuration
 * const routes = [{
 *   path: '/dashboard',
 *   displayName: 'Dashboard',
 *   baseName: 'Home'
 * }];
 * <PageTitle routes={routes} />
 * @component
 * @param {Props} props - Component props
 * @param {string} [props.pageTitle] - Optional custom page title to display
 * @param {Routes.Route[]} [props.routes] - Optional array of route configuration objects
 * @returns {JSX.Element} Rendered page title navigation component
 */
const PageTitle: FunctionComponent<Props> = ({ routes, pageTitle }) => {
	const location = useLocation();
	const getCurrentRoute = routes?.find((route) => route.path === location.pathname);
	// Get the page title and base name from the current route
	const getPageTitle = getCurrentRoute?.displayName ?? '';
	const getPageBaseName = getCurrentRoute?.baseName ?? '';

	/**
	 * Renders the page title navigation links
	 * @returns {JSX.Element} Page title navigation links with appropriate breadcrumb structure
	 */
	const renderComponent = () => {
		// If page title is provided, render it
		if (!!pageTitle) {
			return <PageTitleNavLink>{pageTitle}</PageTitleNavLink>;
		}

		// If not work it out from the route defs
		if (!!getPageBaseName) {
			return (
				<Fragment>
					<PageTitleNavLink showMenuDivider>{getPageBaseName}</PageTitleNavLink>
					<PageTitleNavLink>{getPageTitle}</PageTitleNavLink>
				</Fragment>
			);
		}

		return <PageTitleNavLink showMenuDivider>{getPageTitle}</PageTitleNavLink>;
	};
	return (
		<Flex mx={4} mb={3}>
			{renderComponent()}
		</Flex>
	);
};

export default PageTitle;
