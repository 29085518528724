import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getIsGroup } from 'features/app/redux/context';
import { SummitLogo, ConnectLogo, Link, Flex } from 'basecamp';

/**
 * Component that renders either the Summit or Connect logo based on the group status
 * @component
 * @returns {JSX.Element} A flex container with a linked logo
 */
const RenderLogo: FunctionComponent = () => {
	const isGroup = useSelector(getIsGroup);

	return (
		<Flex setAs="row" withAlign="center">
			<Link setAs="wrapping" withInternalUrl="/home" dataTest="alpsLogo">
				{isGroup ? <SummitLogo /> : <ConnectLogo />}
			</Link>
		</Flex>
	);
};

export default RenderLogo;
