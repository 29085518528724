/**
 * @module useConnectSecondaryNavRoutes
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import {
	useManageUserRoutes,
	useManageAccountRoutes,
	useAlpsAdminRoutes,
	useAnalysisSettingRoutes,
} from '../';
import { knowledgeBaseRoutes } from '../../common/knowledgeBaseRoutes';
import { getIsContractOwner } from 'features/app/redux/context';
import {
	getEmbargo,
	getImpersonationMode,
	getSubscriptionStatus,
} from 'features/app/redux/context';
import { getFeature } from 'features/app/redux/features';
import { getUserRoles } from 'features/app/redux/context';

/**
 * Hook that returns the navigation routes for ALPS admin users
 * @returns {Object} Navigation route configuration object containing:
 * - displayName: Translated heading text
 * - icon: Icon name to display
 * - isVisible: Whether route should be visible based on permissions
 * - flyoutContent: Array of flyout menu items with:
 *   - heading: Translated flyout heading
 *   - flyoutLinks: Array of link objects with:
 *     - displayName: Translated link text
 *     - isVisible: Whether link should be visible
 *     - withInternalUrl: Internal route URL
 */
const useConnectHomeRoutes = (): SecondaryNavigationLinks[] => {
	// Alps Admin Route Definitions
	const { alpsAdminHomeRoutes } = useAlpsAdminRoutes();
	// Manage Data Conditional logic
	const permissions: string[] | undefined = useSelector(getUserRoles);
	const impersonation = useSelector(getImpersonationMode);
	const embargoFeature = useSelector(getFeature('embargo_feature'));
	const embargoPeriod = useSelector(getEmbargo);
	const applyEmbargo = embargoFeature && embargoPeriod?.some((x) => x.value === true);
	const enable_connect_data = useSelector(getFeature('enable_connect_data'));
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	// Manage Users Route Definitions
	const { manageUserHomeRoutes } = useManageUserRoutes();
	// Analysis Settings Route Definitions
	const { analysisSettingsHomeRoutes } = useAnalysisSettingRoutes();
	// Reports Route Definitions
	const isContractOwner = useSelector(getIsContractOwner);
	// Manage Account Route Definitions
	const { manageAccountHomeRoutes } = useManageAccountRoutes();

	return [
		// Alps Admin Routes
		alpsAdminHomeRoutes,
		// Manage Data Routes
		{
			displayName: translate('home.singleSchool.dataManagement.HEADING') as string,
			isVisible: impersonation?.includes('MAT')
				? false
				: permissions?.length === 0
				? false
				: permissions?.includes('DataExchange_Manage', 0)
				? !applyEmbargo
				: false,
			disabled: !enable_connect_data || !subscriptionStatus.hasValidSubscriptionOrContract,
			icon: 'database',
			flyoutContent: [
				{
					heading: translate('home.singleSchool.dataManagement.FLYOUT_HEADING_1') as string,
					flyoutLinks: [
						{
							displayName: translate('home.singleSchool.dataManagement.links.COHORTS') as string,
							withExternalUrl: '/data/cohorts',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.GRADEPOINTS'
							) as string,
							withExternalUrl: '/data/gradepoints',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate('home.singleSchool.dataManagement.links.GRADES') as string,
							withExternalUrl: '/data/grades',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate('home.singleSchool.dataManagement.links.STUDENTS') as string,
							withExternalUrl: '/data/students',
							andTarget: '_self',
							isVisible: true,
						},
					],
				},
				{
					heading: translate('home.singleSchool.dataManagement.FLYOUT_HEADING_2') as string,
					flyoutLinks: [
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.COLUMNS_SUBJECT_MAPPINGS'
							) as string,
							withExternalUrl: '/data/column_subject_mappings',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.FINE_GRADES'
							) as string,
							withExternalUrl: '/data/fine_grades',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.CONSORTIUM_SCHOOLS'
							) as string,
							withExternalUrl: '/data/consortium_schools',
							andTarget: '_self',
							isVisible: true,
						},
						{
							displayName: translate(
								'home.singleSchool.dataManagement.links.CUSTOM_COLUMNS'
							) as string,
							withExternalUrl: '/data/custom_columns',
							andTarget: '_self',
							isVisible: true,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
		// Manage Users Routes
		manageUserHomeRoutes,
		// Analysis Settings Routes
		analysisSettingsHomeRoutes,
		// Reports Routes
		...(!applyEmbargo
			? [
					{
						displayName: translate('home.singleSchool.reportsDownloads.HEADING') as string,
						isVisible: true,
						disabled: !subscriptionStatus.hasValidSubscriptionOrContract,
						icon: 'file',
						flyoutContent: [
							{
								heading: translate('home.singleSchool.reportsDownloads.FLYOUT_HEADING') as string,
								flyoutLinks: [
									{
										displayName: translate(
											'home.singleSchool.reportsDownloads.links.MEGS'
										) as string,
										withInternalUrl: '/reports/meg-reports',
										isVisible: !applyEmbargo && !isContractOwner,
									},
									{
										displayName: translate(
											'home.singleSchool.reportsDownloads.links.MY_REPORTS'
										) as string,
										withInternalUrl: '/reports/my-reports',
										isVisible:
											!applyEmbargo &&
											!isContractOwner &&
											!!permissions &&
											permissions.includes('DataExchange_View', 0),
									},
									{
										displayName: translate(
											'home.singleSchool.reportsDownloads.links.ADDITIONAL_DOWNLOADS'
										) as string,
										withInternalUrl: '/reports/additional-downloads',
										isVisible:
											!applyEmbargo && permissions?.length === 0
												? false
												: permissions?.includes('DataExchange_View', 0)
												? true
												: false,
									},
								],
							},
							knowledgeBaseRoutes,
						],
					},
			  ]
			: []),
		// Manage Account Routes
		manageAccountHomeRoutes,
	];
};

export default useConnectHomeRoutes;
