/**
 Application Root - Webpack Entry Point
 **/
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { store, persistor } from './store/store';
import { Loading } from 'basecamp';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { LicenseManager as LicenseManagerV27 } from 'ag-grid-enterprise';
import { constants } from './constants';
import App from 'features/app';

/**
 ** Ag Grid Alps Theme
 * Some colour overrides exist in Basecamp this is for the
 * colour switching between Summit and Connect
 */
import './agGrid/css/ag-theme-alps.css';
// Alps fonts
//import './resources/font.css';

LicenseManager.setLicenseKey(constants.agGridLicense);
/**
 * Temporarily add license for V27 as well as V25
 * until we migrate fully to V27
 */
LicenseManagerV27.setLicenseKey(constants.agGridLicense);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			{/**
			 * Persist gate delays the rendering of the app's UI until our persisted
			 * state has been retrieved and saved to Redux
			 */}
			<PersistGate loading={<Loading />} persistor={persistor}>
				<Router>
					<App />
				</Router>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
