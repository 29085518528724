/**
 * @module useAnalysisSettingRoutes
 * @description Module containing hook for managing analysis settings navigation routes
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { getFeature, getConfig } from 'features/app/redux/features';
import {
	getBenchmarkSetCountryFromNationDataSetBenchmark,
	getCanSwitchClientBenchmark,
	getImpersonationMode,
	getSubscriptionStatus,
	getUserRoles,
	getViewAsSchool,
	hasGroupAccess,
} from 'features/app/redux/context';
import { knowledgeBaseRoutes } from '../../common/knowledgeBaseRoutes';

/** Base URL for analysis settings routes */
const BASE_URL = '/analysis-settings';

/**
 * Hook that returns the navigation routes for analysis settings
 * @returns {Object} Navigation route configuration object
 * @property {SecondaryNavigationLinks[]} analysisSettingsRoutes - Array of analysis settings navigation links
 * @property {SecondaryNavigationLinks} analysisSettingsHomeRoutes - Analysis settings home navigation configuration
 */
const useAnalysisSettingRoutes = (): {
	analysisSettingsRoutes: SecondaryNavigationLinks[];
	analysisSettingsHomeRoutes: SecondaryNavigationLinks;
} => {
	/**
	 * Whether the current context is a group
	 * @type {boolean}
	 */
	const isGroup = useSelector(hasGroupAccess);

	/**
	 * Current impersonation mode
	 * @type {string}
	 */
	const impersonationMode = useSelector(getImpersonationMode);

	/**
	 * Whether user can switch client benchmark
	 * @type {boolean}
	 */
	const canSwitchClientBenchmark = useSelector(getCanSwitchClientBenchmark);

	/**
	 * Whether user can view as client
	 * @type {boolean}
	 */
	const canViewAsClient = useSelector(getViewAsSchool);

	/**
	 * Available benchmarks from config
	 * @type {string[]}
	 */
	const avaliableBenchmarks = useSelector(getConfig('avaliable_benchmarks'));

	/**
	 * Current benchmark setting
	 * @type {string}
	 */
	const currentBenchmark = useSelector(getBenchmarkSetCountryFromNationDataSetBenchmark);

	/**
	 * Whether current benchmark is in allowed list
	 * @type {boolean}
	 */
	const isBenchmarkAllowed = avaliableBenchmarks?.includes(currentBenchmark);

	/**
	 * Summit benchmark feature flag
	 * @type {boolean}
	 */
	const summitBenchmark = useSelector(getFeature('summit_benchmark'));

	/**
	 * Connect benchmark feature flag
	 * @type {boolean}
	 */
	const connectBenchmark = useSelector(getFeature('client_benchmark'));

	/**
	 * Correct benchmark flag based on context
	 * @type {boolean}
	 */
	const correctContextBenchmarkFlag = isGroup ? summitBenchmark : connectBenchmark;

	/**
	 * Whether user can access client benchmark switch screen
	 * @type {boolean}
	 */
	const canAccessSwitchClientBenchmarkScreen =
		correctContextBenchmarkFlag && isBenchmarkAllowed && impersonationMode === 'None'
			? canSwitchClientBenchmark || canViewAsClient
			: true;

	/**
	 * Whether user is an admin
	 * @type {boolean}
	 */
	const isAdmin = useSelector(getUserRoles)?.includes('Settings_Users', 0);

	/**
	 * Subscription status
	 * @type {Object}
	 */
	const subscriptionStatus = useSelector(getSubscriptionStatus);

	/** Array of analysis settings navigation route configurations */
	const analysisSettingsRoutes: SecondaryNavigationLinks[] = [
		{
			isVisible: !isGroup ?? false,
			withInternalUrl: `${BASE_URL}/my-comparisons`,
			icon: 'user',
			displayName: translate(
				'analysisSettings.sidebar.analysisSetup.links.CUSTOM_COMPARISONS'
			) as string,
		},
		{
			isVisible: canAccessSwitchClientBenchmarkScreen ?? false,
			withInternalUrl: `${BASE_URL}/client-benchmarks`,
			icon: 'wrench',
			displayName: translate(
				'analysisSettings.sidebar.analysisSetup.links.CLIENT_BENCHMARKS'
			) as string,
		},
		{
			isVisible: (isAdmin && isBenchmarkAllowed && correctContextBenchmarkFlag) ?? false,
			withInternalUrl: `${BASE_URL}/benchmark-selector`,
			icon: 'wrench',
			displayName: translate(
				'analysisSettings.sidebar.analysisSetup.links.BENCHMARK_SELECTOR'
			) as string,
		},
	];

	/** Home navigation route configuration */
	const analysisSettingsHomeRoutes = {
		displayName: translate('analysisSettings.sidebar.analysisSetup.HEADING') as string,
		isVisible: true,
		disabled: !subscriptionStatus.hasValidSubscriptionOrContract,
		icon: 'chart-line',
		flyoutContent: [
			{
				heading: translate('analysisSettings.sidebar.analysisSetup.FLYOUT_HEADING') as string,
				flyoutLinks: analysisSettingsRoutes,
			},
			knowledgeBaseRoutes,
		],
	};

	return {
		analysisSettingsRoutes,
		analysisSettingsHomeRoutes,
	};
};

export default useAnalysisSettingRoutes;
