export enum CountryEnum {
	England,
	Wales,
	Ireland,
	International,
	Jersey,
}

export enum BenchmarkCountryEnum {
	England = 'England',
	Wales = 'Wales',
	Northernireland = 'Northern Ireland',
	International = 'International',
	Jersey = 'Jersey',
}

export enum ExamLevels {
	A = 1,
	AS = 2,
	BTEC = 4,
	BTECCert = 8,
	BTEC90Cred = 16,
	KS4 = 32,
	PreU = 64,
	IB = 128,
	Ks5Voc = BTEC | BTEC90Cred | BTECCert,
	Ks5 = A | AS | Ks5Voc,
	Ks5Extended = PreU | IB | Ks5,
}

export enum Direction {
	NO_CHANGE,
	UP,
	DOWN,
}

export enum SummitKeyStageValues {
	KS4 = 'KS4',
	KS5 = 'KS5',
}

export enum PriorAchievementType {
	Unknown = 0,
	GCSE = 1,
	KS2FineGrade = 2,
	AvgWNTScore = 3,
	KS2ScaledScore = 4,
	AvgWNTAdaptiveScore = 5,
	CAT4MeanSAS = 11,
	CAT4MeanSAS_KS5 = 16,
	MixedPATypes = 99,
}

export enum BenchmarkType {
	National = 1,
	Client = 2,
}

export enum PerformanceMeasuresTypes {
	ALevel = 1,
	IB = 2,
}

export enum RowType {
	ALL = 1,
	COMPARISON = 2,
	EMBEDDED = 3,
	FULL_WIDTH = 4,
	TOTAL = 5,
}

//ordered for display
export enum AlpsRolesId {
	HeadTeacher = 0,
	HeadOfSixth = 1,
	DataManager = 2,
	KS4Leader = 3,
	Finance = 4,
}

export enum CensusQuestionsIds {
	AlpsUsage = 0,
	OtherSystems = 1,
	MIS = 2,
	DateOfMonitoring = 3,
}

export enum CensusAuditStatus {
	Complete = 0,
	Pending = 1,
	Expired = 2,
}

export enum CensusModalStep {
	User = 1,
	Systems = 2,
	Finish = 3,
}
