import { getSlice } from '../../../../../sharedSelectors/index';
import { SortType } from '../../ks5pm/res/staticValues';

/**
 * Selectors for KS5 Performance Measures state management
 */

/**
 * Retrieves the list of available gradepoints that can be selected
 * @param state - The Redux root state
 * @returns Array of SelectOption objects representing available gradepoints
 */
export const getActiveGradepoints = (state: RootState): CommonUiTypes.SelectOption[] => {
	return state.summitPerformanceMeasuresSlice.activeGradepoints;
};

/**
 * Gets the currently selected main gradepoint for comparison
 * @param state - The Redux root state
 * @returns SelectOption object representing the main selected gradepoint
 */
export const getMainGradepoint = (state: RootState): CommonUiTypes.SelectOption => {
	return state.summitPerformanceMeasuresSlice.mainGroup;
};

/**
 * Gets the currently selected comparison gradepoint
 * @param state - The Redux root state
 * @returns SelectOption object representing the comparison gradepoint
 */
export const getComparisonGradepoint = (state: RootState): CommonUiTypes.SelectOption => {
	return state.summitPerformanceMeasuresSlice.comparisonGroup;
};

/**
 * Retrieves both comparison and main gradepoints as an array
 * @param state - The Redux root state
 * @returns Array containing both comparison and main gradepoints
 */
export const getComparisonAndMainGradepoints = (state: RootState): CommonUiTypes.SelectOption[] => {
	return [getComparisonGradepoint(state), getMainGradepoint(state)];
};

/**
 * Determines whether the Average Points Score difference chart should be displayed
 * Chart is shown only when multiple gradepoints are available and a comparison is selected
 * @param state - The Redux root state
 * @returns boolean indicating if the difference chart should be shown
 */
export const getToggleAveragePointsScoreDifferenceChart = (state: RootState): boolean => {
	const activeGradepoints = getActiveGradepoints(state);
	const comparisonGradepoint = getComparisonGradepoint(state);

	return activeGradepoints?.length > 1 && !!comparisonGradepoint;
};

/**
 * Gets the initial loading state of the performance measures
 * @param state - The Redux root state
 * @returns boolean indicating if the initial data is still loading
 */
export const getInitialLoading = (state: RootState): boolean => {
	return state.summitPerformanceMeasuresSlice.loading;
};

/**
 * Gets the graph loading state
 * @param state - The Redux root state
 * @returns boolean indicating if the graph is loading
 */
export const getGraphLoading = (page: string) => (state: RootState): boolean => {
	const slice = getSlice(page)(state);

	return slice.graphLoading;
};

/**
 * Gets the Grid loading state
 * @param state - The Redux root state
 * @returns boolean indicating if the grid is loading
 */
export const getGridLoading = (page: string) => (state: RootState): boolean => {
	const slice = getSlice(page)(state);

	return slice.loading;
};

/**
 * Gets the error state
 * @param state - The Redux root state
 * @returns string indicating the error
 */
export const getError = (page: string) => (state: RootState): string | undefined => {
	const slice = getSlice(page)(state);

	return slice.error;
};

/**
 * Gets the graph error state
 * @param state - The Redux root state
 * @returns string indicating the error
 */
export const getGraphError = (page: string) => (state: RootState): string | undefined => {
	const slice = getSlice(page)(state);

	return slice.graphError;
};

/**
 * Gets the selected outcome group
 * @param state - The Redux root state
 * @returns string indicating the selected outcome group
 */
export const getSelectedOutcomeGroup = (
	state: RootState
): CommonUiTypes.SelectOption | undefined => {
	return state.cumulativePercentage.activeOutcomeGroup;
};

/**
 * Gets the sort type
 * @param state - The Redux root state
 * @returns string indicating the sort type
 */
export const getSortType = (state: RootState): SortType => {
	return state.summitPerformanceMeasuresSlice.setSortType;
};

export const getIsKs4 = (state: RootState): boolean => {
	return state.summitPerformanceMeasuresSlice.isKs4;
};
