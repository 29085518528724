import { SagaIterator } from 'redux-saga';
import { getAlpsRoles, getQuestionResults, setCensusModalLoading } from '../users';
import { cancel, put, select, retry } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../constants';
import { uploadResultsOfCensus } from '../../../../api/contextAPI';
import { Census } from 'features/manageUsers/types';
import { CensusAuditStatus } from '../../../../types/enum';
import { setIsCensusInProgress } from 'features/app/redux/context';

/**
 * Saga which handles submitting
 */
export function* submitSagaFlow(): SagaIterator<void> {
	//Check State to for the alps Roles
	const alpsRoles: Census.AlpsRolePair[] | undefined = yield select(getAlpsRoles);

	//check state for systems questions
	const systemUsageResults: Census.AlpsOrganisationQuestions[] | undefined = yield select(
		getQuestionResults
	);

	//dispatch all to the api
	yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		uploadResultsOfCensus,
		alpsRoles ?? [],
		systemUsageResults ?? []
	);

	//set the internal modal loading
	yield put(setCensusModalLoading(false));

	yield put(setIsCensusInProgress(CensusAuditStatus.Complete));
	//bail
	yield cancel();
}
