import { createSlice, PayloadAction } from '@reduxjs/toolkit';

/**
 ** Initial State
 */
const initialState: StandardPage.State = {
	gpSelectorIsOpen: false,
	userProfileIsOpen: false,
	rtmMessagingIsOpen: false,
};

/**
 ** Standard Page Slice
 */
const standardPage = createSlice({
	name: 'standardPage',
	initialState,
	reducers: {
		toggleGpSelector(state, action: PayloadAction<boolean>) {
			return {
				...state,
				gpSelectorIsOpen: action.payload,
			};
		},
		toggleUserProfile(state, action: PayloadAction<boolean>) {
			return {
				...state,
				userProfileIsOpen: action.payload,
			};
		},
		toggleRtmMessaging(state, action: PayloadAction<boolean>) {
			return {
				...state,
				rtmMessagingIsOpen: action.payload,
			};
		},
	},
});

/**
 ** Export Actions
 */
export const { toggleGpSelector, toggleUserProfile, toggleRtmMessaging } = standardPage.actions;

/**
 ** Export Selectors
 */
export const getGpSelectorState = ({ standardPage }: RootState) => standardPage.gpSelectorIsOpen;
export const getUserProfileState = ({ standardPage }: RootState) => standardPage.userProfileIsOpen;
export const getRtmMessagingState = ({ standardPage }: RootState) =>
	standardPage.rtmMessagingIsOpen;
// Selectors to detemine if the topbar menu items should be visible

/**
 ** Export Reducers
 */
export default standardPage.reducer;
