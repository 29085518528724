import { theme } from 'basecamp';

/**
 * Gets the numeric value of the grade factoring in zero value grades such as U, Q or X, with a zero grade value as 0
 */
export const gradesToNumericValue = (outcomes: string[], grade: string): number => {
	// Lowest grade is last in outcomes, an X/Q/U should be a higher value. Also 0 indexed.
	return gradesAndZeroValueToNumericValue(outcomes, grade, 0);
};

/**
 * Gets the numeric value of the grade factoring in zero value grades such as U, Q or X, with a custom zero grade value
 */
const gradesAndZeroValueToNumericValue = (
	outcomes: string[],
	grade: string,
	zeroValueGradeAsNumerical: number
): number => {
	const zeroValueGrades = ['X', 'Q', 'U'];

	return zeroValueGrades.includes(grade) ? zeroValueGradeAsNumerical : outcomes.indexOf(grade);
};

/**
 * Calculates the diff of two grades
 * @param outcomes Current outcome set
 * @param grade Grade we're comapring to
 * @param comparisonGrade The comparison grade
 */
type Grade = {
	display: string;
	value: string;
};

export const calculateGradeDiff = (
	outcomes: string[],
	grade: Grade,
	comparisonGrade: Grade
): string => {
	/**
	 * Get the numeric value of the grade
	 */
	const actual = !grade
		? 0
		: gradesAndZeroValueToNumericValue(outcomes, grade.value, outcomes.length);
	const comparison = !comparisonGrade
		? 0
		: gradesAndZeroValueToNumericValue(outcomes, comparisonGrade.value, outcomes.length);

	/**
	 * We ignore Q grades, so if the grade or comparison is a Q grade return ''
	 * Also check for empty grades in table data
	 */
	if (
		!grade ||
		!comparisonGrade ||
		(grade && grade.value === 'Q') ||
		(comparisonGrade && comparisonGrade.value === 'Q') ||
		grade.value === '' ||
		comparisonGrade.value === ''
	) {
		return '';
	}

	/**
	 * Grades are equal so return 0
	 */
	if (actual === comparison) {
		return '0';
	}

	/**
	 * Diff is minus
	 */
	if (actual > comparison) {
		return `-${actual - comparison}`;
	}

	/**
	 * Diff is plus
	 */
	return `+${comparison - actual}`;
};

/**
 * Cell styling helper for grade diff
 */
export const handleDiffCellStyles = (params: any) => {
	if (params.value.includes('+')) {
		return {
			color: theme.colors.THERMOMETER.tRed[8],
			backgroundColor: theme.colors.THERMOMETER.tRed[2],
		};
	}

	if (params.value.includes('-')) {
		return {
			color: theme.colors.THERMOMETER.tBlue[8],
			backgroundColor: theme.colors.THERMOMETER.tBlue[2],
		};
	}

	return;
};

/**
 * Cell styling for Av Pa
 */
export const handleAvPaCellStyles = (params: any) => {
	switch (params.colDef.field) {
		case 'paKS5':
			return { background: theme.colors.KS5A.green[1] };
		case 'paKS4':
			return { background: theme.colors.KS4.darkTeal[1] };
		default:
			return { background: 'inherit' };
	}
};

/**
 * Get id / outcome for meg
 * @param meg meg target
 * @param megs megs target set
 */
export const getMegIdOutcome = (meg: string, megs: StudentModal.Target[]) => {
	const matched = megs.find((m: StudentModal.Target) => m.Outcome === meg);

	return {
		display: matched?.Outcome,
		value: matched?.Target,
		order: matched?.Order,
	};
};

/**
 * Get display value
 * @param outcome outcome
 * @params outcomes set
 */
export const getDisplayValue = (
	outcome: string | undefined,
	outcomes: StudentModal.AllOutcomesWithFineGrades[]
) => {
	return outcomes.find((o: StudentModal.AllOutcomesWithFineGrades) => o.display === outcome);
};

/**
 * Gradepoint renderer
 */
export const GradepointRenderer = (params: any) => {
	return !params ||
		!params.value ||
		params.value.display === undefined ||
		params.value.display === null
		? '-'
		: params.value.display;
};

/**
 * Personalised Targets renderer
 */
export const PTRenderer = (params: any) => {
	return !params || !params.data || params.data.pt === undefined || params.data.pt === null
		? '-'
		: params.data.pt;
};

/**
 * HML renderer
 */
export const HMLRenderer = (params: any) => {
	return params.value == 'High' || params.value == 'Mid' || params.value == 'Low'
		? params.value + ' PA'
		: params.value;
};

/**
 * Excel styles
 */
export const excelStyles = [
	{
		id: 'Name',
		dataType: 'string',
	},
	{
		id: 'TeachingSet',
		dataType: 'string',
	},
	{
		id: 'paKS4',
		dataType: 'string',
		interior: {
			color: theme.colors.KS4.darkTeal[1],
			pattern: 'Solid',
		},
	},
	{
		id: 'paKS5',
		dataType: 'string',
		interior: {
			color: theme.colors.KS5A.green[1],
			pattern: 'Solid',
		},
	},
	{
		id: 'Meg',
		dataType: 'string',
	},
	{
		id: 'PT',
		dataType: 'string',
	},
	{
		id: 'TrendLine',
		dataType: 'string',
	},
	{
		id: 'Comparison',
		dataType: 'string',
	},
	{
		id: 'SelectedComparison',
		dataType: 'string',
		interior: {
			color: theme.colors.UI.secondary[1],
			pattern: 'Solid',
		},
	},
	{
		id: 'Diff',
		dataType: 'string',
	},
	{
		id: 'DiffNegative',
		dataType: 'string',
		interior: {
			color: theme.colors.THERMOMETER.tBlue[2],
			pattern: 'Solid',
		},
		font: {
			color: theme.colors.THERMOMETER.tBlue[8],
		},
	},
	{
		id: 'DiffPositive',
		dataType: 'string',
		interior: {
			color: theme.colors.THERMOMETER.tRed[2],
			pattern: 'Solid',
		},
		font: {
			color: theme.colors.THERMOMETER.tRed[8],
		},
	},
];

/**
 * Handle excel diff cell styling
 * @param params
 */
export const excelDiffCellRules = (params: any) => {
	if (params.value.includes('+')) {
		return 'DiffPositive';
	}

	if (params.value.includes('-') && params.value !== '-') {
		return 'DiffNegative';
	}

	return 'Diff';
};

/**
 * Handle excel comparison cell styling
 * @param props params, selected comaprison
 */
export const excelComparisonCellRules = (props: { params: any; selected: string; pgp: string }) => {
	const { params, selected, pgp } = props;

	if (params.colDef.field === `gp${selected}` || params.colDef.field === `gp${pgp}`) {
		return 'SelectedComparison';
	}

	return 'Comparison';
};

/** Handles sorting of gps.
 * @param current The current value
 * @param next The next value
 */
export const handleGpSorting = (current: { order: number }, next: { order: number }) => {
	if (!current) {
		return 1;
	}

	if (!next) {
		return -1;
	}

	return current.order - next.order;
};

export const handlePtSorting = (current: { order: number }, next: { order: number }) => {
	if (!current) {
		return 1;
	}

	if (!next) {
		return -1;
	}

	return current.order - next.order;
};

/** Handles sorting of gps.
 * @param current The current value
 * @param next The next value
 */
export const handleMegSorting = (current: { order: number }, next: { order: number }) => {
	if (!current) {
		return 1;
	}

	if (!next) {
		return -1;
	}

	return next.order - current.order;
};

/**
 * Handles number sorting with types to prevent unwanted string sorting.
 * @param current The current numeric value
 * @param next The next numeric value
 */
export const handleNumberSorting = (a: string, b: string) => {
	const current = a === '' ? Number.NEGATIVE_INFINITY : Number(a);
	const next = b === '' ? Number.NEGATIVE_INFINITY : Number(b);

	return current - next;
};

/**
 * Gets a style for the what if cell background
 * @param whatIfValueIsGrade
 * @param isInvalidWhatIfGrade
 * @returns
 */
export const getWhatIfBgColour = (
	whatIfValueIsGrade: boolean,
	isInvalidWhatIfGrade: boolean
): string => {
	return isInvalidWhatIfGrade
		? 'status.warning.0'
		: whatIfValueIsGrade
		? 'transparent'
		: 'UI.accent.0';
};
