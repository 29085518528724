/**
 * @fileoverview Module containing hook for managing reporting season navigation routes
 */

import { translate } from '../../../utils/locale';

/**
 * Hook that returns the navigation routes for reporting season section
 * @returns {SecondaryNavigationLinks[]} Array of reporting season navigation links
 */
const useReportingSeasonRoutes = (): SecondaryNavigationLinks[] => {
	return [
		{
			isVisible: true,
			withInternalUrl: `/reporting-season/client-info`,
			icon: 'chart-line',
			displayName: translate('reportingSeason.nav.sidebar.CLIENT_INFO') as string,
		},
	];
};

export default useReportingSeasonRoutes;
