import { AgChartThemeName, CellClassParams, ExcelStyle } from 'ag-grid-community';
import normalizeKey from '../../../utils/normalizeKey';
import _ from 'lodash';
import { dataVisColours } from '../../../resources/dataVisTheme';

/**
 * Charts
 */
export const customChartThemes = {
	myCustomTheme: {
		baseTheme: 'ag-default' as AgChartThemeName,
		palette: {
			fills: dataVisColours,
			strokes: dataVisColours,
		},
		overrides: {
			common: {
				title: {
					fontSize: 22,
					fontFamily: 'open_sans_regular, sans-serif',
				},
			},
		},
	},
};

/**
 * Excel Styling
 */
export const excelStyles: ExcelStyle[] = [
	{
		id: 'header',
		interior: {
			color: '#aaaaaa',
			pattern: 'Solid',
		},
	},
	{
		id: 'body',
		interior: {
			color: '#dddddd',
			pattern: 'Solid',
		},
	},
];

export const fakeAlpsGradeColors = (params: CellClassParams) => {
	const above = params.value > 5;
	const below = params.value < 3;

	return below ? 'alps-grade-blue' : above ? 'alps-grade-red' : 'alps-grade-transparent';
};

export const fakeAlpsScoreColors = (params: CellClassParams) => {
	const above = params.value === '1.00';
	const below = params.value === '0.00';

	return below ? 'alps-grade-blue' : above ? 'alps-grade-red' : 'alps-grade-transparent';
};

/**
 * Flatten data coming from the API
 * @param response Raw grid data from redux
 * @returns Response data with gridValues obj flattened
 */
export const flattenResponse = (
	response: Ks5PerformanceMeasures.Rows<Ks5PerformanceMeasures.ResponseRow>
) => {
	return {
		id: response.id,
		byId: Object.entries(response.byId).reduce((acc, [key, val]) => {
			// Flatten the gridValues object and normalise keys
			const flattenGridValues = Object.entries(val.gridValues).reduce((acc, [key, val]) => {
				return {
					...acc,
					[normalizeKey(key)]: val,
				};
			}, {});
			// Clean up after ourselves and remove the unused obj
			const { gridValues: _, ...removeGridValuesObj } = val;

			return {
				...acc,
				[key]: Object.assign(flattenGridValues, { ...removeGridValuesObj }),
			};
		}, {}),
		allIds: response.allIds,
	};
};

/**
 * Util to compare equality of applied / selected object arrays
 * @param arr1 First array
 * @param arr2 Second array
 * @returns boolean
 */
export const appliedAndSelectedAreEqual = <T>(arr1: T[], arr2: T[]) =>
	_(arr1).xorWith(arr2, _.isEqual).isEmpty();

/**
 * Takes a comparison option and makes a key value pair.
 * Useful when you do not want the pipe in the comparison for display purposes
 * @param comparisons The comparison option to break down into key value pair
 * @returns A key value pair of the selected comparison option
 */
export const GetKeyValueFromComparisonOption = (
	comparisons: Ks5PerformanceMeasures.ComparisonOption
) => {
	const comparisonsKeyValue = comparisons.reduce(
		(acc, curr) => {
			const keyVal = curr.value.split('|');
			return [
				//@ts-ignore
				...acc,
				{
					Key: keyVal[0],
					Value: keyVal[1],
				},
			];
		},
		[] as {
			Key: string;
			Value: string;
		}[]
	);

	return comparisonsKeyValue.map((comparison) => {
		if (comparison.Value === 'Non-Disadvantaged') {
			return {
				...comparison,
				Value: 'No',
			};
		}

		if (comparison.Value === 'Disadvantaged') {
			return {
				...comparison,
				Value: 'Yes',
			};
		}

		return {
			...comparison,
			Value: comparison.Value,
		};
	});
};
