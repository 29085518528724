import * as React from 'react';
import { FunctionComponent, useContext, useEffect, useState } from 'react';
import { translate } from '../../../utils/locale';

import { Flex, Heading, Text, Label, Checkbox, Select, Switch, Icon } from 'basecamp';

import { ManageUsers } from '../types';

type Props = {
	data: ManageUsers.Contract;
	display: any;
	i: number;
};

const DataSharingToggle: FunctionComponent<Props> = ({ data, display, i }) => {
	return (
		<Flex
			setAs="column"
			//withAlign="distribute"
			borderRadius={3}
			border={3}
			bg={'UI.secondary.1'}
			p={2}
			mt={3}
			mb={3}
			borderColor={data.allowed ? 'STATUS.success.0' : 'STATUS.warning.0'}
			boxShadow={'xl'}
		>
			<Flex
				bg={data.allowed ? 'STATUS.success.0' : 'STATUS.warning.0'}
				position="absolute"
				top={-22}
				right={20}
				borderRadius={5}
				withAlign="center"
				py={2}
				px={3}
			>
				<Text m={0} setAs="h5" withColor="white">
					{data.allowed
						? translate('manageAccount.pages.dataSharing.page.CONSENT')
						: translate('manageAccount.pages.dataSharing.page.NO_CONSENT')}
				</Text>
			</Flex>

			<Flex setAs="row" mb={1}>
				<Flex key={i} borderRadius={2} p={3} withAlign="distribute">
					<Label mr={6}>{data.contractName}</Label>

					{display}
				</Flex>
			</Flex>
		</Flex>
	);
};

export default DataSharingToggle;
