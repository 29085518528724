import * as React from 'react';
import { FunctionComponent } from 'react';

import { Page, Content } from 'basecamp';

type Props = {
	dataTest?: string;
	filterBar?: React.ReactNode;
	preventScroll?: boolean;
};

const PageWithoutSidebar: FunctionComponent<Props> = (props) => {
	/**
	 ** Render Component
	 */
	return (
		<Page dataTest={props.dataTest} overflow="hidden">
			{props.filterBar && props.filterBar}

			<Content
				id="content"
				dataTest={props.dataTest}
				height={props.filterBar ? 'calc(100vh - 279px)' : 'calc(100vh - 164px)'}
				pt={0}
				pb={4}
				overflowY={props.preventScroll ? 'hidden' : 'auto'}
				isFullWidth
				zIndex={9}
				position="relative"
				px={0}
			>
				{props.children}
			</Content>
		</Page>
	);
};

export default PageWithoutSidebar;
