/**
 * @module useManageUserRoutes
 * @description Module containing hook for managing user navigation routes
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { getFeature } from 'features/app/redux/features';
import { getClient } from 'features/app/redux/context';
import { getUserRoles } from 'features/app/redux/context';
import { knowledgeBaseRoutes } from '../../common/knowledgeBaseRoutes';

/**
 * Hook that returns the navigation routes for managing users
 * @returns {Object} Navigation route configuration object
 * @property {SecondaryNavigationLinks[]} manageUserRoutes - Array of user management navigation links
 * @property {SecondaryNavigationLinks} homeRoutes - Home navigation configuration for user management
 */
const useManageUserRoutes = (): {
	manageUserRoutes: SecondaryNavigationLinks[];
	manageUserHomeRoutes: SecondaryNavigationLinks;
} => {
	/** Current user permissions */
	const permissions: string[] | undefined = useSelector(getUserRoles);

	/** Array of user management navigation route configurations */
	const manageUserRoutes: SecondaryNavigationLinks[] = [
		{
			isVisible: true,
			withInternalUrl: `/manage-users/all-users`,
			icon: 'users',
			displayName: translate('home.singleSchool.manageUsers.links.ALL_USERS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `/manage-users/add-user`,
			icon: 'user-plus',
			displayName: translate('home.singleSchool.manageUsers.links.ADD_USER') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `/manage-users/bulk-add-users`,
			icon: 'users-medical',
			displayName: translate('home.singleSchool.manageUsers.links.BULK_ADD_USERS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `/manage-users/deactivated-user-accounts`,
			icon: 'users-slash',
			displayName: translate('home.singleSchool.manageUsers.links.DISABLED_USERS') as string,
		},
	];

	/** Home navigation route configuration for user management */
	const manageUserHomeRoutes = {
		displayName: translate('home.singleSchool.manageUsers.HEADING') as string,
		isVisible:
			permissions?.length === 0 ? false : permissions?.includes('Settings_Users', 0) ? true : false,
		icon: 'users',
		flyoutContent: [
			{
				heading: 'User Administration',
				flyoutLinks: manageUserRoutes,
			},
			knowledgeBaseRoutes,
		],
	};

	return {
		manageUserRoutes,
		manageUserHomeRoutes,
	};
};

export default useManageUserRoutes;
