import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getUser, getClient } from 'features/app/redux/context';

import { HeaderLink } from 'basecamp';

// TODO - import from connect utils in prod
import * as impersonationUtils from '../../../../utils/impersonation';
import { styles } from './common';

/**
 * @typedef {Object} Props
 * @property {() => void} toggleMenu - Function to toggle the user profile menu
 * @property {boolean} isSmallScreen - Whether the screen is small
 */
type Props = {
	toggleMenu: () => void;
	isSmallScreen: boolean;
	setSmallScreenMenuIsOpen: (isOpen: boolean) => void;
};

const UserProfileMenuBtn: FunctionComponent<Props> = ({
	toggleMenu,
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	/**
	 ** Handle Selectors
	 */
	// Get user information
	const user = useSelector(getUser);
	// Get client information
	const client = useSelector(getClient);
	// Get client impersonation type
	const isImpostor = user ? impersonationUtils.isImpostor(user.impersonationMode) : false;

	/**
	 ** Render Component
	 */
	return (
		<HeaderLink
			dataTest="userProfileBtn"
			buttonText={client?.name}
			lozengeIcon={isImpostor ? 'user-secret' : 'user'}
			onClick={() => {
				toggleMenu();
				setSmallScreenMenuIsOpen(false);
			}}
			{...styles(isSmallScreen)}
		/>
	);
};

export default UserProfileMenuBtn;
