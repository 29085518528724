/**
 * @fileoverview Hook for managing menu components in the standard page layout
 * @module features/layouts/standardPage/hooks/useMenuComponents
 */

import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWindowSize, useResponsiveMenu } from '../../../utils/hooks';
import ZohoHelpBtn from 'features/layouts/standardPage/components/zohoHelpBtn';
import {
	ClientBenchmarkActiveWarning,
	GradepointSelectorButton,
	GroupTrendIndicator,
	StrategicAnalysisIndicator,
	RtmMessagingBtn,
	UserProfileMenuBtn,
} from 'features/layouts/standardPage/components';

import {
	getUserProfileState,
	toggleGpSelector,
	toggleUserProfile,
	toggleRtmMessaging,
} from 'features/layouts/standardPage/redux/standardPage';
import { getRtmMessages } from 'features/rtmMessaging/redux';
import { getConfig, getFeature } from 'features/app/redux/features';
import {
	getAllGradepoints,
	getBenchmarkSetCountryFromNationDataSetBenchmark,
	getSubscriptionStatus,
} from 'features/app/redux/context';
import { getAllGroupTrends, getSelectedTrend } from 'features/groups/analysis/redux/selectors';
import { getGroupAccessRecords } from 'features/groups/setup/redux/selectors';
import { hasActiveAnalysisSession } from 'features/strategicAnalysis/redux';

/**
 * Props for the useMenuComponents hook
 * @typedef {Object} Props
 * @property {Groups.Setup.Trend | undefined} selectedTrend - Currently selected trend
 * @property {() => void} handleRtmMessaging - Handler for RTM messaging toggle
 * @property {boolean} isGroup - Whether the current context is a group
 */
type Props = {
	selectedTrend: Groups.Setup.Trend | undefined;
	handleRtmMessaging: () => void;
	isGroup: boolean;
	setSmallScreenMenuIsOpen: (isOpen: boolean) => void;
};

/**
 * Hook that manages the menu components for the standard page layout
 * @param {Props} props - Component props
 * @returns {Object} Object containing large and small screen menu configurations
 */
const useTopBarMenuComponents = ({
	selectedTrend,
	handleRtmMessaging,
	isGroup,
	setSmallScreenMenuIsOpen,
}: Props) => {
	const dispatch = useDispatch();
	const rtmMessages = useSelector(getRtmMessages);
	const userProfileMenuIsOpen = useSelector(getUserProfileState);
	const canViewZohoHelp = useSelector(getFeature('zoho_help'));
	const summitBenchmark = useSelector(getFeature('summit_benchmark'));
	const connectBenchmark = useSelector(getFeature('client_benchmark'));
	const avaliableBenchmarks = useSelector(getConfig('avaliable_benchmarks'));
	const currentBenchmark = useSelector(getBenchmarkSetCountryFromNationDataSetBenchmark);
	const correctContextBenchmarkFlag = isGroup ? summitBenchmark : connectBenchmark;
	const isBenchmarkAllowed = avaliableBenchmarks?.includes(currentBenchmark);
	const gradepoints = useSelector(getAllGradepoints);
	const trends = useSelector(getAllGroupTrends);
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	const selectedTrendId = useSelector(getSelectedTrend);
	const accessRecords = useSelector(getGroupAccessRecords);
	const hasActiveSession = useSelector(hasActiveAnalysisSession);
	const canViewStrategicAnalysis = useSelector(getFeature('strategic_analysis'));

	const getAccessRecord =
		accessRecords &&
		!!selectedTrend &&
		!!selectedTrendId?.groupAccessId &&
		accessRecords?.find((record) => record.id === selectedTrendId.groupAccessId);

	const unreadMsgs = rtmMessages.length;

	/**
	 * Array of menu component configurations
	 * @type {Array<{name: string, width: number, isVisible: boolean, menuTransferOrder: number, component: JSX.Element}>}
	 */
	const topBarMenuComponents = [
		{
			name: 'zohoHelp',
			width: 180,
			isVisible: canViewZohoHelp,
			menuTransferOrder: 1,
			component: <ZohoHelpBtn isSmallScreen={true} />,
		},
		{
			name: 'benchmarkWarning',
			width: 264,
			isVisible: correctContextBenchmarkFlag && isBenchmarkAllowed,
			menuTransferOrder: 6,
			component: (
				<ClientBenchmarkActiveWarning
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={() => setSmallScreenMenuIsOpen(false)}
				/>
			),
		},
		{
			name: 'gradepointSelector',
			width: 300,
			isVisible:
				!!subscriptionStatus.hasValidSubscriptionOrContract &&
				(gradepoints.length > 0 || trends.length > 0),
			menuTransferOrder: 7,
			component: (
				<GradepointSelectorButton
					selectedTrend={selectedTrend}
					toggleGradepointSelector={() => {
						dispatch(toggleUserProfile(false));
						dispatch(toggleRtmMessaging(false));
						dispatch(toggleGpSelector(true));
					}}
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={() => setSmallScreenMenuIsOpen(false)}
				/>
			),
		},
		{
			name: 'groupTrendIndicator',
			width: 236,
			isVisible:
				!!subscriptionStatus.hasValidSubscriptionOrContract &&
				trends &&
				trends.length > 0 &&
				getAccessRecord,
			menuTransferOrder: 5,
			component: (
				<GroupTrendIndicator
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={() => setSmallScreenMenuIsOpen(false)}
				/>
			),
		},
		{
			name: 'strategicAnalysisIndicator',
			width: 304,
			isVisible: hasActiveSession && canViewStrategicAnalysis,
			menuTransferOrder: 4,
			component: (
				<StrategicAnalysisIndicator
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={() => setSmallScreenMenuIsOpen(false)}
				/>
			),
		},
		{
			name: 'rtmMessagingBtn',
			width: 64,
			isVisible: true,
			menuTransferOrder: 2,
			component: (
				<RtmMessagingBtn
					unreadMsgs={unreadMsgs}
					toggleRtmMessaging={() => handleRtmMessaging()}
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={() => setSmallScreenMenuIsOpen(false)}
				/>
			),
		},
		{
			name: 'userProfileMenuBtn',
			width: 200,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<UserProfileMenuBtn
					toggleMenu={() => {
						dispatch(toggleUserProfile(!userProfileMenuIsOpen));
						dispatch(toggleRtmMessaging(false));
						dispatch(toggleGpSelector(false));
					}}
					isSmallScreen={true}
					setSmallScreenMenuIsOpen={setSmallScreenMenuIsOpen}
				/>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses topBarMenuComponents array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(topBarMenuComponents, width, 180, [
		correctContextBenchmarkFlag,
		isBenchmarkAllowed,
		trends,
		getAccessRecord,
		hasActiveSession,
		canViewStrategicAnalysis,
		subscriptionStatus,
	]);

	// Return menu components for use in the top bar
	return { largeScreenMenu, smallScreenMenu };
};

export default useTopBarMenuComponents;
