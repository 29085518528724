/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { useRouteMatch } from 'react-router-dom';
import { translate } from '../../../utils/locale';
import { getFeature } from 'features/app/redux/features';
import { useSelector } from 'react-redux';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useSubjectsNavLinks = () => {
	const { params }: { params: PrimaryNavigation.RouteParams } = useRouteMatch();

	/**
	 * Check if the user has access to the subject area attainment page
	 */
	const canViewSubjectAreaAttainmentPage: boolean = useSelector(
		getFeature('subject_area_attainment_profile')
	);

	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const subjectsNavLinks = [
		{
			name: 'subjectOverview',
			width: 110,
			isVisible: true,
			menuTransferOrder: 7,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-overview-link`}
					withInternalUrl={`/subjects/${params.subject}/overview`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.OVERVIEW')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectAttainment',
			width: 110,
			isVisible: canViewSubjectAreaAttainmentPage,
			menuTransferOrder: 6,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-attainment-link`}
					withInternalUrl={`/subjects/${params.subject}/AttainmentProfile`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.ATTAINMENT')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectStudents',
			width: 110,
			isVisible: true,
			menuTransferOrder: 5,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-students-link`}
					withInternalUrl={`/subjects/${params.subject}/students`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.STUDENTS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectOutcomes',
			width: 110,
			isVisible: true,
			menuTransferOrder: 4,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-outcomes-link`}
					withInternalUrl={`/subjects/${params.subject}/outcomes`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.OUTCOMES')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectTrends',
			width: 110,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-trends-link`}
					withInternalUrl={`/subjects/${params.subject}/trends`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.TRENDS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectFineGrades',
			width: 110,
			isVisible: true,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-fine-grades-link`}
					withInternalUrl={`/subjects/${params.subject}/fine-grades`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.FINE_GRADES')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectSaved',
			width: 110,
			isVisible: true,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-subject-saved-link`}
					withInternalUrl={`/subjects/${params.subject}/saved`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.subjects.SAVED')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses subjectsNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(subjectsNavLinks, width, 0, []);

	return { largeScreenMenu, smallScreenMenu };
};

export default useSubjectsNavLinks;
