import * as React from 'react';
import { useState } from 'react';
import { FunctionComponent, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Flex, Label, Select, Input, IconButton, Modal, Switch, Box, Heading } from 'basecamp';
import {
	ValueRenderer,
	GroupRenderer,
	OptionWithAddAll,
	MenuRenderer,
} from '../../../select/renderers';
import { SPOContext } from '../spo';
import { translate } from '../../../utils/locale';
import {
	getAvailableStudentFilters,
	getAvailableStudentComparisons,
	getClient,
	getPrimaryGradepoint,
} from '../../app/redux/context';
import { getSubjects, getTutorGroups, isLoading as loading, setLoading } from '../redux';
import { DEFAULT_FILENAME } from '../../../utils/fileExports';
import { fetchSpoExport } from '../../../api/spoAPI';
import { getComparisonOptions } from 'features/analysisSettings/redux';

import { useWindowSize, useFilterBar } from '../../../utils/hooks';
import { MoreActions, ConditionalWrapper, studentAnalysisConfig } from 'features/filterBar';
import { ReportHandlerModal } from '../../../components';
import { getFeature } from 'features/app/redux/features';
import { fetchPDF } from '../../../api/reportAPI';

const FilterBarOverview: FunctionComponent = () => {
	/**
	 ** Context
	 * Allows get / set subjects overview state
	 */
	const {
		search,
		setSearch,
		showCAT4,
		setShowCAT4,
		showStatusToggle,
		togglePts,
		setTogglePts,
		filters,
		setFilters,
		comparisons,
		setComparisons,
		focusOn,
		setFocusOn,
		tutorGroup,
		setTutorGroup,
	} = useContext<SPO.Context>(SPOContext);

	const dispatch = useDispatch();
	const [showExcelDownloadModal, setShowExcelDownloadModal] = useState<boolean>(false);
	const [showPdfRequestedModal, setShowPdfRequestedModal] = useState<boolean>(false);
	const [showPDFDownloadOptionsModal, setShowPDFDownloadOptionsModal] = useState<boolean>(false);

	const [excelFilename, setExcelFilename] = useState<string>('');
	const [pdfFileName, setPdfFileName] = useState<string>('');

	/**
	 ** Set options using custom option renderer
	 * this allows adding of all grouped items in one hit
	 */
	const clientId = useSelector(getClient)?.id;
	const customComparisons = useSelector(getComparisonOptions('Student', clientId));
	const comparisonGroups = useSelector(getAvailableStudentComparisons);

	const statusToggleFf = useSelector(getFeature('show_status_toggle'));

	const comparisonOptions = [
		...comparisonGroups.map((filter) => {
			return OptionWithAddAll(filter.label, filter.options, comparisons, (options) =>
				dispatch(setComparisons(options))
			);
		}),
		customComparisons,
	];

	const tutorGroups = useSelector(getTutorGroups);
	const subjectOptions = useSelector(getSubjects);
	const filterOptions = useSelector(getAvailableStudentFilters);
	const isLoading = useSelector(loading);

	const downloadExcelReport = () => {
		dispatch(setLoading(true));
		setShowExcelDownloadModal(false);
		const excelExportName = excelFilename;
		setExcelFilename('');
		fetchSpoExport({
			filename: excelExportName,
			searchString: search,
			focusOnSubject: focusOn?.value as string,
			tutorGroup: tutorGroup ? decodeURIComponent(tutorGroup) : undefined,
			useNewExportAPI: true,
			usePtValues: togglePts,
		}).then(() => dispatch(setLoading(false)));
	};

	/**
	 * Menu config
	 */
	const [moreActionsIsOpen, setMoreActionsIsOpen] = useState<boolean>(false);
	const winWidth = useWindowSize().width;
	const config = studentAnalysisConfig();
	const filterBar = useFilterBar(Object.entries(config), winWidth, 0, []);
	const primaryGradepoint = useSelector(getPrimaryGradepoint);

	const downloadPDFReport = () => {
		dispatch(setLoading(true));
		setShowPDFDownloadOptionsModal(false);

		fetchPDF(
			'Interactive.StudentPostcard',
			'Student',
			{
				filename: pdfFileName,
				formatting: 'Connect/StudentPostcards',
				searchString: search,
				focusOnSubject: focusOn?.value as string,
				filters: tutorGroup
					? [{ type: 'StudentEntry', name: 'Tutor Group', value: decodeURIComponent(tutorGroup) }]
					: undefined,
				gradepoints: [primaryGradepoint],
			},
			true,
			true,
			true,
			togglePts
		).then(() => dispatch(setLoading(false)));

		setShowPdfRequestedModal(true);
	};

	/**
	 * Filters and controls
	 */
	const FilterBarElements = (menu: string[], isMoreActions: boolean = false) => {
		const mb = isMoreActions && filterBar.moreActions.length > 1 ? 4 : 0;
		return (
			<React.Fragment>
				{/**
				 * Conditional wrapper containing filter bar elements excluding downloads
				 * These will be pushed to the left on larger screens
				 **/}
				<ConditionalWrapper
					condition={!isMoreActions && filterBar.moreActions.length === 0}
					wrapper={(children) => <Flex withAlign="distribute">{children}</Flex>}
				>
					<React.Fragment>
						{/* Search Subjects */}
						{menu.includes('search') && (
							<Flex
								dataTest="search"
								withAlign="centerLeft"
								minWidth={config.search}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.SEARCH')}</Label>
								{/* @ts-ignore TODO this error sort in Basecamp*/}
								<Input
									disabled={isLoading}
									placeholder={translate('spo.filterBar.SEARCH_PLACEHOLDER') as string}
									value={search}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value)}
								/>
							</Flex>
						)}

						{/* Tutor Group */}
						{menu.includes('tutorGroup') && (
							<Flex
								dataTest="tutorGroup"
								withAlign="centerLeft"
								minWidth={config.tutorGroup}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.TUTOR_GROUP')}</Label>
								<Select
									isDisabled={isLoading}
									options={tutorGroups}
									withWidth="100%"
									value={
										tutorGroup ? { value: tutorGroup, label: decodeURIComponent(tutorGroup) } : null
									}
									isClearable={true}
									onChange={(option: SPO.Option) => setTutorGroup(option?.value as string)}
								/>
							</Flex>
						)}

						{/* Focus On */}
						{menu.includes('focusOn') && (
							<Flex
								dataTest="focusOn"
								withAlign="centerLeft"
								minWidth={config.focusOn}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.FOCUS_ON')}</Label>
								<Select
									isDisabled={isLoading}
									options={subjectOptions}
									withWidth="100%"
									value={focusOn}
									isClearable={true}
									onChange={(option: SPO.Option) => setFocusOn(option)}
								/>
							</Flex>
						)}

						{/* Apply Filters */}
						{menu.includes('applyFilters') && (
							<Flex
								dataTest="applyFilters"
								withAlign="centerLeft"
								minWidth={config.applyFilters}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.APPLY_FILTERS')}</Label>
								<Select
									isDisabled={isLoading}
									options={filterOptions}
									withWidth="100%"
									withComponents={{
										ValueContainer: ValueRenderer,
										GroupHeading: GroupRenderer,
									}}
									isMulti
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									value={filters}
									onChange={(option: SPO.Option[]) => dispatch(setFilters(option))}
								/>
							</Flex>
						)}

						{/* Comparison Filters */}
						{menu.includes('applyComparisons') && (
							<Flex
								dataTest="applyComparisons"
								withAlign="centerLeft"
								minWidth={config.applyComparisons}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.APPLY_COMPARISONS')}</Label>
								<Select
									isDisabled={isLoading}
									options={comparisonOptions}
									withWidth="100%"
									withComponents={{
										GroupHeading: GroupRenderer,
										ValueContainer: ValueRenderer,
										Menu: MenuRenderer,
									}}
									isMulti
									closeMenuOnSelect={false}
									hideSelectedOptions={false}
									withInternalUrl="/analysis-settings/my-comparisons"
									value={comparisons}
									onChange={(option: SPO.Option[]) => dispatch(setComparisons(option))}
								/>
							</Flex>
						)}

						{/* Show CAT 4 */}
						{menu.includes('showCat4') && (
							<Flex
								dataTest="showCat4"
								withAlign="centerLeft"
								minWidth={config.showCat4}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{'Show CAT4'}</Label>
								<Flex withAlign="centerLeft" height="40px">
									<Switch isOn={showCAT4} setAs="primary" onClick={() => setShowCAT4(!showCAT4)} />
								</Flex>
							</Flex>
						)}

						{/** Toggle PT and MEG status **/}
						{menu.includes('toggleStatus') && showStatusToggle && statusToggleFf && (
							<Flex
								dataTest="toggleStatus"
								withAlign="centerLeft"
								minWidth={config.toggleStatus}
								mr={4}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.STATUS_TOGGLE_MEG')}</Label>
								<Flex withAlign="centerLeft" height="40px">
									<Switch
										isOn={togglePts}
										onClick={() => setTogglePts(!togglePts)}
										setAs="primary"
									/>
								</Flex>
							</Flex>
						)}
					</React.Fragment>
				</ConditionalWrapper>

				{/**
				 * Conditional wrapper containing downloads.
				 * These will be pushed to right on larger screens
				 **/}
				<ConditionalWrapper
					condition={!isMoreActions && filterBar.moreActions.length === 0}
					wrapper={(children) => <Flex withAlign="distribute">{children}</Flex>}
				>
					<React.Fragment>
						{/* Download Excel */}
						{menu.includes('downloadXlxs') && (
							<Flex
								dataTest="downloadXlxs"
								withAlign="centerLeft"
								minWidth={config.downloadXlxs}
								mr={isMoreActions ? 4 : 0}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('spo.filterBar.DOWNLOADS')}</Label>
								<IconButton
									withIcon="file-excel"
									disabled={isLoading}
									setAs="accent"
									onClick={() => setShowExcelDownloadModal(true)}
								/>
							</Flex>
						)}

						{/* Download PDF */}
						{menu.includes('downloadPdf') && (
							<Flex
								dataTest="downloadPdf"
								withAlign="centerLeft"
								minWidth={config.downloadPdf}
								mr={isMoreActions ? 4 : 0}
								mb={mb}
								setAs="column"
							>
								<Label>{translate('subjectsOverview.filterBar.tooltips.DOWNLOAD_PDF')}</Label>
								<IconButton
									withIcon="file-pdf"
									disabled={isLoading}
									setAs="accent"
									onClick={() => setShowPDFDownloadOptionsModal(true)}
								/>
							</Flex>
						)}
					</React.Fragment>
				</ConditionalWrapper>
			</React.Fragment>
		);
	};

	/**
	 ** Render Component
	 */
	return (
		<React.Fragment>
			{/* Excel downloads modal */}
			{showExcelDownloadModal && (
				<Modal
					setAs={'medium'}
					withTitle={translate('exportModal.TITLE')}
					withCancelText={translate('exportModal.CANCEL')}
					withActions={[
						{
							type: 'accent',
							title: translate('exportModal.download.XLSX'),
							onClick: downloadExcelReport,
						},
					]}
					close={() => setShowExcelDownloadModal(false)}
				>
					<Label>{translate('exportModal.FILENAME')}</Label>
					{/* @ts-ignore TODO this error sort in Basecamp*/}
					<Input
						placeholder={DEFAULT_FILENAME}
						value={excelFilename}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setExcelFilename(e.target.value)}
					/>
				</Modal>
			)}

			{showPDFDownloadOptionsModal && (
				<Modal
					setAs={'small'}
					withTitle={translate('exportModal.TITLE')}
					withCancelText={translate('exportModal.CANCEL')}
					withActions={[
						{
							title: translate('exportModal.download.PDF'),
							type: 'accent',
							onClick: downloadPDFReport,
						},
					]}
					close={() => setShowPDFDownloadOptionsModal(false)}
				>
					<Box mt={2}>
						<Heading setAs={'small'}>{translate('exportModal.FILENAME')}</Heading>
						{/* @ts-ignore TODO this error sort in Basecamp*/}
						<Input
							placeholder={DEFAULT_FILENAME}
							value={pdfFileName}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPdfFileName(e.target.value)}
						/>
					</Box>
				</Modal>
			)}

			{showPdfRequestedModal && (
				<ReportHandlerModal
					click={() => {
						setShowPdfRequestedModal(false);
					}}
					data={() => downloadPDFReport}
				/>
			)}

			{/* Filter bar / more actions */}
			<Flex
				setAs="row"
				withAlign="distribute"
				zIndex={10}
				px={4}
				py={4}
				bg="UI.white"
				width={1}
				maxHeight="115px"
			>
				{/* Standard filter bar elements */}
				<Flex width={1}>{FilterBarElements(filterBar.filterBar)}</Flex>

				{/* Show more actions toggle and menu if we cannot fit the filter bar elements within the window width */}
				{filterBar.moreActions.length > 0 && !isLoading && (
					<MoreActions
						onMouseEnter={() => setMoreActionsIsOpen(true)}
						onMouseLeave={() => setMoreActionsIsOpen(false)}
						moreActionsIsOpen={moreActionsIsOpen}
					>
						{FilterBarElements(filterBar.moreActions, filterBar.moreActions.length > 0)}
					</MoreActions>
				)}
			</Flex>
		</React.Fragment>
	);
};

export default FilterBarOverview;
