import { takeLatest, throttle } from 'redux-saga/effects';
import { completeCensusFlow } from './completeCensusSaga';
import { submitSagaFlow } from './submitCensusSaga';

/**
 * Export all Manage Users Sagas in one array
 * This is imported into a root saga in the store.
 * Root saga is effectively the same as the root reducer.
 */
export const manageUsersSagas = [
	//Flows triggered by specifc Actions
	takeLatest('context/triggerCensus', completeCensusFlow),
	takeLatest('user/submitCensus', submitSagaFlow),

	//Flows triggered by other flows automatically - currently will max refire once per second
	throttle(1000, 'user/retriggerCensus', completeCensusFlow),
];
