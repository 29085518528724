import * as React from 'react';
import { FunctionComponent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, getClient, logoutThunk } from 'features/app/redux/context';
import { translate } from '../../../../utils/locale';

import { UserProfileDropdown, SecondaryNavLink } from 'basecamp';

// TODO - import from connect utils in prod

import { endMATViewAs } from '../../../../utils/impersonation';
import * as impersonationUtils from '../../../../utils/impersonation';

/**
 ** Props
 */
type Props = {
	close: () => void;
};

const UserProfileMenu: FunctionComponent<Props> = (props) => {
	const dispatch = useDispatch();

	/**
	 ** Handle Selectors
	 */
	// Get user information
	const user = useSelector(getUser);
	// Get client information
	const client = useSelector(getClient);

	/**
	 ** Get client impersonation type
	 */
	const isImpostor = impersonationUtils.isImpostor(user.impersonationMode);

	/**
	 ** Render Component
	 */
	return (
		<UserProfileDropdown
			school={client.name}
			username={user.fullname}
			email={user.username}
			andImpersonate={user.impersonationMode}
			impersonationUtils={impersonationUtils}
			close={() => props.close()}
			boxShadow="lg"
			dataTest="userProfileDropdown"
			zIndex={9999}
			top={70}
		>
			{/* Handle impersonation button */}
			<SecondaryNavLink
				setAs={isImpostor ? 'secondary' : 'primary'}
				withInternalUrl={isImpostor ? '/auth/login' : '/select-organisation'}
				onClick={() => {
					props.close();
					if (user?.impersonationMode === 'MAT') {
						endMATViewAs();
					}
					if (user?.impersonationMode === 'Full' || user?.impersonationMode === 'Client') {
						localStorage.removeItem('keyStageStorage');
						dispatch(logoutThunk());
					}
				}}
				withIcon={isImpostor ? 'user-secret' : 'school'}
				dataTest="impersonationBtn"
			>
				{isImpostor
					? translate('standardPage.userProfileDropdown.END_IMPERSONATION')
					: translate('standardPage.userProfileDropdown.SELECT_ORGANISATION')}
			</SecondaryNavLink>

			{/* Sign out button */}
			<SecondaryNavLink
				setAs={isImpostor ? 'secondary' : 'primary'}
				withIcon="right-from-bracket"
				dataTest="signOutBtn"
				onClick={(e: React.MouseEvent<HTMLElement>) => {
					e.preventDefault();
					dispatch(logoutThunk());
				}}
			>
				{translate('standardPage.userProfileDropdown.SIGN_OUT')}
			</SecondaryNavLink>
		</UserProfileDropdown>
	);
};

export default UserProfileMenu;
