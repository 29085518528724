import { SagaIterator } from 'redux-saga';
import { cancel, retry, put, select } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../../src/constants';
import { getTrends } from '../../../../api/groupsAPI';
import { setAnalysisTrends, setAnalysisErrorMessage } from 'features/groups/analysis/redux';
import {
	getClient,
	retriggerSingleSchoolPerformanceMeasures,
	triggerMatTrendSelectorSaga,
} from 'features/app/redux/context';

/**
 * Saga which fetches the trends for the selected group and key stage
 */
export function* matTrendSelectorFlow(): SagaIterator<void> {
	//get the client
	const client = yield select(getClient);

	//if there is no client, trigger the saga again
	if (!client) {
		//trigger the saga again
		yield put(triggerMatTrendSelectorSaga());

		//bail
		yield cancel();
	}

	//if there is no group access, bail
	if (!client.hasGroupAccess) {
		//bail
		yield cancel();
	}

	//call the api
	const res = yield retry(
		sagaFlowConfigurations.apiRetry,
		sagaFlowConfigurations.milliSeconds,
		getTrends
	);

	try {
		if (res.HasError) {
			//if there is an error, set the error message
			yield put(setAnalysisErrorMessage(res.Errors[0].message));

			//bail
			yield cancel();
		}
		//set the trends
		yield put(setAnalysisTrends(res.ResponseObject));

		//recall the SchoolsInGroupSaga
		yield put(retriggerSingleSchoolPerformanceMeasures);
	} catch (err) {
		//set the error message
		yield put(setAnalysisErrorMessage(res.Errors[0].message));
	}

	//bail
	yield cancel();
}
