import { cancel, put } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { handleOverallFetchingSingleSchoolPerformanceFlow } from '../../ks5PerformanceMeasures/singleSchool/slice';
import { retriggerSingleSchoolPerformanceMeasures } from 'features/app/redux/context';
/**
 * Retrigger the school and data fetch
 * @returns {SagaIterator<void>}
 */
export function* retriggerSchoolAndDataFetchFlow(): SagaIterator<void> {
	//retrigger the school fetch
	yield put(retriggerSingleSchoolPerformanceMeasures);

	//retrigger the data fetch
	yield put(handleOverallFetchingSingleSchoolPerformanceFlow);

	//Bail out of the saga
	yield cancel();
}
