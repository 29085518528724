/**
 * @module useManageAccountRoutes
 * @description Hook for managing account navigation routes and links
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { getFeature } from 'features/app/redux/features';
import { getUserRoles, hasGroupAccess } from 'features/app/redux/context';
import { knowledgeBaseRoutes } from '../../common/knowledgeBaseRoutes';
import { getCurrentSubscriptionAgreement } from 'features/manageAccount/redux/selectors/index';

/**
 * Hook that returns the navigation routes for managing user accounts
 * @returns {Object} Navigation route configuration object
 * @property {SecondaryNavigationLinks[]} manageAccountRoutes - Array of account management navigation links
 * @property {SecondaryNavigationLinks} manageAccountHomeRoutes - Account management home navigation configuration
 */
const useManageAccountRoutes = (): {
	manageAccountRoutes: SecondaryNavigationLinks[];
	manageAccountHomeRoutes: SecondaryNavigationLinks;
} => {
	const isGroup = useSelector(hasGroupAccess);

	/**
	 * Get feature flags from Redux store
	 */
	const canViewSubscriptions = useSelector(getFeature('subscriptions')) && !isGroup;
	const roles: string[] | undefined = useSelector(getUserRoles);
	const enableMfa = useSelector(getFeature('enable_mfa'));
	const censusFeature = useSelector(getFeature('alps_annual_census'));
	const hideOrg = useSelector(getFeature('hide_organisation_management'));
	const subscriptionAgreement = useSelector(getCurrentSubscriptionAgreement);

	const hasCurrentSubscription = subscriptionAgreement && subscriptionAgreement.length > 0;

	/**
	 * Determines if user has account setting roles (DataExchange_Manage or Settings_Users)
	 * @type {boolean}
	 */
	const accountSettingRoles = roles
		? roles.some((role) => ['DataExchange_Manage', 'Settings_Users'].includes(role))
		: false;

	/**
	 * Checks if user has no roles assigned
	 * @type {boolean}
	 */
	const hasRoles = !roles || roles.length === 0;

	/**
	 * Array of account management navigation route configurations
	 * @type {SecondaryNavigationLinks[]}
	 */
	const manageAccountRoutes: SecondaryNavigationLinks[] = [
		{
			isVisible: true,
			withInternalUrl: '/manage-account/change-password',
			icon: 'lock',
			displayName: translate('home.singleSchool.manageAccount.links.CHANGE_PASSWORD') as string,
		},
		{
			isVisible: !canViewSubscriptions
				? false
				: !hasCurrentSubscription
				? false
				: hasRoles
				? false
				: roles.includes('Settings_Users'),
			withInternalUrl: !hasCurrentSubscription
				? '/manage-account/subscribe-step-1'
				: '/manage-account/manage-subscriptions',
			icon: 'users',
			displayName: translate('home.singleSchool.manageAccount.links.SUBSCRIPTIONS') as string,
		},
		{
			isVisible: isGroup ? false : hasRoles ? false : accountSettingRoles,
			withInternalUrl: `/manage-account/data-sharing`,
			icon: 'database',
			displayName: translate('home.singleSchool.manageAccount.links.DATA_SHARING') as string,
		},
		{
			isVisible: hideOrg ? false : isGroup ? false : hasRoles ? false : accountSettingRoles,
			withInternalUrl: `/manage-account/organisation-management`,
			icon: 'school',
			displayName: translate(
				'home.singleSchool.manageAccount.links.ORGANISATION_MANAGEMENT'
			) as string,
		},
		{
			isVisible: !isGroup && censusFeature,
			withInternalUrl: `/manage-account/alps-annual-census`,
			icon: 'users-slash',
			displayName: translate('home.singleSchool.manageAccount.links.ALPS_ANNUAL_CENSUS') as string,
		},
		{
			isVisible: !!enableMfa,
			disabled: !enableMfa,
			withInternalUrl: `/manage-account/mfaSetup`,
			icon: 'lock',
			displayName: translate(
				'home.singleSchool.manageAccount.links.MULTI_FACTOR_AUTHENTICATION'
			) as string,
		},
	];

	/**
	 * Home navigation route configuration with flyout menu
	 * @type {SecondaryNavigationLinks}
	 */
	const manageAccountHomeRoutes = {
		displayName: translate('home.singleSchool.manageAccount.HEADING') as string,
		isVisible: true,
		disabled: false,
		icon: 'gear',
		flyoutContent: [
			{
				heading: translate('home.singleSchool.manageAccount.FLYOUT_HEADING') as string,
				flyoutLinks: manageAccountRoutes,
			},
			knowledgeBaseRoutes,
		],
	};

	return {
		manageAccountRoutes,
		manageAccountHomeRoutes,
	};
};

export default useManageAccountRoutes;
