import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
// @ts-ignore
import { SummitPerformanceMeasures } from '../../../types/summitPerformanceMeasures/index';
import { CommonAgGridChartStateTypes } from '../../../../../types/commonAgGridTypes';
import { SortType } from '../../ks5pm/res/staticValues';

const initialState: SummitPerformanceMeasures.State = {
	loading: true,
	activeGradepoints: undefined,
	error: false,
	toggleAveragePointsScoreDifferenceChart: false,
	comparisonGradepoint: undefined,
	mainGradepoint: undefined,
	averagePointsScoreHeadlineMeasuresGridData: undefined,
	averagePointsScoreDifferenceChartData: undefined,
	averagePointsScoreAndAverageGradeForSelectedGradepoints: undefined,
	aLevelRawAttainmentChartData: undefined,
	aLevelRawAttainmentDifferenceChartData: undefined,
	rawAttainmentCumulativePercentagesData: undefined,
	best3CumulativePercentageHeadlineMeasuresData: undefined,
	setSortType: undefined,
	isKs4: false,
};

const summitPerformanceMeasuresSlice = createSlice({
	name: 'summitPerformanceMeasures',
	initialState,
	reducers: {
		setInitialLoading(state: SummitPerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		setSelectedSummitGradepoint(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<CommonUiTypes.SelectOption[]>
		) {
			return {
				...state,
				activeGradepoints: action.payload,
			};
		},
		setComparisonGradepoint(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<CommonUiTypes.SelectOption>
		) {
			return {
				...state,
				comparisonGroup: action.payload,
			};
		},
		setMainGradepoint(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<CommonUiTypes.SelectOption>
		) {
			return {
				...state,
				mainGroup: action.payload,
			};
		},
		setErrorStateForGradepoint(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<boolean>
		) {
			return {
				...state,
				error: action.payload,
			};
		},
		setToggleAveragePointsScoreDifferenceChart(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<boolean>
		) {
			return {
				...state,
				toggleAveragePointsScoreDifferenceChart: action.payload,
			};
		},
		setALevelRawAttainmentChartData(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<ResponseBuilder<CommonAgGridChartStateTypes.GridData<any>>>
		) {
			return {
				...state,
				aLevelRawAttainmentChartData: action.payload,
			};
		},
		setALevelRawAttainmentDifferenceChartData(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<ResponseBuilder<CommonAgGridChartStateTypes.GridData<any>>>
		) {
			return {
				...state,
				aLevelRawAttainmentDifferenceChartData: action.payload,
			};
		},
		setRawAttainmentCumulativePercentagesData(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<ResponseBuilder<CommonAgGridChartStateTypes.GridData<any>>>
		) {
			return {
				...state,
				rawAttainmentCumulativePercentagesData: action.payload,
			};
		},
		/*
        Tab 3
         */
		setBest3CumulativePercentageHeadlineMeasuresData(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<ResponseBuilder<CommonAgGridChartStateTypes.GridData<any>>>
		) {
			return {
				...state,
				best3CumulativePercentageHeadlineMeasuresData: action.payload,
			};
		},
		setSortType(
			state: SummitPerformanceMeasures.State,
			action: PayloadAction<SortType | undefined>
		) {
			return {
				...state,
				setSortType: action.payload,
			};
		},
		setIsKs4(state: SummitPerformanceMeasures.State, action: PayloadAction<boolean>) {
			return {
				...state,
				isKs4: action.payload,
			};
		},
	},
});

/**
 * Export Saga watcher actions
 */
export const triggerGpHandler = createAction(
	'summitPerformanceMeasures/summitGradepointSelectorHandlerFlow'
);

export const getSummitInitialPerformanceMeasures = createAction(
	'summitPerformanceMeasures/getSummitInitialPerformanceMeasures'
);

export const triggerSummitPmsFlow = createAction('summitPerformanceMeasures/triggerSummitPmsFlow');

export const getHeadlinePerformanceMeasuresGridData = createAction(
	'summitPerformanceMeasures/getHeadlinePerformanceMeasuresGridData'
);

export const getHeadlinePerformanceMeasuresGraphData = createAction(
	'summitPerformanceMeasures/getHeadlinePerformanceMeasuresGraphData'
);

export const getCumulativePercentagesPerformanceMeasuresGridData = createAction(
	'summitPerformanceMeasures/getCumulativePercentagesPerformanceMeasuresGridData'
);

export const getCumulativePercentagesPerformanceMeasuresGraphData = createAction(
	'summitPerformanceMeasures/getCumulativePercentagesPerformanceMeasuresGraphData'
);

export const get3CumulativePerformanceMeasuresGridData = createAction(
	'summitPerformanceMeasures/get3CumulativePerformanceMeasuresGridData'
);

export const resetSummitPerformanceMeasuresState = createAction(
	'summitPerformanceMeasures/resetSummitPerformanceMeasuresState'
);

/**
 ** Export Reducers
 */
export default summitPerformanceMeasuresSlice.reducer;

/**
 ** Export Actions
 */
export const {
	setSelectedSummitGradepoint,
	setComparisonGradepoint,
	setMainGradepoint,
	setErrorStateForGradepoint,
	setSortType,
	setToggleAveragePointsScoreDifferenceChart,
	setInitialLoading,
	setIsKs4,
} = summitPerformanceMeasuresSlice.actions;
