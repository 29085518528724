import * as React from 'react';
import { Component } from 'react';
import { translate } from '../../../utils/locale';

import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

import {
	DroppableFlex,
	DraggableFlex,
	Flex,
	Box,
	Label,
	Gradepoint,
	GradepointSelector,
	Select,
	Icon,
	IconButton,
} from 'basecamp';

import * as utils from '../../../utils/gradepointSelector';
import { Context } from 'features/app/types';

/**
 ** Props
 */
type Props = {
	gps: string[];
	gpYearGroups: { [gp: string]: string[] };
	selectedGradepoints: string[];
	onGradepointSave: (
		newGradepointKeys: string[],
		rerunAutoGpPopulation: boolean,
		canCloseAndSave?: boolean
	) => void;
	isGradepointTrendAutoPopulated?: boolean;
	onClose?: () => void;
	isModal?: boolean;
	hasAnalysisSession: boolean;
};

/**
 ** State
 */
type State = {
	academicYear: Context.Filter[];
	selectedAcademicYear: string | null;
	yearGroups: Context.Filter[];
	distinctYearGroups: number[];
	selectedYearGroup: string | null;
	gradepointType: Context.Filter[];
	selectedGradepointType: string | null;
	gradepoints: GradepointSelector.Gradepoint[];
	prevGradepoints: string[];
	selectedGradepoints: Array<GradepointSelector.Gradepoint | undefined>;
	selectedGradepoint: string | null;
	activeGradepoint: boolean[];
	gradepointFilters: {
		ac: string | null;
		yg: string | null;
		type: string | null;
	};
	filteredGradepoints: GradepointSelector.Gradepoint[];
	selectPlaceholderText: string;
	rerunAutoGpPopulation: boolean;
	gradepointTrendIsAutoPopulated: boolean;
};

/**
 ** Get Current Year
 */
const getCurrentYear: string = new Date().getFullYear().toString();

/**
 ** Get Selector Object from Key
 */
const getSelectorObjectFromKey = (
	gpKey: string,
	ygs: string[] = []
): GradepointSelector.Gradepoint => {
	let type = gpKey && utils.getGradepointTypeFromKey(gpKey);

	return {
		key: gpKey,
		label: `${utils.getGradepointNameFromKey(gpKey)} -- ${
			type && utils.getGradepointDisplay(type) === 'Monitoring'
				? translate('gradepointSelector.gradepointLabel.TYPE_MONITORING_POINT')
				: translate('gradepointSelector.gradepointLabel.TYPE_EXAM_RESULTS')
		} ${
			utils.getGradepointYearFromKey(gpKey) === getCurrentYear
				? `${translate('gradepointSelector.gradepointLabel.FOR')} 
          ${utils.academicYearStandardFormat(Number(utils.getGradepointYearFromKey(gpKey)))}`
				: `${translate('gradepointSelector.gradepointLabel.FROM')} 
          ${utils.academicYearStandardFormat(Number(utils.getGradepointYearFromKey(gpKey)))}`
		}`,
		gradepointLabel: utils.getGradepointNameFromKey(gpKey) as string,
		value: utils.getGradepointNameFromKey(gpKey) as string,
		icon: utils.getGradepointIcon(type) as 'inyear' | 'endofyear' | 'startofyear',
		type: utils.getGradepointDisplay(type) as Context.GradepointType,
		ac: Number(utils.getGradepointYearFromKey(gpKey)),
		ygs,
	};
};

/**
 ** Gradepoint Selector Component
 */
class GradepointSelectorComponent extends Component<Props, State> {
	constructor(props: Props) {
		super(props);

		this.state = {
			academicYear: [],
			selectedAcademicYear: null,
			yearGroups: [],
			distinctYearGroups: [],
			selectedYearGroup: null,
			gradepointType: [],
			selectedGradepointType: null,
			gradepoints: [],
			prevGradepoints: [],
			selectedGradepoints: props.selectedGradepoints
				? //@ts-ignore spread error
				  [...Array(4).fill(undefined)].map((_, i) => {
						const reversedArr: string[] = [...props.selectedGradepoints].reverse();
						return reversedArr[3 - i] ? getSelectorObjectFromKey(reversedArr[3 - i]) : undefined;
				  })
				: [...Array(4).fill(undefined)],
			selectedGradepoint: null,
			activeGradepoint: [...Array(4).fill(true)],
			gradepointFilters: { ac: null, yg: null, type: null },
			filteredGradepoints: [],
			selectPlaceholderText: translate('gradepointSelector.SELECT_PLACEHOLDER_TEXT') as string,
			rerunAutoGpPopulation: false,
			gradepointTrendIsAutoPopulated: false,
		};
	}

	/**
	 ** Set state as props change
	 */
	static getDerivedStateFromProps(nextProps: Props, prevState: State) {
		if (nextProps.gps !== prevState.prevGradepoints) {
			const { gpYearGroups } = nextProps;

			const yearGroupsArrays: string[][] = Object.values(gpYearGroups);
			const allYearGroups = ([] as string[]).concat(...yearGroupsArrays);
			const distinctYearGroupsSet: Set<string> = new Set(allYearGroups);
			const distinctYearGroups: string[] = Array.from(distinctYearGroupsSet);

			const yearGroupFilterOptions: GradepointSelector.Filter[] = distinctYearGroups.map((yg) => ({
				label: yg,
				value: yg,
			}));

			const [gradepoints, gradepointType, academicYear] = nextProps.gps.reduce(
				(
					[gps, gptype, ac]: [
						GradepointSelector.Gradepoint[],
						GradepointSelector.Filter[],
						GradepointSelector.Filter[]
					],
					el
				) => {
					const type = utils.getGradepointTypeFromKey(el);

					gps.push(getSelectorObjectFromKey(el, gpYearGroups[el]));

					if (!gptype.some((t) => t.label === utils.getGradepointDisplay(type))) {
						gptype.push({
							label: utils.getGradepointDisplay(type),
							value: utils.getGradepointDisplay(type),
						});
					}

					if (!ac.some((y) => y.value === utils.getGradepointYearFromKey(el))) {
						const acYear = utils.getGradepointYearFromKey(el);
						ac.push({
							label: utils.academicYearStandardFormat(Number(acYear)) as string,
							value: acYear as string,
						});
					}

					return [gps, gptype, ac];
				},
				[[], [], []]
			);

			/**
			 * Function to sort array by object key
			 * @param arr Arr to sort
			 */
			const sortByKeyDescending = (arr: any[], key: string) =>
				arr.sort((a, b) => (a[key] > b[key] ? -1 : a[key] < b[key] ? 1 : 0));

			// Sort the Year Groups in decending order
			const yearGroupsSorted = sortByKeyDescending(yearGroupFilterOptions, 'value');

			// Sort the Academic Year in decending order
			const academicYearSorted = sortByKeyDescending(academicYear, 'value');

			// Sort the gradepoints in decending order
			const gradepointsSorted = sortByKeyDescending(gradepoints, 'ac');

			return {
				gradepoints,
				filteredGradepoints: gradepointsSorted,
				gradepointType,
				academicYear: academicYearSorted,
				prevGradepoints: nextProps.gps,
				yearGroups: yearGroupsSorted,
				distinctYearGroups,
			};
		}

		return null;
	}

	/**
	 ** Update the gradepoint trend if the auto populate procedure has run
	 * @param prevProps
	 */
	componentDidUpdate(prevProps: Props) {
		const { selectedGradepoints, isGradepointTrendAutoPopulated } = this.props;

		// If selected gradepoints have change and exist
		if (selectedGradepoints !== prevProps.selectedGradepoints && selectedGradepoints) {
			// Set state if trend has been auto populated
			if (isGradepointTrendAutoPopulated) {
				this.setState(
					{
						selectedGradepoints: [...Array(4).fill(undefined)].map((_, i) => {
							const reversedArr = [...selectedGradepoints].reverse();
							return reversedArr[3 - i] ? getSelectorObjectFromKey(reversedArr[3 - i]) : undefined;
						}),
						gradepointTrendIsAutoPopulated: true,
					},
					() => this.handleFilteredGradepoints()
				);
			} else {
				// Update the filters and reset gradepointTrendIsAutoPopulated flag
				this.setState(
					{
						gradepointTrendIsAutoPopulated: false,
					},
					() => this.handleFilteredGradepoints()
				);
			}
		}
	}

	/**
	 ** Set the active gradepoint
	 * @param {number} i Index of the actively selected gradepoint
	 */
	private handleActiveGradepoint = (i: number): void => {
		const { activeGradepoint, selectedGradepoints } = this.state;
		const gp = activeGradepoint.map((_, _i) => _i === i);
		const shouldRerunAutoGpPopulation = i === selectedGradepoints.length - 1;

		this.setState(
			{
				selectedGradepoint: null,
				activeGradepoint: gp,
				rerunAutoGpPopulation: shouldRerunAutoGpPopulation,
			},
			() => this.handleFilteredGradepoints()
		);
	};

	/**
	 ** Remove the gradepoint at index
	 * @param {number} i Index of the selected gradepoint to unset
	 */
	private handleRemoveGradepoint = (i: number): void => {
		const { selectedGradepoints, rerunAutoGpPopulation } = this.state;
		const { onGradepointSave } = this.props;

		const isRemovingPrimary = i === 3;
		let newSelectedGps: Array<GradepointSelector.Gradepoint | undefined>;

		/**
		 * If user has removed primary gradepoint -
		 * 1. reset gradepointTrendIsAutoPopulated flag
		 * 2. Prevent user from closing the selector
		 * 3. handle the gp filters
		 */
		if (isRemovingPrimary) {
			newSelectedGps = [...Array(4).fill(undefined)];
			onGradepointSave([], false, false);
		} else {
			// remove gradepoint and backfill with blank
			newSelectedGps = [
				undefined,
				...selectedGradepoints.slice(0, i),
				...selectedGradepoints.slice(i + 1),
			];
		}

		// then set state regardless of which GP has been removed
		this.setState(
			{
				selectedGradepoints: newSelectedGps,
				selectedGradepoint: null,
				activeGradepoint: [...Array(4).fill(true)],
				gradepointTrendIsAutoPopulated: false,
			},
			() => {
				const orderedSelectedGpsWithoutBlanks = newSelectedGps
					.filter((gp) => Boolean(gp))
					.map((gp) => (gp ? gp.key : ''))
					.reverse();

				onGradepointSave(
					orderedSelectedGpsWithoutBlanks,
					isRemovingPrimary ? false : rerunAutoGpPopulation,
					!!orderedSelectedGpsWithoutBlanks.length
				);
				this.handleFilteredGradepoints();
			}
		);
	};

	/**
	 ** Handle the filtering of the gradepoints, based on user feedback
	 */
	private handleFilteredGradepoints = (): void => {
		const { gradepointFilters, gradepoints, selectedGradepoints } = this.state;

		// Set the filter functions
		const filters = {
			ac: (ac: string) => (!gradepointFilters.ac ? true : ac === gradepointFilters.ac),
			ygs: (ygs: string) => (!gradepointFilters.yg ? true : ygs.includes(gradepointFilters.yg)),
			type: (type: string) => (!gradepointFilters.type ? true : type === gradepointFilters.type),
		};

		// Filter the gradepoints using filterArray and filter functions
		const filteredGradepoints = utils.filterArray(gradepoints, filters);

		const gpsNotSelected = filteredGradepoints.filter(
			(gp) => !selectedGradepoints.some((sgp) => sgp && sgp.key === gp.key)
		);

		this.setState({
			filteredGradepoints: gpsNotSelected,
		});
	};

	/**
	 ** Handle the filters set by the user
	 * @param {Object} selectedOption from the select component
	 * @param {string} filter the filter type ac, yg or type
	 * @param {string} selectedValue dynamic state key to set the selected value on theselect component
	 */
	private handleSelectedFilterOption = (
		selectedOption: GradepointSelector.Filter,
		valueFormatter: (value: string | number) => any,
		filter: string,
		selectedValue: string
	): void => {
		if (selectedOption) {
			this.setState<never>(
				{
					[selectedValue]: selectedOption.label,
					gradepointFilters: {
						...this.state.gradepointFilters,
						[filter]: valueFormatter(selectedOption.value),
					},
				},
				() => this.handleFilteredGradepoints()
			);
		} else {
			this.setState<never>(
				{
					[selectedValue]: selectedOption,
					gradepointFilters: {
						...this.state.gradepointFilters,
						[filter]: selectedOption,
					},
				},
				() => this.handleFilteredGradepoints()
			);
		}
	};

	/**
	 ** Handle the selected gradepoints
	 * @param {Object} gp the selected gradepoint
	 */
	private handleSelectedGradepoint = (gp: GradepointSelector.Gradepoint): void => {
		const { selectedGradepoints, activeGradepoint, rerunAutoGpPopulation } = this.state;

		// Don't allow duplicate gradepoints to be selected
		if (selectedGradepoints.includes(gp)) {
			return;
		}

		// Get the index of the active gradepoint
		const active = activeGradepoint.findIndex((agp) => agp);

		// update the selected gradepoints
		const newSelectedGradepoints = selectedGradepoints.map((selectedGradepoint, i) =>
			i === active ? gp : selectedGradepoint
		);

		this.props.onGradepointSave(
			newSelectedGradepoints
				.filter((gp) => Boolean(gp))
				.map((gp) => (gp ? gp.key : ''))
				.reverse(),
			rerunAutoGpPopulation
		);

		// Set state and reset the active gradepoint list
		if (gp) {
			this.setState({
				selectedGradepoint: gp.label,
				selectedGradepoints: newSelectedGradepoints,
				activeGradepoint: [...Array(4).fill(true)],
			});
		} else {
			this.setState({
				selectedGradepoint: gp,
				activeGradepoint: [...Array(4).fill(true)],
			});
		}
	};

	/**
	 **  Handle the canceling of gradepoints
	 */
	private handleGradepointCancel = (): void => {
		this.setState({
			activeGradepoint: [...Array(4).fill(true)],
		});
	};

	/**
	 ** Handle the rendering of the gradepoints
	 */
	private renderGradepoints = () => {
		const { selectedGradepoints, activeGradepoint } = this.state;

		return selectedGradepoints.map((_, i) => {
			// Disable the selector if the previous GP has no value set
			const shouldDisableSelector = i < selectedGradepoints.lastIndexOf(undefined);

			/**
			 * Add disabled style to the selector if the user is currently
			 * selecting a GP or if the previous GP has no value set
			 */
			const styleIsDisabled =
				i < selectedGradepoints.lastIndexOf(undefined) || !activeGradepoint[i];

			// If the user has not selected any gradepoints display the gradepoint selector component
			if (!selectedGradepoints[i]) {
				return (
					<GradepointSelector
						key={i}
						p={0}
						width={1 / 4}
						mr={4}
						disabled={shouldDisableSelector}
						onClick={() => this.handleActiveGradepoint(i)}
						dataTest="addGradepointPill"
						gpPosition={selectedGradepoints.length - (i + 2)}
						setAs={styleIsDisabled ? 'disabled' : 'active'}
					/>
				);
			}

			// Else set as comparison / secondary gradepoint
			return (
				<Draggable
					key={selectedGradepoints[i]!.key}
					draggableId={selectedGradepoints[i]!.key}
					index={i}
				>
					{(provided) => (
						<DraggableFlex
							withAlign="distribute"
							width={1 / 4}
							mr={4}
							withColor={
								styleIsDisabled
									? 'disabled'
									: i === selectedGradepoints.length - 1
									? 'primary'
									: 'secondary'
							}
							borderRadius={4}
							{...provided.draggableProps}
							innerRef={provided.innerRef}
							dataTest="selectedGradepointPill"
							overflow="hidden"
						>
							<Flex
								withAlign="center"
								width="40px"
								height="30px"
								display={!shouldDisableSelector ? 'flex' : 'none'}
								{...provided.dragHandleProps}
							>
								<Icon icon="arrow-right-arrow-left" />
							</Flex>

							<Gradepoint
								flex={1}
								setAs={
									styleIsDisabled
										? 'disabled'
										: i === selectedGradepoints.length - 1
										? 'primary'
										: 'primary'
								}
								withGP={selectedGradepoints[i]!.icon}
								disabled={shouldDisableSelector}
								pl={0}
								onClick={() => this.handleActiveGradepoint(i)}
								withShadow={false}
							>
								{selectedGradepoints[i]!.gradepointLabel}
							</Gradepoint>
						</DraggableFlex>
					)}
				</Draggable>
			);
		});
	};

	/**
	 ** Handle the drag & drop reordering of the gradepoints
	 */
	private onDragEnd = (result: DropResult): void => {
		const { selectedGradepoints } = this.state;

		// If the user does not complete the drag / drop bail
		if (!result.destination) {
			return;
		}

		// Reorder gradepoints
		const reorder = (
			list: Array<GradepointSelector.Gradepoint | undefined>,
			startIndex: number,
			endIndex: number
		): Array<GradepointSelector.Gradepoint | undefined> => {
			const result = Array.from(list);
			const [removed] = result.splice(startIndex, 1);
			result.splice(endIndex, 0, removed);

			return result;
		};

		// Reordered gradepoints
		const reorderedGPS: Array<GradepointSelector.Gradepoint | undefined> = reorder(
			selectedGradepoints,
			result.source.index,
			result.destination.index
		);

		this.setState(
			{
				selectedGradepoints: reorderedGPS,
				// Check to see if the primary gradepoint has been changed
				rerunAutoGpPopulation:
					result.destination!.index === selectedGradepoints.length - 1 ||
					result.source!.index === selectedGradepoints.length - 1,
			},
			() => {
				this.props.onGradepointSave(
					reorderedGPS
						.filter((gp) => Boolean(gp))
						.map((gp) => (gp ? gp.key : ''))
						.reverse(),
					this.state.rerunAutoGpPopulation
				);
			}
		);
	};

	/**
	 ** Render Component
	 */
	render(): React.ReactElement {
		const {
			academicYear,
			yearGroups,
			gradepointType,
			gradepoints,
			selectedAcademicYear,
			selectedGradepointType,
			selectedYearGroup,
			selectedGradepoint,
			selectedGradepoints,
			selectPlaceholderText,
			activeGradepoint,
			filteredGradepoints,
			gradepointTrendIsAutoPopulated,
		} = this.state;

		// Handle the select component value - selected value or placeholder
		const handleSelectedFilterValue = (select: string | number) => ({
			label: select ? select : selectPlaceholderText,
		});

		// Handle the select component disabled state
		const gpsDisabled: boolean = activeGradepoint.filter((agp) => agp).length === 4;

		// Get the index of the active gradepoint
		const active: number = activeGradepoint.findIndex((agp) => agp);

		return (
			<Box width={1} dataTest="gpSelector" bg="UI.secondary.1">
				{/* GPS */}
				<Flex setAs="column" withAlign="center" pt={6}>
					<Flex withAlign="even" width={1} pl={4}>
						{/* Handle the rendering of the gradepoint labels and cancel controls */}
						{selectedGradepoints.map((sgp, i) => {
							return (
								<Flex key={i} withAlign="distribute" flex={1} mt={0} mr={4}>
									<Label dataTest="gpLabel">
										{i === 3
											? translate('gradepointSelector.gradepoint.label.MAIN_GRADEPOINT')
											: `${translate(
													'gradepointSelector.gradepoint.label.COMPARISON_GRADEPOINT'
											  )} ${selectedGradepoints.length - 1 - i}`}
										{gradepointTrendIsAutoPopulated &&
											translate('gradepointSelector.gradepoint.label.AUTO_SELECTED')}
									</Label>
									<Flex>
										{!gpsDisabled && active === i && sgp !== undefined ? (
											<>
												<IconButton
													withIcon="trash"
													setAs="secondary"
													withSize="small"
													mt={-3}
													ml={2}
													onClick={() => this.handleRemoveGradepoint(i)}
													dataTest="removeGpIcon"
												/>
											</>
										) : null}
										{!gpsDisabled && active === i ? (
											<>
												<IconButton
													withIcon="xmark"
													setAs="secondary"
													withSize="small"
													mt={-3}
													ml={2}
													onClick={this.handleGradepointCancel}
													dataTest="cancelGpIcon"
												/>
											</>
										) : null}
										{gpsDisabled && sgp ? (
											<IconButton
												withIcon="pen-to-square"
												setAs="secondary"
												withSize="small"
												mt={-3}
												ml={2}
												onClick={() => this.handleActiveGradepoint(i)}
												dataTest="activeGpIcon"
											/>
										) : null}
									</Flex>
								</Flex>
							);
						})}
					</Flex>
					<DragDropContext onDragEnd={this.onDragEnd}>
						<Droppable droppableId="GPDroppable" direction="horizontal">
							{(provided) => (
								<DroppableFlex
									width={1}
									pl={4}
									{...provided.droppableProps}
									innerRef={provided.innerRef}
								>
									{this.renderGradepoints()}
									{provided.placeholder}
								</DroppableFlex>
							)}
						</Droppable>
					</DragDropContext>
				</Flex>
				{/* GP Filter Bar */}
				<Flex setAs="row" withAlign="centerRight" py={6} pl={4} width={1}>
					{/* Filter by Academic Year */}
					<Flex setAs="column" flex={1} mr={4}>
						<Label setAs={gpsDisabled ? 'disabled' : 'default'}>
							{translate('gradepointSelector.filters.ACADEMIC_YEAR')}
						</Label>
						<Select
							withOptions={academicYear}
							withWidth="100%"
							isClearable={selectedAcademicYear ? true : false}
							isDisabled={gpsDisabled}
							menuPlacement={this.props.isModal ? 'top' : 'bottom'}
							value={handleSelectedFilterValue(selectedAcademicYear as string)}
							onChange={(selectedOption: GradepointSelector.Filter) =>
								this.handleSelectedFilterOption(
									selectedOption,
									(x) => Number(x),
									'ac',
									'selectedAcademicYear'
								)
							}
							dataTest="academicYearFilters"
						/>
					</Flex>
					{/* Filter by Year Group */}
					<Flex setAs="column" flex={1} mr={4}>
						<Label setAs={gpsDisabled ? 'disabled' : 'default'}>
							{translate('gradepointSelector.filters.YEAR_GROUP')}
						</Label>
						<Select
							withOptions={yearGroups}
							withWidth="100%"
							isClearable={selectedYearGroup ? true : false}
							isDisabled={gpsDisabled}
							menuPlacement={this.props.isModal ? 'top' : 'bottom'}
							value={handleSelectedFilterValue(selectedYearGroup as string)}
							onChange={(selectedOption: GradepointSelector.Filter) =>
								this.handleSelectedFilterOption(selectedOption, (x) => x, 'yg', 'selectedYearGroup')
							}
							dataTest="yearGroupFilters"
						/>
					</Flex>
					{/* Filter by Gradepoint Type */}
					<Flex setAs="column" flex={1} mr={4}>
						<Label setAs={gpsDisabled ? 'disabled' : 'default'}>
							{translate('gradepointSelector.filters.GRADEPOINT_TYPE')}
						</Label>
						<Select
							withOptions={gradepointType}
							withWidth="100%"
							isClearable={selectedGradepointType ? true : false}
							isDisabled={gpsDisabled}
							menuPlacement={this.props.isModal ? 'top' : 'bottom'}
							value={handleSelectedFilterValue(selectedGradepointType as string)}
							onChange={(selectedOption: GradepointSelector.Filter) =>
								this.handleSelectedFilterOption(
									selectedOption,
									(x) => x,
									'type',
									'selectedGradepointType'
								)
							}
							dataTest="gradepointTypeFilters"
						/>
					</Flex>
					{/* Available Gradepoints */}
					<Flex setAs="column" flex={2} mr={4}>
						<Label setAs={gpsDisabled ? 'disabled' : 'default'}>
							{this.props.hasAnalysisSession
								? translate('gradepointSelector.filters.AVAILABLE_SESSION_GRADEPOINTS')
								: translate('gradepointSelector.filters.AVAILABLE_GRADEPOINTS')}
						</Label>
						<Flex>
							<Select
								withOptions={filteredGradepoints.length ? filteredGradepoints : gradepoints}
								withWidth="100%"
								isClearable={selectedGradepoint ? true : false}
								isDisabled={gpsDisabled || !filteredGradepoints.length}
								menuPlacement={this.props.isModal ? 'top' : 'bottom'}
								value={
									filteredGradepoints.length
										? handleSelectedFilterValue(selectedGradepoint as string)
										: 'No gradepoints match selected filters'
								}
								placeholder={
									filteredGradepoints.length
										? selectPlaceholderText
										: 'No gradepoints match selected filters'
								}
								onChange={this.handleSelectedGradepoint}
								dataTest="availableGradepointList"
							/>
						</Flex>
					</Flex>
					{/* Close Button */}
					{this.props.onClose ? (
						<Flex setAs="column" flex={0} mr={4} height="66px" withAlign="bottomLeft">
							<IconButton
								setAs="primary"
								withIcon="check"
								withSize="large"
								onClick={this.props.onClose}
							/>
						</Flex>
					) : null}
				</Flex>
			</Box>
		);
	}
}

export default GradepointSelectorComponent;
