import fetch from './';
import {
	HubConnection,
	HubConnectionBuilder,
	HubConnectionState,
	JsonHubProtocol,
} from '@microsoft/signalr';

/**
 *  Create RTM signalr connection
 */

let connection: HubConnection;

export enum notificationTypes {
	MEG = 1,
	SUMMIT = 2,
	GRADEPOINT = 3,
	PDF = 4,
	KS4 = 5,
	ALPS = 6,
	SummitPerformanceMeasures = 8,
}

//Maps the backend enum value to a RTM.MessageType
const typeMap = new Map([
	[
		notificationTypes.MEG,
		{
			name: 'MEG Notification',
			color: 'KS5AS.brown.0',
			icon: 'calculator',
		},
	],
	[
		notificationTypes.ALPS,
		{
			name: 'Alps Notification',
			color: 'thermometer.tBlue.0',
			icon: 'newspaper',
		},
	],
	[
		notificationTypes.SUMMIT,
		{
			name: 'Summit Notification',
			color: 'UI.primary.0',
			icon: 'users-cog',
		},
	],
	[
		notificationTypes.GRADEPOINT,
		{
			name: 'Gradepoint Notification',
			color: 'KS5A.lightGreen.0',
			icon: 'upload',
		},
	],
	[
		notificationTypes.PDF,
		{
			name: 'PDF Notification',
			color: 'KS5A.lightGreen.0',
			icon: 'file-pdf',
		},
	],
	[
		notificationTypes.KS4,
		{
			name: 'KS4 Notification',
			color: 'KS5A.lightGreen.0',
			icon: 'calculator',
		},
	],
	[
		notificationTypes.SummitPerformanceMeasures,
		{
			name: 'Summit Notification',
			color: 'UI.primary.0',
			icon: 'users-cog',
		},
	],
]);

export const setupRtmConnection = (
	onNotification: (notification: RTM.Message) => void,
	clientId: number | undefined
): Promise<void> => {
	connection = new HubConnectionBuilder()
		.withUrl('/api/notification-hub')
		.withHubProtocol(new JsonHubProtocol())
		.withAutomaticReconnect()
		.build();

	connection.on('ReceiveNotification', (notification: any) => {
		const type = typeMap.get(notification.type);

		if (!type || notification.clientId !== clientId) {
			// safety first, kids!
			// if the type doesn't exist or the notification is for the correct user but wrong client, don't do anything
			// trying to do something with an unrecognised type will just cause the UI to fall apart
			return;
		}

		var message: RTM.Message = {
			...notification,
			type: {
				...type,
				id: notification.type,
			},
		};

		onNotification(message);

		//raise OS notification
		if (!message.acknowledged) {
			new Notification(message.type.name, {
				body: message.title,
				data: message.id,
				icon: 'https://alps.education/wp-content/uploads/2020/06/AlpsLogoSq-01.jpg',
			});
		}
	});

	return connection.start();
};

export const getMessages = async (): Promise<RTM.Message[]> => {
	var response: any = await fetch('/api/notification');
	var messages: any = response.ResponseObject;

	return messages.map((message: any) => ({
		...message,
		type: {
			...typeMap.get(message.type),
			id: message.type,
		},
	}));
};

export const getConnectionState = (): HubConnectionState => {
	return connection.state;
};

/**
 * Remove a single message
 * @param id
 * @returns
 */
export const removeMessage = async (id: number): Promise<void> => {
	return await fetch(`/api/notification/MarkAsRead`, {
		method: 'POST',
		body: { id: id },
	});
};

/**
 * Remove multiple messages
 * @param ids
 * @returns
 */
export const removeManyMessages = async (ids: number[]): Promise<void> => {
	return await fetch(`/api/notification/MarkManyAsRead`, {
		method: 'POST',
		body: { ids: serializeData(ids) },
	});
};

export const acknowledgeMessage = async (id: number): Promise<void> => {
	return await removeMessage(id);
};

function serializeData(obj: any): any {
	if (obj.payload) {
		obj.payload = JSON.stringify(obj.payload);
	}
	return obj;
}
