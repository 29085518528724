/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { useSelector } from 'react-redux';
import { getFeature } from 'features/app/redux/features';
import {
	getClientCountry,
	hasKS4Entitlement,
	getEmbargo,
	hasKS5Entitlement,
	getViewAsSchool,
	getSubscriptionStatus,
	getEntitlment,
	getAppliedGradepoints,
} from 'features/app/redux/context';
import { CountryEnum } from '../../../types/enum';
import { withinEmbargoAndViewAsSchool } from '../../../utils/embargoHelpers';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useConnectPrimaryNavLinks = () => {
	const hasKs4 = useSelector(hasKS4Entitlement);
	const hasKs5 = useSelector(hasKS5Entitlement);
	const canViewKs4PerfMeasures = useSelector(getFeature('ks4_perf_measures'));
	const canViewALevelPerfMeasures: boolean = useSelector(getFeature('ks5_a_level_perf_measures'));
	const embargoFeature = useSelector(getFeature('embargo_feature'));
	const country = useSelector(getClientCountry);
	const canViewKs4 = hasKs4 && canViewKs4PerfMeasures && country !== CountryEnum.Wales;
	const canViewKs5 = hasKs5 && canViewALevelPerfMeasures && country !== CountryEnum.Wales;
	const canViewStrategicAnalysis = useSelector(getFeature('strategic_analysis'));
	const canViewOldStrategicAnalysis = useSelector(getFeature('strategic_analysis_old'));
	const applyEmbargo = useSelector(getEmbargo);
	const canViewIBPerfMeasures: boolean = useSelector(getFeature('ks5_ib_perf_measures'));
	const subscriptionStatus = useSelector(getSubscriptionStatus);
	const viewAsSchool = useSelector(getViewAsSchool);
	const embargoAccess = withinEmbargoAndViewAsSchool(embargoFeature, applyEmbargo, viewAsSchool);
	const currentEntitlement = useSelector(getEntitlment);
	const appliedGradepoints = useSelector(getAppliedGradepoints);
	const hasCurrentEntitlement =
		currentEntitlement && appliedGradepoints && appliedGradepoints.length > 0;
	const commonDisabledLinkCritieria =
		!hasCurrentEntitlement || embargoAccess || !subscriptionStatus.hasValidSubscriptionOrContract;

	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const connectPrimaryNavLinks = [
		{
			name: 'strategicAnalysisOld',
			width: 110,
			isVisible: canViewOldStrategicAnalysis,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-strategic-analysis-old-link`}
					withInternalUrl="/strategic"
					disabled={!canViewOldStrategicAnalysis || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.STRATEGIC_ANALYSIS_OLD')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysis',
			width: 110,
			isVisible: canViewStrategicAnalysis,
			menuTransferOrder: 8,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-strategic-analysis-link`}
					withInternalUrl="/strategic-analysis/summary"
					disabled={!canViewStrategicAnalysis || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.STRATEGIC_ANALYSIS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'subjectAnalysis',
			width: 100,
			isVisible: true,
			menuTransferOrder: 7,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-subject-analysis-link`}
					withInternalUrl="/subjects"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.SUBJECT_ANALYSIS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'studentAnalysis',
			width: 104,
			isVisible: true,
			menuTransferOrder: 6,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-student-analysis-link`}
					withInternalUrl="/students/overview"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.STUDENT_ANALYSIS')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'monitoringAccuracy',
			width: 124,
			isVisible: true,
			menuTransferOrder: 5,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-monitoring-accuracy-link`}
					withInternalUrl="/monitoring/difference"
					disabled={commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.MONITORING')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'ks4Pms',
			width: 175,
			isVisible: canViewKs4,
			menuTransferOrder: 4,

			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-ks4-pms-link`}
					withInternalUrl="/performance-measures/attainment-measures"
					disabled={!canViewKs4 || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.KS4_PERFORMANCE_MEASURES')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'ks5Pms',
			width: 175,
			isVisible: canViewKs5,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-ks5-pms-link`}
					withInternalUrl="/Ks5PerformanceMeasures/Alevel"
					disabled={!canViewKs5 || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.KS5_PERFORMANCE_MEASURES')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'ibPms',
			width: 165,
			isVisible: canViewIBPerfMeasures,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="primary"
					data-test={`primary-nav-ib-pms-link`}
					withInternalUrl="/ib-performance-measures"
					disabled={!canViewIBPerfMeasures || commonDisabledLinkCritieria}
				>
					{translate('primaryNavigation.connect.root.IB_PERFORMANCE_MEASURES')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses primaryNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(
		connectPrimaryNavLinks,
		width,
		100,
		[]
	);

	return { largeScreenMenu, smallScreenMenu };
};

export default useConnectPrimaryNavLinks;
