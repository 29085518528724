import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getIsGroup, getPrimaryGradepoint } from 'features/app/redux/context';
import { translate } from '../../../../utils/locale';
import { HeaderLink } from 'basecamp';
import { styles } from './common';

/**
 * Props interface for GradepointSelectorButton component
 * @interface Props
 * @property {() => void} toggleGradepointSelector - Function to toggle the gradepoint selector
 * @property {Groups.Setup.Trend | undefined} selectedTrend - Currently selected trend for groups
 * @property {boolean} isSmallScreen - Whether the screen is small
 */
type Props = {
	toggleGradepointSelector: () => void;
	selectedTrend: Groups.Setup.Trend | undefined;
	isSmallScreen: boolean;
	setSmallScreenMenuIsOpen: (isOpen: boolean) => void;
};

/**
 * Component that renders a button to toggle the gradepoint/trend selector
 * Only displays if user has valid subscription and has gradepoints/trends
 *
 * @component
 * @param {Props} props - Component props
 * @param {() => void} props.toggleGradepointSelector - Function to toggle the gradepoint selector
 * @param {Groups.Setup.Trend | undefined} props.selectedTrend - Currently selected trend for groups
 * @param {boolean} props.isSmallScreen - Whether the screen is small
 * @returns {JSX.Element | null} HeaderLink component or null if conditions not met
 */
const GradepointSelectorButton: FunctionComponent<Props> = ({
	toggleGradepointSelector,
	selectedTrend,
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	const isGroup = useSelector(getIsGroup);
	const pgp = useSelector(getPrimaryGradepoint);

	return (
		<HeaderLink
			buttonText={
				isGroup
					? translate('standardPage.gradepointSelector.CURRENT_TREND')
					: translate('standardPage.gradepointSelector.CURRENT_GRADEPOINT')
			}
			lozengeText={isGroup ? selectedTrend?.name : pgp?.name}
			lozengeIcon="circle-check"
			onClick={() => {
				toggleGradepointSelector();
				setSmallScreenMenuIsOpen(false);
			}}
			dataTest="gradepointSelectorBtn"
			{...styles(isSmallScreen)}
		/>
	);
};

export default GradepointSelectorButton;
