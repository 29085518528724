import { SagaIterator } from 'redux-saga';
import { cancel, put, retry, select } from 'redux-saga/effects';
import {
	getPrimaryGradepoint,
	getSubjectsTakenByPrimaryGradepoint,
	hasGroupAccess,
	setSubjectsTaken,
	setSubjectsTakenError,
} from '../context';
import { Context } from 'features/app/types';
import { sagaFlowConfigurations } from '../../../../constants';
import { getSubjectsByPrimaryGradepointId } from '../../../../api/contextAPI';
import { getFeature } from '../features';

/**
 * Saga that fetches a list of subjects taken for a given primary gradepoint id
 */
export function* fetchSubjectsTakenFlow(): SagaIterator<void> {
	//Get the Primary Grade Point
	const primaryGradePoint: Context.Gradepoint = yield select(getPrimaryGradepoint);

	//check if we already have the subjects for this gradepoint
	const subjectsTaken = yield select(getSubjectsTakenByPrimaryGradepoint);

	//get the feature flags
	const featureFlag = yield select(getFeature('ui_subjects_taken'));

	//is the school a mat
	const isMat = yield select(hasGroupAccess);

	//if the feature flag is disabled, cancel the saga or its a summit account
	if (!featureFlag || isMat) {
		//bail
		yield cancel();
	}

	//check to see if we already have the subjects for this gradepoint
	if (subjectsTaken && subjectsTaken.gradepointId === primaryGradePoint.gradepointId) {
		//if we do, cancel the saga
		yield cancel();
	}

	//if there is a grade point selected pass it into the api req
	const response: ResponseBuilder<Context.SubjectsTaken[]> = yield retry(
		sagaFlowConfigurations.apiRetry, // Retry
		sagaFlowConfigurations.milliSeconds, // Delay between retry (MS)
		getSubjectsByPrimaryGradepointId,
		primaryGradePoint.gradepointId
	);

	//if there is an error, set the error in redux and cancel the saga
	if (response.hasError) {
		//set the error in redux
		yield put(setSubjectsTakenError(response.Errors[0].Message));
		//cancel the saga
		yield cancel();
	}

	//format the response into the correct type to match the subjects to the gp id
	const subjectsByPrimaryGradepoint: Context.SubjectsByPrimaryGradepoint = {
		gradepointId: primaryGradePoint.gradepointId,
		subjects: response.responseObject,
	};

	//set the subjects taken in redux
	yield put(setSubjectsTaken(subjectsByPrimaryGradepoint));

	//bail
	yield cancel();
}
