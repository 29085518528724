import { SubjectArea } from 'features/subjectArea/types';
import { SagaIterator } from 'redux-saga';
import { cancel, put, retry, select } from 'redux-saga/effects';
import { Context } from 'features/app/types';
import { getAppliedGradepoints, getBenchmarkSetName } from 'features/app/redux/context';
import { fetchChartData } from '../../../../api/subjectAreaAPI';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';
import { setsubjectAreaLoading } from '../slice';
import { getUdfTypeAndValue } from 'features/subjectPage/redux';

/**
 * Saga that fetchs Ag grid chart data
 * @param subjectAreaPages Current Subject Area
 * @param examLevel Current ExamLevel
 * @param subjectId Current Subject Id
 */
export function* mainChartData(
	subjectAreaPages: SubjectArea.SubjectAnalysisPages,
	examLevel: CommonFeatureTypes.ExamLevels,
	subjectId: number
): SagaIterator<void> {
	//Applied Gradepoints
	const gradepoints: Context.Gradepoint[] = yield select(getAppliedGradepoints);
	//Current Benchmark
	const benchmarkSet: string = yield select(getBenchmarkSetName);
	//check the current udf values
	const udfTypeAndValue: { type: string; value: string } = yield select(getUdfTypeAndValue);

	try {
		//Call Chart endpoint
		const SECOND = 1000;
		const response: ResponseBuilder<CommonAgGridChartStateTypes.GraphData<
			CommonAgGridChartStateTypes.Graphs
		>> = yield retry(
			3, // Try calling 3 times
			10 * SECOND, // with a delay of 10 seconds
			fetchChartData, // API to call
			subjectAreaPages,
			examLevel,
			benchmarkSet,
			gradepoints,
			[subjectId],
			udfTypeAndValue
		);

		// Check the response builder to see if any errors occurred or response is empty
		if (!response || response.hasError) {
			//Handle errors
			yield put({
				type: `subjectArea${subjectAreaPages}/setMainGraphDataFail`,
				payload: !response ? 'Grid data is empty' : response.errors[0].message,
			});
			yield put({
				type: `subjectArea${subjectAreaPages}/setSagaFlowStatus`,
				payload: true,
			});
			yield put(setsubjectAreaLoading(false));
			yield cancel();
		}

		yield put({
			type: `subjectArea${subjectAreaPages}/setMainGraphDataFail`,
			payload: undefined,
		});

		//Add data to redux
		yield put({
			type: `subjectArea${subjectAreaPages}/setMainGraphDataSuccess`,
			payload: response.responseObject,
		});

		yield put({
			type: `subjectArea${subjectAreaPages}/setSagaFlowStatus`,
			payload: false,
		});
	} catch (e) {
		// set error message
		yield put({
			type: `subjectArea${subjectAreaPages}/setMainGraphDataFail`,
			payload: e,
		});
	}
}
