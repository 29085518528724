import { createAction, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	getAppContext,
	logout,
	login,
	autoSelectClient,
	loginAndActivate,
	acceptTermsAndConditions,
	fetchMatAppliedGpKeys,
	switchActiveBenchmark,
	fetchFiltersAndComparisonsFromBigfootReq,
	loginMfa,
	getCensusContext,
} from '../../../api/contextAPI';
import { BenchmarkType, CensusAuditStatus, CountryEnum } from '../../../types/enum';
import { fetchPersonalisedTargets, clearReports } from '../../reports/redux';
import { setGroupAccessId } from '../../groups/setup/redux';
import { fetchGroupTrends } from '../../groups/analysis/redux';
import { Context } from '../types';
import flagsmith from 'flagsmith';
import { apm } from '@elastic/apm-rum';
import {
	autoGradepointPopulation,
	saveGPTrendToLocalStorage,
} from '../../../utils/gradepointSelector';
import { loadingSuccess } from 'features/selectOrganisation/redux/switch';
import ExamLevels from '../../../utils/examLevel';
import { clearMetrics } from 'features/performanceMeasures/redux';
import { getFeature, setLoadingFlags, getConfig } from 'features/app/redux/features';
import {
	setBulkPermissionsFromRolesThunk,
	setLoadingPermissions,
} from 'features/app/redux/permissions';
import { Role } from 'features/app/types/roles';
import moment from 'moment';
import { benchmarkToCountry, ConvertCountryToEnum } from '../../../utils/country';
import { getAllAgreements } from 'features/home/redux';
import { AnalysisSettings } from 'features/analysisSettings/types';
import { MultiFactorAuthentication } from 'features/manageAccount/types/mfa';
import { getCurrentSubscriptionAgreementThunk } from 'features/manageAccount/redux';
import {
	setCensusCompletionUserInfo,
	setConfirmationOfRoleRequest,
} from 'features/manageUsers/redux/users';

/**
 ** Initial State
 */
const initialState: Context.State = {
	isAuthenticated: false,

	availableComparisons: [],
	appliedComparisons: [],
	allComparisons: [],
	availableFilters: [],
	appliedFilters: [],

	alpsPrimaryDataContact: '',
	isCustomComparison: false,
	entitlements: [],
	appliedComparisonsType: 'simple',
	user: undefined,
	client: undefined,
	currentEntitlement: true,
	isPivoted: false,
	yetiToken: undefined,
	showAlpsKeyContactsReminder: true,
	reportContext: {
		currentDataset: {
			benchmarkSet: undefined,
			benchmarkSetId: 0,
			datasetItem: undefined,
			sessionBenchmarkSet: undefined,
			examLevels: [],
			gradepoints: [],
			targetSettingGradepoints: [],
			primaryGradepoint: undefined,
			appliedGradepoints: [],
			hasOrderedReports: false,
			availableBenchmarks: [],
		},
	},
	permissions: {
		canSwitchClientBenchmark: false,
		groupsMonitoringTimeline: false,
		groupsViewAsSchool: false,
	},
	alpsKeyContactsReminderLatestVersion: 1,
	loading: false,
	hasContext: false,
	alpsChampions: [],
	gradepointTrendIsAutoPopulated: false,
	roles: [],
	examLevels: [],
	appliedExamLevels: [],
	requiresTermsAcceptance: false,
	embargoPeriods: [],
	useClientBenchmark: false,
	viewAsClient: false,
	token: '',
	isMfaActive: false,
	mfaConfiguredTypes: undefined,
	storedEmailForMfa: '',
	isCensusInProgress: undefined,
	subjectsTaken: undefined,
	subjectsTakenError: undefined,
	schoolsInGroup: undefined,
	activeChildSchool: undefined,
	schoolsInGroupError: undefined,
};

/**
 ** Context Slice
 */
export const context = createSlice({
	name: 'context',
	initialState,
	reducers: {
		setAppContextPending(state: Context.State) {
			state.loading = true;
		},
		setAppContextSuccess(state, action: PayloadAction<Context.State>) {
			const payload: Context.State = action.payload;

			//TODO: Add AppInsightsUser

			return {
				...state,
				...payload,
				loading: false,
				hasContext: true,
				isAuthenticated: payload.user && payload.user.id > 0,
			};
		},
		setAppContextFailed(state: Context.State) {
			return {
				...state,
				loading: false,
				hasContext: true,
			};
		},
		changePrimaryGradepoint: (
			state,
			{
				payload: { key, gradepointSelection, rerunAutoGpPopulation, saveToStorage = true },
			}: PayloadAction<Context.ChangePrimaryGP>
		) => {
			let appliedGradePoints;

			// Get gradepoints
			const gradepoints = state.reportContext.currentDataset.gradepoints;

			// Get primary gradepoint from key
			const pgp = gradepoints.find((gp) => gp.key === key) as Context.Gradepoint;
			state.reportContext.currentDataset.primaryGradepoint = pgp;

			/**
			 ** Handle auto population if primary gradepoint has changed
			 */
			if (rerunAutoGpPopulation) {
				// Set state
				appliedGradePoints = autoGradepointPopulation(
					pgp,
					state.reportContext.currentDataset.gradepoints
				);
				state.gradepointTrendIsAutoPopulated = true;
			} else {
				/**
				 ** Otherwise update with comparison gradepoints
				 */

				appliedGradePoints = gradepointSelection;
				state.gradepointTrendIsAutoPopulated = false;
			}

			if (saveToStorage) {
				// Update state & local storage with the applied grade points
				saveGPTrendToLocalStorage(appliedGradePoints);
			}
			state.reportContext.currentDataset.appliedGradepoints = appliedGradePoints;
		},
		setAppFiltersSuccess(
			state: Context.State,
			action: PayloadAction<{ comparisons: Array<any>; fields: Array<any> }>
		) {
			return {
				...state,
				availableFilters: action.payload.fields?.map(({ name, values, ...filter }) => {
					return {
						id: name,
						name,
						...filter,
						Values: values.map((value: string) => ({
							id: `${name}|${value}`,
							value,
						})),
					};
				}),
				availableComparisons: action.payload.comparisons?.map(
					({ name, groups, displayOrder, ...comparison }) => {
						return {
							id: name,
							name,
							displayOrder,
							...comparison,
							values: groups.map((group) => ({
								id: `${name}|${group.name}`,
								label: group.name,
								value: group.filters[0].value,
								type: group.filters[0].type,
								comparisonGroupId: group.filters[0].descriptorId,
								comparisonId: group.filters[0].descriptorListItemId,
							})),
						};
					}
				),
			};
		},
		setAllComparisonsSuccess(
			state: Context.State,
			action: PayloadAction<{ comparisons: Array<any>; fields: Array<any> }>
		) {
			return {
				...state,
				loading: false,
				allComparisons: action.payload.comparisons.map(({ name, groups, ...comparison }) => {
					return {
						id: name,
						name,
						...comparison,
						values: groups.map((group) => ({
							id: `${name}|${group.name}`,
							label: group.name,
							value: group.filters[0].value,
							type: group.filters[0].type,
						})),
					};
				}),
			};
		},
		logoutSuccess(state: Context.State) {
			return {
				...state,
				initialState,
			};
		},
		setAppliedComparisons(state: Context.State, action: PayloadAction<any>) {
			return {
				...state,
				appliedComparisons: action.payload ?? [],
			};
		},
		clearAppliedComparisons(state: Context.State) {
			return {
				...state,
				appliedComparisons: [],
			};
		},
		setAppliedFilters(state: Context.State, action: PayloadAction<string[]>) {
			return {
				...state,
				appliedFilters: action.payload ?? [],
			};
		},
		clearAppliedFilters(state: Context.State) {
			return {
				...state,
				appliedFilters: [],
			};
		},
		setRolesSucess(state: Context.State, action: PayloadAction<string[] | undefined>) {
			state.roles = action.payload;
		},
		// TODO: Change the action type to PayloadAction<Entitlment[]>
		setAlpsAdminEntitlements(state: Context.State, action: PayloadAction<Context.Entitlement[]>) {
			state.entitlements = action.payload;
		},
		updateLoading(state: Context.State) {
			state.loading = false;
		},
		termsAcceptedSuccess(state: Context.State) {
			state.requiresTermsAcceptance = false;
		},
		setAppliedExamlevels(state: Context.State, action: PayloadAction<any>) {
			state.appliedExamLevels = action.payload;
		},
		setEmbargoPeriods(state: Context.State, action: PayloadAction<Context.EmbargoInformation[]>) {
			state.embargoPeriods = action.payload;
		},
		setContextGroupAccessId(state: Context.State, action: PayloadAction<number>) {
			state.client.groupAccessId = action.payload;
		},
		setTrendAccessId(state: Context.State, action: PayloadAction<number | undefined>) {
			state.client.trendAccessId = action.payload;
		},
		setImpersonationAppliedGradepoint(state: Context.State, action: PayloadAction<string[]>) {
			state.reportContext.currentDataset.appliedGradepoints = action.payload;
		},
		setUseClientBenchmark(state: Context.State, action: PayloadAction<boolean>) {
			return {
				...state,
				useClientBenchmark: action.payload,
			};
		},
		setViewAsClient(state: Context.State, action: PayloadAction<boolean>) {
			return {
				...state,
				viewAsClient: action.payload,
			};
		},
		setChangeSessionBenchmark(state: Context.State, action: PayloadAction<string | undefined>) {
			state.reportContext.currentDataset.sessionBenchmarkSet = action.payload;
		},
		updateCurrentBenchmark(state: Context.State, action: PayloadAction<string>) {
			state.reportContext.currentDataset.benchmarkSet = action.payload;
		},
		mfaLogin(state: Context.State, action: PayloadAction<Auth.MfaValues>) {
			return {
				...state,
				token: action.payload.token,
				isMfaActive: action.payload.isMfaActive,
				mfaConfiguredTypes: action.payload.mfaConfiguredTypes,
			};
		},
		updateIsMfaActive(state: Context.State, action: PayloadAction<boolean>) {
			return {
				...state,
				isMfaActive: action.payload,
			};
		},
		setTriggerRedirectBoolean(state: Context.State, action: PayloadAction<boolean>) {
			return {
				...state,
				triggerRedirect: action.payload,
			};
		},
		updateMfaTokenAndValidation(
			state: Context.State,
			action: PayloadAction<MultiFactorAuthentication.AuthenticationResponse>
		) {
			return {
				...state,
				token: action.payload.mfaToken,
				mfaConfiguredTypes: state.mfaConfiguredTypes?.map((x) => {
					return {
						...x,
						isValidated:
							x.mfaTypeId === action.payload.mfaTypeId ? action.payload.isValidated : x.isValidated,
					};
				}),
			};
		},
		addCopyOfEmailToState(state: Context.State, action: PayloadAction<string>) {
			return {
				...state,
				storedEmailForMfa: action.payload,
			};
		},
		setIsCensusInProgress(state: Context.State, action: PayloadAction<CensusAuditStatus>) {
			return {
				...state,
				isCensusInProgress: action.payload,
			};
		},
		setSubjectsTaken(
			state: Context.State,
			action: PayloadAction<Context.SubjectsByPrimaryGradepoint>
		) {
			return {
				...state,
				subjectsTaken: action.payload,
			};
		},
		setSubjectsTakenError(state: Context.State, action: PayloadAction<string>) {
			return {
				...state,
				subjectsTakenError: action.payload,
			};
		},
		setSchoolsInGroup(state: Context.State, action: PayloadAction<Context.ArrayOfSchools[]>) {
			return {
				...state,
				schoolsInGroup: action.payload,
			};
		},
		setActiveChildSchool(state: Context.State, action: PayloadAction<Context.ArrayOfSchools>) {
			return {
				...state,
				activeChildSchool: action.payload,
			};
		},
		setSchoolsInGroupError(state: Context.State, action: PayloadAction<string>) {
			return {
				...state,
				schoolsInGroupError: action.payload,
			};
		},
		setClearClient(state: Context.State) {
			return {
				...state,
				client: undefined,
			};
		},
	},
});

/**
 * Export Saga watcher actions
 */

export const triggerMfaSagaLoop = createAction('context/triggerSagaLoop');
export const triggerEmailAuthenticationOnValidation = createAction(
	'context/resendAuthenticationEmail'
);
export const triggerLoginSaga = createAction('context/triggerLogin');
export const triggerCensusSaga = createAction('context/triggerCensus');

export const triggerSchoolsInGroupSaga = createAction('context/triggerSchoolsInGroup');
export const retriggerSingleSchoolPerformanceMeasures = createAction(
	'context/retriggerSingleSchoolPerformanceMeasures'
);
export const triggerMatTrendSelectorSaga = createAction('context/triggerMatTrendSelector');

/**
 ** Export Actions
 */
export const {
	changePrimaryGradepoint,
	setAppContextPending,
	setAppContextSuccess,
	setAppContextFailed,
	setAppFiltersSuccess,
	logoutSuccess,
	setAppliedFilters,
	clearAppliedFilters,
	setAppliedComparisons,
	clearAppliedComparisons,
	setRolesSucess,
	setAlpsAdminEntitlements,
	updateLoading,
	termsAcceptedSuccess,
	setAllComparisonsSuccess,
	setAppliedExamlevels,
	setEmbargoPeriods,
	setContextGroupAccessId,
	setTrendAccessId,
	setImpersonationAppliedGradepoint,
	setUseClientBenchmark,
	setViewAsClient,
	setChangeSessionBenchmark,
	updateCurrentBenchmark,
	mfaLogin,
	updateMfaTokenAndValidation,
	addCopyOfEmailToState,
	updateIsMfaActive,
	setIsCensusInProgress,
	setSubjectsTaken,
	setSubjectsTakenError,
	setSchoolsInGroup,
	setActiveChildSchool,
	setSchoolsInGroupError,
	setClearClient,
} = context.actions;

/**
 ** Export Selectors
 */

/**
 * Get the schools in the group error
 */
export const getSchoolsInGroupError = ({ context }: RootState): string | undefined =>
	context.schoolsInGroupError;

/**
 * Get the schools in the group
 * @param state - The root state
 * @returns The schools in the group
 */
export const getSchoolsInGroup = ({ context }: RootState): Context.ArrayOfSchools[] =>
	context.schoolsInGroup;

/**
 * Get the active child school
 * @param state - The root state
 * @returns The active child school
 */
export const getActiveChildSchool = ({ context }: RootState): Context.ArrayOfSchools | undefined =>
	context.activeChildSchool;

/**
 * A get A list of subjects taken for the current primary gradepoint
 * @param state - The root state
 * @returns The subjects taken for the current primary gradepoint
 */
export const getSubjectsTaken = ({ context }: RootState): Context.SubjectsTaken[] | undefined =>
	context.subjectsTaken?.subjects;

/**
 * Get the subjects taken for the current primary gradepoint linked to the gradepoint id
 * @param state - The root state
 * @returns The subjects taken for the current primary gradepoint
 */
export const getSubjectsTakenByPrimaryGradepoint = ({
	context,
}: RootState): Context.SubjectsByPrimaryGradepoint | undefined => context.subjectsTaken;

//Check if census is in progress
export const censusStatus = ({ context }: RootState): CensusAuditStatus | undefined =>
	context.isCensusInProgress;

//Get Copy of user Email
export const copyOfUserEmail = ({ context }: RootState): string => context.storedEmailForMfa;

//Get the user defined MFA configuration types
export const userDefinedMfaConfiguration = ({
	context,
}: RootState): Context.MfaList[] | undefined => context.mfaConfiguredTypes;

// Get the context redirect flag
export const getContextRedirectFlag = ({ context }: RootState): boolean => context.triggerRedirect;

//get if the response has mfa enabled
export const getIsMfaEnabled = ({ context }: RootState): boolean => context.isMfaActive;

// Get the jwt token for mfa
export const getToken = ({ context }: RootState): string => context.token;

// Get the Trend access Id (this will be one of the MAT's groupAccessId)
export const getTrendAccessId = ({ context }: RootState) => context.client?.trendAccessId;

// Is user authenticated
export const isAuthenticated = ({ context }: RootState) => context.isAuthenticated;

export const requiresTermsAcceptance = ({ context }: RootState) => context.requiresTermsAcceptance;

// Is feature loading
export const isLoading = ({ context: { loading } }: RootState) => loading;

export const hasContext = ({ context: { hasContext } }: RootState) => hasContext;

// Get User Information
export const getUser = ({ context: { user } }: RootState) => user;

// Get username
export const getUsername = ({ context: { user } }: RootState): string | undefined => user?.username;

//Get impersonationMode
export const getImpersonationMode = (state: RootState) => state.context.user?.impersonationMode;

// Get Client Information
export const getClient = ({ context: { client } }: RootState) => client;

export const getClientCountryName = ({ context }: RootState): string =>
	context?.client?.country.name ?? '';

export const getClientId = ({ context: { client } }: RootState): number | undefined => client?.id;

export const getClientCountry = (state: RootState): CountryEnum =>
	ConvertCountryToEnum(getClientCountryName(state));

// Get Primary Gradepoint
export const getPrimaryGradepoint = ({
	context: { reportContext },
}: RootState): Context.Gradepoint =>
	reportContext.currentDataset?.primaryGradepoint ?? ({} as Context.Gradepoint);

// Get All Gradepoints
export const getAllGradepoints = ({ context: { reportContext } }: RootState) =>
	reportContext.currentDataset?.gradepoints || [];

// Get target setting gradepoints
export const getTargetSettingGradepoints = ({ context: { reportContext } }: RootState) =>
	reportContext?.currentDataset?.targetSettingGradepoints || [];

// Get the applied gradepoint keys
export const getAppliedGradepointKeys = ({ context: { reportContext } }: RootState) =>
	reportContext?.currentDataset?.appliedGradepoints;

//Get the most recent Exam Gp
export const getRecentHistoricGp = ({
	context: { reportContext },
}: RootState): Context.Gradepoint | undefined => {
	return (
		reportContext.currentDataset?.gradepoints
			?.filter((key) => key.type.toLowerCase() === 'endofyear')
			.sort((a, b) => b.year - a.year)[0] || undefined
	);
};

// Get Applied Gradepoints
export const getAppliedGradepoints = ({
	context: { reportContext },
}: RootState): Array<Context.Gradepoint> => {
	let orderedGradepoints = new Array<any>();

	const appliedGradepoints = reportContext?.currentDataset?.appliedGradepoints;
	const primaryGradepoint = reportContext?.currentDataset?.primaryGradepoint;

	if (!appliedGradepoints) return [];

	const availableGradepoints = reportContext.currentDataset?.gradepoints;

	availableGradepoints?.map((x: Context.Gradepoint) => {
		const foundIndex = appliedGradepoints.findIndex((agp) => agp === x.key);
		const isPrimary = x.key === primaryGradepoint?.key;

		if (foundIndex > -1)
			orderedGradepoints.push({ key: foundIndex, value: { ...x, order: foundIndex, isPrimary } });
	});

	return orderedGradepoints
		.sort(
			(
				a: { key: number; value: Context.Gradepoint },
				b: { key: number; value: Context.Gradepoint }
			) => (a.value.order ?? 0) - (b.value.order ?? 0)
		)
		.map((x) => x.value);
};

export const isCustomComparison = ({ context }: RootState): boolean => {
	return context.isCustomComparison;
};

export const getAppliedFilters = ({ context }: RootState): SubjectPage.Option[] => {
	return context.appliedFilters ?? [];
};

export const getAppliedComparisons = ({ context }: RootState): SubjectPage.Option[] => {
	return context.appliedComparisons ?? [];
};

// Is trend auto populated
export const isGradepointTrendAutoPopulated = ({ context }: RootState) =>
	context.gradepointTrendIsAutoPopulated;

// Has group access
export const hasGroupAccess = ({ context }: RootState) => context.client?.hasGroupAccess;

// Get is group
export const getIsGroup = ({ context }: RootState) => {
	const hasGroupAccess = context.client?.hasGroupAccess;
	const hasMatImpersonation = context.user?.impersonationMode.toLowerCase() === 'mat';

	return hasGroupAccess || hasMatImpersonation;
};
// Get group access id
export const getGroupAccessId = ({ context }: RootState) => context.client?.groupAccessId;

// Get Context
export const getContext = ({ context }: RootState) => context;

export const getIsContractOwner = ({ context }: RootState) => context.client?.isContractOwner;

export const hasBenchmark = ({ subjectPage }: RootState) => {
	const data: SubjectPage.Data = subjectPage.data;
	const selectedSubject = data && data[subjectPage.currentSubjectId];

	if (!data || !selectedSubject) {
		return false;
	}
	return selectedSubject.Benchmark !== null && selectedSubject.Benchmark !== [];
};

export const hasKS4Entitlement = ({ context }: RootState): boolean => {
	return context.entitlements.some(({ LevelSelections }) => {
		return LevelSelections['KS4'];
	});
};

export const hasKS5Entitlement = ({ context }: RootState): boolean => {
	return context.entitlements.some(({ LevelSelections }) => {
		return ['A', 'AS', 'BTEC', 'BTECCert', 'BTEC90Cred', 'PreU', 'IB'].some(
			(level) => LevelSelections[level]
		);
	});
};

export const getAvailableExamLevels = ({ context }: RootState) => {
	return context.examLevels
		.filter((x: any) => ExamLevels[x])
		.map((x) => {
			return {
				value: x,
				label: ExamLevels[x]?.compactDisplay,
			};
		});
};

const getAvailableFilters = (
	context: Context.State,
	predicate: (value: Context.AvailableFilter) => boolean
) => {
	return context.availableFilters.filter(predicate).map(({ name, Values }) => ({
		label: name,
		options: Values.map(({ id, value }: { id: string; value: string }) => ({
			label: value,
			value: id,
		})),
	}));
};

const getAvailableComparisons = (
	context: Context.State,
	predicate: (value: Context.AvailableComparison) => boolean
) => {
	return context.availableComparisons.filter(predicate).map(({ name, values }) => ({
		label: name,
		options: values.map(({ id, label }) => ({
			label: label,
			value: id,
		})),
	}));
};

const getLimitedComparisonsByList = (context: Context.State) => {
	function KS5ComparisonsOnly(value: any) {
		const comps = ['Gender', 'Disadvantaged', 'EAL', 'SEND'];
		return comps.some((x) => x === value.name);
	}

	return context.availableComparisons.filter(KS5ComparisonsOnly).map(({ name, values }) => ({
		label: name,
		options: values.map(({ id, label }) => ({
			label: label,
			value: id,
		})),
	}));
};

const getAllComparisons = (
	context: Context.State,
	predicate: (value: Context.AvailableComparison) => boolean = () => true
) => {
	return context.allComparisons.filter(predicate).map(({ name, values }) => ({
		label: name,
		options: values.map(({ id, label }) => ({
			label: label,
			value: id,
		})),
	}));
};

export const getCustomComparisons = (state: RootState) => {
	return state.settings.comparisons;
};

export const getAllAvailableComparisons = ({ context }: RootState) => {
	return getAvailableComparisons(context, (_) => true);
};

export const getAvailableSubjectFilters = ({ context }: RootState) => {
	return getAvailableFilters(context, ({ allowInSubjectAnalysis }) => allowInSubjectAnalysis);
};

export const getAvailableSubjectComparisons = ({ context }: RootState) => {
	return getAvailableComparisons(context, ({ allowInSubjectAnalysis }) => allowInSubjectAnalysis);
};

export const getLimitedKS5ComparisonsByList = ({ context }: RootState) => {
	return getLimitedComparisonsByList(context);
};

export const getAvailableStudentFilters = ({ context }: RootState) => {
	return getAvailableFilters(context, ({ allowInStudentAnalysis }) => allowInStudentAnalysis);
};

export const getAvailableStudentComparisons = ({ context }: RootState) => {
	return getAvailableComparisons(context, ({ allowInStudentAnalysis }) => allowInStudentAnalysis);
};

export const getAllSubjectComparisons = ({ context }: RootState) => {
	return getAllComparisons(context, ({ allowInSubjectAnalysis }) => allowInSubjectAnalysis);
};

export const getAllStudentComparisons = ({ context }: RootState) => {
	return getAllComparisons(context, ({ allowInStudentAnalysis }) => allowInStudentAnalysis);
};

/**
 * Get the numberic comparison ids from the drop down applied string comparison names
 * @param appliedComparisons Selected String Comparisons
 * @returns Array of comparisons with comparisons Ids
 */
export const getRawAvailableComparisonsByAppliedKeys = (
	appliedComparisons: CommonFeatureTypes.ComparisonOption
) => ({ context }: RootState): Context.ReportAppliedComparisons[] => {
	const arrayOfComparisonStringIds = appliedComparisons.map((x) => x.value);
	const rawComparisonData = context.availableComparisons
		.flatMap((x) => x.values)
		.filter((c) => arrayOfComparisonStringIds.includes(c.id));

	return rawComparisonData.map((x) => {
		return {
			comparisonId: x.comparisonId,
			comparisonGroupId: x.comparisonGroupId,
			comparisonDisplayName: x.id.replace('|', ' - '),
			comparisonType: x.type,
		};
	});
};

type Option = {
	label: string;
	options: {
		label: string;
		value: string;
	}[];
};

const getAppliedFromAvailable = (applied: { label: any; value: any }[], available: Option[]) => {
	var options = available.flatMap((x) => x.options);
	return applied.filter((x) => options.find((y) => y.value == x.value && y.label == x.label));
};

export const getAppliedStudentFilters = createSelector(
	getAppliedFilters,
	getAvailableStudentFilters,
	(filters, available) => getAppliedFromAvailable(filters, available)
);

export const getAppliedSubjectFilters = createSelector(
	getAppliedFilters,
	getAvailableSubjectFilters,
	(filters, available) => getAppliedFromAvailable(filters, available)
);

// get a list of available benchamrks (Alps and client bms)
export const getAvailableBenchmarkOptions = ({
	context,
}: RootState): AnalysisSettings.BenchmarkOption[] | undefined => {
	const benchmarkOptions = context.reportContext?.currentDataset?.availableBenchmarks;

	return (
		benchmarkOptions &&
		benchmarkOptions.map((benchmarks) => {
			return {
				label: benchmarks.type === 1 ? 'National Benchmark Set' : 'Alps KS5 Provider Benchmark Set',
				value: benchmarks.benchmarkSet,
			};
		})
	);
};

// get a list of available benchamrks (Alps and client bms)
export const getAvailableBenchmarkObjects = ({
	context,
}: RootState): Context.AvailableBenchmarks[] | undefined => {
	return context.reportContext?.currentDataset?.availableBenchmarks;
};

export const getSessionBenchmarkSet = ({ context }: RootState): string | undefined => {
	return context.reportContext?.currentDataset?.sessionBenchmarkSet;
};

// Returns the client benchmark set name
export const getClientBenchmarkSet = ({ context }: RootState): string | undefined => {
	// If there are no available benchmarks then return undefined so we cna use the default
	if (context.reportContext?.currentDataset?.availableBenchmarks === undefined) return undefined;
	// Find the client benchmark set, which is currently type 2
	const clientBenchmarkSet = context.reportContext?.currentDataset?.availableBenchmarks.find(
		(benchmark) => benchmark.type === BenchmarkType.Client
	);

	return clientBenchmarkSet?.benchmarkSet;
};

// get country name from benchmark set
export const getBenchmarkSetCountry = ({ context }: RootState) => {
	return benchmarkToCountry(context.reportContext?.currentDataset?.benchmarkSet);
};

// get country name from benchmark set
export const getBenchmarkSetCountryFromNationDataSetBenchmark = ({ context }: RootState) => {
	const findNationBenchmark = context.reportContext?.currentDataset?.availableBenchmarks?.find(
		(benchmarks) => benchmarks.type === BenchmarkType.National
	);

	return benchmarkToCountry(findNationBenchmark?.benchmarkSet);
};

export const getBenchmarkSetName = (state: RootState) => {
	const clientBenchmarkName = getClientBenchmarkSet(state);
	const canUseBenchmarkFeature =
		getFeature('client_benchmark')(state) || getFeature('summit_benchmark')(state);

	const useClientBenchmark = getUseClientBenchmark(state);
	let benchmarkSetName = getContextBenchmark(state);

	if (canUseBenchmarkFeature && useClientBenchmark && !!clientBenchmarkName) {
		// We are allowed to use client benchmarks, we have the client benchmark set name and the boolean is true to use it so lets use it
		return clientBenchmarkName;
	}

	// check session bm settings..
	const sessionBenchmarkName = getSessionBenchmarkSet(state);

	// If the session BM is different (ie it has been changed from the default) it should be used
	if (sessionBenchmarkName && sessionBenchmarkName !== benchmarkSetName) {
		return sessionBenchmarkName;
	}

	return benchmarkSetName;
};

export const getContextBenchmark = (state: RootState) => {
	return state.context.reportContext.currentDataset?.benchmarkSet;
};

export const getCanSwitchClientBenchmark = ({ context }: RootState) => {
	const permissions = context.permissions as Context.Permissions;

	return permissions?.canSwitchClientBenchmark;
};

/**
 * Determines if client benchmarks should be used. First check session storage, if this is not set then use redux data.
 * Session storage will only be set when the page is loaded from a Summit account via the view as feature.
 * @returns True if client benchmarks should be used. Otherwise will return false if national benchmarks should be used.
 */
export const getUseClientBenchmark = ({ context }: RootState): boolean => {
	const sessionStorageValue = window.sessionStorage.getItem('useClientBenchmark');

	if (sessionStorageValue !== null) {
		// Intentional string comparison as session storage is always stored as a string value.
		return sessionStorageValue === 'true';
	}

	return context.useClientBenchmark;
};

export const getBenchmarkSet = ({ context }: RootState) => {
	const [id] = context.reportContext.currentDataset.benchmarkSet.split('/');

	return id;
};

export const getBenchmarkSetId = ({ context }: RootState) => {
	const id = context.reportContext.currentDataset.benchmarkSetId;

	return id;
};

//get roles
export const getUserRoles = ({ context }: RootState): string[] | undefined => context.roles;

//get embargo
export const getEmbargo = ({ context }: RootState): Context.EmbargoInformation[] | undefined =>
	context.embargoPeriods;

//get entitlements
export const getContextEntitlements = ({ context }: RootState): any => context.entitlements;

//currentEntitlement boolean
export const getEntitlment = (context: RootState): boolean => {
	return context.context.currentEntitlement;
};

//hasRenewedSubscription boolean
export const hasRenewedSubscription = (state: RootState): Homepage.Agreement | undefined => {
	return state.homepage.agreements?.find((x) => x.HasRenewedSubscription);
};

//get applied Exam levels
export const getAppliedExamLevels = (state: RootState): Context.AppliedExamLevels[] => {
	return state.context.appliedExamLevels;
};

//Current entitlements
export const getCurrentEntitlements = (state: RootState): Context.Entitlement[] => {
	const now = moment().format('yyyy-MM-DD');
	// get all entitlements
	return state.context.entitlements
		.filter((e: Context.Entitlement) => {
			//current is that current time is between the start and end
			return (
				moment(e.Start).format('yyyy-MM-DD') <= now && moment(e.End).format('yyyy-MM-DD') >= now
			);
		})
		.sort((a: Context.Entitlement, b: Context.Entitlement) => {
			// sort the filtered list by start date
			return new Date(a.Start).getTime() - new Date(b.Start).getTime();
		});
};

/**
 * Get the status of the clients subscription or contract
 * Used to handle the paywall functionality
 * @returns an object showing status of the clients subscription or contract
 */
export const getSubscriptionStatus = (state: RootState): Context.SubscriptionStatus => {
	// Get all agreements and ensure there is at least one
	const agreements = getAllAgreements(state);
	const hasAtLeastOneAgreement = !!agreements && agreements.length > 0;

	// Get temporary feature flag for paywall
	// TODO: Remove this when paywall is enabled for all clients
	const paywallEnabled = getFeature('enable_paywall_restrictions')(state);

	// Determine if the user is viewing sad client aka they are a trainer
	const viewingAsClient = getViewAsClient(state);

	// Determine if the client has a subscription or if the user is viewing as client
	const hasSubscription =
		(hasAtLeastOneAgreement && agreements.some((agreement) => agreement.HasSubscription)) ||
		viewingAsClient;

	// Determine if the client has a contract or if the user is viewing as client
	const hasContract =
		(hasAtLeastOneAgreement && agreements.some((agreement) => agreement.HasContract)) ||
		viewingAsClient;

	// Determine if the client has a valid subscription or contract
	const hasValidSubscriptionOrContract = hasSubscription || hasContract;

	return {
		// Return subscription/contract status in case we ever need to use them separately
		hasSubscription: !!paywallEnabled ? hasSubscription : true,
		hasContract: !!paywallEnabled ? hasContract : true,
		// Return a combined value for ease of use
		hasValidSubscriptionOrContract: !!paywallEnabled ? hasValidSubscriptionOrContract : true,
	};
};

// Get if use is viewing as client
export const getViewAsClient = (state: RootState): boolean => {
	return state.context.viewAsClient;
};

// get data item
export const getDataSetItem = ({ context: { reportContext } }: RootState): any => {
	return reportContext.currentDataset?.datasetItem;
};

// get yeti token
export const getYetiToken = ({ context }: RootState): any => {
	return context.yetiToken;
};

//Get View Data
export const getPermissions = (state: RootState): any => {
	return state.context.permissions;
};

//Is view as school permission set to true
export const getViewAsSchool = (state: RootState): boolean => {
	const permissions = state.context.permissions as Context.Permissions;
	return permissions?.groupsViewAsSchool;
};

//Get Monitoring Timeline Data

/**
 ** Export Reducers
 */
export default context.reducer;

/**
 * Export Thunks
 */
//TODO: Time to move to sibling folder
// Thunk that gets:
// app context from yeti
// trends and schools if user is a MAT
// app filters from yeti reporting
// personalised targets
export const fetchAppContext = (): AppThunk => async (dispatch, getState) => {
	dispatch(setAppContextPending());

	let appContextResponse: Context.State;

	const state = getState();

	try {
		appContextResponse = await getAppContext().then(async (contextResponse) => {
			return await matAppliedTrends(contextResponse);
		});
	} catch (e) {
		if (window.flagsmithAPIKey) {
			flagsmith.getFlags().catch((err) => {
				dispatch(setLoadingFlags(false));
				console.error(err);
			});
			dispatch(setLoadingPermissions(false));
			dispatch(setAppContextFailed());
		}
		dispatch(setLoadingPermissions(false));
		dispatch(setAppContextFailed());
		return;
	}

	var hasGroupAccess =
		appContextResponse && appContextResponse.client && appContextResponse.client.hasGroupAccess;

	let groupPromises: any = [];
	groupPromises = [dispatch(setAppContextSuccess(appContextResponse))];

	let flagsmithPromises: any = [];

	if (window.flagsmithAPIKey) {
		// only try do flagsmith stuff if the API key is provided, so the client is running

		if (appContextResponse.user?.username) {
			const handleFlagsmithError = (err: Error) => {
				dispatch(setLoadingFlags(false));

				if (apm.isActive()) {
					apm.captureError(err);
				}
			};

			const [, domain] = appContextResponse.user.username.split('@');

			// Create a map of traits to set on the user
			const traits: Record<string, string | number | boolean> = {
				email_domain: domain,
				username: appContextResponse.user.username,
				z_reference: appContextResponse.client?.reference || '',
				impersonation_mode: appContextResponse.user.impersonationMode?.toUpperCase(),
				group_access: hasGroupAccess || false,
				is_data_manager:
					(appContextResponse.roles?.includes('DataExchange_View') &&
						appContextResponse.roles?.includes('DataExchange_Manage')) ||
					false,
				is_admin:
					(appContextResponse.roles?.includes('Settings_Users') &&
						appContextResponse.roles?.includes('Settings_Organisation')) ||
					false,
			};

			flagsmithPromises.push(
				flagsmith
					.identify(appContextResponse.user.username, traits)
					.then(() => flagsmith.getFlags())
					.then(() => {
						dispatch(setLoadingFlags(false));
					})
					.catch(handleFlagsmithError)
			);
		}
	}
	/**
	 * Keep in for when we hook up MATS soon. Currently breaks for MAT accounts.
	 */
	if (hasGroupAccess) {
		//set group access id in group setup slice
		dispatch(setGroupAccessId(appContextResponse.client?.groupAccessId));
		//set data in the analysis slice
		groupPromises.push(dispatch(fetchGroupTrends()));
	}

	const numericRoles = (appContextResponse.roles || [])
		.map((roleId: string) => {
			// @ts-ignore
			return Role[roleId];
		})
		.filter((r) => !isNaN(r));

	// We want to maintain the client benchmark (set previously at default context call)
	const matImpersonation = appContextResponse.user?.impersonationMode === 'MAT';

	const isActiveBenchmarkClientBenchamrk = appContextResponse.reportContext.currentDataset?.availableBenchmarks?.find(
		(activeBenchmark) => activeBenchmark.active
	)?.type;

	let sessionBenchmarkName = appContextResponse.reportContext.currentDataset?.availableBenchmarks?.find(
		(benchmark) =>
			benchmark.type ===
			(appContextResponse.useClientBenchmark ? BenchmarkType.Client : BenchmarkType.National)
	)?.benchmarkSet;

	const setClientBenchmark = [
		matImpersonation
			? [
					dispatch(
						setChangeSessionBenchmark(
							sessionBenchmarkName ?? appContextResponse.reportContext.currentDataset?.benchmarkSet
						)
					),
			  ]
			: isActiveBenchmarkClientBenchamrk === BenchmarkType.Client
			? dispatch(setUseClientBenchmark(true))
			: dispatch(setUseClientBenchmark(false)),
	];

	return Promise.all([
		fetchFiltersAndComparisonsFromBigfootReq(
			appContextResponse.reportContext.currentDataset?.benchmarkSet,
			[appContextResponse.reportContext.currentDataset?.primaryGradepoint]
		),
		dispatch(fetchPersonalisedTargets()),
		dispatch(clearReports()),
		dispatch(clearMetrics()),
		...setClientBenchmark,
		//sets loading to false for switch org
		dispatch(loadingSuccess()),
		dispatch(setBulkPermissionsFromRolesThunk(numericRoles)),
		dispatch(getCurrentSubscriptionAgreementThunk()),
		...groupPromises,
		...flagsmithPromises,
	])
		.then((response) => {
			dispatch(censusContextThunk());
			const HIGH_MID_LOW_FILTERS_ENABLED = getFeature('filters_high_mid_low')(state);
			const HIGH_MID_LOW_COMPARISONS_ENABLED = getFeature('comparisons_high_mid_low')(state);
			dispatch(triggerSchoolsInGroupSaga());
			dispatch(
				setAppFiltersSuccess({
					...response[0].responseObject.reports[0],
					fields: response[0].responseObject.reports[0]?.fields?.filter(
						({ Name }: { Name: string }) =>
							HIGH_MID_LOW_FILTERS_ENABLED ||
							Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
					),
					comparisons: response[0].responseObject.reports[0]?.comparisons?.filter(
						({ Name }: { Name: string }) =>
							HIGH_MID_LOW_COMPARISONS_ENABLED ||
							Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
					),
				})
			);

			if (matImpersonation) {
				window.sessionStorage.removeItem('isImpersonation');
			}
		})
		.catch((e) => {
			dispatch(setAppContextFailed());
		});
};

/**
 * Thunk that sets the grade point state and gets the new app filters and comparisons
 * @param {Context.ChangePrimaryGP} primaryGPChange Details about the grade point change
 */
export const changePrimaryGradepointThunk = (
	primaryGPChange: Context.ChangePrimaryGP
): AppThunk => async (dispatch, getState) => {
	await dispatch(changePrimaryGradepoint(primaryGPChange));

	const state = getState();
	const primaryGradePoint = getPrimaryGradepoint(state);

	const HIGH_MID_LOW_FILTERS_ENABLED = getFeature('filters_high_mid_low')(state);
	const HIGH_MID_LOW_COMPARISONS_ENABLED = getFeature('comparisons_high_mid_low')(state);
	const currentBm = getContextBenchmark(state);

	fetchFiltersAndComparisonsFromBigfootReq(currentBm, [primaryGradePoint]).then((response) => {
		dispatch(
			setAppFiltersSuccess({
				...response.responseObject.reports[0],
				fields: response.responseObject.reports[0]?.fields.filter(
					({ Name }: { Name: string }) =>
						HIGH_MID_LOW_FILTERS_ENABLED ||
						Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
				),
				comparisons: response.responseObject.reports[0]?.comparisons?.filter(
					({ Name }: { Name: string }) =>
						HIGH_MID_LOW_COMPARISONS_ENABLED ||
						Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
				),
			})
		);
	});
};

/**
 * Fetch the Census Context State and calulate if we are within in window
 * @returns
 */
export const censusContextThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	const permissions = getUserRoles(state);
	const client = getClient(state);
	const hasGroupAccess = client?.hasGroupAccess;

	//for safety set is first call to true on context
	dispatch(setConfirmationOfRoleRequest(false));

	//Is the User an admin
	const isUserAdmin =
		permissions?.includes('Settings_Users') && permissions?.includes('Settings_Organisation');

	isUserAdmin &&
		!hasGroupAccess &&
		getCensusContext().then((response) => {
			//Check the status of the the current census
			const status = response.ResponseObject;
			//Now check the Db to see the state of the Current Census,
			//ie is someone else doing it or can the current user start it, what a treat!
			dispatch(setIsCensusInProgress(status.status));
			dispatch(
				setCensusCompletionUserInfo({
					userWhoCompleted: status.user,
					dateTimeOfCompletion: status.dateTime,
				})
			);
		});
};

export const getEmbargoPeriod = (): AppThunk => async (dispatch, getState) => {
	const state = getState();

	let withinKs4Embargo = false;
	let withinKs5Embargo = false;

	const permissions = getUserRoles(state);
	const hasKs4 = hasKS4Entitlement(state);
	const hasKs5 = hasKS5Entitlement(state);
	const groupAccess = hasGroupAccess(state);

	//Get Configs
	const ks4EmbargoPeriod = getConfig('early_access_ks4')(state);
	const ks5EmbargoPeriod = getConfig('early_access_ks5')(state);

	//Check to see if the are within the Embargo Period
	const localDate = moment.utc();

	const ks4Embargo =
		moment(localDate) > moment(ks4EmbargoPeriod.start) &&
		moment(localDate) < moment(ks4EmbargoPeriod.end);

	const ks5Embargo =
		moment(localDate) > moment(ks5EmbargoPeriod.start) &&
		moment(localDate) < moment(ks5EmbargoPeriod.end);

	const hasPrivilege = permissions?.includes('EarlyAccessPrivilege');

	const matImpersonation = getImpersonationMode(state) === 'MAT';

	if ((hasPrivilege === false && !matImpersonation) || (groupAccess && !matImpersonation)) {
		//TODO: Tidy up after KS4 Results day/Embargo period
		if ((hasKs4 && ks4Embargo) || (groupAccess && ks4Embargo)) {
			withinKs4Embargo = true;
		}
		if ((hasKs5 && ks5Embargo) || (groupAccess && ks5Embargo)) {
			withinKs5Embargo = true;
		}
	}

	dispatch(
		setEmbargoPeriods([
			{ keyStage: 'ks4', value: withinKs4Embargo },
			{ keyStage: 'ks5', value: withinKs5Embargo },
		])
	);
};

export const getAllComparisonsThunk = (): AppThunk => async (dispatch, getState) => {
	const state = getState();
	const gradepoints: Context.Gradepoint[] =
		state.context.reportContext.currentDataset?.gradepoints ?? [];
	const comparisons = getAllComparisons(state.context);

	const benchmark = getContextBenchmark(state);

	const HIGH_MID_LOW_FILTERS_ENABLED = getFeature('filters_high_mid_low')(state);
	const HIGH_MID_LOW_COMPARISONS_ENABLED = getFeature('comparisons_high_mid_low')(state);

	// dont try fetch if we've already loaded comparisons
	if (comparisons.length > 0) {
		return;
	}

	dispatch(setAppContextPending());

	fetchFiltersAndComparisonsFromBigfootReq(benchmark, gradepoints).then((response) => {
		dispatch(
			setAllComparisonsSuccess({
				...response.responseObject.reports[0],
				fields: response.responseObject.reports[0]?.fields.filter(
					({ Name }: { Name: string }) =>
						HIGH_MID_LOW_FILTERS_ENABLED ||
						Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
				),
				comparisons: response.responseObject.reports[0]?.comparisons?.filter(
					({ Name }: { Name: string }) =>
						HIGH_MID_LOW_COMPARISONS_ENABLED ||
						Name.toLowerCase() !== 'High/Middle/Low PA'.toLowerCase()
				),
			})
		);
	});
};

/**
 * Thunk that logs the user in, sets a shared cookie and then gets the app context
 * @param {Auth.Login} loginModel
 */
export const loginThunk = (loginModel: Auth.Login): AppThunk => async (dispatch) => {
	return login(loginModel)
		.then(() => {
			dispatch(fetchAppContext());
		})
		.then(() => {
			autoSelectClient();
		});
};

/**
 * Thunk that logs the user in use new mfa endpoint, sets a shared cookie and then gets the app context
 * @param {Auth.Login} loginModel
 */
export const loginMfaThunk = (loginModel: Auth.Login): AppThunk => async (dispatch) => {
	return loginMfa(loginModel).then((response) => {
		//trigger watched action
		dispatch(
			mfaLogin({
				isMfaActive: response.ResponseObject.isMfaActive,
				token: response.ResponseObject.mfaToken,
				mfaConfiguredTypes: response.ResponseObject.mfaActiveList,
			})
		);
	});
};

/**
 * Login and set the activate client link at the same time. Used during activation process to log an existing user in and activate the client.
 * @param {Auth.Login & Auth.Activation} loginAndActivateModel
 */
export const loginWithClientLinkThunk = (
	loginAndActivateModel: Auth.Login & Auth.Activation
): AppThunk => async (dispatch) => {
	return loginAndActivate(loginAndActivateModel)
		.then((response) => {
			if (response.HasError) {
				return Promise.reject(response.Errors[0].Message);
			} else {
				dispatch(fetchAppContext());
			}
		})
		.then(() => {
			autoSelectClient();
		});
};

export const switchDefaultBenchmark = (): AppThunk => async (dispatch) => {
	// loading?
	return switchActiveBenchmark().then((response) => {
		//guess work
		if (response.type === 2) {
			dispatch(setUseClientBenchmark(true));
		} else {
			dispatch(setUseClientBenchmark(false));
		}
		dispatch(updateCurrentBenchmark(response.benchmarkSet));
		dispatch(setChangeSessionBenchmark(response.benchamrkSet));
	});
};

/**
 * Thunk that logs user out. The cookie will be cleared and the context state will be reset.
 */
export const logoutThunk = (): AppThunk => async (dispatch) => {
	return logout()
		.then(() => dispatch(logoutSuccess()))
		.then(() => window.localStorage.removeItem('CensusDelay'))
		.then(() => location.replace('/auth/login'));
};

export const acceptTermsThunk = (): AppThunk => async (dispatch) => {
	return acceptTermsAndConditions().then(() => dispatch(termsAcceptedSuccess()));
};

/**
 * Function to remapped the relivent gps for mat view as school
 */

const matAppliedTrends = async (contextResponse: any) => {
	const appliedTrendId = window.sessionStorage.getItem('trendId');

	const impersonationView = contextResponse.user.impersonationMode;
	const gradepoints = contextResponse.reportContext.currentDataset.gradepoints;

	if (impersonationView === 'MAT') {
		await fetchMatAppliedGpKeys(Number(appliedTrendId)).then((response) => {
			const availableResponseGps = response
				.filter((value) => gradepoints.map((x) => x.key).includes(value.key))
				.sort((a, b) => b.ordinal - a.ordinal)
				.map((x) => x.key);

			if (availableResponseGps.length) {
				contextResponse.reportContext.currentDataset.appliedGradepoints = availableResponseGps;
				contextResponse.reportContext.currentDataset.primaryGradepoint = gradepoints.find(
					(y) => y.key === availableResponseGps[availableResponseGps.length - 1]
				);
			}
		});

		return contextResponse;
	} else {
		return contextResponse;
	}
};
