/**
 * @module useConnectSecondaryNavRoutes
 * @description Module containing hook for ALPS admin navigation routes
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { getFeature } from 'features/app/redux/features';
import { getUserRoles } from 'features/app/redux/context';

/** Base URL for admin routes */
const BASE_URL = '/admin';

/**
 * Hook that returns the navigation routes for ALPS admin users
 * @returns {Object} Navigation route configuration object
 * @property {SecondaryNavigationLinks[]} alpsAdminRoutes - Array of admin navigation links
 * @property {SecondaryNavigationLinks} homeRoutes - Home navigation configuration
 */
const useAlpsAdminRoutes = (): {
	alpsAdminRoutes: SecondaryNavigationLinks[];
	alpsAdminHomeRoutes: SecondaryNavigationLinks;
} => {
	/** Current user permissions */
	const permissions: string[] | undefined = useSelector(getUserRoles);

	/** Feature flag for reporting season functionality */
	const reportingSeasonFeatureFlag = useSelector(getFeature('reporting_season'));

	/** Feature flag for viewing contracts */
	const canViewContracts = useSelector(getFeature('show_contracts'));

	/** Feature flag for changing prior attainments */
	const canViewChangePriorAttainments = useSelector(getFeature('show_change_prior_attainments'));

	/** Array of admin navigation route configurations */
	const alpsAdminRoutes: SecondaryNavigationLinks[] = [
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/admin-search`,
			icon: 'user',
			displayName: translate('alpsAdmin.sidebar.links.ADMIN_SEARCH') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/details`,
			icon: 'circle-info',
			displayName: translate('alpsAdmin.sidebar.links.DETAILS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/all-users`,
			icon: 'users',
			displayName: translate('alpsAdmin.sidebar.links.ALL_USERS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/entitlements`,
			icon: 'user-tag',
			displayName: translate('alpsAdmin.sidebar.links.ENTITLEMENTS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/config-entitlements`,
			icon: 'user-pen',
			displayName: translate('alpsAdmin.sidebar.links.CONFIG') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/subscriptions`,
			icon: 'user-group',
			displayName: translate('alpsAdmin.sidebar.links.SUBSCRIPTIONS') as string,
		},
		{
			isVisible: true,
			withInternalUrl: `${BASE_URL}/view-as-client`,
			icon: 'eye',
			displayName: translate('alpsAdmin.sidebar.links.VIEW_AS_CLIENT') as string,
		},
		{
			isVisible: canViewContracts,
			withInternalUrl: `${BASE_URL}/contracts`,
			icon: 'file-contract',
			displayName: translate('alpsAdmin.sidebar.links.CONTRACTS') as string,
		},
		{
			isVisible: canViewChangePriorAttainments,
			withInternalUrl: `${BASE_URL}/patype-config`,
			icon: 'cog',
			displayName: translate('alpsAdmin.sidebar.links.PATYPE_CONFIG') as string,
		},
	];

	/** Home navigation route configuration */
	const alpsAdminHomeRoutes = {
		displayName: translate('home.singleSchool.alpsAdmin.HEADING') as string,
		icon: 'space-station-moon-construction',
		isVisible:
			permissions?.length === 0 ? false : permissions?.includes('Mountaineer') ? true : false,
		flyoutContent: [
			{
				heading: translate('home.singleSchool.alpsAdmin.HEADING') as string,
				flyoutLinks: [
					{
						displayName: translate('home.singleSchool.alpsAdmin.links.ALPS_ADMIN') as string,
						isVisible:
							permissions?.length === 0
								? false
								: permissions?.includes('Mountaineer')
								? true
								: false,
						withInternalUrl: `${BASE_URL}/admin-search`,
					},
					{
						displayName: translate('home.singleSchool.alpsAdmin.links.REPORTING') as string,
						isVisible:
							permissions?.length === 0
								? false
								: permissions?.includes('Mountaineer')
								? reportingSeasonFeatureFlag
								: false,
						withInternalUrl: '/reporting-season',
					},
				],
			},
		],
	};

	return {
		alpsAdminRoutes,
		alpsAdminHomeRoutes,
	};
};

export default useAlpsAdminRoutes;
