// Import necessary React dependencies and custom hooks
import * as React from 'react';
import { FunctionComponent } from 'react';
import { useWindowSize } from '../utils/hooks';
import { Box } from 'basecamp';

// Define the component's props interface
type Props = {
	classNames?: string; // Optional CSS classes
	isMultiGrid?: boolean; // Flag for multi-grid layout
	hasFooter?: boolean; // Flag to indicate if footer is present
	isTabbedGrid?: boolean; // Flag for tabbed grid layout
	width?: string; // Optional width override
};

// Define the main wrapper component for AG Grid with fixed height calculations
const AgGridFixedHeightWrapper: FunctionComponent<Props> = ({
	isMultiGrid,
	isTabbedGrid,
	children,
	hasFooter,
	width,
	classNames,
}) => {
	// Get current window height using custom hook
	const { height } = useWindowSize();

	// Calculate different height scenarios based on layout requirements
	const heightWithFooter = height && height - 383; // Height adjustment when footer is present
	const heightWithoutFooter = height && height - 303; // Height adjustment without footer
	const sizeToFitToTabs = height && height - 358; // Height adjustment for tabbed layout

	// Determine final height based on component configuration
	const handleHeight = isTabbedGrid
		? sizeToFitToTabs
		: hasFooter
		? heightWithFooter
		: heightWithoutFooter;

	// Render the Box component with calculated dimensions and styling
	return (
		<Box
			// Apply appropriate class names based on grid type
			className={
				isMultiGrid ? `ag-theme-alps is-multi-grid ${classNames}` : `ag-theme-alps  ${classNames}`
			}
			height={handleHeight}
			// Use provided width or default to responsive calculation
			width={width ? width : 'calc(100% - 30px)'}
			maxWidth="calc(100% - 30px)"
		>
			{children}
		</Box>
	);
};

export default AgGridFixedHeightWrapper;
