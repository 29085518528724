import React, { Fragment, FunctionComponent } from 'react';

import EndStrategicSessionModal from 'features/strategicAnalysis/endStrategicSessionModal';
import { getModalStatus } from 'features/studentModal/redux';
import { useSelector } from 'react-redux';
import { getLoadingStatus } from 'features/studentModal/redux';
import {
	shouldShowStrategicPdfReportModal,
	shouldShowStrategicSessionModal,
} from 'features/strategicAnalysis/redux';
import StrategicPdfReportModal from 'features/strategicAnalysis/strategicPdfReportModal';
import { StudentModal } from 'features/studentModal';

/**
 * Component that handles rendering of various modals in the standard page layout
 *
 * @component
 * @returns {JSX.Element} Fragment containing conditionally rendered modals
 */
const RenderModals: FunctionComponent = () => {
	const showStrategicModal = useSelector(shouldShowStrategicSessionModal);
	const showStrategicPdfModal = useSelector(shouldShowStrategicPdfReportModal);
	const showStudentModal = useSelector(getModalStatus);
	const studentInfoIsLoading = useSelector(getLoadingStatus);

	return (
		<Fragment>
			{/* End active strategic analysis modal */}
			{showStrategicModal && <EndStrategicSessionModal />}
			{/* Show modal when user clicks on the strategic analysis create report button */}
			{showStrategicPdfModal && <StrategicPdfReportModal />}
			{/* Student Modal */}
			{!studentInfoIsLoading && showStudentModal && <StudentModal />}
		</Fragment>
	);
};

export default RenderModals;
