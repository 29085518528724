/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { useSelector } from 'react-redux';
import { getFeature } from 'features/app/redux/features';
import { getClientCountry } from 'features/app/redux/context';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useStudentsNavLinks = () => {
	const canViewKs4PerfMeasures = useSelector(getFeature('ks4_perf_measures'));
	const clientCountry = useSelector(getClientCountry);
	const isClientWelshSchool = clientCountry === 1;
	const canViewP8Measures = useSelector(getFeature('ks4_progress_8_spo')) && !isClientWelshSchool;

	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const studentsNavLinks = [
		{
			name: 'studentsOverview',
			width: 110,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-students-overview-link`}
					withInternalUrl={`/students/overview`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.spo.OVERVIEW')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'studentsAttainmentMeasures',
			width: 110,
			isVisible: canViewKs4PerfMeasures,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-students-attainment-measures-link`}
					withInternalUrl={`/students/performance-measures`}
					disabled={!canViewKs4PerfMeasures}
				>
					{translate('primaryNavigation.connect.spo.KS4_ATTAINMENT_MEASURES')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'studentsProgress8Measures',
			width: 110,
			isVisible: canViewP8Measures,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-students-progress8-link`}
					withInternalUrl={`/students/progress8`}
					disabled={!canViewP8Measures}
				>
					{translate('primaryNavigation.connect.spo.KS4_P8_MEASURES')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses studentsNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes
	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(studentsNavLinks, width, 0, []);

	return { largeScreenMenu, smallScreenMenu };
};

export default useStudentsNavLinks;
