import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SingleSchoolKpiSummary } from 'features/groups/analysis/redux/types/singleSchoolKpiSummary';
import { CommonAgGridChartStateTypes } from 'types/commonAgGridTypes';

const initialState: SingleSchoolKpiSummary.State = {
	loading: false,
	initaialised: false,
	exportExcel: false,
	error: undefined,
	summaryKpis: undefined,
	rawAttainmentSummaryPercentageKpis: undefined,
	componentLoading: {
		summaryTile: false,
		rawAttainmentTile: false,
		summaryProfileGrid: false,
		averagePointsScoreGraph: false,
		facilitatingSubjects: false,
		best3SummaryProfileGrid: false,
		best3GradeDistributionProfileGrid: false,
		rawAttainmentGraph: false,
	},
	componentErrors: {
		summaryTile: '',
		rawAttainmentTile: '',
		summaryProfileGrid: '',
		averagePointsScoreGraph: '',
		facilitatingSubjects: '',
		best3SummaryProfileGrid: '',
		best3GradeDistributionProfileGrid: '',
		rawAttainmentGraph: '',
	},
	ks4HasBeenChecked: false,

	graphComparisons: undefined,
	grids: undefined,
	rows: undefined,
	comparisons: undefined,
	graphLoading: true,
	graphs: undefined,
	xAxis: undefined,
	graphError: undefined,
};

const singleSchoolKpiSummary = createSlice({
	name: 'matSingleSchoolPerformanceMeasures',
	initialState,
	reducers: {
		setLoading(state: SingleSchoolKpiSummary.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},

		setSingleSchoolKpiSummarySuccess(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<SingleSchoolKpiSummary.Tile[]>
		) {
			return {
				...state,
				summaryKpis: action.payload,
				loading: false,
				error: undefined,
			};
		},
		setRawAttainmentPercentageSuccess(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<SingleSchoolKpiSummary.Tile[]>
		) {
			return {
				...state,
				rawAttainmentSummaryPercentageKpis: action.payload,
				loading: false,
				error: undefined,
			};
		},
		handleExcelExport(state: SingleSchoolKpiSummary.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		setError(state: SingleSchoolKpiSummary.State, action: PayloadAction<string | undefined>) {
			return {
				...state,
				error: action.payload,
			};
		},
		setComponentLoading(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<{
				component: keyof SingleSchoolKpiSummary.ComponentLoading;
				value: boolean;
			}>
		) {
			const { component, value } = action.payload;
			return {
				...state,
				componentLoading: {
					...state.componentLoading,
					[component]: value,
				},
			};
		},
		setComponentErrors(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<{
				component: keyof SingleSchoolKpiSummary.ComponentErrors;
				value: string | undefined;
			}>
		) {
			const { component, value } = action.payload;
			return {
				...state,
				componentErrors: {
					...state.componentErrors,
					[component]: value,
				},
			};
		},
		setKs4HasBeenChecked(state: SingleSchoolKpiSummary.State, action: PayloadAction<boolean>) {
			return {
				...state,
				ks4HasBeenChecked: action.payload,
			};
		},
		setMainGridDataSuccess(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<
				CommonAgGridChartStateTypes.GridData<CommonAgGridChartStateTypes.ResponseRow>
			>
		) {
			const { grids, rows } = action.payload;
			// If this is the first fetch just set the data
			if (!state.grids) {
				return {
					...state,
					grids,
					rows,
				};
			}
			// If this is a subsequent fetch update the data

			return {
				...state,
				grids: {
					...state.grids,
					id: grids.id,
					byId: {
						...state.grids?.byId,
						...grids.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.grids!.allIds, ...grids.allIds],
				},
				rows: {
					...state.rows,
					id: rows.id,
					byId: {
						...state.rows!.byId,
						...rows.byId,
					},
					//@ts-ignore bloody spread error again
					allIds: [...state.rows!.allIds, ...rows.allIds],
				},
			};
		},
		setMainGraphDataSuccess(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<
				CommonAgGridChartStateTypes.GraphData<CommonAgGridChartStateTypes.ResponseXAxisValues>
			>
		) {
			const { examLevels, graphs, xAxis } = action.payload;

			// If this is the first fetch just set the data
			if (!state.graphs) {
				return {
					...state,
					graphs,
					xAxis,
				};
			}
			// If this is a subsequent fetch update the data
			return {
				...state,
				graphError: undefined,
				graphs: {
					...state.graphs,
					id: graphs.id,
					byId: {
						...state.graphs?.byId,
						...graphs.byId,
					},
					allIds: [...state.graphs!.allIds, ...graphs.allIds],
				},
				xAxis: {
					...state.xAxis,
					id: xAxis.id,
					byId: {
						...state.xAxis!.byId,
						...xAxis.byId,
					},
					allIds: [...state.xAxis!.allIds, ...xAxis.allIds],
				},
			};
		},
		resetSingleSchoolPerformanceMeasures(state: SingleSchoolKpiSummary.State) {
			return initialState;
		},
		setInitialised(state: SingleSchoolKpiSummary.State, action: PayloadAction<boolean>) {
			return {
				...state,
				initaialised: action.payload,
			};
		},
		setComparisons(
			state: SingleSchoolKpiSummary.State,
			action: PayloadAction<CommonUiTypes.SelectOption[] | undefined>
		) {
			return {
				...state,
				comparisons: action.payload,
			};
		},
	},
});

export const handleOverallFetchingSingleSchoolPerformanceFlow = createAction(
	'summitPerformanceMeasures/handleOverallFetchingSingleSchoolPerformanceFlow'
);

export const getSummaryKpiTileFlow = createAction(
	'summitPerformanceMeasures/getSchoolOverviewDataFlow'
);

export const getAllSingleSchoolPerformanceMeasuresDataFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getAllSingleSchoolPerformanceMeasuresDataFlow'
);

export const getSummaryProfileGridFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getSummaryProfileGridFlow'
);

export const getRawAttainmentGraphFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getRawAttainmentGraphFlow'
);

export const getAveragePointsScoreGraphFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getAveragePointsScoreGraphFlow'
);

export const getFacilitatingSubjectsFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getFacilitatingSubjectsFlow'
);

export const getBest3SummaryProfileGridFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getBest3SummaryProfileGridFlow'
);

export const getBest3GradeDistributionProfileGridFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getBest3GradeDistributionProfileGridFlow'
);

export const getRawAttainmentTileFlow = createAction(
	'summitPerformanceMeasuresSingleSchool/getRawAttainmentTileFlow'
);

export const retriggerSchoolAndDataFetch = createAction(
	'summitPerformanceMeasuresSingleSchool/retriggerSchoolAndDataFetchFlow'
);

/**
 ** Export Reducers
 */
export default singleSchoolKpiSummary.reducer;

/**
 ** Export Actions
 */
export const {
	setInitialised,
	setLoading,
	setSingleSchoolKpiSummarySuccess,
	setRawAttainmentPercentageSuccess,
	handleExcelExport,
	setError,
	setComponentLoading,
	setMainGridDataSuccess,
	setMainGraphDataSuccess,
	resetSingleSchoolPerformanceMeasures,
	setComponentErrors,
	setKs4HasBeenChecked,
	setComparisons,
} = singleSchoolKpiSummary.actions;
