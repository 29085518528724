/**
 * @fileoverview Primary navigation component that handles both desktop and mobile navigation
 * @module features/primaryNavigation/primaryNavigationNew
 */
import React, { FunctionComponent, useState } from 'react';
import { PrimaryNavigation, ResponsiveMenu } from 'basecamp';
import { useConnectPrimaryNavLinks, useSummitPrimaryNavLinks } from '../../routes/hooks';

/**
 * @typedef {Object} Props Empty props object as component doesn't accept any props
 */
type Props = {
	isGroup: boolean;
};

/**
 * Primary navigation component that displays navigation links and handles responsive behavior
 * @component
 * @param {Props} props - Component props (empty object)
 * @returns {JSX.Element} The rendered navigation component with responsive menu
 */
const AppPrimaryNavigation: FunctionComponent<Props> = ({ isGroup }) => {
	/**
	 * State to manage the small screen menu
	 */
	const [smallScreenMenuIsOpen, setSmallScreenMenuIsOpen] = useState<boolean>(false);

	/**
	 * Get menu configurations for different screen sizes
	 * @type {{largeScreenMenu: JSX.Element[], smallScreenMenu: JSX.Element[]}}
	 */
	const connectNavigation = useConnectPrimaryNavLinks();
	const summitNavigation = useSummitPrimaryNavLinks();

	/**
	 * Memoized values for large and small screen menus based on whether viewing Summit or Connect
	 * @type {{largeScreenMenu: JSX.Element[], smallScreenMenu: JSX.Element[]}}
	 */
	const largeScreenMenu = React.useMemo(
		() => (isGroup ? summitNavigation.largeScreenMenu : connectNavigation.largeScreenMenu),
		[isGroup, summitNavigation.largeScreenMenu, connectNavigation.largeScreenMenu]
	);

	const smallScreenMenu = React.useMemo(
		() => (isGroup ? summitNavigation.smallScreenMenu : connectNavigation.smallScreenMenu),
		[isGroup, summitNavigation.smallScreenMenu, connectNavigation.smallScreenMenu]
	);

	return (
		<PrimaryNavigation width={1} zIndex={9001}>
			<ResponsiveMenu
				largeScreenMenu={largeScreenMenu}
				smallScreenMenu={smallScreenMenu}
				setSmallScreenMenuIsOpen={(isOpen: boolean) => setSmallScreenMenuIsOpen(isOpen)}
				smallScreenMenuIsOpen={smallScreenMenuIsOpen}
			/>
		</PrimaryNavigation>
	);
};

/**
 * Memoized version of PrimaryNav component to prevent unnecessary re-renders
 */
export default AppPrimaryNavigation;
