import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { getIsGroup } from 'features/app/redux/context';
import { getGpSelectorState } from '../redux/standardPage';
import { TrendSelector } from 'features/gradepointSelector';
import { GradepointSelector } from 'features/gradepointSelector';

/**
 * A component that conditionally renders either a GradepointSelector or TrendSelector
 * based on whether the current view is for a group and if the selector is open.
 *
 * @returns {JSX.Element|null} Returns GradepointSelector for individual view,
 * TrendSelector for group view, or null if selector is closed
 */
const RenderGradepointTrendSelector: FunctionComponent = () => {
	const isGroup = useSelector(getIsGroup);
	const gpSelectorIsOpen = useSelector(getGpSelectorState);

	/**
	 * If the current view is for an individual and the selector is open,
	 * render the GradepointSelector component.
	 */
	if (!isGroup && gpSelectorIsOpen) {
		return <GradepointSelector />;
	}

	/**
	 * If the current view is for a group and the selector is open,
	 * render the TrendSelector component.
	 */
	if (isGroup && gpSelectorIsOpen) {
		return <TrendSelector />;
	}

	/**
	 * If the selector is not open, return null.
	 */
	return null;
};

export default RenderGradepointTrendSelector;
