/**
 * @module useGroupSetupRoutes
 * @description Module containing hook for managing group setup navigation routes
 */
import { useSelector } from 'react-redux';
import { translate } from '../../../utils/locale';
import { getPermissions } from 'features/app/redux/context';
import { knowledgeBaseRoutes } from '../../common';

/** Base URL for group setup routes */
const BASE_URL = '/groups/setup';

/**
 * Hook that returns the navigation routes for group setup
 * @returns {SecondaryNavigationLinks[]} Array of navigation route configurations
 */
const useGroupSetupRoutes = (): SecondaryNavigationLinks[] => {
	/**
	 * Get monitoring permissions from Redux state
	 * @type {boolean}
	 */
	const monitoring = useSelector(getPermissions);
	console.log('monitoring', monitoring);

	/**
	 * Array of navigation route configurations
	 * Contains monitoring setup, analysis setup and knowledge base sections
	 * Each section has a display name, icon and flyout menu with links
	 * @type {SecondaryNavigationLinks[]}
	 */
	return [
		{
			displayName: translate('groups.sidebar.headings.MONITORING_SETUP') as string,
			icon: 'chart-line',
			isVisible: true,
			disabled: false,
			flyoutContent: [
				{
					heading: translate('groups.sidebar.headings.MONITORING_SETUP') as string,
					flyoutLinks: [
						{
							isVisible: monitoring.groupsMonitoringTimeline,
							withInternalUrl: `${BASE_URL}/monitoring-timeline`,
							displayName: translate('groups.sidebar.links.MONITORING_TIMELINE') as string,
						},
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/custom-columns`,
							displayName: translate('groups.sidebar.links.CUSTOM_COLUMNS') as string,
						},
					],
				},
			],
		},
		{
			displayName: translate('groups.sidebar.headings.ANALYSIS_SETUP') as string,
			icon: 'chart-area',
			isVisible: true,
			disabled: false,
			flyoutContent: [
				{
					heading: translate('groups.sidebar.headings.ANALYSIS_SETUP') as string,
					flyoutLinks: [
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/school-groups`,
							displayName: translate('groups.sidebar.links.SCHOOL_GROUPS') as string,
						},
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/custom-comparisons`,
							displayName: translate('groups.sidebar.links.CUSTOM_COMPARISONS') as string,
						},
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/trends`,
							displayName: translate('groups.sidebar.links.TRENDS') as string,
						},
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/my-schools`,
							displayName: translate('groups.sidebar.links.MY_SCHOOLS') as string,
						},
						{
							isVisible: true,
							withInternalUrl: `${BASE_URL}/rollover`,
							displayName: translate('groups.sidebar.links.ROLLOVER') as string,
						},
					],
				},
				knowledgeBaseRoutes,
			],
		},
	];
};

export default useGroupSetupRoutes;
