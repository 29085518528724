import * as React from 'react';
import { FunctionComponent } from 'react';

import { Flex } from 'basecamp';

type Props = {
	dataTest?: string;
	sidebarContent: any;
};

const PageWithSidebarNew: FunctionComponent<Props> = ({ dataTest, sidebarContent, children }) => {
	/**
	 ** Render Component
	 */
	return (
		<Flex withAlign="distributeTop" width={1} bg="UI.white" dataTest={dataTest} pb={4}>
			{/* Sidebar */}
			<Flex
				position="relative"
				zIndex={1040}
				width={[130, 130, 200]}
				mx={4}
				mb={4}
				dataTest={`${dataTest} sidebar`}
				boxSizing="border-box"
			>
				{sidebarContent}
			</Flex>
			{/* Content */}
			<Flex
				setAs="column"
				width={1}
				position="relative"
				dataTest={`${dataTest} content`}
				boxSizing="border-box"
			>
				{children}
			</Flex>
		</Flex>
	);
};

export default PageWithSidebarNew;
