/**
 * Rows selectors
 */
import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';
import { getSlice } from '../common/selectors';

/**
 * Gets all the loaded row data
 * @param state redux state
 * @returns An object byId / allIds for the loaded row data
 */
export const getAllRowData = (page: string) => (
	state: RootState
): CommonAgGridChartStateTypes.Rows<CommonAgGridChartStateTypes.GridRow> | undefined => {
	const slice = getSlice(page)(state);
	return slice.rows;
};

/**
 * Gets a single row id for the active exam level
 * @param page the active page
 * @param activeExamLevel active page exam level
 * @returns string array
 */
export const getRowIdsByExamLevel = (
	page: string,
	activeExamLevel: CommonFeatureTypes.ExamLevels
) => (state: RootState): string[] => {
	const data = getAllRowData(page)(state);

	return data
		? Object.values(data.byId)
				.filter((row) => row.examLevelId === activeExamLevel)
				.map((row) => row.rowId)
		: [];
};
