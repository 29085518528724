import { getSlice } from '../../sharedSelectors/common/selectors';
import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';

/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllGraphComparisonData = (page: string) => (
	state: RootState
):
	| CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ComparisonXAxis>
	| undefined => {
	const slice = getSlice(page)(state);

	return slice.graphComparisons;
};

/**
 * Gets the individual page level applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedPageComparisons = (page: string) => (
	state: RootState
): CommonFeatureTypes.ComparisonOption => {
	//¯\_(ツ)_/¯
	//@ts-ignore
	const appliedPageComparisons = state[`${page}`].appliedComparisons;

	return appliedPageComparisons ?? [];
};
