import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Best3CumulativePercentage } from 'features/groups/analysis/redux/types/best3CumulativePercentage';

/**
 * Initial State
 */

const initialState: Best3CumulativePercentage.State = {
	loading: false,
	exportExcel: false,
	examLevels: undefined,
	grids: undefined,
	rows: undefined,
	error: undefined,
};

/**
 * Reducers
 */
const best3CumulativePercentage = createSlice({
	name: 'best3CumulativePercentage',
	initialState,
	reducers: {
		setLoading(state: Best3CumulativePercentage.State, action: PayloadAction<boolean>) {
			return {
				...state,
				loading: action.payload,
			};
		},
		setGridDataSuccess(
			state: Best3CumulativePercentage.State,
			action: PayloadAction<Best3CumulativePercentage.GridData>
		) {
			const { examLevels, grids, rows } = action.payload;
			return {
				...state,
				examLevels,
				grids,
				rows,
				loading: false,
				error: undefined,
			};
		},
		handleExcelExport(state: Best3CumulativePercentage.State, action: PayloadAction<boolean>) {
			return {
				...state,
				exportExcel: action.payload,
			};
		},
		setError(state: Best3CumulativePercentage.State, action: PayloadAction<string | undefined>) {
			return {
				...state,
				error: action.payload,
			};
		},
		resetBest3CumulativePercentageState(state: Best3CumulativePercentage.State) {
			return initialState;
		},
	},
});

/**
 ** Export Actions
 */
export const {
	setLoading,
	setGridDataSuccess,
	handleExcelExport,
	setError,
	resetBest3CumulativePercentageState,
} = best3CumulativePercentage.actions;

/**
 ** Export Reducers
 */
export default best3CumulativePercentage.reducer;
