import fetch from './';
import { formatReportRequest } from '../utils/reportingAPI';
import { saveAs } from 'file-saver';
import { sanitizeFilename } from '../utils/fileExports';
import { Report } from '../features/reports/types';
import { store } from '../store/store';
import { getAppliedStudentFilters, getAppliedSubjectFilters } from 'features/app/redux/context';

import {
	getAppliedSubjectComparisons,
	getAppliedStudentComparisons,
} from '../utils/selectors/comparisonSelectors';
import { SubjectPage } from 'features/subjectPage/types';
import { getMATViewAs } from '../utils/impersonation';
import { StrategicAnalysis } from 'features/strategicAnalysis/types';

const oldExportUri = '/rpt/req';
const newExportUri = '/export/req';

const getFiltersAndComparisonsForArea = (area: ReportArea) => {
	const state = store.getState();

	switch (area) {
		case 'Subject':
			return {
				appliedFilters: getAppliedSubjectFilters(state),
				appliedComparisons: getAppliedSubjectComparisons(state),
			};
		case 'Student':
			return {
				appliedFilters: getAppliedStudentFilters(state),
				appliedComparisons: getAppliedStudentComparisons(state),
			};
		case 'Overall':
		case 'None':
		default:
			return {
				appliedFilters: [],
				appliedComparisons: [],
			};
	}
};

export const fetchReport = (
	reportReference: string | string[],
	area: ReportArea,
	options: ReportAPIOptions = {},
	includeTargetSetting: boolean = false,
	includeTeachingSets: boolean = false,
	excelFormatting: boolean = false,
	useNewEndpoint: boolean = false,
	useNewExportAPI: boolean = false,
	usePtValues: boolean = false,
	alternativePaEnabled: boolean = false
) => {
	const { appliedFilters, appliedComparisons } = getFiltersAndComparisonsForArea(area);

	if (!useNewEndpoint) {
		return fetch(
			useNewExportAPI && alternativePaEnabled
				? newExportUri + '?altPa=true'
				: useNewExportAPI
				? newExportUri
				: oldExportUri,
			{
				abortId: reportReference,
				body: formatReportRequest(
					reportReference,
					appliedFilters,
					appliedComparisons,
					options,
					includeTargetSetting,
					includeTeachingSets,
					excelFormatting,
					usePtValues
				),
				...options,
			}
		);
	} else {
		// Does not need wrapping in new export API
		return fetch('/api/dataapi/pdfreports?filename=' + options.filename, {
			abortId: reportReference,
			body: formatReportRequest(
				reportReference,
				appliedFilters,
				appliedComparisons,
				options,
				includeTargetSetting,
				includeTeachingSets,
				excelFormatting,
				usePtValues
			),
			...options,
		});
	}
};

export const fetchXLXS = (
	reportReference: string,
	area: ReportArea,
	options: ReportAPIOptions = {},
	includeTargetSetting: boolean = false,
	includeTeachingSets: boolean = false,
	useNewExportAPI: boolean = false,
	excelFormatting: boolean = false,
	usePtValues: boolean = false,
	alternativePaEnabled: boolean = false
) => {
	return fetchReport(
		reportReference,
		area,
		{
			...options,
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
			},
			output: 'Excel',
		},
		includeTargetSetting,
		includeTeachingSets,
		excelFormatting,
		false, // This applies to pdfs only
		useNewExportAPI,
		usePtValues,
		alternativePaEnabled
	).then((blob: Blob) => saveAs(blob, sanitizeFilename(options.filename, 'xlsx')));
};

export const fetchPDF = (
	reportReference: string | string[],
	area: ReportArea,
	options: ReportAPIOptions = {},
	includeTargetSetting = true,
	usePDFRunner = false,
	useNewExportAPI: boolean = false,
	usePtValues: boolean = false
) => {
	const fetchReportResult = fetchReport(
		reportReference,
		area,
		{
			...options,
			headers: {
				Accept: '*/*',
				'Content-Type': 'application/pdf',
			},
			output: 'PDF',
		},
		includeTargetSetting,
		false, // include teaching sets, we go via a different route for that (needs to be refactored)
		false, // excel formatting, irrelevant for PDF request
		usePDFRunner,
		useNewExportAPI,
		usePtValues
	);

	if (!usePDFRunner) {
		// parse the PDF
		return fetchReportResult.then((blob: Blob) =>
			saveAs(blob, sanitizeFilename(options.filename, 'pdf'))
		);
	} else {
		// return the promise
		return fetchReportResult;
	}
};

/**
 * Get the all the personalised targets
 */
export const getPersonalisedTargets = (targetSettingGradepoints: any): Promise<Report.State> => {
	return fetchReport('TargetSettingReportAllPersonalised', 'None', {
		gradepoints: targetSettingGradepoints,
	});
};

/**
 * Get the subjects overview data
 */
export const getSubjectsOverview = (): Promise<{ reports: [Report.SubjectsOverview] }> => {
	return fetchReport('Interactive.SubjectsOverview', 'Subject');
};

type UDFKeyValue = { key: string; value: string };
type RequestedUDF = Array<UDFKeyValue>;

/**
 * Get the subject page data
 */
export const getSubjectPage = (subjectName: string, requestedUDF: RequestedUDF): Promise<any> => {
	return fetchReport(['Interactive.SubjectPage', 'Interactive.SubjectSummary'], 'Subject', {
		parameters: [
			...requestedUDF.map((udf: UDFKeyValue, _, arr: RequestedUDF) => {
				if (!arr.length) {
					return;
				}

				return {
					key: udf.key,
					value: udf.value,
				};
			}),
			{
				key: 'reqSubjectPageName',
				value: subjectName,
			},
		],
	});
};

export const fetchSubjectPagePdf = (
	subjectName: string,
	filename: string,
	includeTeachingSets: boolean,
	teachingSets: { [key: string]: SubjectPage.Group },
	params: any,
	usePDFRunner: boolean = false,
	isTeachingSetExport: boolean = false,
	useNewExportAPI: boolean = false,
	includeTargetSetting: boolean = true
) => {
	if (!includeTeachingSets) {
		fetchPDF(
			'InteractiveSubjectPage_PDF',
			'Subject',
			{
				filename,
				formatting: 'Connect/SubjectPages',
				parameters: [
					...params,
					{
						key: 'reqSubjectPageName',
						value: subjectName,
					},
				],
			},
			includeTargetSetting,
			usePDFRunner,
			useNewExportAPI
		);
	} else {
		const { appliedFilters, appliedComparisons } = getFiltersAndComparisonsForArea('Subject');
		const baseOptions = {
			parameters: [
				...params,
				{
					key: 'reqSubjectPageName',
					value: subjectName,
				},
			],
			formatting: 'Connect/SubjectPages',
			output: 'PDF' as const,
		};

		const main = formatReportRequest(
			'InteractiveSubjectPage_PDF',
			appliedFilters,
			appliedComparisons,
			baseOptions
		);

		const reports = Object.values(teachingSets).map((set) => {
			return formatReportRequest(
				'InteractiveSubjectPage_PDF',
				appliedFilters,
				appliedComparisons,
				{
					...baseOptions,
					parameters: [
						...baseOptions.parameters,
						{
							key: 'reqSubjectPageUDFValue',
							value: set.Name,
						},
					],
					filters: [
						{
							type: 'SubjectEntry',
							name: 'Teaching Set',
							value: set.Name,
						},
					],
				},
				undefined,
				isTeachingSetExport
			);
		});

		// if we're exporting a teaching set, skip the overall pages of the report
		const combined = {
			...main,
			reports: !isTeachingSetExport
				? [main.reports[0], ...reports.flatMap((x) => x.reports)]
				: reports.flatMap((x) => x.reports),
		};

		if (!usePDFRunner) {
			fetch(useNewExportAPI ? newExportUri : oldExportUri, {
				abortId: 'InteractiveSubjectPage_PDF',
				body: combined,
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/pdf',
				},
			}).then((blob: Blob) => saveAs(blob, sanitizeFilename(filename, 'pdf')));
		} else {
			fetch('/api/dataapi/pdfreports?filename=' + filename, {
				abortId: 'InteractiveSubjectPage_PDF',
				body: combined,
				headers: {
					Accept: '*/*',
					'Content-Type': 'application/pdf',
				},
				output: 'PDF',
			});
		}
	}
};

/**
 * Get the student modal information
 */
export const getStudentInformation = (id: number): Promise<any> => {
	return fetchReport(
		'InteractiveStudentTrendInformationReport',
		'None',
		{
			parameters: [{ key: 'reqStuInfoId', value: id }],
		},
		true
	);
};

/**
 * Report downloads
 */
export const fetchMyReports = (): Promise<{
	Reports: Report.Report[];
	AvailableYears: number[];
}> => {
	return fetch('/api/downloads/reports', { body: {} });
};

export const fetchReportData = (
	id: number
): Promise<{ Report: Report.Report; PreviousReports: Report.Report[] }> => {
	return fetch('/api/downloads/reports/viewreport', { body: id });
};

/*
 * Additional report downloads
 */
export const fetchAdditional = (): Promise<{
	EnquiryJobs: Report.Additional[];
	AvailableYears: number[];
}> => {
	return fetch('/api/downloads/additionaldownloads', { body: {} });
};

export const fetchAdditionalData = (
	id: number
): Promise<
	Report.Additional & { Attachments: Report.Attachment[]; Comments: Report.Comment[] }
> => {
	return fetch('/api/downloads/additionaldownloads/viewjob', { body: id });
};

/**
 * Get user defined fine grades settings
 * e.g + stretch, = secure, - atRisk
 */
export const getFineGradesSettings = () => {
	const { matImpersonateClientId } = getMATViewAs();
	return fetch('/api/dataapi/ClientSettingsStorage', {
		headers: {
			viewAsClientId: matImpersonateClientId,
		},
	});
};

//School and College reports  - mats (rename)
export const getMatGroupReport = (): Promise<MAT.Years> => {
	return fetch('/api/Groups/Children', {}).then((response) => {
		return Object.keys(response).reduce((obj: MAT.Years, key) => {
			const clientArr = response[key];
			const mapped = clientArr.map((x) => {
				return {
					id: x.Id,
					name: x.Name,
					contract: {
						academicYear: x.Contract.AcademicYear,
						bundles: x.Contract.Bundles,
						connectAccess: x.Contract.ConnectAccess,
						contractJobId: x.Contract.ContractJobId,
						dxDataSubmissionAcess: x.Contract.DXDataSubmissionAcess,
						dxReportAccess: x.Contract.DxReportAccess,
						effectiveStartData: x.Contract.EffectiveStartData,
						id: x.Contract.Id,
						isContract: x.Contract.IsContract,
						notes: x.Contract.Notes,
						relationshipId: x.Contract.RelationshipId,
						showInGroupReports: x.Contract.ShowInGroupReports,
						tags: x.Contract.Tags,
						startDate: x.Contract.contractStartDate,
						endDate: x.Contract.contractEndDate,
					},
					contractAccess: x.ContractAccess
						? {
								allowed: x.ContractAccess.Allowed,
								auditId: x.ContractAccess.AuditId,
								contractId: x.ContractAccess.ContractId,
								id: x.ContractAccess.Id,
						  }
						: null,
				};
			}, {});
			return {
				...obj,
				[key]: mapped,
			};
		}, {});
	});
};

//individual school report - mats (rename)
export const getIndividualMatSchoolReport = (id: number): Promise<any> => {
	return fetch(`/api/Groups/Children/${id}`).then((response) => {
		let lowerCaseObject = {};

		Object.keys(response.Reports).map((key) => {
			lowerCaseObject[key] = response.Reports[key].map((report: any) => {
				return {
					reportId: report.ReportId,
					reportName: report.ReportName,
				};
			});
		});

		return {
			name: response.Name,
			reports: lowerCaseObject,
		};
	});
};

/**
 * Fetch the strategic analysis pdf report
 * @param pdfReport the pdf report config
 */
export const fetchStrategicPdfReport = (pdfReport: StrategicAnalysis.PdfReportRequest) => {
	return fetch('/api/dataapi/pdfreports', {
		method: 'POST',
		body: pdfReport,
	});
};
