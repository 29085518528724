import { routes } from '../routes';
import { theme } from 'basecamp';
import { useEffect, useState } from 'react';

/**
 * Get all routes as flat array
 */
export const allRoutes: Routes.Route[] = routes.flatMap((route) => {
	if (route.routes) {
		return [...route.routes, route];
	}

	return route;
});

/**
 ** Get the current static menu items
 * This is used to build the navigation UI
 */
export const getMenuItems = (
	matchRoute: Routes.Route | undefined,
	params: PrimaryNavigation.RouteParams,
	handleTrim: PrimaryNavigation.Trim,
	idToName?: string
): string[] => {
	return [
		matchRoute?.baseName,
		...Object.entries(params).reduce((acc: any[], [key, val]: [string, any]) => {
			// We don't want to show the udf type or userId in the nav, only the value so skip
			if (['udftype', 'userId', 'id'].includes(key)) {
				return acc;
			}

			/**
			 * Handle conversion of id's
			 * E.g groupReportsId to report name or subjectId to subject name in Summit
			 */
			if (['groupReportsId', 'subjectId'].includes(key)) {
				return [...acc, idToName];
			}

			// Handle trimming of the subject name if the navWidth > winWidth
			if (key === 'subject' && handleTrim.shouldTrim) {
				const getSubject = decodeURIComponent(val).split(' - ')[1];
				const trimmedSubject = getSubject.replace(handleTrim.trimLength, '$1');

				return [...acc, trimmedSubject];
			}

			// Handle trimming of the udf if the navWidth > winWidth
			if (key === 'udfvalue' && handleTrim.shouldTrim) {
				return [...acc, decodeURIComponent(val).replace(/^(.{8}[^\s]*).*/, '$1')];
			}

			// Handle param vals if the navWidth < winWidth
			if (['subject', 'udfvalue', 'tutorGroup'].includes(key)) {
				return [...acc, decodeURIComponent(val)];
			}

			if (key === 'groupKs5Pms') {
				return val === 'undefined' ? [...acc, ''] : [...acc, decodeURIComponent(val)];
			}

			return [...acc, matchRoute?.displayName];
		}, []),
	];
};

/**
 ** Get the relevant dropdown menus for each section of the menu
 * Dropdowns listed on the route are in the main route config
 */
export const getDropdownMenus = (
	matchRoute: Routes.Route | undefined,
	params: PrimaryNavigation.RouteParams,
	dropdowns: { [key in PrimaryNavigation.ParamKeys]?: PrimaryNavigation.Route[] }
) =>
	matchRoute?.dropdownMenus?.map((menuType): PrimaryNavigation.Route[] => {
		// If the menu type is udftype then we need to format the string
		const menu =
			menuType === 'udftype'
				? (params[menuType].toLowerCase().replace(' ', '') as PrimaryNavigation.Params)
				: (menuType as PrimaryNavigation.Params);

		return dropdowns[menu] as PrimaryNavigation.Route[];
	});

/**
 ** Gets the link for the navigation back functionality
 * Navigate back currently works for the first 2 elements of the current route
 * Consider the following where we have a udf applied
 * Subjects / A Maths / TS0001 / Overview
 * 1. Clicking the TS or the current subject page (Overview) does nothing,
 * 2. Clicking the subject will remove the udf and navigate back to Subjects / A Maths / Overview.
 * 3. Clicking Subjects will navigate back to the Subjects Overview page
 * @param i he index of the clicked link
 * @returns an updated path that is passed to the Link component
 */
export const getBackLink = (i: number, url: string, hasGroupAccess?: boolean): string => {
	const level = hasGroupAccess ? 3 : 2;

	const r = url
		.split('/')
		// Hacky way of filtering out the udftype
		.filter((el) => !el.toLowerCase().includes('teach'))
		.slice(0, i + level)
		.join('/');

	return i > 1 || i < 0 ? '' : r;
};

/**
 ** Gets the link for each item in the dropdown menus
 * Changes the current route to the desired route but will retain parts such as subject, udf or page
 * E.g if we have a route such as:
 * Subjects / A - Mathematics / TS001 / Students
 * and we want to change just the udf the output will be (retains the subject and page):
 * Subjects / A - Mathematics / TS002 / Students
 * but if we change the subject the output will be (retains the page)
 * Subjects / A - English Language / Students
 * @param i the index of the open menu
 * @param url the current url
 * @param params the current route params
 * @param route the part of the route we want to navigate to
 * @returns an updated path that is passed to the Link component
 */
export const getLink = (
	i: number,
	cancelUdf: boolean,
	url: string,
	params: any,
	route: any,
	isGroup: boolean | undefined
): string => {
	// Get the individual paths
	const paths = url.split('/').filter(Boolean);
	/**
	 * If a udf is applied and the user clicks a subject we must cancel the udf
	 * otherwise we retain the udf value
	 */
	const filteredPaths =
		cancelUdf && paths.length === 5 ? [...paths.slice(0, 2), paths[paths.length - 1]] : paths;
	// Settings, connect and summit all have different root level menus
	const getRoot = isGroup
		? 'groupsRoot'
		: filteredPaths[0].includes('manage')
		? 'settingsRoot'
		: 'root';
	/**
	 * Again, if a udf is applied and the user clicks a subject we must cancel the udf
	 * otherwise we retain the udf value
	 */
	const filteredParams = cancelUdf
		? Object.fromEntries(
				Object.entries(params).filter(([key, _]) => key !== 'udftype' && key !== 'udfvalue')
		  )
		: params;
	const allParams = Object.assign({ root: getRoot }, filteredParams);

	// Build the routes shown in the dropdowns
	const r = Object.entries(allParams).reduce((acc, [key, val], index) => {
		return key === 'udftype'
			? acc + `/${val}`
			: key === route.type
			? acc + route.path
			: acc + `/${filteredPaths[index]}`;
	}, ``);

	return i > 0 && getRoot === 'root' ? r : route.path;
};

/**
 ** Grab colours from the theme
 */
const {
	colors: {
		UI: { accent, black, white },
	},
} = theme;

/**
 ** Link colouring
 */
export const handleLinksColours = (i: number, getParamKeys: string[]): string => {
	return i === 0 ? black[0] : i === getParamKeys.length ? white[0] : accent[8 - i];
};

/**
 ** Nav height
 */
export const primaryNavHeight = 48;

/**
 ** Handles the visibility of the slash in the static route
 */
export const shouldShowSlash = (i: number, getParamKeys: string[]): boolean => {
	return getParamKeys.length > 0 && i < getParamKeys.length && !getParamKeys[i].includes('id');
};

/**
 ** Handles the visibility of the dropdown menus
 */
export const shouldShowDropdown = (
	i: number,
	showMenu: number | null,
	dropdownMenus: PrimaryNavigation.Route[][] | undefined
): boolean | undefined => {
	return showMenu === i && dropdownMenus && dropdownMenus[i] && dropdownMenus[i].length >= 1;
};

/**
 ** Determines if we should trim the nav text on smaller screens
 * Take the char max length and the subsequent non-space chars ensure whole words are retained
 */
const trimlength = (winWidth: number) => {
	if (winWidth < 1024) {
		return /^(.{10}[^\s]*).*/;
	} else if (winWidth < 1200) {
		return /^(.{15}[^\s]*).*/;
	} else if (winWidth < 1400) {
		return /^(.{20}[^\s]*).*/;
	} else if (winWidth < 1600) {
		return /^(.{25}[^\s]*).*/;
	} else if (winWidth < 1800) {
		return /^(.{30}[^\s]*).*/;
	} else {
		return /^(.{40}[^\s]*).*/;
	}
};

export const handleNavTrim = (
	primaryNav: React.MutableRefObject<HTMLDivElement | null>
): PrimaryNavigation.Trim => {
	const [shouldTrimNavText, setShouldTrimNavText] = useState<PrimaryNavigation.Trim>({
		shouldTrim: false,
		trimLength: trimlength(window.innerWidth),
	});

	useEffect(() => {
		let timeoutId: any = null;

		// Handler to call on window resize
		const handleTrim = () => {
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId);

			// Set boolean to state
			timeoutId = setTimeout(() => {
				if (primaryNav && primaryNav.current) {
					// Add the padding from the parent el to the nav width
					const comparisonWidth = primaryNav.current.clientWidth + 110;

					return setShouldTrimNavText({
						shouldTrim: comparisonWidth > window.innerWidth,
						trimLength: trimlength(window.innerWidth),
					});
				}
			}, 500);
		};

		// Add event listener
		window.addEventListener('resize', handleTrim);

		// Call handler right away so state gets updated with initial window size
		handleTrim();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleTrim);
	}, []);

	return shouldTrimNavText;
};
