import { debounce } from 'lodash';
import {
	useEffect,
	useState,
	useRef,
	RefObject,
	useCallback,
	useLayoutEffect,
	createRef,
} from 'react';

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState<{
		width: number | undefined;
		height: number | undefined;
	}>({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		let timeoutId: any = null;

		// Handler to call on window resize
		function handleResize() {
			clearTimeout(timeoutId);
			// Set window width/height to state
			timeoutId = setTimeout(() => {
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}, 500);
		}

		// Add event listener
		window.addEventListener('resize', handleResize);

		// Call handler right away so state gets updated with initial window size
		handleResize();

		// Remove event listener on cleanup
		return () => window.removeEventListener('resize', handleResize);
	}, []); // Empty array ensures that effect is only run on mount

	return windowSize;
}

/**
 * Hook that manages responsive menu components based on screen width
 *
 * @param {Object[]} menuComponents - Array of menu component objects
 * @param {string} menuComponents[].name - Name identifier for the component
 * @param {number} menuComponents[].width - Width of the component in pixels
 * @param {boolean} menuComponents[].isVisible - Whether the component should be visible
 * @param {number} menuComponents[].menuTransferOrder - The order in which the component should be displayed in the menu
 * @param {React.ReactNode} menuComponents[].component - The React component to render
 * @param {number|undefined} windowWidth - Current window width
 * @param {number} [pinnedElements=0] - Width of any pinned elements that should be accounted for
 * @param {any[]} [recalcOn=[]] - Array of dependencies that should trigger menu recalculation
 * @returns {ResponsiveMenu} Object containing arrays of components for large and small screen menus
 */
export function useResponsiveMenu(
	menuComponents: {
		name: string;
		width: number;
		isVisible: boolean;
		menuTransferOrder: number;
		component: React.ReactNode;
	}[],
	windowWidth: number | undefined,
	pinnedElements: number = 0,
	recalcOn: Array<any> = []
): ResponsiveMenu {
	// State to track menu components split between large and small screen layouts
	const [menuLists, setMenuLists] = useState<ResponsiveMenu>({
		largeScreenMenu: [], // Components that fit in the main menu
		smallScreenMenu: [], // Components that overflow into dropdown
	});

	useLayoutEffect(() => {
		// Initial width accounts for pinned elements + 95px for core UI elements
		let componentsTotalWidth = pinnedElements ? pinnedElements + 95 : 95;

		// Only process menu components that should be visible
		const visibleMenuComponents = menuComponents.filter(({ isVisible }) => isVisible);

		// Sort by menuTransferOrder to ensure transfer order is respected
		const sortedByTransferOrder = [...visibleMenuComponents].sort(
			(a, b) => b.menuTransferOrder - a.menuTransferOrder
		);

		// Distribute components between large and small screen menus based on available width
		const menuItems = sortedByTransferOrder.reduce(
			(acc: ResponsiveMenu, { width, component }) => {
				// Add component width + 24px margin if width is provided
				if (!!width) {
					componentsTotalWidth += width + 24;
				}

				// If total width fits in window, add to large screen menu
				// Otherwise add to small screen overflow menu
				if (windowWidth && componentsTotalWidth < windowWidth) {
					return { ...acc, largeScreenMenu: [...acc.largeScreenMenu, component] };
				} else {
					return { ...acc, smallScreenMenu: [...acc.smallScreenMenu, component] };
				}
			},
			{
				largeScreenMenu: [],
				smallScreenMenu: [],
			}
		);

		// Restore original order for large screen menu
		menuItems.largeScreenMenu = visibleMenuComponents
			.filter(({ component }) => menuItems.largeScreenMenu.includes(component))
			.map(({ component }) => component);

		setMenuLists(menuItems);
	}, [windowWidth, pinnedElements, ...recalcOn]); // Recalculate when window size, pinned elements, or other deps change

	return menuLists;
}

export function useFilterBar(
	// List of elements in the filter bar
	filterBarElements: [string, number][],
	// Window width
	windowWidth: number | undefined,
	/**
	 * Width for any pinned elements, in addition to the more actions button
	 * Example - adhoc controls in Connect or apply button in Summit
	 */
	pinnedElements: number = 0,
	/**
	 * Anything that should trigger a recalculation of the menu state
	 * Example - hiding and showing a menu button such as "show in one table" on the subject overview
	 */
	recalcOn: Array<any> = []
): FilterBar {
	/**
	 * The menu state
	 * One for the filter bar, one for the more actions dropdown
	 */
	const [filterBarMoreActionsLists, setFilterBarMoreActionsLists] = useState<FilterBar>({
		filterBar: [],
		moreActions: [],
	});

	useLayoutEffect(() => {
		/**
		 * Initial value for components total width
		 * Starts at 85 because of the "more actions" button plus any other pinned elements
		 */
		let componentsTotalWidth = pinnedElements ? pinnedElements + 95 : 95;

		/**
		 * Work out how the filter bar / more actions menu should be populated
		 * This is based a running a total of the items in the filter bar vs the windows width
		 * Will return two lists - the filter bar / the more actions dropdown
		 */
		const filterBarMoreActionsItems = filterBarElements.reduce(
			(acc: FilterBar, [key, val]) => {
				/**
				 * Update the running total of the components width
				 * val = width of component
				 * 24 = components margin
				 * 48 = filter bars total padding
				 */
				if (!!val) {
					componentsTotalWidth = componentsTotalWidth += val + 24;
				}

				/**
				 * If the components total width is less than the windows width
				 * Populate the filter bar
				 */
				if (windowWidth && componentsTotalWidth < windowWidth) {
					return { ...acc, filterBar: [...acc.filterBar, key as string] };
				} else {
					/**
					 * Else populate the more actions dropdown
					 */
					return { ...acc, moreActions: [...acc.moreActions, key as string] };
				}
			},
			{
				filterBar: [],
				moreActions: [],
			}
		);
		// Set state with the result if
		setFilterBarMoreActionsLists(filterBarMoreActionsItems);
	}, [windowWidth, pinnedElements, ...recalcOn]);

	// Return the calculated result
	return filterBarMoreActionsLists;
}

export function usePrevious<T>(value: T): T | undefined {
	const ref = useRef<T>();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export function useOutsideClick(ref: RefObject<HTMLDivElement>, func: any) {
	useEffect(() => {
		/**
		 * Call func if clicked on outside of element
		 */
		const handleClickOutside = (event: any): void => {
			if (ref.current && !ref.current.contains(event.target)) {
				return func();
			}
		};
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [ref]);
}

export const useDebounceCallback = (callback, delay) => {
	const callbackRef = useRef();
	callbackRef.current = callback;
	return useCallback(
		debounce((...args) => callbackRef.current(...args), delay),
		[]
	);
};

/**
 *
 * @param originalCollection mapped type object keyed on strings
 * @param keySelector function to return key from object
 * @returns [local copy of collection, func to set local copy, func to set individual item, func to delete individual item]
 */
export function useLocalCopy<ObjectType>(
	originalCollection: { [key: string]: ObjectType },
	keySelector: (object: ObjectType) => string | undefined
) {
	const [localCollection, setLocalCollection] = useState<{ [key: string]: ObjectType }>(
		originalCollection
	);

	const setLocalItem = (item: ObjectType) => {
		var key = keySelector(item);
		if (!key) return; //todo: throw error, or should we pass in key creator function?

		setLocalCollection((stateValue) => {
			return {
				...stateValue,
				[key as string]: item,
			};
		});
	};

	const deleteLocalItem = (itemKey: string) => {
		const initial: { [key: string]: ObjectType } = {};
		setLocalCollection((stateValue) => {
			return Object.entries(stateValue)
				.filter(([key]) => key !== itemKey)
				.reduce((previous, [key, value]) => {
					return {
						...previous,
						[key]: value,
					};
				}, initial);
		});
	};

	return [localCollection, setLocalCollection, setLocalItem, deleteLocalItem] as const;
}

/**
 * Dynamic Refs
 */
export function useDynamicRefs<T>(): [
	(key: number) => React.RefObject<T>,
	(key: number) => React.RefObject<T>,
	() => void
] {
	const map = new Map<number, React.RefObject<unknown>>();

	function clearRefs(): void {
		return map.clear();
	}

	function setRef<T>(key: number): React.RefObject<T> {
		const ref = createRef<T>();
		map.set(key, ref);
		return ref;
	}

	function getRef<T>(key: number): React.RefObject<T> {
		return map.get(key) as React.RefObject<T>;
	}

	return [getRef, setRef, clearRefs];
}
