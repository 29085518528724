/**
 * @module ZohoHelpBtn
 * @description A button component that opens the Zoho help documentation in a new tab
 */

import * as React from 'react';
import { FunctionComponent } from 'react';
import { HeaderLink } from 'basecamp';
import { useSelector } from 'react-redux';
import { getConfig } from 'features/app/redux/features';
import { translate } from '../../../../utils/locale';
import { styles } from './common';

/**
 * @typedef {Object} Props
 */
type Props = { isSmallScreen: boolean };

/**
 * ZohoHelpBtn component
 * @component
 * @returns {JSX.Element|null} Returns the help button or null if user doesn't have access
 */
const ZohoHelpBtn: FunctionComponent<Props> = ({ isSmallScreen }) => {
	/**
	 * Get the Zoho help link from config
	 * @type {string}
	 */
	const zohoLink = useSelector(getConfig('zoho_link'));

	/**
	 * Opens Zoho help documentation in new tab
	 * @function
	 * @returns {void}
	 */
	const handleZohoHelp = () => {
		window.open(`${zohoLink}/en/kb/alps-education`, '_blank');
	};

	return (
		<HeaderLink
			buttonText={translate('supportHub.MAIN_TEXT')}
			icon="book"
			onClick={() => handleZohoHelp()}
			{...styles(isSmallScreen)}
		/>
	);
};

export default ZohoHelpBtn;
