import { cancel, retry, put, select, call } from 'redux-saga/effects';
import { sagaFlowConfigurations } from '../../../../../constants';
import { getCumulativePercentagesPerformanceMeasuresGraphData } from '../../../../../api/groupsAPI';
import { SagaIterator } from 'redux-saga';
import { CommonAgGridChartStateTypes } from '../../../../../types/commonAgGridTypes';
import {
	setGraphError,
	setGraphLoading,
	setMainGraphDataSuccess,
} from 'features/groups/analysis/redux/ks5PerformanceMeasures/cumulativePercentage/slice';
import { getAllRowData } from '../../../../../sharedSelectors';
import { getClient, getGroupAccessId } from 'features/app/redux/context';
import { translate } from '../../../../../utils/locale';
import { getGroupAccessRecords } from 'features/groups/setup/redux/selectors';
import { getIsKs4 } from '../ks5PerformanceMeasures/selectors';
import { setIsKs4 } from '../ks5PerformanceMeasures/slice';
import { getConnectPage } from '../../../../../utils/sagaHelpers';
/**
 * Get the cumulative percentages performance measures graph data
 * @returns {SagaIterator<void>}
 */
export function* getCumulativePercentagesPerformanceMeasuresGraphDataFlow(): SagaIterator<void> {
	//get the area of connet
	const page = yield call(getConnectPage);
	//if the page is not the connect page, cancel the saga
	if (page !== 'group-overview') {
		//bail
		yield cancel();
	}

	const client = yield select(getClient);

	//if the client is undefined, cancel the saga
	if (client === undefined) {
		//bail
		yield cancel();
	}

	//check to see if the data is already in the store
	const initialData = yield select(getAllRowData('cumulativePercentage'));

	//Check to see if the group access id is in the store
	const groupAccessId = yield select(getGroupAccessId) ?? 0;

	//get the group access records
	const accessRecords = yield select(getGroupAccessRecords);

	//if the access records are undefined or empty, cancel the saga
	if (accessRecords === undefined || accessRecords.length === 0) {
		//bail
		yield cancel();
	}

	//check to see if the group access id is in a KS4 one
	const isKs4 = accessRecords?.find((x) => x.id === groupAccessId).examLevel === 32;

	//check to see if the KS4 has been checked
	const ks4HasBeenChecked = yield select(getIsKs4);

	//if the group access id is in a KS4 one, then check to see if the KS4 has been checked
	if (isKs4) {
		if (!ks4HasBeenChecked) {
			yield put(setIsKs4(true));
		}
		yield put(setGraphLoading(false));
		yield cancel();
	}

	//set the KS4 has been checked to false
	yield put(setIsKs4(false));

	//if the group access id is 0, cancel the saga
	if (groupAccessId == 0) {
		//Set the graph loading state to false
		yield put(setGraphLoading(false));

		//Log the error
		yield put(setGraphError(translate('groups.ks5Pm.common.NO_ACCESS_ID') as string));

		//Bail out of the saga
		yield cancel();
	}

	//Set the graph loading state to true
	yield put(setGraphLoading(true));

	//reset the error state
	yield put(setGraphError(undefined));

	//if no data then gop get it
	if (initialData === undefined) {
		const cumulativePercentagesPerformanceMeasuresGraphData: ResponseBuilder<CommonAgGridChartStateTypes.GridData<
			any
		>> = yield retry(
			sagaFlowConfigurations.apiRetry,
			sagaFlowConfigurations.milliSeconds,
			getCumulativePercentagesPerformanceMeasuresGraphData,
			groupAccessId
		);

		//If there is no data from the api, cancel the saga
		if (
			!cumulativePercentagesPerformanceMeasuresGraphData?.responseObject ||
			cumulativePercentagesPerformanceMeasuresGraphData?.hasError
		) {
			//Set the error state
			yield put(setGraphError(translate('groups.ks5Pm.common.MESSAGE') as string));

			//Set the loading state to false
			yield put(setGraphLoading(false));

			//Bail out of the saga
			yield cancel();
		}

		//Put the data into redux
		yield put(
			setMainGraphDataSuccess(cumulativePercentagesPerformanceMeasuresGraphData.responseObject)
		);
	}

	//Set the graph loading state to false
	yield put(setGraphLoading(false));

	//Bail out of the saga
	yield cancel();
}
