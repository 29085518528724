import { translate } from '../../utils/locale';

export const knowledgeBaseRoutes = {
	heading: translate('home.knowledgeBase.HEADING') as string,
	flyoutLinks: [
		{
			displayName: translate('home.knowledgeBase.links.KNOWLEDGE_BASE') as string,
			isVisible: true,
			isDisabled: false,
			withExternalUrl: translate('home.knowledgeBase.links.KNOWLEDGE_BASE_LINK') as string,
			andTarget: '_blank',
		},
	],
};
