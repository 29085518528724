/**
 * @fileoverview Hook that provides primary navigation links configuration and responsive menu handling
 * @module features/primaryNavigation/routes/usePrimaryNavLinks
 */
import React from 'react';
import { PrimaryNavLink } from 'basecamp';
import { useResponsiveMenu, useWindowSize } from '../../../utils/hooks';
import { translate } from '../../../utils/locale';
import { useSelector } from 'react-redux';
import { hasActiveAnalysisSession } from 'features/strategicAnalysis/redux/selectors';

/**
 * Custom hook that manages primary navigation links and responsive menu behaviour
 * @returns {Object} Object containing large and small screen menu configurations
 * @returns {Array} largeScreenMenu - Array of menu items for large screen display
 * @returns {Array} smallScreenMenu - Array of menu items for small screen display
 */
const useStrategicAnalysisNavLinks = () => {
	const hasActiveSession = useSelector(hasActiveAnalysisSession);

	/**
	 * Configuration array for all primary navigation links
	 * Each object contains name, width, visibility, menu order and component details
	 */
	const strategicAnalysisNavLinks = [
		{
			name: 'strategicAnalysisSummary',
			width: 70,
			isVisible: !hasActiveSession,
			menuTransferOrder: 11,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-summary-link`}
					withInternalUrl={`/strategic-analysis/Summary`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.SUMMARY')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisOverview',
			width: 70,
			isVisible: true,
			menuTransferOrder: 10,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-overview-link`}
					withInternalUrl={`/strategic-analysis/Overview`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.OVERVIEW')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisAttainmentProfile',
			width: 140,
			isVisible: true,
			menuTransferOrder: 4,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-attainment-profile-link`}
					withInternalUrl={`/strategic-analysis/AttainmentProfile`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.ATTAINMENT_PROFILE')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisQualityIndicator',
			width: 140,
			isVisible: true,
			menuTransferOrder: 6,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-quality-indicator-link`}
					withInternalUrl={`/strategic-analysis/QualityIndicator`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.QUALITY_INDICATOR')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisSubjectValueAdded',
			width: 150,
			isVisible: true,
			menuTransferOrder: 2,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-subject-value-added-link`}
					withInternalUrl={`/strategic-analysis/SubjectValueAdded`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.SUBJECT_VALUE_ADDED')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisBbpaMultiYear',
			width: 140,
			isVisible: true,
			menuTransferOrder: 8,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-bbpa-multi-year-link`}
					withInternalUrl={`/strategic-analysis/BbpaMultiYear`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.BBPA_MULTI_YEAR')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisBbpaCurrentYear',
			width: 140,
			isVisible: true,
			menuTransferOrder: 9,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-bbpa-current-year-link`}
					withInternalUrl={`/strategic-analysis/BbpaCurrentYear`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.BBPA_CURRENT_YEAR')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisTScore',
			width: 70,
			isVisible: true,
			menuTransferOrder: 1,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-t-score-link`}
					withInternalUrl={`/strategic-analysis/Tscore`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.T_SCORE')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisQualityOfTeaching',
			width: 140,
			isVisible: true,
			menuTransferOrder: 5,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-quality-of-teaching-link`}
					withInternalUrl={`/strategic-analysis/QualityOfTeaching`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.QUALITY_OF_TEACHING')}
				</PrimaryNavLink>
			),
		},
		{
			name: 'strategicAnalysisStudentsNotIncluded',
			width: 150,
			isVisible: true,
			menuTransferOrder: 3,
			component: (
				<PrimaryNavLink
					setAs="secondary"
					data-test={`primary-nav-strategic-analysis-students-not-included-link`}
					withInternalUrl={`/strategic-analysis/StudentsNotIncluded`}
					disabled={false}
				>
					{translate('primaryNavigation.connect.strategicAnalysis.STUDENTS_NOT_INCLUDED')}
				</PrimaryNavLink>
			),
		},
	];

	/**
	 * Get the current window width to determine responsive menu breakpoints
	 * @type {number} width - Current window width in pixels
	 */
	const { width } = useWindowSize();

	// Generate responsive menus based on screen size and dependencies
	// Uses strategicAnalysisNavLinks array to create large and small screen menu variants
	// Dependencies array ensures menu updates when relevant data changes

	const { largeScreenMenu, smallScreenMenu } = useResponsiveMenu(
		strategicAnalysisNavLinks,
		width,
		0,
		[]
	);

	return { largeScreenMenu, smallScreenMenu };
};

export default useStrategicAnalysisNavLinks;
