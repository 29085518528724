/**
 * Errors selectors
 */

import { getSlice } from '../common/selectors';

/**
 * Gets error messages from state for the active page
 * @param page the active analysis page
 * @param state Redux state
 * @returns string
 */
export const getErrors = (page: string, examLevel: CommonFeatureTypes.ExamLevels) => (
	state: RootState
): string | undefined => {
	const slice = getSlice(page)(state);
	const errors = slice.error;

	return errors?.[examLevel];
};
