import { getSlice } from '../common/selectors';
import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';
import { getAppliedPageComparisons, getAllGraphComparisonData } from './comparisons';

/**
 * Gets all the loaded x axis data
 * @param state redux state
 * @returns An object byId / allIds for the loaded row data
 */
export const getAllXAxisData = (page: string) => (
	state: RootState
): CommonAgGridChartStateTypes.Rows<CommonAgGridChartStateTypes.XAxis> | undefined => {
	const slice = getSlice(page)(state);
	return slice.xAxis;
};

/* Get the x axis data with comparisons added
 * @param rowIds row ids to map over
 * @returns top level (all) rows with comparisons rows
 */
export const getXAxisDataWithComparisons = (page: string, rowIds: string[]) => (
	state: RootState
): (CommonAgGridChartStateTypes.XAxis | CommonAgGridChartStateTypes.ComparisonXAxis)[] | null => {
	//ren
	const rows = getAllXAxisData(page)(state);
	const comparisons = getAllGraphComparisonData(page)(state);
	return !!rows
		? rowIds.flatMap((id) => {
				const comparisonIds = rows.byId[id].comparisonIds;
				return [
					rows.byId[id],
					...comparisonIds.map((compId) => {
						return comparisons!.byId[compId];
					}),
				];
		  })
		: null;
};

/**
 * Get the x axis data without comparisons added
 * @param xAxisIds row ids to map over
 * @returns top level (all) axis
 */
export const fetchAllIds = (page: any, xAxisIds: string[]) => (state: RootState): any[] | null => {
	const axis = getAllXAxisData(page)(state);

	return !!axis ? xAxisIds.flatMap((id) => axis.byId[id]) : null;
};

/**
 * Creates the graphs data for the main grids
 * @param xAxis row ids to map over
 * @returns Formatted row data, with or without comparisons
 */
export const createGraphData = (page: string, axisIds: string[] | undefined) => (
	state: RootState
): (CommonAgGridChartStateTypes.XAxis | CommonAgGridChartStateTypes.ComparisonXAxis)[] | null => {
	if (!axisIds) {
		return null;
	}

	const axis = getAllXAxisData(page)(state);
	const axisWithComparisons = getXAxisDataWithComparisons(page, axisIds)(state);

	const axisWithoutComparisons = fetchAllIds(page, axisIds)(state);
	const appliedComparisons = getAppliedPageComparisons(page)(state);
	const axisComparisonsIds = axis?.byId[axisIds[0]].comparisonIds;

	const hasComparisonsForRow =
		axisComparisonsIds &&
		axisComparisonsIds.some((id) => {
			const applied = appliedComparisons[0] ? appliedComparisons[0].value.split('|').join('-') : '';
			return id.includes(applied);
		});

	// If applied comparisons
	if (!!appliedComparisons && appliedComparisons.length > 0) {
		// Check if first grid row has the comparison ids populated, else return null
		return !!hasComparisonsForRow ? axisWithComparisons : null;
	}

	// Else if no applied comparisons just fetch the all row
	return axisWithoutComparisons;
};
