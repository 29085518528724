/**
 * @fileoverview Component for rendering the top navigation bar links in the standard page layout
 * @module features/layouts/standardPage/components/renderTopbarLinks
 */

import React, { FunctionComponent, useState } from 'react';
import { Flex, ResponsiveMenu } from 'basecamp';
import { useTopBarMenuComponents } from '../../../../routes/hooks';

/**
 * Props for the RenderTopbarLinks component
 * @typedef {Object} Props
 * @property {Groups.Setup.Trend | undefined} selectedTrend - Currently selected trend
 * @property {() => void} handleRtmMessaging - Handler for RTM messaging toggle
 * @property {boolean} isGroup - Whether the current context is a group
 */
type Props = {
	selectedTrend: Groups.Setup.Trend | undefined;
	handleRtmMessaging: () => void;
	isGroup: boolean;
};

/**
 * Component that renders the top navigation bar links
 * @param {Props} props - Component props
 * @returns {JSX.Element} Rendered component
 */
const RenderTopbarLinks: FunctionComponent<Props> = ({
	selectedTrend,
	handleRtmMessaging,
	isGroup,
}) => {
	/**
	 * State to manage the small screen menu
	 */
	const [smallScreenMenuIsOpen, setSmallScreenMenuIsOpen] = useState<boolean>(false);

	const { largeScreenMenu, smallScreenMenu } = useTopBarMenuComponents({
		selectedTrend,
		handleRtmMessaging,
		isGroup,
		setSmallScreenMenuIsOpen,
	});

	return (
		<Flex setAs="row" withAlign="centerRight" width={1} mr={4}>
			<ResponsiveMenu
				largeScreenMenu={largeScreenMenu}
				smallScreenMenu={smallScreenMenu}
				setSmallScreenMenuIsOpen={setSmallScreenMenuIsOpen}
				smallScreenMenuIsOpen={smallScreenMenuIsOpen}
			/>
		</Flex>
	);
};

export default RenderTopbarLinks;
