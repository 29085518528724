/**
 * Comparison selectors
 */

import { CommonAgGridChartStateTypes } from '../../types/commonAgGridTypes';
import { getSlice } from '../common/selectors';
import { appliedAndSelectedAreEqual } from '../../utils/agGridUtils/agGridComparisonsHelpers';

/**
 * Gets the default report level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedDefaultComparisons = (state: RootState) => {
	return state.strategicAnalysis.selectedDefaultComparisons;
};

/**
 * Gets all the loaded comparison data
 * @param state redux state
 * @returns An object byId / allIds for the loaded comparison data
 */
export const getAllComparisonData = (page: string) => (
	state: RootState
):
	| CommonAgGridChartStateTypes.Comparisons<CommonAgGridChartStateTypes.ComparisonRow>
	| undefined => {
	const slice = getSlice(page)(state);

	return slice.comparisons;
};

/**
 * Gets the individual page level applied comparisons
 * @param state Redux state
 * @returns Array of applied comparisons
 */
export const getAppliedPageComparisonsOrDefault = (page: string) => (
	state: RootState
): CommonFeatureTypes.ComparisonOption => {
	const appliedPageComparisons = getSlice(page)(state);

	return appliedPageComparisons.appliedComparisons ?? [];
};

/**
 * Gets and array of all the loaded comparison ids
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIdsOrDefault = (page: string) => (state: RootState): string[] => {
	const data = getAllComparisonData(page)(state);
	return data ? data.allIds : [];
};
/**
 * Get the row ids for the loaded comparisons, arse way round of checking as the comparison id is currently a guid and has no meaning
 * @param state redux state
 * @returns An array of row ids thats correspond to the loaded comparisons. Shows us where the comparison belongs in the grid row order.
 */
export const getRowIdsForLoadedComparisonsOrDefault = (page: string) => (
	state: RootState
): string[] => {
	const comparisons = getAllComparisonData(page)(state);
	const ids = comparisons && comparisons.allIds;
	const rowIds = comparisons && ids!.map((id) => comparisons!.byId[id].rowId);

	return rowIds ? rowIds : [];
};

/**
 * Gets and array of all the loaded comparison ids for a given page
 * @param state redux state
 * @returns An array of all the loaded comparison ids
 */
export const getAllComparisonIdsForActivePageOrDefault = (page: string) => (
	state: RootState
): string[] => {
	const slice = getSlice(page)(state);
	const data = slice.comparisons;

	return data ? data.allIds : [];
};

/**
 * Gets the individual page level selected comparisons
 * @param state Redux state
 * @returns Array of selected comparisons
 */
export const getSelectedPageComparisonsOrDefault = (page: string) => (
	state: RootState
): CommonFeatureTypes.ComparisonOption => {
	const selectedPageComparisons = getSlice(page)(state);
	return selectedPageComparisons.selectedComparisons ?? [];
};

/**
 * Check if we have comparisons applied
 * @param page Analysis page
 * @returns boolean
 */
export const hasAppliedPageComparisons = (page: string) => (state: RootState): boolean => {
	const appliedPageComparisons = getAppliedPageComparisonsOrDefault(page)(state);

	return appliedPageComparisons.length > 0;
};

/**
 * Determine if the comparisons have changed
 * @param page analysis page
 * @returns boolean
 */
export const checkComparisonStatesAreEqual = (page: string) => (state: RootState): boolean => {
	const appliedComparisons = getAppliedPageComparisonsOrDefault(page)(state);
	const selectedComparisons = getSelectedPageComparisonsOrDefault(page)(state);

	return appliedAndSelectedAreEqual<{ label: string; value: string }>(
		appliedComparisons,
		selectedComparisons
	);
};
