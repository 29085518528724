/**
 * @fileoverview Component that displays a warning button indicating whether client or national benchmarks are active
 * and provides navigation to benchmark settings based on user permissions.
 * @module ClientBenchmarkActiveWarning
 */

import * as React from 'react';
import { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { HeaderLink } from 'basecamp';
import { translate } from '../../../../utils/locale';
import { useSelector } from 'react-redux';
import {
	getIsGroup,
	getPermissions,
	getUseClientBenchmark,
	getUserRoles,
} from 'features/app/redux/context';
import { styles } from './common';

/**
 * Props for the ClientBenchmarkActiveWarning component.
 * @typedef {Object} Props
 */
type Props = { isSmallScreen: boolean; setSmallScreenMenuIsOpen: (isOpen: boolean) => void };

/**
 * Component that displays a warning button for benchmark status.
 * Shows whether client or national benchmarks are currently active and allows navigation
 * to benchmark settings based on user permissions.
 *
 * @component
 * @example
 * return (
 *   <ClientBenchmarkActiveWarning />
 * )
 */
const ClientBenchmarkActiveWarning: FunctionComponent<Props> = ({
	isSmallScreen,
	setSmallScreenMenuIsOpen,
}) => {
	/**
	 * React Router history object for navigation
	 * @type {History}
	 */
	const history = useHistory();

	/**
	 * Whether the user is viewing group/MAT level data
	 * @type {boolean}
	 */
	const isGroup = useSelector(getIsGroup);

	/**
	 * Whether the user has permission to switch between client/national benchmarks
	 * @type {boolean}
	 */
	const canSwitchBenchmarks = useSelector(getPermissions)?.canSwitchClientBenchmark;

	/**
	 * Whether the user has admin role
	 * @type {boolean}
	 */
	const isAdmin = useSelector(getUserRoles)?.includes('Settings_Users', 0);

	/**
	 * Whether client benchmarks are currently active
	 * @type {boolean}
	 */
	const clientBenchmarkActive = useSelector(getUseClientBenchmark);

	/**
	 * Handles navigation to the appropriate benchmark settings page based on user permissions.
	 * - For MAT users, navigates to client benchmarks page
	 * - For users with switch permission, navigates to client benchmarks page
	 * - For admin users without switch permission, navigates to benchmark selector
	 * @function
	 */
	const pushToBenchmark = () => {
		if (isGroup) {
			return history.push('/analysis-settings/client-benchmarks');
		}
		if (canSwitchBenchmarks) {
			return history.push('/analysis-settings/client-benchmarks');
		}
		if (isAdmin && !canSwitchBenchmarks) {
			return history.push('/analysis-settings/benchmark-selector');
		}
	};

	return (
		<HeaderLink
			buttonText={translate('standardPage.benchmarkButton.CURRENT_BENCHMARK')}
			lozengeText={
				clientBenchmarkActive
					? translate('standardPage.warnings.CLIENT_BENCHMARK_ACTIVE')
					: translate('standardPage.warnings.NATION_BENCHMARK_ACTIVE')
			}
			lozengeIcon="circle-exclamation"
			disabled={isGroup ? false : isAdmin ? false : !canSwitchBenchmarks}
			onClick={() => {
				pushToBenchmark();
				setSmallScreenMenuIsOpen(false);
			}}
			helpText={translate('standardPage.benchmarkButton.HELP_TEXT')}
			{...styles(isSmallScreen)}
		/>
	);
};

export default ClientBenchmarkActiveWarning;
