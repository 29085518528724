import { takeLatest } from 'redux-saga/effects';
import { summitGradepointSelectorHandlerFlow } from 'features/groups/analysis/redux/sagas/summitGradepointSelectorHandlerFlow';
import { get3CumulativePerformanceMeasuresGridDataFlow } from 'features/groups/analysis/redux/sagas/get3CumulativePerformanceMeasuresGridDataFlow';
import { getHeadlinePerformanceMeasuresGridDataFlow } from 'features/groups/analysis/redux/sagas/getHeadlinePerformanceMeasuresGridDataFlow';
import { getCumulativePercentagesPerformanceMeasuresGraphDataFlow } from 'features/groups/analysis/redux/sagas/getCumulativePercentagesPerformanceMeasuresGraphDataFlow';
import { getCumulativePercentagesPerformanceMeasuresGridDataFlow } from 'features/groups/analysis/redux/sagas/getCumulativePercentagesPerformanceMeasuresGridDataFlow';
import { getHeadlinePerformanceMeasuresGraphDataFlow } from './getHeadlinePerformanceMeasuresGraphDataFlow';
import { resetSummitPerformanceMeasuresFlow } from './resetSummitPerformanceMeasuresFlow';

export const summitPerformanceMeasuresSaga = [
	//set up grade saga logic
	takeLatest('groupAnalysis/setAnalysisTrends', summitGradepointSelectorHandlerFlow),
	takeLatest('groupAnalysis/setGroupTrend', summitGradepointSelectorHandlerFlow),
	takeLatest(
		'summitPerformanceMeasures/summitGradepointSelectorHandlerFlow',
		summitGradepointSelectorHandlerFlow
	),

	// fetch datat for Summit Pms
	//TAB 1
	takeLatest(
		'summitPerformanceMeasures/getSummitInitialPerformanceMeasures',
		getHeadlinePerformanceMeasuresGridDataFlow
	),

	takeLatest(
		'summitPerformanceMeasures/getSummitInitialPerformanceMeasures',
		getHeadlinePerformanceMeasuresGraphDataFlow
	),

	//TAB 2
	takeLatest(
		'summitPerformanceMeasures/triggerSummitPmsFlow',
		getCumulativePercentagesPerformanceMeasuresGraphDataFlow
	),
	takeLatest(
		'summitPerformanceMeasures/triggerSummitPmsFlow',
		getCumulativePercentagesPerformanceMeasuresGridDataFlow
	),

	//Tab 3
	takeLatest(
		'summitPerformanceMeasures/triggerSummitPmsFlow',
		get3CumulativePerformanceMeasuresGridDataFlow
	),

	//Individual Retriggers
	//Tab 1 - graphs
	takeLatest(
		'summitPerformanceMeasures/getHeadlinePerformanceMeasuresGraphData',
		getHeadlinePerformanceMeasuresGraphDataFlow
	),
	//Tab 1 - grids
	takeLatest(
		'summitPerformanceMeasures/getHeadlinePerformanceMeasuresGridData',
		getHeadlinePerformanceMeasuresGridDataFlow
	),

	//Tab 2 - graphs
	takeLatest(
		'summitPerformanceMeasures/getCumulativePercentagesPerformanceMeasuresGraphData',
		getCumulativePercentagesPerformanceMeasuresGraphDataFlow
	),
	//Tab 2 - grids
	takeLatest(
		'summitPerformanceMeasures/getCumulativePercentagesPerformanceMeasuresGridData',
		getCumulativePercentagesPerformanceMeasuresGridDataFlow
	),

	//Tab 3 - grids
	takeLatest(
		'summitPerformanceMeasures/get3CumulativePerformanceMeasuresGridData',
		get3CumulativePerformanceMeasuresGridDataFlow
	),

	//Reset Summit Pms State
	takeLatest(
		'summitPerformanceMeasures/resetSummitPerformanceMeasuresState',
		resetSummitPerformanceMeasuresFlow
	),
];
